import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export const getMembers = {
  url: ({ organisationId, filters }) =>
    `/organisation/${organisationId}/members${constructQueryString(filters)}`,
  type: "get",
  transformResponse: handleTableDataTransform,
}

export const getMembersWithoutStats = {
  url: ({ organisationId, filters }) =>
    `/organisation/${organisationId}/members/no-stats${constructQueryString(filters)}`,
  type: "get",
  transformResponse: handleTableDataTransform,
}

export default [
  {
    target: "addMembers",
    payload: {
      url: ({ organisationId, imported }) =>
        `/organisation/${organisationId}/members${imported ? "?imported=true" : ""
        }`,
      type: "post",
      storeData: false,
      refreshPageData: true,
      transform: (data) => {
        return data
          .filter((member) => member.name && member.email)
          .map((member) => {
            if (member.groups && Array.isArray(member.groups)) {
              return {
                ...member,
                groups: member.groups
                  .filter((g) => g)
                  .map((group) => ({
                    name: group.name || group,
                  })),
              }
            }

            return member
          })
      },
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "checkForExistingMembers",
    payload: {
      type: "post",
      url: ({ organisationId }) =>
        `/organisation/${organisationId}/members/check`,
      storeData: false,
    },
  },
  {
    target: "getMembersWithoutStats",
    payload: getMembersWithoutStats
  },
  {
    target: "getMembers",
    payload: getMembers,
  },
  {
    target: "getUndeliverableMembers",
    payload: {
      url: ({ organisationId }) =>
        `/organisation/${organisationId}/members?member[or][undeliverable]=1&member[or][emailIssue]=1`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "attachMemberToGroup",
    payload: {
      url: ({ organisationId, filters }) =>
        `/organisation/${organisationId}/groups/members${constructQueryString(
          filters
        )}`,
      type: "post",
      storeData: false,
      // refreshPageData: true
    },
  },
  {
    target: "getMemberInvoices",
    payload: {
      url: ({ memberId, filters }) =>
        `/member/${memberId}/invoices${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getMemberDetails",
    payload: {
      url: ({ memberId }) => `/member/${memberId}`,
      type: "get",
      contextKey: "member",
    },
  },
  {
    target: "updateMemberDetails",
    payload: {
      url: ({ memberId }) => `/member/${memberId}/details`,
      type: "post",
      contextKey: "member",
    },
  },
  {
    target: "deleteMember",
    payload: {
      url: ({ memberId }) => `/member/${memberId}/delete`,
      type: "post",
    },
  },
  {
    target: "deleteMembers",
    payload: {
      url: ({ organisationId }) =>
        `/organisation/${organisationId}/members/delete`,
      type: "post",
      refreshPageData: true,
      storeData: false,
    },
  },
  {
    target: "sendMemberReminders",
    payload: {
      url: ({ memberId, resentInvoices }) =>
        `/member/${memberId}/remind?resentInvoices=${resentInvoices}`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "sendUpdateMembers",
    payload: {
      url: ({ organisationId }) =>
        `/organisation/${organisationId}/members/update`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getMembersInvoices",
    payload: {
      url: ({ organisationId, memberIds, statuses, deliveryStatus }) =>
        `/organisation/${organisationId}/members/invoices${constructQueryString(
          { memberId: memberIds, statuses, deliveryStatus }
        )}`,
      type: "get",
    },
  },
  {
    target: "getMemberStats",
    payload: {
      url: ({ memberId, organisationId }) =>
        `/member/${memberId}/stats?organisationId=${organisationId}`,
      type: "get",
    },
  },
  {
    target: "getMemberPayments",
    payload: {
      url: ({ memberId, filters, organisationId }) =>
        `/member/${memberId}/payments${constructQueryString(
          filters,
          `organisationId=${organisationId}`
        )}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
]
