import { returnOrganisationsTransform } from "../transformers"

export const deleteOnboardingConfirmation = (
  send,
  application,
  isAffiliate = false
) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "deleteOnboardingPayload",
      title: `Delete ${isAffiliate ? "application" : application.name}`,
      body: isAffiliate
        ? "You have already been approved by Payzip. Would you like to delete this application and start again?"
        : `${application.name} has already been approved by Payzip. Would you like to delete this club and create a new one?`,
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: isAffiliate ? "Delete application" : "Delete club",
          type: "danger",
          iconType: "duotone",
          icon: "exclamation-circle",
          colWidth: 7,
          submitting:
            "app.requests.deleteOrganisation.requesting|app.requests.deleteApplication.requesting",
          callback: () => {
            if (isAffiliate) {
              send("deleteApplication", {
                data: {
                  onSuccess: {
                    notification: {
                      title: `Application deleted`,
                    },
                    target: "CLOSE_MODAL",
                  },
                },
              })
            } else {
              send("deleteOrganisation", {
                data: {
                  variables: {
                    organisationId: application.id,
                  },
                  transformResponse: returnOrganisationsTransform,
                  onSuccess: {
                    notification: {
                      title: `${application.name} deleted`,
                    },
                    target: "CLOSE_MODAL",
                  },
                },
              })
            }
          },
        },
      ],
    },
  })
}

export const removeTeamMemberConfirmation = (
  send,
  organisation,
  row,
  isInvite = false
) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "removeManagerInvoice",
      title: "Remove manager",
      body: `Remove ${
        row.name || row.email
      } from your team? They will no longer have access to this account until you invite them again.`,
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Remove manager",
          type: "danger",
          iconType: "duotone",
          icon: "exclamation-circle",
          colWidth: 7,
          submitting: isInvite
            ? "app.requests.removeTeamInvite.requesting"
            : "app.requests.removeTeamMember.requesting",
          callback: () => {
            if (isInvite) {
              send("removeTeamInvite", {
                data: {
                  variables: {
                    organisationId: organisation.id,
                    inviteId: row.id,
                  },
                },
              })
            } else {
              send("removeTeamMember", {
                data: {
                  variables: {
                    organisationId: organisation.id,
                    teamMemberId: row.id,
                  },
                  onSuccess: {
                    notification: {
                      title: `${row.name} removed from team`,
                    },
                    target: "CLOSE_MODAL",
                  },
                },
              })
            }
          },
        },
      ],
    },
  })
}

export const resendTeamMemberInviteConfirmation = (send, organisation, row) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "resendTeamMemberInvite",
      title: "Resend invite",
      body: `Would you like to resend an invite to  ${row.name || row.email}`,
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_MODAL"),
        },
        {
          text: "Resend invite",
          type: "primary",
          colWidth: 7,
          icon: "paper-plane",
          submitting: "app.requests.resendTeamInvite.requesting",
          callback: () => {
            send("resendTeamInvite", {
              data: {
                variables: {
                  organisationId: organisation.id,
                  teamMemberId: row.id,
                },
              },
            })
          },
        },
      ],
    },
  })
}
