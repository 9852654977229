import React, { useEffect } from "react"

import MemberRow from "./MemberRow"
import { useFormValidator } from "../../Forms/Form"

export default ({ state, setState }) => {
  const [validator] = useFormValidator()
  const updateMember = ({ name, value }, index) => {
    const { members } = state
    members[index][name] = value

    setState({ members })
  }

  const removeRow = (index) => {
    const { members } = state
    members.splice(index, 1)
    setState({ members })
    validator.purgeFields()
  }

  const updateOptions = (options, index) => {
    updateMember(
      { name: "groups", value: options.filter((o) => o.checked) },
      index
    )
  }

  useEffect(() => {
    let { members } = state
    members = members || []
    const lastMember = members[members.length - 1]

    if (
      !lastMember ||
      lastMember.name ||
      lastMember.email ||
      lastMember.groups.length
    ) {
      members.push({ name: "", email: "", groups: [] })

      setState({ members })
    }
  }, [state, setState])

  if (state.members) {
    return (
      <div>
        <div className="grid grid-cols-add-members w-full gap-sm">
          <div className="block text-neutral-5 font-medium mb-xs">Name</div>
          <div className="block text-neutral-5 font-medium mb-xs">Email</div>
          <div className="block text-neutral-5 font-medium mb-xs">
            Group (optional)
          </div>
        </div>
        {state.members.map((member, index) => (
          <MemberRow
            key={"member" + index}
            index={index}
            member={member}
            removeRow={() => removeRow(index)}
            updateOptions={(mem) => updateOptions(mem, index)}
            updateMember={(mem) => updateMember(mem, index)}
            displayBin={index !== state.members.length - 1}
            lastRow={
              index !== state.members.length - 1 || state.members.length === 1
            }
            errors={{}}
          />
        ))}
      </div>
    )
  }

  return <React.Fragment />
}
