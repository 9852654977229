import React from "react"
import Input from "../Input"

export default function InviteManager({
  state: { manager },
  setState,
  onSubmit,
}) {
  const updateManager = ({ target: { name, value } }) => {
    setState({
      manager: {
        ...manager,
        [name]: value,
      },
    })
  }

  return (
    <div>
      <div className="mb-md">
        Enter the email address of the manager you'd like to invite to your
        team.
      </div>
      <Input
        large
        label="Email"
        name="email"
        value={manager.email}
        placeholder="Email"
        onChange={updateManager}
        validation={{
          messages: {
            required: "Enter an email",
            email: "Enter a valid email",
          },
          rules: "required|email",
        }}
      />
    </div>
  )
}
