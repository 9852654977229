import React from "react"

export default ({ text, children, className }) => {
  return (
    <div
      className={`min-h-2xl bg-primary-8 py-md text-center sm:px-md px-sm ${className}`}
    >
      {text || children}
    </div>
  )
}
