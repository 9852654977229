import React, { useContext, useCallback } from "react"

import { useEventListener } from "../../utils/hooks"
import Header from "./Header"
import Body from "./ModalBody"
import Footer from "./Footer"
import { MachineContext } from "../../state"
import Form from "../Forms/Form"

export default () => {
  const [parentMachine, send] = useContext(MachineContext)

  const current = parentMachine.children.confirmation.state
  const modal = current.context || {}

  const closeModal = (event) => {
    if (event.currentTarget === event.target) {
      send("CLOSE_CONFIRMATION")
    }
  }

  const checkButtonPress = useCallback(
    (event) => {
      if (modal.buttons && modal.buttons.length && current.matches("open")) {
        if (event.keyCode === 13) {
          const submitButton = modal.buttons.find(
            (button) => button.type === "primary" || button.type === "danger"
          )
          if (submitButton) {
            submitButton.callback()
          }
        }

        if (event.keyCode === 27) {
          const cancelButton = modal.buttons.find((button) => !button.type)
          if (cancelButton) {
            cancelButton.callback()
          }
        }
      }
    },
    [current, modal.buttons]
  )

  useEventListener("keydown", checkButtonPress)

  if (current.matches("open")) {
    return (
      <div onMouseDown={closeModal} className="modal-background z-60">
        <div
          onMouseDown={closeModal}
          className="w-full flex flex-col items-center justify-center"
        >
          <div className="relative sm:max-h-full sm:max-w-md sm:h-auto h-full w-full">
            {modal.component ? (
              modal.component
            ) : (
              <div
                className={`sm:max-w-md w-full rounded shadow-lg flex sm:h-auto h-full`}
              >
                <div className="w-full">
                  <Form className="h-full sm:block flex flex-col">
                    <Header title={modal.title} />

                    <Body body={modal.body} />

                    {modal.buttons.length > 0 && (
                      <Footer buttons={modal.buttons} />
                    )}
                  </Form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return <React.Fragment />
}
