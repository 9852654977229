import React, { useEffect, useRef } from "react"

import moment from "moment"

import DateInput from "./DateInput"
import DateRange from "./DateRange"

import { range } from "../../utils/functions"

import Select from "./DateSelect"
import Icon from "../Icon"
import Form from "../Forms/Form"

export default ({
  date,
  endDate,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,

  // Custom props
  state,
  setTypedDate,
  setTypedEndDate,
  dateInput,
  dateRange,
  selectedRange,
  setSelectedRange,
  ...props
}) => {
  const selectedDateRef = useRef(date)
  useEffect(() => {
    selectedDateRef.current = date
  }, [date])

  const today = new Date()
  let year = today.getFullYear()
  let month = today.getMonth()

  if (date) {
    let initDate = date
    if (typeof initDate === "number") {
      initDate = moment(initDate).toDate()
    }
    year = initDate.getFullYear()
    month = initDate.getMonth()
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const years = range(1930, 2025)

  useEffect(() => {
    const newDate = selectedRange === "selectsEnd" ? endDate : state
    const currentDate = selectedDateRef.current

    if (newDate) {
      const stateYear = newDate.getFullYear()
      const stateMonth = newDate.getMonth()

      if (stateMonth !== currentDate.getMonth()) {
        changeMonth(stateMonth)
      }
      if (stateYear !== currentDate.getFullYear()) {
        changeYear(stateYear)
      }
    }
  }, [state, changeMonth, changeYear, selectedDateRef, selectedRange, endDate])

  const handleDecreaseMonth = (event) => {
    event.preventDefault()
    decreaseMonth()
  }
  const handleIncreaseMonth = (event) => {
    event.preventDefault()
    increaseMonth()
  }

  return (
    <div className="px-md">
      {dateInput && <DateInput date={state} setTypedDate={setTypedDate} />}
      {dateRange && (
        <DateRange
          startDate={state}
          endDate={endDate}
          setTypedDate={setTypedDate}
          setTypedEndDate={setTypedEndDate}
          setSelectedRange={setSelectedRange}
          selectedRange={selectedRange}
        />
      )}
      <div className="flex flex-1 justify-between py-md bg-white text-base font-sans">
        <button
          className={`${prevMonthButtonDisabled ? "opacity-50" : ""}`}
          onClick={handleDecreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Icon type="duotone" icon="chevron-circle-left" baseSize="20px" />
        </button>

        <Form className="text-neutral-1">
          <Select
            id="months"
            value={months[month]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            placeholder="Month"
            options={months}
          />
          <div className="pl-xs inline-block">
            <Select
              id="years"
              value={year}
              onChange={({ target: { value } }) => changeYear(value)}
              placeholder="Year"
              options={years}
            />
          </div>
        </Form>

        <button
          className={`${nextMonthButtonDisabled ? "opacity-50" : ""}`}
          onClick={handleIncreaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Icon type="duotone" icon="chevron-circle-right" baseSize="20px" />
        </button>
      </div>
    </div>
  )
}
