import React, { useEffect, useState } from "react"

import SelectChevron from "../../Input/SelectChevron"
import FilterDropdown from "../../FilterDropdown"

const ColumnHeader = ({
  index,
  columnHeaders,
  columnCount,
  setColumnHeader,
  error,
  refresh,
}) => {
  const [headerOptions, setHeaderOptions] = useState([
    {
      checked: false,
      name: "First name",
      value: "first_name",
    },
    {
      checked: false,
      name: "Last name",
      value: "last_name",
    },
    {
      checked: false,
      name: "Full name",
      value: "name",
    },
    {
      checked: false,
      name: "Email",
      value: "email",
    },
    {
      checked: false,
      name: "Group",
      value: "groups",
    },
  ])
  const [danger, setDanger] = useState(false)

  useEffect(() => {
    setDanger(
      error && columnHeaders[index] && error.type === columnHeaders[index].value
    )
    setHeaderOptions((options) =>
      options.map((option) => {
        return {
          ...option,
          checked: columnHeaders[index]
            ? columnHeaders[index].value === option.value
            : false,
        }
      })
    )
  }, [error, refresh, columnHeaders[index]])

  const updateColumnHeaders = (selectedOption) => {
    setColumnHeader(selectedOption, index)
  }

  return (
    <FilterDropdown
      single
      fullWidth
      closeOnSelect
      clearable={false}
      updateOnClose={false}
      className={`sm:inline-block hidden ${
        index !== columnCount - 1 ? "pr-sm" : ""
      }`}
      icons={{
        selected: {
          type: "duotone",
          icon: "check-circle",
        },
      }}
      options={headerOptions}
      placeholder="Select"
      onChange={(option) => updateColumnHeaders(option)}
      placement="bottom"
      fallbackPlacements={["top", "left", "right"]}
    >
      <div
        className={`relative ${
          danger ? "shadow-border-danger" : "shadow-border"
        } rounded bg-white placeholder-neutral-8 px-sm py-xs w-full leading-sm font-regular ${
          !columnHeaders[index] ? "text-neutral-8" : "text-neutral-1"
        } min-w-xxs`}
      >
        {columnHeaders[index] ? columnHeaders[index].name : "Select"}
        <SelectChevron value={columnHeaders[index]} />
      </div>
    </FilterDropdown>
  )
}

export default ColumnHeader
