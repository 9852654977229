import axios from "axios"
import { updateAppState } from "./functions"
import config from "../config"

const baseURL = config.api_url

// move this to a config file
const instance = axios.create({
  baseURL,
})

const handleCall = (
  initialRequest,
  data,
  context,
  onSuccess,
  onError,
  type,
  allRequests
) => {
  if (!initialRequest.type) {
    throw new Error("No request type defined")
  }
  let url = initialRequest.url
  if (typeof url === "function") {
    url = initialRequest.url(data ? data.variables : {}, context)
  }

  const request = JSON.parse(JSON.stringify(initialRequest)) // clone request to stop request object being overwritten
  request.url = url
  request.transformResponse =
    data && data.transformResponse
      ? data.transformResponse
      : initialRequest.transformResponse

  instance.defaults.headers.common["Authorization"] = `Bearer ${context.token}`

  if (context.organisation && context.organisation.id) {
    instance.defaults.headers.common["x-organisation"] = context.organisation.id
  }

  if (data && data.contextKey) {
    request.contextKey = data.contextKey
  }

  if (data && data.storeData !== undefined) {
    request.storeData = data.storeData
  }

  const body = data && data.body ? data.body : null
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {}
      if (data && data.headers) {
        headers = data.headers
      }
      let response = await instance[request.type](url, body, {
        headers,
      })

      if (request.transformResponse) {
        response = request.transformResponse(response, context)
      }

      if (request.appState) {
        updateAppState(response.data, request.contextKey)
      }
      return resolve({
        response: response.data,
        ...request,
        onSuccess,
        onError,
      })
    } catch (e) {
      let mappedRequests = JSON.parse(JSON.stringify(allRequests)) // clone request to stop request object being overwritten

      if (Array.isArray(mappedRequests)) {
        mappedRequests = mappedRequests.map((req) => {
          if (typeof req.url === "function") {
            req.url = req.url(req.variables)
          }
          req.onSuccess = onSuccess
          req.onError = onError
          return req
        })
      } else {
        if (typeof mappedRequests.url === "function") {
          mappedRequests.url = mappedRequests.url(
            data ? data.variables : {},
            context
          )
        }
        // set body data against request to be used by error handler
        mappedRequests.body = body
        mappedRequests.onSuccess = onSuccess
        mappedRequests.onError = onError
      }

      return reject({
        message: "There was an issue",
        data: e.response ? e.response.data : null,
        status: e.response ? e.response.status : 503,
        request: mappedRequests,
        onSuccess,
        onError,
        type,
      })
    }
  })
}

export const submitData = (
  request = {},
  data = {},
  context,
  onSuccess,
  onError,
  eventType
) => {
  if (request.payload && request.payload.transform) {
    data.body = request.payload.transform(data.body)
  }

  if (Array.isArray(request.payload)) {
    const response = Promise.all(
      request.payload.map((r) => {
        return handleCall(
          r,
          data[r.id],
          context,
          onSuccess,
          onError,
          eventType,
          request.payload.map((payload) => {
            // Used to handle requests that error
            if (data[payload.id]) {
              // set relevant request data
              return {
                ...data[payload.id],
                ...payload,
              }
            }
            return payload
          })
        )
      })
    )

    return response
  }

  if (!Array.isArray(request.payload)) {
    return handleCall(
      request.payload,
      data,
      context,
      onSuccess,
      onError,
      eventType,
      request.payload
    )
  }

  return
}
