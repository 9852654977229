import React, { useContext, useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react'

import Header from '../../containers/Header'
import Footer from '../../containers/Footer'
import Outline from './Outline'
import AddingMembers from './AddingMembers'
import CardPayments from './CardPayments'
import ReceivingPayouts from './ReceivingPayouts'
import DisplayTip from './DisplayTip'
import CreatingInvoices from './CreatingInvoices'
import { MachineContext } from '../../../state'

export default forwardRef(({ send, state }, ref) => {
  const [{ context }] = useContext(MachineContext)
  const [title, setTitle] = useState('Let\'s get started!')
  const [step, setStep] = useState(state.step)
  const [continueButton, setContinueButton] = useState({})
  const [body, setBody] = useState(<React.Fragment />)

  const closeModalRequest = useCallback(() => {
    const {displayFinalTip, showOnMount} = context.tutorial
    if (displayFinalTip || showOnMount) {
      send('updateTutorial', {
        data: {
          variables: {
            organisationId: context.organisation.id
          },
          body: {
            showOnMount: false,
            displayFinalTip: false
          },
          onSuccess: {
            callback: () => send('CLOSE_MODAL')
          }
        }
      })
    } else {
      send('CLOSE_MODAL')
    }

    const updatedOrgs = context.organisations.map(org => {
      if (context.organisation.id === org.id) {
        org.tutorial.showOnMount = false
      }
      return org
    })
    send('UPDATE_CONTEXT', {
      organisations: updatedOrgs
    })
  }, [context.tutorial, context.organisations, context.organisation.id, send])

  const closeOrLastStep = () => {
    if (context.tutorial && context.tutorial.displayFinalTip && step !== 6) {
      setStep(6)
    } else {
      closeModalRequest()
    }
  }

  useImperativeHandle(ref, () => ({
    onClose () {
      closeOrLastStep()
    }
  }))

  useEffect(() => {
    switch (step) {
      case 6:
        setBody(<DisplayTip />)
        setTitle('Tip')
        setContinueButton({
          text: 'Okay',
          icon: 'check-circle',
          iconType: 'duotone',
          type: 'primary',
          callback: closeModalRequest
        })
        break
      case 5:
        setBody(<ReceivingPayouts />)
        setTitle('Receive your first payout')
        setContinueButton({
          text: 'Done',
          icon: 'check-circle',
          iconType: 'duotone',
          type: 'primary',
          callback: () => {
            if (context.tutorial && context.tutorial.displayFinalTip) {
              setStep(6)
            } else {
              closeModalRequest()
            }
          }
        })
        break
      case 4:
        setBody(<CardPayments />)
        setTitle('Receive your first card payment')
        setContinueButton({
          text: 'Receiving payouts',
          icon: 'arrow-circle-right',
          iconType: 'duotone',
          type: 'primary',
          callback: () => setStep(5)
        })
        break
        case 3:
          setBody(<CreatingInvoices />)
          setTitle('Create your first invoice')
          setContinueButton({
            text: 'Card payments',
            icon: 'arrow-circle-right',
            iconType: 'duotone',
            type: 'primary',
            callback: () => setStep(4)
          })
          break
      case 2:
        setBody(<AddingMembers />)
        setTitle('Add your first members')
        setContinueButton({
          text: 'Creating invoices',
          icon: 'arrow-circle-right',
          iconType: 'duotone',
          type: 'primary',
          callback: () => setStep(3)
        })
        break
      case 1:
      default:
        setBody(<Outline />)
        setTitle('Let\'s get started!')
        setContinueButton({
          text: 'Add members',
          icon: 'arrow-circle-right',
          iconType: 'duotone',
          type: 'primary',
          callback: () => setStep(2)
        })
        break
    }
  }, [closeModalRequest, context.tutorial, send, step])

  const headerAction = [
    {
      title: 'Close',
      type: 'default',
      callback: closeOrLastStep,
      hide: step === 6
    }
  ]

  return (
    <div>
      <Header title={ title } actions={ headerAction } />
        <div className="bg-neutral-10 text-neutral-5 p-md" style={{ minHeight: step !== 6 ? '23.571rem' : '' }}>
          { body }
        </div>
      <Footer
        useValidation={ false }
        leftButton={
          (step !== 1 && step !== 6) ?
            {
              text: 'Previous',
              callback: () => setStep(step - 1)
            } :
            null
        }
        buttons={ [continueButton] }
      />
    </div>
  )
})
