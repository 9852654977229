import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import moment from "moment"

import Ribbon from "../../containers/Ribbon"
import ActivityFeed from "./ActivityFeed"
import RecordPayment from "./RecordPayment"
import Invoice from "../../Invoice"
import Switch from "../../Input/Switch"
import Icon from "../../Icon"
import { MachineContext } from "../../../state"
import { formatCurrency } from "../../../utils/functions"
import ActionDropdown from "../../ActionDropdown"
import CreditModal from "../Credit"
import Tag from "../../Tag"
import {
  viewEditInvoiceModal,
  viewShareInvoiceModal,
} from "../../../utils/modals/invoice"
import {
  cannotDeleteInvoiceConfirmation,
  cannotEditInvoiceConfirmation,
  cannotSendIndividualReminderConfirmation,
  deleteInvoiceConfirmation,
  sendIndividualReminderConfirmation,
} from "../../../utils/confirmations/invoice"

const InvoiceModal = forwardRef(
  (
    {
      state: { invoice = {} },
      setState,
      handleClose,
      ownInvoice,
      isAdmin: isAdminView = true,
    },
    ref
  ) => {
    const history = useHistory()
    const location = useLocation()

    const [current, send] = useContext(MachineContext)
    const { context } = current
    const initialReminder = useRef(invoice.ignoreReminders)
    const [ignoreReminders, setIgnoreReminders] = useState(
      invoice.ignoreReminders
    )
    const [note, setNote] = useState("")
    const [redirectTo404, setRedirect] = useState(false)
    const [activity, setActivity] = useState([])
    const activityRef = useRef()

    const lineItems =
      invoice.lineItems && invoice.lineItems.length
        ? invoice.lineItems.filter((i) => i.description && i.amount)
        : []
    const total = lineItems
      ? lineItems.reduce((a, b) => a + parseInt(b.amount), 0)
      : 0
    const totalPaid = invoice.payments
      ? invoice.payments.reduce((a, b) => a + parseInt(b.amount), 0)
      : 0
    const paid = invoice.datePaid

    const [showActivity, updateShowActivity] = useState(false)
    const [manualPayment, setManualPayment] = useState({
      amount: invoice.owed,
      paymentDate: "",
      paymentMethod: "",
    })

    const handlePaymentRemoved = (payment) => {
      setManualPayment({
        amount: invoice.owed + payment.amount,
        paymentDate: "",
        paymentMethod: "",
      })
    }

    const removeNote = useCallback(
      (activity) => {
        send("deleteNote", {
          data: {
            variables: {
              noteId: activity.id,
              batchId: invoice.metadata.batchId,
              memberId: invoice.member.id,
            },
            onSuccess: {
              notification: {
                title: "Note removed",
              },
              target: "CLOSE_CONFIRMATION",
              callback: ({ response }) => {
                send("UPDATE_MODAL_STATE", {
                  payload: {
                    state: response,
                  },
                })
              },
            },
          },
        })
      },
      [invoice.member.id, invoice.metadata.batchId, send]
    )

    const confirmRemoveNote = useCallback(
      (activity) => {
        send("OPEN_CONFIRMATION", {
          payload: {
            id: "removeNote",
            title: "Remove note",
            body: `Are you sure you want to remove this note?`,
            buttons: [
              {
                text: "Cancel",
                callback: () => {
                  send("CLOSE_CONFIRMATION")
                },
              },
              {
                text: "Remove note",
                type: "danger",
                iconType: "duotone",
                icon: "exclamation-circle",
                callback: () => {
                  removeNote(activity)
                },
              },
            ],
          },
        })
      },
      [removeNote, send]
    )

    useEffect(() => {
      let activity = invoice.activity || []

      if (activity) {
        activity = activity.map((act) => {
          if (act.title === "Note added") {
            return {
              ...act,
              isRemovable: invoice.member && !invoice.member.archived,
              removeCallback: confirmRemoveNote,
            }
          }

          return act
        })
        setActivity(activity)
      }
    }, [confirmRemoveNote, invoice.activity, invoice.member])

    const updateManualPayment = ({ target: { name, value } }) => {
      setManualPayment({
        ...manualPayment,
        [name]: value,
      })
    }

    const renderRightButtons = () => {
      return !paid ? (
        <div className="pl-sm pr-md py-sm">
          <div
            className={`rounded-full cursor-pointer text-neutral-5 p-sm inline-block ${
              !showActivity ? "bg-neutral-9" : ""
            }`}
            onClick={() => updateShowActivity(false)}
          >
            Record payment
          </div>
          <div
            className={`rounded-full cursor-pointer text-neutral-5 p-sm inline-block ${
              showActivity ? "bg-neutral-9" : ""
            }`}
            onClick={() => updateShowActivity(true)}
          >
            Activity & notes
          </div>
        </div>
      ) : (
        <h2 className="px-md pt-sm pb-sm text-xl">Activity & notes</h2>
      )
    }

    const handleModalClose = () => {
      if (initialReminder.current !== ignoreReminders) {
        send("toggleReminderStatus", {
          data: {
            variables: {
              organisationId: context.organisation.id,
              batchId: invoice.metadata.batchId,
              memberId: invoice.member.id,
            },
            onError: {
              notification: {
                title: "Whoops",
                description: "Something went wrong",
              },
            },
          },
        })
      } else {
        send("CLOSE_MODAL")
      }
    }

    useImperativeHandle(ref, () => ({
      onClose() {
        handleModalClose()
      },
    }))

    const createManualPayment = () => {
      send("createManualPayment", {
        data: {
          body: manualPayment,
          variables: {
            organisationId: context.organisation.id,
            batchId: invoice.metadata.batchId,
            memberId: invoice.member.id,
          },
          onSuccess: {
            callback: ({ response }) => {
              updateShowActivity(true)
              send("UPDATE_MODAL_STATE", {
                payload: {
                  state: response,
                },
              })
            },
            notification: {
              title: "Manual payment recorded",
              description: `${
                manualPayment.paymentMethod
              } payment for ${formatCurrency(manualPayment.amount)} saved`,
            },
          },
        },
      })

      setManualPayment({
        amount: invoice.owed - manualPayment.amount,
        paymentDate: "",
        paymentMethod: "",
      })
    }

    const recordPayment = (event) => {
      event.preventDefault()

      if (
        invoice.owed - manualPayment.amount < 50 &&
        invoice.owed - manualPayment.amount !== 0
      ) {
        send("OPEN_CONFIRMATION", {
          payload: {
            id: "owedAmountWarning",
            title: "Warning",
            body: [
              "This will make the outstanding balance less than £0.50 which will not be able to be paid online. Do you want to continue?",
            ],
            buttons: [
              {
                text: "Cancel",
                colWidth: 4,
                callback: () => send("CLOSE_CONFIRMATION"),
              },
              {
                text: "Continue",
                type: "primary",
                icon: "check-circle",
                colWidth: 7,
                iconType: "duotone",
                callback: () => {
                  send("CLOSE_CONFIRMATION")
                  createManualPayment()
                },
              },
            ],
          },
        })
      } else {
        createManualPayment()
      }
    }

    const sendNote = (e) => {
      e.preventDefault()

      if (note) {
        send("createInvoiceNote", {
          data: {
            variables: {
              organisationId: context.organisation.id,
              batchId: invoice.metadata.batchId,
              memberId: invoice.member.id,
            },
            body: { note },
            onSuccess: {
              callback: ({ response }) => {
                setNote("")
                send("UPDATE_MODAL_STATE", {
                  payload: {
                    state: response,
                  },
                })
              },
            },
          },
        })
      }
    }

    const isAdmin = context.user.roles.includes("admin") && isAdminView

    const renderBody = () => {
      if (!isAdmin && totalPaid === total) return <React.Fragment />

      let body
      body = (
        <div className="flex flex-1 overflow-y-auto flex-col">
          <RecordPayment
            send={send}
            recordPayment={recordPayment}
            overdue={invoice.overdue}
            total={total}
            totalPaid={totalPaid}
            invoice={invoice}
            amount={manualPayment.amount}
            paymentDate={manualPayment.paymentDate}
            paymentMethod={manualPayment.paymentMethod}
            updateManualPayment={updateManualPayment}
            closeModal={handleModalClose}
          />
        </div>
      )

      if (paid || showActivity) {
        body = (
          <div className="flex flex-1 flex-col overflow-y-auto justify-between">
            <div className="overflow-y-auto">
              <ActivityFeed
                ref={activityRef}
                reverse
                activity={activity.sort((a, b) => {
                  if (
                    moment(b.createdAt).isSame(moment(a.createdAt), "second")
                  ) {
                    return b.id > a.id ? 1 : -1
                  } else {
                    return new Date(b.createdAt) > new Date(a.createdAt)
                      ? 1
                      : -1
                  }
                })}
              />
            </div>
            {!paid && (
              <form
                onSubmit={sendNote}
                className="shadow-t-border flex flex-row items-center px-md"
                style={{ minHeight: "65.91px" }}
              >
                {/* Set note input max height to be the same as the record payment buttons */}
                <input
                  className="pr-sm py-sm my-xs placeholder-neutral-8 text-neutral-1 w-full outline-none"
                  onChange={({ target: { value } }) => setNote(value)}
                  placeholder="Type a note..."
                  value={note}
                />
                <div onClick={sendNote} className="cursor-pointer">
                  <Icon type="duotone" icon="arrow-circle-up" baseSize="28px" />
                </div>
              </form>
            )}
          </div>
        )
      }

      return (
        <div className="w-full max-w-sm border-l border-neutral-8 bg-white rounded-r flex flex-col">
          <div className="relative rounded-tr">
            <Ribbon
              logo={true}
              background={paid ? "success" : invoice.overdue ? "danger" : ""}
            />
            {isAdmin && !ownInvoice && renderRightButtons()}
            <div className="absolute top-lg right-lg">
              {invoice.overdue && !paid && (
                <Tag type="danger" className="font-medium">
                  Overdue
                </Tag>
              )}
              {paid && (
                <Tag type="success" className="font-medium">
                  Paid
                </Tag>
              )}
            </div>
          </div>
          {body}
        </div>
      )
    }

    const checkInvoice = (callback) => {
      send("checkMemberInvoiceIsValid", {
        data: {
          variables: {
            organisationId: invoice.organisation.id,
            batchId: invoice.metadata.batchId,
            memberId: invoice.member.id,
            redirectPath: !context.user.roles.includes("admin")
              ? `?customReference=${invoice.metadata.batchId},memberReference=${invoice.member.id},organisationId=${invoice.organisation.id}`
              : `/dashboard?customReference=${invoice.metadata.batchId},memberReference=${invoice.member.id},organisationId=${invoice.organisation.id}`,
          },
          onSuccess: {
            callback: ({ response }) => {
              callback(response)
            },
          },
          onError: {
            callback: () => {
              const queryString = !context.user.roles.includes("admin")
                ? `?customReference=${invoice.metadata.batchId},memberReference=${invoice.member.id},organisationId=${invoice.organisation.id}`
                : `/dashboard?customReference=${invoice.metadata.batchId},memberReference=${invoice.member.id},organisationId=${invoice.organisation.id}`

              const redirectPath = `${location.pathname}${queryString}`
              send("getInvoiceDetails", {
                data: {
                  variables: {
                    organisationId: invoice.organisation.id,
                    batchId: invoice.metadata.batchId,
                    memberId: invoice.member.id,
                    queryString: {
                      redirectPath,
                      storeViewActivity: false,
                    },
                  },
                  storeData: false,
                  onError: {
                    callback: (response) => {
                      if (response.status === 404) {
                        send("CLOSE_MODAL")
                        setRedirect(true)
                      }
                    },
                  },
                },
              })
            },
          },
        },
      })
    }

    const handleReminderToggle = ({ target: { checked } }) => {
      setIgnoreReminders(!checked)
    }

    const actions = [
      {
        text: "Send reminder",
        disabled:
          (ignoreReminders !== undefined ? ignoreReminders : true) ||
          invoice.member.undeliverable,
        callback: () => {
          invoice.lastReminderDate
            ? cannotSendIndividualReminderConfirmation(
                send,
                invoice,
                moment().isBefore(moment(invoice.dateSent).add(3, "hours"))
              )
            : sendIndividualReminderConfirmation(send, invoice.member, invoice)
        },
      },
      {
        text: "Add credit",
        callback: () => {
          send("OPEN_CONFIRMATION", {
            payload: {
              component: (
                <CreditModal invoice={invoice} member={invoice.member} />
              ),
            },
          })
        },
      },
      {
        text: "Edit invoice",
        callback: () => {
          if (invoice.payments.length === 0 && invoice.credits.length === 0) {
            viewEditInvoiceModal(send, invoice, invoice.member)
          } else {
            cannotEditInvoiceConfirmation(send)
          }
        },
      },
      {
        text: "Share link",
        callback: () => {
          viewShareInvoiceModal(send, invoice, invoice.member)
        },
      },
      {
        text: "Delete invoice",
        callback: () => {
          if (invoice.payments.length > 0 || invoice.credits.length > 0) {
            cannotDeleteInvoiceConfirmation(send)
          } else {
            deleteInvoiceConfirmation(send, invoice.member, invoice, history)
          }
        },
        danger: true,
      },
    ]

    if (redirectTo404) {
      return <Redirect to="/404" />
    }

    return (
      <Invoice
        setState={setState}
        invoice={invoice}
        showRightColumn={(isAdmin && !ownInvoice) || !paid}
        displayInvoiceInBody={isAdmin && !ownInvoice}
        displayEmailInBody={isAdmin && !ownInvoice}
        displayClubInBody={!isAdmin || ownInvoice}
        displayMemberInBody={!isAdmin || ownInvoice}
        withShadow={false}
        isAdmin={isAdmin && !ownInvoice}
        maxHeight="max-h-modal-container"
        checkInvoiceIsValid={checkInvoice}
        paymentRemoved={handlePaymentRemoved}
        customHeader={
          isAdmin &&
          !ownInvoice && (
            <div className="bg-white p-md flex flex-row justify-between items-center rounded-tl shadow-b-border">
              <h1>
                {isAdmin
                  ? invoice.member
                    ? invoice.member.name
                    : ""
                  : invoice.title}
              </h1>
              {!paid && isAdmin && !ownInvoice && (
                <div className="flex">
                  <Switch
                    id="toggleReminder"
                    label="Reminders"
                    value={
                      ignoreReminders !== undefined ? !ignoreReminders : true
                    }
                    onChange={handleReminderToggle}
                  />
                  <div className="ml-sm">
                    <ActionDropdown
                      closeOnClick={true}
                      key="action"
                      label="Options"
                      actions={actions}
                      icon="chevron-circle-down"
                      iconLayer={false}
                      iconType="duotone"
                      iconBaseSize="20px"
                    />
                  </div>
                </div>
              )}
            </div>
          )
        }
      >
        {renderBody()}
      </Invoice>
    )
  }
)

export default InvoiceModal
