import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "getCredits",
    payload: {
      url: ({ filters }) => `/credit${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "sendCreditReceipt",
    payload: {
      url: ({ creditId }) => `/credit/${creditId}/receipt`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "removeCredit",
    payload: {
      url: ({ creditId }) => `/credit/${creditId}/delete`,
      type: "post",
      refreshPageData: true,
      storeData: false,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
]
