import React, { forwardRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Button from '../Button'

export default forwardRef(({
  tabs = [],
  tabPage = '',
  actions = []
}, ref) => {
  const location = useLocation()
  return <div>
    {
      tabs && (
        <div className={ `grid grid-cols-${tabs.length} mx-md` }>
          {
            tabs.map((tab, index) => {
              return (
                <Link
                  to={{
                    pathname: location.pathname,
                    hash: '#' + tab.page
                  }}
                  key={ 'mobile-tab-' + index }
                  onClick={ tab.onClick }
                  className={ `
                    py-sm
                    text-center leading-tight
                    first:rounded-l last:rounded-r
                    cursor-pointer
                    border-primary-4
                    border
                    font-medium
                    ${tabPage === tab.page ? 'text-primary-10' : 'text-primary-4'}
                    ${tabPage === tab.page ? 'bg-primary-4' : ''}
                  ` }
                >
                  { tab.label }
                </Link>
              )
            })
          }
        </div>
      )
    }
    <div ref={ ref } className="flex justify-between p-md">
      {
        actions.filter(action => !action.hide).map((action, index) => {
          if (!action.hideOnMobile) {
            if (action.render) {
              return (
                <div key={ 'mobile-action-' + index } className={ `inline-block first:ml-0 ${action.containerClass}` }>
                  { action.render }
                </div>
              )
            }

            return (
              <div key={ 'mobile-action-' + index } className="inline-block">
                <Button
                  onClick={ action.callback }
                  to={ action.to }
                  type={ action.type || 'primary' }
                  icon={ action.icon }
                  className={ action.className }
                  iconType={ action.iconType }
                  disabled={ action.disabled }
                >
                  { action.title }
                </Button>
              </div>
            )
          }

          return <React.Fragment key={ 'mobile-fragment-' + index } />
        })
      }
    </div>
  </div>
})
