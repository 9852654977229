import React from 'react'
import ShareableLinks from '../containers/ShareableLinks'

export default function AffiliateTutorial({ send, state: { affiliateLinks } }) {
  const onCopy = () => {
    send('updateAffiliateTutorial', {
      data: {
        body: {
          linkShared: true
        }
      }
    })
  }
  return (
    <div className="grid grid-cols-1 gap-sm">
      <div>
        Welcome to your Payzip affiliate account!
      </div>
      <div>
        You can now earn money every time a club which you sign up to Payzip receives a card payment.
      </div>
      <div>
        Once you’re ready to talk with your audience about Payzip, you’ll need to share your affiliate link with them, which is:
      </div>
      <ShareableLinks urls={ affiliateLinks } onCopy={ onCopy } />
      <div>
        You can copy or share this link at any time by clicking the ’Share link’ button on the Payzip dashboard.
      </div>
      <div>
        If we can help answer a question or if you’d like to discuss your promotional strategy, get in touch with us at <a href="mailto:affiliates@payzip.co.uk">affiliates@payzip.co.uk</a>.
      </div>
      <div>
        Good luck!
      </div>
    </div>
  )
}
