import React, { useContext, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"

import Ribbon from "../containers/Ribbon"
import Header from "../containers/Header"
import { MachineContext } from "../../state"
import { formatCurrency } from "../../utils/functions"
import Button from "../Button"
import Tag from "../Tag"
import Footer from "../containers/Footer"
import Banner from "../containers/Banner"
import Icon from "../Icon"
import moment from "moment"

export default ({
  ping,
  children,
  customHeader = null,
  withShadow = true,
  maxHeight = "",
  isAdmin,
  checkPingIsValid,
  errorFetchingLink = false,
  organisation = {},
  changeMemberQuantity,
  addNote,
  displayRemaining,
  paid = false,
  receiptView = false,
  handleNotYou = () => {},
  editMemberCommentsCallback = () => {},
}) => {
  const [viewingDetails, toggleViewingDetails] = useState(paid)

  const [current, send] = useContext(MachineContext)
  const {
    context: { user },
  } = current
  const memberView =
    !user || (user && !user.roles.includes("admin")) || !isAdmin

  const goToCheckout = async () => {
    if (!paid) {
      checkPingIsValid(async (ping) => {
        const stripe = await loadStripe(ping.publishableKey)

        stripe.redirectToCheckout({
          sessionId: ping.session,
        })
      })
    }
  }

  const renderHeader = () => {
    if (memberView) {
      return (
        <div className="bg-white sm:rounded-t pb-md shadow-b-border">
          {/* PING ERROR BANNER */}
          {((ping.value > 0 && ping.value < 50) || errorFetchingLink) && (
            <Banner>
              {ping.value < 50
                ? "Balance below £0.50. Payment cannot be made online."
                : "There was an issue getting the Ping. Payment cannot be made online"}
            </Banner>
          )}

          <div className="relative">
            <Ribbon
              rounded={!errorFetchingLink}
              logo={true}
              background={paid ? "success" : "primary"}
            />

            {/* PING STATUS PILL */}
            {memberView && (
              <div className="absolute top-lg right-lg">
                {paid && (
                  <Tag type="success" className="font-medium">
                    {ping.amount > 0 ? "Paid" : "Confirmed"}
                  </Tag>
                )}
              </div>
            )}
          </div>
          <div className="px-md text-center">
            <h2 className="text-xl mt-xs">{ping.description}</h2>
            <div className="text-neutral-5 font-medium text-lg">
              {organisation.name}
            </div>
          </div>
        </div>
      )
    }
    return <Header title={ping.description} className="rounded-tl" />
  }

  return (
    <div
      className={`sm:max-w-${memberView ? "md" : "xl"} sm:min-w-${
        memberView ? "lg" : "xxl"
      }
        sm:rounded
        sm:mx-auto
        flex
        ${withShadow ? "shadow-container" : ""}
        bg-white
        sm:min-h-modal
        sm:${maxHeight} h-full`}
    >
      <div
        className={`sm:inline-block
          w-full
          sm:max-w-md
          sm:${memberView ? "rounded" : "rounded-l"}
          ${maxHeight ? "sm:overflow-y-auto" : ""}
          ${!user ? "sm:container-button-spacing" : ""}
          relative
          flex flex-col sm:h-auto h-full overflow-y-auto`}
      >
        {customHeader || renderHeader()}
        <div
          className={`grid gap-sm bg-neutral-10 sm:py-md px-md shadow-b-border`}
        >
          <div
            className={`grid grid-cols-1 gap-sm ${
              viewingDetails ? "sm:pt-0 pt-md" : "sm:block hidden"
            }`}
          >
            {ping.comments && (
              <div
                className={
                  "whitespace-pre-line " +
                  (paid
                    ? "sm:pb-0 pb-md"
                    : ping.limit || ping.endDate
                    ? "pb-md"
                    : "")
                }
              >
                {ping.comments}
              </div>
            )}
            {!paid && (
              <React.Fragment>
                {ping.limit > 0 && (
                  <div className="flex">
                    <div className="font-semibold pr-xs">Limit:</div>
                    <div>{ping.limit} per member</div>
                  </div>
                )}
                {ping.endDate && (
                  <div className="flex">
                    <div className="font-semibold pr-xs">Closing date:</div>
                    <div>{moment(ping.endDate).format("DD MMM YYYY")}</div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          {!paid && (
            <div
              className={`sm:hidden text-center cursor-pointer text-neutral-5 font-medium flex items-center justify-center ${
                viewingDetails ? "py-md" : "py-sm"
              } -mx-md`}
              onClick={() => toggleViewingDetails(!viewingDetails)}
            >
              <div className="inline-block mr-xs font-medium">
                {viewingDetails ? "Hide" : "Show"} details
              </div>
              <Icon
                baseSize="20px"
                type="duotone"
                icon={viewingDetails ? "arrow-circle-up" : "arrow-circle-down"}
                className="inline-block"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col flex-1 sm:h-auto h-full sm:overflow-y-hidden sm:mb-0 mb-55">
          {paid ? (
            <div className="bg-white pt-sm pb-md rounded-b">
              <table className="bg-white w-full">
                <thead className="border-b border-neutral-8">
                  <tr>
                    <th className="text-left px-md py-sm font-medium">Item</th>
                    <th className="text-right px-md py-sm font-medium">Qty</th>
                    <th className="text-right px-md font-medium">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {ping.purchase.members.map((mem, index) => {
                    return (
                      <tr key={"member-" + mem.id + index}>
                        <td className="px-md py-sm">{mem.name}</td>
                        <td className="text-right px-md py-sm">
                          {mem.quantity}
                        </td>
                        <td className="text-right px-md py-sm">
                          {mem.amount ? formatCurrency(mem.amount) : "FREE"}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <table className="w-full">
                <tbody>
                  {ping.amount ? (
                    <tr className="px-md py-sm">
                      <td className="px-md py-sm">
                        Paid by Card on{" "}
                        {moment(ping.datePaid).format("DD MMM YYYY HH:mm")}
                      </td>
                      <td className="text-right px-md whitespace-nowrap">
                        -{formatCurrency(ping.amount)}
                      </td>
                    </tr>
                  ) : (
                    <tr className="px-md py-sm">
                      <td className="px-md py-sm">
                        Confirmed on{" "}
                        {moment(ping.datePaid).format("DD MMM YYYY HH:mm")}
                      </td>
                    </tr>
                  )}
                  <tr
                    key="totalPaid"
                    className="bg-neutral-10 border-t border-b border-neutral-8 px-md py-sm"
                  >
                    <td className="px-md py-sm font-medium">Amount due</td>
                    <td className="text-right px-md font-medium whitespace-nowrap">
                      {formatCurrency(0)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="bg-white p-md whitespace-pre-line">
                {ping.notes}
                {isAdmin && (
                  <div
                    className="link pt-sm"
                    onClick={editMemberCommentsCallback}
                  >
                    {ping.notes
                      ? "Edit member comments"
                      : "Add member comments"}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="bg-white pt-sm pb-md">
                <table className="bg-white w-full shadow-b-border">
                  <thead className="border-b border-neutral-8">
                    <tr>
                      <th className="text-left px-md py-sm font-medium">
                        Item
                      </th>
                      <th className="text-right px-md font-medium">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="px-md py-sm sm:h-auto h-55">
                      <td className="px-md py-sm">{ping.description}</td>
                      <td className="text-right px-md">
                        {ping.amount ? formatCurrency(ping.amount) : "FREE"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table className="bg-white w-full shadow-b-border">
                <thead className="border-b border-neutral-8">
                  <tr className="sm:h-auto h-55">
                    <th className="text-left px-md py-sm font-medium">
                      Member{" "}
                      {
                        <span
                          className="link font-regular ml-sm"
                          onClick={handleNotYou}
                        >
                          Not you?
                        </span>
                      }
                    </th>
                    <th className="text-right px-md font-medium">
                      {displayRemaining && !paid && (
                        <span className="mr-sm text-danger-5 font-regular">
                          Only {ping.remaining} left!
                        </span>
                      )}
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ping.members &&
                    ping.members.length > 0 &&
                    ping.members.map((member, index) => {
                      const memberPurchaseLimit =
                        ping.limit -
                        ping.purchases
                          .flatMap((p) => p.members)
                          .filter((mem) => mem.id === member.id)
                          .reduce((acc, mem) => acc + mem.quantity, 0)
                      const maxSelectable =
                        ping.remaining -
                        ping.members
                          .filter((mem) => mem.id !== member.id)
                          .reduce((acc, mem) => acc + mem.quantity, 0)

                      let maxSelectableLabel = ping.limit
                      if (maxSelectable < ping.limit) {
                        maxSelectableLabel = maxSelectable
                      }

                      if (member.quantity > maxSelectable) {
                        maxSelectableLabel = member.quantity
                      }

                      if (memberPurchaseLimit < maxSelectableLabel) {
                        maxSelectableLabel = memberPurchaseLimit
                      }

                      return (
                        <tr
                          key={"member-" + member.id + index}
                          className="px-md py-sm border-b border-neutral-8"
                        >
                          <td className="px-md py-sm">{member.name}</td>
                          <td className="px-md">
                            <div className="flex items-center justify-end sm:py-0 py-xs">
                              {/* {
                                ping.limit > 0 && (
                                  <div className="mr-md text-neutral-5 whitespace-nowrap">Max { maxSelectableLabel }</div>
                                )
                              } */}
                              <Icon
                                onClick={() =>
                                  changeMemberQuantity(
                                    member,
                                    member.quantity - 1
                                  )
                                }
                                className={
                                  "sm:text-20 text-28 " +
                                  (member.quantity
                                    ? "text-neutral-7 cursor-pointer"
                                    : "text-neutral-9")
                                }
                                icon="minus-circle"
                                type="regular"
                                baseSize=""
                              />
                              <div className="sm:w-xl w-40 text-center">
                                {member.quantity}
                              </div>
                              <Icon
                                onClick={() =>
                                  changeMemberQuantity(
                                    member,
                                    member.quantity + 1
                                  )
                                }
                                className={
                                  "sm:text-20 text-28 " +
                                  ((!ping.limit ||
                                    member.quantity !== memberPurchaseLimit) &&
                                  ping.members.reduce(
                                    (acc, mem) => acc + mem.quantity,
                                    0
                                  ) !== ping.quantity &&
                                  (ping.remaining === null ||
                                    ping.members.reduce(
                                      (acc, mem) => acc + mem.quantity,
                                      0
                                    ) < ping.remaining)
                                    ? "text-neutral-7 cursor-pointer"
                                    : "text-neutral-9")
                                }
                                baseSize=""
                                icon="plus-circle"
                                type="regular"
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
              <div
                className={`bg-white whitespace-pre-line sm:${
                  memberView ? "rounded-b" : "rounded-bl"
                }`}
                style={{ marginTop: "1px" }}
              >
                {" "}
                {/* Add margin top to allow row borders to appear */}
                {memberView ? (
                  <div>
                    <div className="text-neutral-1 px-md pt-md pb-xs font-semibold">
                      Add note
                    </div>
                    <textarea
                      rows="3"
                      className="px-md pb-md pt-xs w-full outline-none"
                      value={ping.notes}
                      name="notes"
                      placeholder="Type your note here"
                      onChange={(event) => addNote(event.target.value)}
                    />
                  </div>
                ) : (
                  ping.notes
                )}
              </div>
            </React.Fragment>
          )}
        </div>

        {memberView && (
          <div className="sm:absolute sm:top-lg sm:right-lg sm:left-auto sm:bottom-auto sm:w-auto fixed bottom-0 w-full">
            {!paid && !errorFetchingLink && (
              <div className="hidden sm:block">
                <Button
                  icon={ping.amount ? "credit-card" : "check-circle"}
                  layer
                  type="primary"
                  iconType={ping.amount ? "solid" : "duotone"}
                  onClick={goToCheckout}
                  disabled={
                    ping.amount
                      ? ping.value === 0
                      : ping.members &&
                        !ping.members.filter((mem) => mem.quantity).length
                  }
                  submitting={current.matches(
                    "app.requests.createPingReservation.requesting"
                  )}
                >
                  {ping.amount
                    ? `Pay ${formatCurrency(ping.value)}`
                    : "Confirm"}
                </Button>
              </div>
            )}
            {!paid && (
              <Footer
                useValidation={false}
                className="sm:hidden"
                buttons={[
                  {
                    text: ping.amount
                      ? `Pay ${formatCurrency(ping.value)}`
                      : "Confirm",
                    colWidth: 7,
                    type: "primary",
                    callback: goToCheckout,
                    disabled: ping.amount
                      ? ping.value === 0
                      : ping.members &&
                        !ping.members.filter((mem) => mem.quantity).length,
                  },
                ]}
              />
            )}
            {paid && !receiptView && (
              <Footer
                useValidation={false}
                className="sm:hidden"
                buttons={[
                  {
                    text: "Close",
                    type: "primary",
                    callback: () => send("CLOSE_MODAL"),
                  },
                ]}
              />
            )}
          </div>
        )}
      </div>
      {!memberView && children}
    </div>
  )
}
