import React from 'react'

export default ({ active, children, onClick }) => (
  <div
    className={ `filter-pill ${active  ? 'filter-pill--active' : ''}` }
    onClick={ onClick }
  >
    { children }
  </div>
)
