import React, { forwardRef, useContext } from "react"
import moment from "moment"

import { MachineContext } from "../../../state"
import { formatCurrency } from "../../../utils/functions"
import InternalLabelInput from "../../Input/InternalLabelInput"
import Textarea from "../../Input/Textarea"

export default forwardRef(({ email, onChange, ping }, ref) => {
  const [{ context }] = useContext(MachineContext)

  return (
    <div className="grid grid-cols-1 gap-sm">
      <div>
        <label
          className="block text-neutral-5 font-medium mb-xs"
          htmlFor="subject"
        >
          Subject
        </label>
        <InternalLabelInput
          label={context.organisation.name + ": "}
          value={email.subject}
          onChange={({ target }) =>
            onChange({
              subject: target.value,
              body: email.body,
            })
          }
          name="subject"
          validation={{
            message: "Please enter a subject",
            rules: "required",
          }}
        />
      </div>
      <div>
        <label
          className="block text-neutral-5 font-medium mb-xs"
          htmlFor="body"
        >
          Email content
        </label>
        <Textarea
          classes={{
            input: "leading-sm",
          }}
          name="body"
          placeholder="Add any additional email content here (optional)"
          onChange={({ target }) =>
            onChange({
              body: target.value,
              subject: email.subject,
            })
          }
          rows={8}
          value={email.body}
        />
      </div>
      <div>
        <div className="block text-neutral-5 font-medium mb-xs">
          We’ll also include this in the email
        </div>
        <div className="border rounded p-sm">
          <div className="mb-sm whitespace-pre-line">{ping.comments}</div>
          <div className="flex">
            <div className="mr-md flex">
              <span className="block text-neutral-5 font-medium mr-xs">
                Amount:
              </span>{" "}
              {ping.amount > 0 ? formatCurrency(ping.amount) : "FREE"}
            </div>
            {ping.limit > 0 && (
              <div className="mr-md flex">
                <span className="block text-neutral-5 font-medium mr-xs">
                  Limit per member:
                </span>{" "}
                {ping.limit}
              </div>
            )}
            {ping.endDate && (
              <div className="flex">
                <span className="block text-neutral-5 font-medium mr-xs">
                  Closing date:
                </span>{" "}
                {moment(ping.endDate).format("DD MMM YYYY")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
