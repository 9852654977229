import React from "react"

export default () => {
  return (
    <div>
      <div>
        Now you're ready to send your first invoice. Open the Create invoice
        wizard by either:
      </div>
      <div className="p-md m-sm">
        <ul>
          <li>
            • Clicking <span className="font-medium">Invoices</span> in the
            sidebar menu and clicking{" "}
            <span className="font-medium">Create invoice</span>, or simply
            clicking <span className="font-medium">Create invoice</span> on the
            Dashboard
          </li>
        </ul>
        <div className="font-medium py-sm">OR</div>
        <ul>
          <li>
            • Clicking plus in the bottom right-hand corner, and then clicking{" "}
            <span className="font-medium">Create invoice</span>
          </li>
        </ul>
      </div>
      <div>
        Detailed instructions are available in our{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.payzip.co.uk/article/36-creating-an-invoice"
        >
          knowledgebase
        </a>
        .
      </div>
    </div>
  )
}
