import React, { useContext, useEffect, useState } from "react"
import Route from "../components/LazyRoute"
import { Switch, Redirect, useLocation, useHistory } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"
import Button from "../components/Button"
import { MachineContext } from "../state"
import { createClubModal } from "../utils/modals/organisation"
import { becomeAnAffiliateModal } from "../utils/modals/affiliate"
import Dashboard from "../components/containers/Dashboard"
import MemberSkeleton from "../pages/organisation/Member/Skeleton"

const Account = React.lazy(() => import("../pages/common/Account"))
const Member = React.lazy(() => import("../pages/organisation/Member"))

export default function MemberRoutes({ applicationInProgress }) {
  const [{ context }, send] = useContext(MachineContext)
  const location = useLocation()
  const history = useHistory()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!context.user.onboardingComplete && !location.hash) {
      history.replace("/onboarding")
    } else {
      setMounted(true)
    }
    switch (location.hash) {
      case "#organisation":
        createClubModal(send, context.user, context.profile, {}, () => {
          send("CLOSE_MODAL")
          history.push("/onboarding")
        })
        break
      case "#affiliate":
        becomeAnAffiliateModal(send, context.user, context.profile, () => {
          send("CLOSE_MODAL")
          history.push("/onboarding")
        })
        break
      default:
        break
    }
  }, [])

  if (mounted) {
    return (
      <Dashboard
        name={context.user.name}
        email={context.user.email}
        logoPath={context.user.roles.includes("affiliate") ? "/affiliate" : "/"}
        dashboardClassNames="sm:px-md"
        banner={
          context.user.roles.includes("admin")
            ? {
                text: "Admin functionality is not yet available on mobile. Use the desktop version to manage your club",
                className: "sm:hidden block",
              }
            : {}
        }
        actions={[
          <Button
            key="create-club-button"
            type="secondary"
            border={false}
            icon="plus-circle"
            iconType="duotone"
            containerStyle={
              (context.user.roles && context.user.roles.includes("admin")) ||
              applicationInProgress ||
              isMobileOnly
                ? "hidden"
                : ""
            }
            onClick={() => createClubModal(send, context.user, context.profile)}
          >
            Create club
          </Button>,
          context.user.roles.includes("affiliate") ? (
            <Button
              key="affiliate-view-button"
              type="support"
              border={false}
              icon="pound-sign"
              iconType="solid"
              containerStyle={isMobileOnly ? "hidden" : "pl-md"}
              to="/affiliate"
            >
              Affiliate view
            </Button>
          ) : (
            <Button
              key="become-affiliate-button"
              type="support"
              border={false}
              icon="pound-sign"
              iconType="solid"
              containerStyle={
                applicationInProgress || isMobileOnly ? "hidden" : "pl-md"
              }
              onClick={() =>
                becomeAnAffiliateModal(send, context.user, context.profile)
              }
            >
              Earn money
            </Button>
          ),
        ]}
      >
        <Switch>
          <Route exact path="/account">
            <Account />
          </Route>

          <Route
            exact
            path={["/", "/:invoiceToken"]}
            fallback={<MemberSkeleton />}
            render={(props) => <Member ownInvoices={true} {...props} />}
          />

          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Dashboard>
    )
  }

  return <React.Fragment />
}
