import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "createPing",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/pings`,
      type: "post",
      refreshPageData: true,
    },
  },
  {
    target: "editPing",
    payload: {
      url: ({ pingId }) => `/ping/${pingId}/update`,
      type: "post",
      refreshPageData: true,
    },
  },
  {
    target: "getPings",
    payload: {
      url: ({ organisationId, filters }) =>
        `/organisation/${organisationId}/pings${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getPing",
    payload: {
      url: ({ pingId, filters }) => `/ping/${pingId}`,
      type: "get",
      contextKey: "ping",
    },
  },
  {
    target: "getPingPurchases",
    payload: {
      url: ({ pingId, filters }) =>
        `/ping/${pingId}/purchases${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getPingAndMembers",
    payload: {
      url: ({ pingCode }) => `/ping/${pingCode}`,
      type: "post",
    },
  },
  {
    target: "createPingReservation",
    payload: {
      url: ({ pingCode }) => `/ping/${pingCode}/reserve`,
      type: "post",
    },
  },
  {
    target: "checkPingIsAvailable",
    payload: {
      url: ({ pingCode }) => `/ping/${pingCode}/check`,
      type: "post",
    },
  },
  {
    target: "getPingPurchase",
    payload: {
      url: ({ pingId, purchaseId }) =>
        `/ping/${pingId}/purchases/${purchaseId}`,
      type: "get",
    },
  },
  {
    target: "getPingPurchaseByInvoice",
    payload: {
      url: ({ pingId, invoiceId }) => `/ping/${pingId}/invoice/${invoiceId}`,
      type: "get",
    },
  },
  {
    target: "getPingStats",
    payload: {
      url: ({ pingId }) => `/ping/${pingId}/stats`,
      type: "get",
    },
  },
  {
    target: "deletePing",
    payload: {
      url: ({ pingId }) => `/ping/${pingId}/delete`,
      type: "post",
    },
  },
  {
    target: "createPingNote",
    payload: {
      url: ({ pingId, purchaseId }) =>
        `/ping/${pingId}/purchases/${purchaseId}/notes`,
      type: "post",
      storeData: false,
      refreshPageData: true,
    },
  },
  {
    target: "deletePingNote",
    payload: {
      url: ({ pingId, purchaseId, noteId }) =>
        `/ping//${pingId}/purchases/${purchaseId}/notes/${noteId}/delete`,
      type: "post",
      storeData: false,
      refreshPageData: true,
    },
    onSuccess: {
      notification: {
        title: "Note removed",
      },
      target: "CLOSE_CONFIRMATION",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getPingPayments",
    payload: {
      url: ({ organisationId, filters }) =>
        `/payments/pings${constructQueryString(
          filters,
          `organisationId=${organisationId}`
        )}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "sendPingEmail",
    payload: {
      url: ({ pingId }) => `/ping/${pingId}/email`,
      type: "post",
    },
  },
  {
    target: "deletePurchase",
    payload: {
      url: ({ pingId, purchaseId }) =>
        `/ping/${pingId}/purchases/${purchaseId}/delete`,
      type: "post",
      refreshPageData: true,
    },
  },
  {
    target: "updatePurchaseNotes",
    payload: {
      url: ({ pingId, purchaseId }) =>
        `/ping/${pingId}/purchase/${purchaseId}/note`,
      type: "post",
    },
  },
  {
    target: "getMemberPings",
    payload: {
      url: ({ memberId, filters }) =>
        `/member/${memberId}/pings${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
]
