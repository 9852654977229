import React from "react"
import Input from "../Input"
import Textarea from "../Input/Textarea"
import DatePicker from "../Datepicker"
import moment from "moment"
import Checkbox from "../Input/Checkbox"

export default function PingForm({ onChange, ping }) {
  return (
    <div className="grid grid-cols-1 gap-sm text-neutral-5">
      <Input
        onChange={onChange}
        value={ping.description}
        name="description"
        label="Name"
        placeholder="Give your Ping a distinct name"
        validation={{
          messages: {
            required: "Your Ping needs a name",
          },
          rules: "required",
        }}
      />
      <Textarea
        rows={4}
        onChange={onChange}
        label="Additional information"
        classes={{
          label: "mb-xs",
          input: "leading-sm",
        }}
        value={ping.comments}
        name="comments"
        placeholder="Provide more information to your members"
        length={500}
        limitPlacement="top"
        validation={{
          name: "comments",
          messages: {
            max: "Maximum character limit exceeded",
            required: "Please enter some additional details",
          },
          rules: "required|max:500",
        }}
      />
      <div>
        <div className="input-label">Amount</div>
        <div className="flex">
          <Input
            onChange={onChange}
            placeholder="e.g. £10.00"
            name="amount"
            value={ping.amount}
            type="currency"
            maskPattern="^\d+.?[0-9]{0,2}$"
            validation={{
              messages: {
                required_if: "Enter an amount",
                min: "Amount must be at least £0.50",
                numeric: "Enter an amount",
              },
              rules: [
                { required_if: !ping.free && !ping.amount },
                ping.free ? "numeric" : "min:50,num",
              ],
            }}
            width="w-1/2"
          />
          <div className="py-xs px-sm leading-tight">or</div>
          <Checkbox
            onChange={onChange}
            className="py-xs"
            iconSize="16px"
            withGrid={false}
            checkboxMargin="mr-sm"
            label="Free"
            name="free"
            checked={ping.free}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Quantity</div>
        <div className="flex">
          <Input
            onChange={onChange}
            name="maxQuantity"
            placeholder="e.g. 100"
            value={ping.maxQuantity}
            width="w-1/2"
            validation={{
              // Additional validation: Value must be greater than currently sold quantity
              messages: {
                required_if: "Enter a quantity",
                integer: "Enter a number",
              },
              rules: [
                { required_if: !ping.unlimited && !ping.maxQuantity },
                "integer",
              ],
            }}
          />
          <div className="py-xs px-sm leading-tight">or</div>
          <Checkbox
            name="unlimited"
            onChange={onChange}
            className="py-xs"
            iconSize="16px"
            withGrid={false}
            checkboxMargin="mr-sm"
            label="Unlimited"
            checked={ping.unlimited}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Limit per member</div>
        <div className="flex">
          <Input
            onChange={onChange}
            name="limit"
            value={ping.limit}
            placeholder="e.g. 2"
            validation={{
              messages: {
                required_if: "Enter a limit per member",
                integer: "Enter a number",
              },
              rules: [{ required_if: !ping.noLimit && !ping.limit }, "integer"],
            }}
            width="w-1/2"
          />
          <div className="py-xs px-sm leading-tight">or</div>
          <Checkbox
            onChange={onChange}
            className="py-xs"
            iconSize="16px"
            withGrid={false}
            checkboxMargin="mr-sm"
            label="No limit"
            name="noLimit"
            checked={ping.noLimit}
          />
        </div>
      </div>
      <div>
        <div className="input-label">End date</div>
        <div className="flex">
          <div className="w-1/2">
            <DatePicker
              date={ping.endDate}
              format="dd/MM/yyyy"
              onChange={(value) =>
                onChange({ target: { name: "endDate", value } })
              }
              placeholder="Select date"
              popperPlacement="bottom"
              minDate={new Date()}
              clearText="Clear date"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "0, 5px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              customInput={
                <Input
                  small
                  date={moment(ping.endDate).format("DD/MM/YYYY")}
                  name="endDate"
                  placeholder="Select date"
                  showSelectChevron
                  classes={{
                    input: "cursor-pointer",
                  }}
                  dateDisplay
                  mask="99/99/9999"
                  validateOnBlur={false}
                  validation={{
                    name: "endDate",
                    messages: {
                      required_if: "Enter an end date",
                      date: "Enter a valid date",
                      after_or_equal: "Pick a date in the future",
                    },
                    rules: [
                      "date",
                      { required_if: !ping.noEndDate && !ping.endDate },
                      { after_or_equal: moment() },
                    ],
                  }}
                />
              }
              todayButton
            />
          </div>
          <div className="py-xs px-sm leading-tight">or</div>
          <Checkbox
            onChange={onChange}
            className="py-xs"
            iconSize="16px"
            withGrid={false}
            checkboxMargin="mr-sm"
            label="No end date"
            name="noEndDate"
            checked={ping.noEndDate}
          />
        </div>
      </div>
      <div className="py-sm">
        <Checkbox
          iconSize="16px"
          withGrid={false}
          checkboxMargin="mr-sm"
          label="Members only"
          disabled
          checked
        />
      </div>
    </div>
  )
}
