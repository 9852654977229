import React, {
  useEffect,
  useContext,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react"

import Footer from "../containers/Footer"
import Table from "../Table"
import Form from "../Forms/Form"
import { pluraliseWord } from "../../utils/functions"
import Header from "../containers/Header"
import { MachineContext } from "../../state"
import { openResentInvoicesModal } from "../../utils/modals/member"

export default forwardRef(({ send, state }, ref) => {
  const [current] = useContext(MachineContext)
  const [members, setMembers] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    setMembers(state.members.filter((mem) => mem.id !== state.memberId))
    setSelectedRows(state.members.filter((mem) => mem.id !== state.memberId))
  }, [state.memberId, state.members])

  const refreshTables = () => {
    send("CLOSE_MODAL", { context: { refreshPageData: true } })
  }

  useImperativeHandle(ref, () => ({
    onClose() {
      refreshTables()
    },
  }))

  const closeModal = (memberIds) => {
    openResentInvoicesModal(send, {
      memberIds,
      newEmail: state.newEmail,
      organisation: current.context.organisation,
    })
  }

  const updateMembers = () => {
    let members = [{ id: state.memberId }]
    members = members.concat(selectedRows)

    send("sendUpdateMembers", {
      data: {
        variables: {
          organisationId: current.context.organisation.id,
        },
        body: {
          members,
          value: { email: state.newEmail },
        },
        onSuccess: {
          callback: () => closeModal(members.map((member) => member.id)),
          notification: {
            title: `${selectedRows.length} ${pluraliseWord(
              "member",
              selectedRows.length
            )} updated`,
          },
        },
      },
    })
  }

  const selectRow = (row) => {
    setSelectedRows((rows) => {
      if (rows.filter((current) => current.id === row.id).length === 0) {
        return rows.concat(row)
      } else {
        return rows.filter((current) => current.id !== row.id)
      }
    })
  }

  const selectAll = (rows) => {
    setSelectedRows(rows)
  }

  return (
    <Form className="max-h-full sm:max-w-md w-full mx-auto">
      <Header title="Update members" />
      <div className="bg-neutral-10 max-h-modal-body overflow-y-auto rounded-b">
        <div className="p-md shadow-b-border text-neutral-5">
          {members.length} other {pluraliseWord("member", members.length)}{" "}
          {pluraliseWord("use", members.length - 1)} {state.oldEmail}. We'll
          also update the {pluraliseWord("one", members.length)} ticked below.
        </div>
        <Table
          disableLoadingSpinner
          withShadow={false}
          onSelectAll={selectAll}
          onRowSelect={selectRow}
          onRowDeselect={selectRow}
          hasRounding={false}
          selectable
          selectedRows={selectedRows}
          allSelected={
            selectedRows.length > 0 && selectedRows.length === members.length
          }
          headers={[
            {
              name: "Member",
              key: "name",
            },
          ]}
          data={members}
        />
        <Footer
          buttons={[
            {
              callback: () => closeModal(state.memberId),
              text: "Don't update",
            },
            {
              type: "primary",
              callback: updateMembers,
              icon: "sync-alt",
              text: "Update",
              disabled: selectedRows.length === 0,
              submitting: current.matches(
                "app.requests.sendUpdateMembers.requesting"
              ),
              background: selectedRows.length === 0 ? "bg-neutral-10" : "",
            },
          ]}
        />
      </div>
    </Form>
  )
})
