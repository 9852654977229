import moment from "moment"
import { deleteOnboardingConfirmation } from "../confirmations/organisation"
import { handleFormSubmission } from "../functions"
import { defaults } from "./config"

export const createClubModal = (
  send,
  user,
  profile = {},
  organisation = {
    name: "",
    type: "",
    intent: "",
    website: "",
    social: "",
  },
  backToOnboarding
) => {
  let userProfile = profile || {}

  if (user.profile) {
    userProfile = {
      dob: moment(user.profile.dob).toDate(),
      addressLine1: user.profile.addressLine1,
      addressLine2: user.profile.addressLine2,
      town: user.profile.town,
      postcode: user.profile.postcode,
      phoneNumber: user.profile.phoneNumber,
    }
  }

  send("OPEN_MODAL", {
    payload: {
      id: "createClub",
      title: "Create club",
      state: {
        step: user.roles.includes("admin") ? 2 : 1,
        declaration: {
          isClubAdmin: false,
          isClubAuthed: false,
          isUKBased: false,
          acceptedTAndC: false,
          ageCheck: false,
        },
        user: {
          name: user.name,
          email: user.email,
          ...userProfile,
        },
        organisation,
        firstClub: !user.roles.includes("admin"),
      },
      confirmOnClose: true,
      handleClose: backToOnboarding
        ? backToOnboarding
        : () => send("CLOSE_MODAL"),
      componentPath: "CreateClub",
      modalSize: "md",
      overrideLayout: true,
    },
  })
}

export const tutorialModal = (send, step = 1) => {
  send("OPEN_MODAL", {
    payload: {
      id: "castJudgment",
      overrideLayout: true,
      state: {
        step,
      },
      componentPath: "Tutorial",
      modalSize: "md",
    },
  })
}

export const restartOnboardingModal = (
  send,
  { application, affiliate, isAffiliateLink }
) => {
  send("OPEN_MODAL", {
    payload: {
      id: "restartOnboardingPayload",
      title: "Start over",
      componentPath: "OnboardingStartOver",
      withHeader: true,
      withButtons: true,
      leftButton: {
        text: "Delete",
        type: "danger-text",
        callback: () =>
          deleteOnboardingConfirmation(send, application, affiliate),
      },
      state: {
        organisation: application,
        affiliate,
        isAffiliateLink,
      },
      overrideDefaultButtons: true,
      buttons: [
        {
          text: "Close",
          colWidth: 4,
          callback: () => send("CLOSE_MODAL"),
        },
        {
          text: "New application",
          type: "primary",
          iconType: "brand",
          colWidth: 7,
          icon: "stripe-s",
          submitting:
            "app.requests.restartStripeOnboarding.requesting|app.requests.restartAffiliateOnboarding.requesting",
          callback: () => {
            const onSuccess = {
              callback: ({ response }) => {
                window.location.href = response.stripeUrl
              },
              target: "CLOSE_MODAL",
            }

            if (affiliate) {
              send("restartAffiliateOnboarding", {
                data: {
                  onSuccess,
                },
              })
            } else {
              send("restartStripeOnboarding", {
                data: {
                  variables: {
                    organisationId: application.id,
                  },
                  onSuccess,
                },
              })
            }
          },
        },
      ],
      modalSize: "md",
    },
  })
}

export const displayStripeSignupModal = (send, affiliateLink = false) => {
  send("OPEN_MODAL", {
    payload: {
      id: "stripeNotification",
      title: "Important",
      state: {
        affiliateLink,
      },
      componentPath: "StripeConnectWarning",
      withHeader: true,
      withButtons: true,
      overrideLayout: true,
      modalSize: "md",
    },
  })
}

export const inviteManagerModal = (send, organisation) => {
  send("OPEN_MODAL", {
    payload: {
      ...defaults,
      id: "inviteManager",
      title: "Invite manager",
      state: {
        manager: {
          email: "",
          role: "admin",
        },
      },
      componentPath: "InviteManager",
      confirmOnClose: true,
      modalSize: "md",
      buttons: [
        {
          text: "Invite manager",
          type: "primary",
          submitting: "app.requests.inviteTeam.requesting",
          colWidth: 7,
          icon: "paper-plane",
          callback: (event, validator, isFormValid, state) => {
            handleFormSubmission(validator, () => {
              send("inviteTeam", {
                data: {
                  body: state.manager,
                  variables: {
                    organisationId: organisation.id,
                  },
                  onSuccess: {
                    notification: {
                      title: `Invite sent to ${state.manager.email}`,
                    },
                    target: "CLOSE_MODAL",
                  },
                },
              })
            })
          },
        },
      ],
    },
  })
}
