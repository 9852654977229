import React from "react"
import payzipLogo from "../../assets/img/logo.svg"

const IncompatibleBrowser = () => {
  return (
    <table className="h-full w-full">
      <tbody>
        <tr className="sm:table-row hidden" />
        <tr>
          <td className="sm:table-cell hidden" />
          <td width="400px">
            <div className="bg-white sm:rounded rounded-none p-md sm:h-auto h-full">
              <img
                className="mb-md w-1/3 min-w-xxs"
                src={payzipLogo}
                alt="Payzip"
              />
              <h1 className="mb-md">Incompatible browser</h1>
              <div className="mb-sm">
                The browser you're using is out-of-date and won't work with
                Payzip. You'll need to switch to a different browser, or update
                this one.
              </div>
              <div>
                If you need help choosing a new browser which will be
                compatible, you can visit{" "}
                <a className="inline" href="https://choosebrowser.com/">
                  choosebrowser.com
                </a>
              </div>
            </div>
          </td>
          <td className="sm:table-cell hidden" />
        </tr>
        <tr className="sm:table-row hidden" />
      </tbody>
    </table>
  )
}
export default IncompatibleBrowser
