import React from "react"

import { getOptionInitials } from "../../utils/functions"

export default ({
  withIcon = true,
  icon,
  title,
  truncateLabel = false,
  truncateWidth = "auto",
  onClick = () => {},
  iconBackground,
}) => (
  <div className="flex flex-1" onClick={onClick}>
    {withIcon && (
      <div
        className={`bg-${
          iconBackground ? iconBackground : "primary-4"
        } rounded-full h-28 w-28 text-white flex items-center justify-center text-sm`}
      >
        {icon || getOptionInitials(title)}
      </div>
    )}
    {title && (
      <div
        className={`ml-sm my-auto text-neutral-1 ${
          truncateLabel ? "truncate" : ""
        }`}
        style={{ maxWidth: truncateWidth }}
      >
        {title}
      </div>
    )}
  </div>
)
