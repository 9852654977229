import { getUserOrganisations } from "./organisation"
import { getUserProfile } from "./user"

export default [
  {
    target: "login",
    payload: {
      type: "post",
      url: "/auth/signin",
      appState: true,
    },
    onSuccess: {
      target: "getLoginDetails",
    },
  },
  {
    target: "getLoginDetails",
    payload: [
      { ...getUserProfile, id: "getUserProfile" },
      {
        ...getUserOrganisations,
        id: "getUserOrganisations",
        transformResponse: (response, context) => {
          if (context.organisation) {
            const { organisation, organisations } = response.data
            let currentOrgId = context.organisation.id
            let fetchedCurrentOrg = organisation
              ? organisation.id === currentOrgId
              : null
            if (fetchedCurrentOrg) {
              response.data.tutorial = context.organisation.tutorial
              return response
            }

            if (organisations && organisations.length) {
              response.data.organisation = organisations.filter(
                (org) => org.id === currentOrgId
              )[0]
            } else {
              response.data.organisation = null
              response.data.tutorial = {}
            }
          }
          if (response.data && response.data.organisation) {
            response.data.tutorial = response.data.organisation.tutorial
          }
          return response
        },
      },
    ],
  },
  {
    target: "register",
    payload: {
      url: "/auth/signup",
      type: "post",
      appState: true,
    },
  },
  {
    target: "requestPasswordReset",
    payload: {
      url: "/auth/password",
      type: "post",
    },
  },
  {
    target: "resendVerificationEmail",
    payload: {
      url: "/auth/verify/resend",
      type: "post",
    },
  },
  {
    target: "updateUserEmail",
    payload: {
      url: "/auth/email/update",
      appState: true,
      type: "post",
    },
  },
  {
    target: "verifyEmail",
    payload: {
      url: ({ verificationString }) => `/auth/verify/${verificationString}`,
      appState: true,
      type: "get",
    },
  },
  {
    target: "checkResetToken",
    payload: {
      url: ({ token }) => `/auth/password/${token}`,
      type: "get",
    },
  },
  {
    target: "resetPassword",
    payload: {
      url: ({ token }) => `/auth/password/${token}`,
      type: "post",
      appState: true,
    },
    onSuccess: {
      target: "getLoginDetails",
    },
  },
  {
    target: "updateUserProfile",
    payload: {
      url: "/users/profile",
      type: "post",
      appState: true,
    },
  },
  {
    target: "updateUserPassword",
    payload: {
      url: `/users/password`,
      type: "post",
    },
    onSuccess: {
      notification: {
        title: "Password updated",
      },
      target: "CLOSE_MODAL",
    },
  },
  {
    target: "verifyInviteToken",
    payload: {
      url: ({ inviteToken }) => `/auth/invite/${inviteToken}`,
      type: "get",
    },
  },
]
