import React from 'react'
import { CalendarContainer } from 'react-datepicker'

export default ({ children, showPopperArrow }) => {
  return (
    <CalendarContainer>
      {
        showPopperArrow && (
          <div className="absolute bg-white transform rotate-45 w-base h-base dropdown-shadow datepicker-arrow" />
        )
      }
      <div className="relative flex flex-col bg-white max-w-20 rounded dropdown-shadow">
        { children }
      </div>
    </CalendarContainer>
  )
}
