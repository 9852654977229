import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react"

import Checkbox from "../../Input/Checkbox"
import { useEventListener } from "../../../utils/hooks"
import { useFormValidator } from "../../Forms/Form"

export default forwardRef(
  (
    {
      step,
      isUKBased,
      acceptedTAndC,
      ageCheck,

      setState,
      nextStep,
    },
    ref
  ) => {
    const [validator] = useFormValidator()
    const [displayError, updateDisplayError] = useState(false)

    useEffect(() => {
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        let checkedFields = {
          isUKBased: validator.fields.isUKBased,
          acceptedTAndC: validator.fields.acceptedTAndC,
          ageCheck: validator.fields.ageCheck,
        }
        let unchecked = Object.values(checkedFields).filter(
          (checked) => !checked
        )

        if (!unchecked.length) {
          nextStep()
        } else {
          updateDisplayError(true)
        }
      },
    }))

    const handleCheck = ({ target }) => {
      const { name, checked } = target

      setState({ [name]: checked })

      let currentState = {
        isUKBased,
        acceptedTAndC,
        ageCheck,
      }
      currentState[name] = checked

      if (!Object.values(currentState).filter((check) => !check).length) {
        updateDisplayError(false)
      }
    }

    const checkKeyPress = useCallback(
      (event) => {
        if (event.keyCode === 13 && step === 1) {
          let checkedFields = {
            isUKBased: validator.fields.isUKBased,
            acceptedTAndC: validator.fields.acceptedTAndC,
            ageCheck: validator.fields.ageCheck,
          }
          let unchecked = Object.values(checkedFields).filter(
            (checked) => !checked
          )

          if (!unchecked.length) {
            nextStep()
          } else {
            updateDisplayError(true)
          }
        }
      },
      [step, validator, nextStep, updateDisplayError]
    )

    useEventListener("keydown", checkKeyPress)

    return (
      <div>
        <div className="mb-md">
          <div className="text-neutral-5 font-medium mb-xs">
            Important information
          </div>
          <div className="grid grid-cols-1 gap-xs">
            <div>
              Having an affiliate account allows you to earn a commission from
              each card payment of clubs and organisatons that you introduce to
              Payzip.
            </div>
            <div>
              For each card payment, Payzip charges a service fee, which
              consists of a percentage and a fixed amount. Your commission is
              calculated using the percentage element of the service fee only,
              net of VAT.
            </div>
            <div>
              Before creating your affiliate account, we need to verify who you
              are and understand how you intend to promote Payzip. In this
              process you will:
            </div>
            <ul className="list-disc pl-md">
              <li>confirm eligibility information about you;</li>
              <li>
                provide personal details so we can contact you if we have
                further questions;
              </li>
              <li>tell us which channels you will use to promote Payzip.</li>
            </ul>
          </div>
        </div>
        <div className="text-neutral-5 font-medium mb-xs">Declaration</div>
        <div>
          To use Payzip, you must tick to confirm you agree to each of the
          following declarations. We will use the information you provide to
          determine if you can become an affiliate.
        </div>
        <div className="grid grid-cols-1 gap-sm mt-sm">
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="ageCheck"
            checked={ageCheck}
            label="I am over 18"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="isUKBased"
            checked={isUKBased}
            label="I live in the UK"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="acceptedTAndC"
            checked={acceptedTAndC}
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          >
            I accept Payzip's{" "}
            <a
              href="https://www.payzip.co.uk/affiliate-terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              terms and conditions
            </a>
          </Checkbox>
        </div>
        {displayError ? (
          <div className="text-danger-5 mt-sm">
            You must be able to truthfully confirm and tick each of the
            checkboxes above to become an affiliate
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
)
