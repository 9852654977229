import React, { useContext, useEffect, useState } from "react"
import moment from "moment"

import { useFormValidator } from "../Forms/Form"
import { MachineContext } from "../../state"

export default ({
  validation,
  name,
  id,
  value,
  large,
  dateValidation = false,
}) => {
  const [
    {
      context: { errors },
    },
  ] = useContext(MachineContext)
  const [validator] = useFormValidator()
  const [validatedValue, setValidatedValue] = useState(value)

  useEffect(() => {
    let validatingValue = value

    if (dateValidation) {
      if (moment(value, "DD/MM/YYYY", true).isValid()) {
        validatingValue = moment(value, "DD/MM/YYYY")
      } else if (moment(value).isValid()) {
        validatingValue = moment(value)
      }
    }

    setValidatedValue(validatingValue)
  }, [dateValidation, value])

  if (validator && validation) {
    return (
      <div className="text-sm">
        {validator.message(name, validatedValue, validation.rules, {
          element: (message) => {
            if (errors && errors.fields && errors.fields[name]) {
              return <React.Fragment />
            }
            return <div className="error-label">{message}</div>
          },
          messages: validation.message
            ? {
                required: validation.message,
              }
            : {
                ...validation.messages,
              },
        })}
        {errors && errors.fields && errors.fields[name] && (
          <div className="error-label">
            {errors.fields && errors.fields[name]}
          </div>
        )}
      </div>
    )
  }

  return <React.Fragment />
}
