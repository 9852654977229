import React from "react"

import { viewCreateOrEditPing } from "../modals/ping"

export const cannotCreatePingConfirmation = (send) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "cannotCreatePing",
      title: "Cannot create Ping",
      body: [
        "You must have at least one member in your club to create a Ping",
        "Go to 'Members' where you can import your member spreadsheet or add members manually.",
      ],
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Go to members",
          type: "primary",
          icon: "arrow-circle-right",
          colWidth: 7,
          iconType: "duotone",
          callback: () => send("CLOSE_MODAL"),
          to: "/members",
        },
      ],
    },
  })
}

export const restartPingConfirmation = (send, context, ping) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "restartPingAfterDelete",
      title: "Restart Ping",
      body: ["Would you like to restart Ping?"],
      buttons: [
        {
          text: "Don't restart",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Restart Ping",
          type: "primary",
          icon: "check-circle",
          iconType: "duotone",
          callback: () => {
            viewCreateOrEditPing(send, context, ping, {
              restartingPing: true,
            })
            send("CLOSE_CONFIRMATION")
          },
        },
      ],
    },
  })
}

export const deletePurchaseConfirmation = (
  send,
  { pingId, purchaseId, members }
) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "deletePingPurchase",
      title: "Delete response",
      body: [
        "This will remove the response for:",
        <ul>
          {members.map((member, index) => (
            <li key={"member-name-" + index}>{member.name}</li>
          ))}
        </ul>,
        "Are you sure you want to delete this response?",
      ],
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Delete response",
          type: "danger",
          iconType: "duotone",
          icon: "exclamation-circle",
          submitting: "app.requests.deletePurchase.requesting",
          colWidth: 7,
          callback: () =>
            send("deletePurchase", {
              data: {
                variables: {
                  pingId,
                  purchaseId,
                },
                onSuccess: {
                  target: "CLOSE_MODAL",
                  callback: () => {
                    send("getPing", {
                      data: {
                        variables: {
                          pingId,
                        },
                        storeData: false,
                        onSuccess: {
                          callback: ({ response }, context) => {
                            if (response.stopped) {
                              restartPingConfirmation(send, context, response)
                            }
                          },
                        },
                      },
                    })
                  },
                },
              },
            }),
        },
      ],
    },
  })
}

export const deletePingConfirmation = (send, ping, notDeleteable, history) => {
  let body = [
    "You cannot delete this Ping as payments have been made against it.",
  ]
  let buttons = [
    {
      text: "Okay",
      type: "primary",
      icon: "check-circle",
      colWidth: 7,
      iconType: "duotone",
      submitting: "app.requests.editPing.requesting",
      callback: () => {
        send("CLOSE_CONFIRMATION")
      },
    },
  ]

  if (!ping.stopped) {
    body.push(
      "You can stop this Ping to deactivate the shareable link and stop any more responses from being recorded. You can restart it later - you may have to pick a new end date if it has passed."
    )
    buttons = [
      {
        text: "Cancel",
        colWidth: 4,
        callback: () => {
          send("CLOSE_CONFIRMATION")
        },
      },
      {
        text: "Stop Ping",
        type: "primary",
        icon: "check-circle",
        colWidth: 7,
        iconType: "duotone",
        submitting: "app.requests.editPing.requesting",
        callback: () => {
          send("editPing", {
            data: {
              variables: {
                pingId: ping.id,
              },
              body: {
                comments: ping.comments,
                description: ping.description,
                stopped: true,
              },
              onSuccess: {
                callback: () => {
                  send("CLOSE_CONFIRMATION")
                },
              },
            },
          })
        },
      },
    ]
  }

  if (notDeleteable) {
    send("OPEN_CONFIRMATION", {
      payload: {
        id: "deletePingConfirmation",
        title: `Delete Ping`,
        body,
        buttons,
      },
    })
  } else {
    send("OPEN_CONFIRMATION", {
      payload: {
        id: "deletePingConfirmation",
        title: `Delete Ping`,
        body: [
          "Deleting this Ping will deactivate the shareable link and remove this Ping from Payzip.",
          ping.members > 0
            ? "Some members have responded to this Ping already. You can still delete it, and if you do, we'll let those members know."
            : "",
        ],
        buttons: [
          {
            text: "Cancel",
            colWidth: 4,
            callback: () => send("CLOSE_CONFIRMATION"),
          },
          {
            text: "Delete Ping",
            type: "danger",
            iconType: "duotone",
            icon: "exclamation-circle",
            colWidth: 7,
            submitting: "app.requests.deletePing.requesting",
            callback: () => {
              send("deletePing", {
                data: {
                  variables: {
                    pingId: ping.id,
                  },
                  onSuccess: {
                    notification: {
                      title: `Ping deleted`,
                    },
                    target: "CLOSE_MODAL",
                    callback: () => {
                      history.push("/pings")
                    },
                  },
                },
              })
            },
          },
        ],
      },
    })
  }
}
