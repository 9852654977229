import React, {
  useReducer,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import moment from "moment"

import Input from "../../Input"
import Button from "../../Button"
import Textarea from "../../Input/Textarea"
import LineItem from "../../Forms/LineItem"
import DatePicker from "../../Datepicker"

import {
  formatCurrency,
  currencyToPennies,
  cloneObject,
  getInvoiceTotal,
} from "../../../utils/functions"
import { MachineContext } from "../../../state"
import { useFormValidator } from "../../Forms/Form"
import Tag from "../../Tag"
import {
  removeMemberConfirmation,
  saveChangesConfirmation,
} from "../../../utils/confirmations/invoice"

const formatInvoice = (invoice = {}) => {
  return {
    title: invoice.title,
    dueDate: invoice.dueDate || "",
    description: invoice.description,
    footer: invoice.footer,
    lineItems: invoice.lineItems,
  }
}

export default forwardRef(
  (
    {
      baseInvoice,
      title,
      dueDate,
      description,
      footer,
      members = [],
      allMembers = [],
      lineItems = [],
      selectedId = 0,
      selectedMember = {},
      memberCount,
      showTotalError,
      totalError,

      setState,
    },
    ref
  ) => {
    const [, send] = useContext(MachineContext)
    const currentInvoice = useRef()
    const [validator, { validateForm }] = useFormValidator()
    const [customInvoice, setCustomInvoice] = useReducer(
      (state, action) => action,
      formatInvoice(baseInvoice)
    )

    useEffect(() => {
      currentInvoice.current = {
        title,
        dueDate,
        description,
        footer,
        lineItems,
      }
    }, [description, dueDate, footer, lineItems, title])

    useEffect(() => {
      validator.purgeFields()
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    const customInvoiceHasChanges =
      JSON.stringify(formatInvoice(currentInvoice.current)) !==
      JSON.stringify(formatInvoice(customInvoice))

    useImperativeHandle(ref, () => ({
      validateStep: (callback) => {
        showTotalError(false)
        const invoiceTotal = customInvoice.lineItems.reduce(
          (a, b) => a + b.amount,
          0
        )

        const invalidValue = invoiceTotal < 50 && invoiceTotal != 0
        if (!validator.allValid() || invalidValue) {
          if (invalidValue) {
            showTotalError(true)
          }
          validator.showMessages()
        } else {
          if (customInvoiceHasChanges) {
            validator.hideMessages()
            saveChangesConfirmation(
              "changeSelectedMember",
              setState,
              resetInvoice,
              validator,
              send,
              {
                currentId: selectedId,
                baseInvoice,
                selectedId,
                customInvoice: {
                  ...customInvoice,
                  custom: true,
                },
                member: selectedMember,
                members: allMembers,
                callback,
              }
            )
          } else {
            validator.hideMessages()

            callback()
          }
        }
      },
    }))

    const batchTotal = members.reduce(
      (a, b) => a + parseInt(getInvoiceTotal(b, baseInvoice)),
      0
    )
    const selectedIndex = members.findIndex((mem) => mem.id === selectedId)

    useEffect(() => {
      const lastLineItem = lineItems[lineItems.length - 1]
      validator.purgeFields()
      if (lastLineItem.description) {
        lineItems.push({ description: "", amount: "" })
      }
      setCustomInvoice({
        title,
        dueDate,
        description,
        footer,
        lineItems: cloneObject(lineItems),
      })
    }, [title, dueDate, description, footer, lineItems, selectedId, validator])

    const updateCustomInvoice = ({ target: { name, value } }) => {
      setCustomInvoice({
        ...customInvoice,
        [name]: value,
      })
    }

    const updateLineItems = ({ name, value }, index) => {
      let items = customInvoice.lineItems
      if (typeof value === "number" && isNaN(value)) {
        value = ""
      }

      items[index][name] = value

      if (index === items.length - 1) {
        items.push({ description: "", amount: "" })
      }

      updateCustomInvoice({ target: { name: "lineItems", value: items } })
    }

    const resetInvoice = () => {
      validator.hideMessages()
      validator.purgeFields()

      setCustomInvoice({
        title,
        dueDate,
        description,
        footer,
        lineItems: cloneObject(lineItems),
      })
    }

    const removeLineItem = (index) => {
      updateCustomInvoice({
        target: {
          name: "lineItems",
          value: customInvoice.lineItems.filter((item, key) => index !== key),
        },
      })
      validator.purgeFields()
    }

    const removeMember = () => {
      if (members.length) {
        removeMemberConfirmation(setState, send, {
          member: selectedMember,
          members: allMembers,
          baseInvoice,
          selectedId,
        })
      }
    }

    const saveCustomInvoice = () => {
      showTotalError(false)
      const invoiceTotal = customInvoice.lineItems.reduce(
        (a, b) => a + b.amount,
        0
      )
      if (validator.allValid() && (invoiceTotal > 49 || invoiceTotal == 0)) {
        validator.hideMessages()

        if (customInvoiceHasChanges) {
          setState({
            members: allMembers.map((member) => {
              if (member.id === selectedId) {
                member.invoice = {
                  ...customInvoice,
                  custom: true,
                  lineItems: customInvoice.lineItems.filter(
                    (item) => item.description
                  ),
                }
              }

              return member
            }),
          })
        }

        if (
          JSON.stringify(formatInvoice(baseInvoice)) ===
          JSON.stringify(formatInvoice(customInvoice))
        ) {
          setState({
            members: allMembers.map((member) => {
              if (member.id === selectedId) {
                member = {
                  id: member.id,
                  name: member.name,
                  email: member.email,
                  groups: member.groups,
                }
              }

              return member
            }),
          })
        }
      } else {
        if (invoiceTotal < 50 && invoiceTotal != 0) {
          showTotalError(true)
        }
        validateForm()
      }
    }

    const selectNewMember = (memberId) => {
      showTotalError(false)
      const invoiceTotal = customInvoice.lineItems.reduce(
        (a, b) => a + b.amount,
        0
      )
      if (validator.allValid() && (invoiceTotal > 49 || invoiceTotal == 0)) {
        validator.hideMessages()
        if (customInvoiceHasChanges) {
          saveChangesConfirmation(
            "changeSelectedMember",
            setState,
            resetInvoice,
            validator,
            send,
            {
              currentId: memberId,
              member: selectedMember,
              baseInvoice,
              selectedId,
              customInvoice,
              members: allMembers,
            }
          )
        } else {
          setState({ selectedId: memberId })
        }
      } else {
        if (invoiceTotal < 50 && invoiceTotal != 0) {
          showTotalError(true)
        }
        validateForm()
      }
    }

    return (
      <div className="grid grid-cols-2 relative h-full sm:max-h-modal-body max-h-modal-mobile">
        <div
          className="grid grid-cols-1 sm:max-h-modal-body max-h-modal-mobile h-full"
          style={{ gridTemplateRows: "auto 48px" }}
        >
          {/* MEMBERS LIST */}
          {allMembers && allMembers.length ? (
            <div className="h-full w-full bg-white text-neutral-1 pt-sm px-md relative shadow-r-border inline-block overflow-y-auto cursor-pointer">
              <div
                className={`grid grid-cols-3 min-h-md pb-sm leading-tight ${
                  members.length === 0 ? "shadow-b-border" : ""
                }`}
              >
                <div className="col-span-2 font-medium py-xs">Name</div>
                <div className="text-right font-medium py-xs">Amount</div>
              </div>

              {members &&
                members.length >= 0 &&
                members.map((member, index) => {
                  return (
                    <div
                      key={"member-" + index}
                      className={`flex justify-between min-h-md py-sm border-neutral-8
                          ${index === selectedIndex + 1 ? "" : "border-t"}
                          ${
                            index === selectedIndex
                              ? "bg-neutral-10 px-md border-b border-t -mx-md"
                              : ""
                          }
                        `}
                      onClick={() => selectNewMember(member.id)}
                    >
                      <div>{member.name}</div>
                      <div className="text-right flex justify-between">
                        {member.invoice && member.invoice.custom && (
                          <Tag className="text-sm h-md mr-sm" type="warning">
                            Custom
                          </Tag>
                        )}
                        {formatCurrency(getInvoiceTotal(member, baseInvoice))}
                      </div>
                    </div>
                  )
                })}
            </div>
          ) : (
            <div className="bg-white shadow-r-border px-xl row-span-2 text-center flex flex-col justify-center items-center py-auto">
              <div className="text-neutral-1 mt-2xl">
                You need at least one recipient before you can send this
                invoice.
              </div>
              <div
                className="rounded-full shadow-border py-xs px-md cursor-pointer inline-block mt-md mb-2xl"
                onClick={() => setState({ step: 2 })}
              >
                Add recipients
              </div>
            </div>
          )}

          {/* MEMBERS LIST STATS */}
          <div
            className={`border-r border-neutral-8 bg-white${
              memberCount === 0 ? " hidden" : ""
            }`}
          >
            <div className="px-md py-xs shadow-t-border w-full">
              <div className="flex justify-between py-sm text-neutral-1 leading-tight">
                <div className="font-medium">
                  {memberCount !== members.length
                    ? `${members.length} of ${memberCount} recipients`
                    : `${members.length} recipients`}
                </div>
                <div className="font-medium text-right">
                  Total: {formatCurrency(batchTotal)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* INVOICE ACTIONS */}
          <div className="bg-white shadow-b-border px-md py-sm">
            <div className="grid grid-cols-3 leading-tight">
              <div>
                <div
                  onClick={removeMember}
                  className="text-left text-danger-5 font-normal py-xs inline-block cursor-pointer hover:underline"
                >
                  Delete
                </div>
              </div>
              <div className="col-span-2 font-medium text-right">
                <div className="inline-block">
                  <Button
                    onClick={resetInvoice}
                    disabled={!customInvoiceHasChanges}
                  >
                    Discard
                  </Button>
                </div>
                <div className="ml-sm inline-block">
                  <Button
                    onClick={saveCustomInvoice}
                    type="primary"
                    disabled={!customInvoiceHasChanges}
                    icon="sync-alt"
                    background={
                      customInvoiceHasChanges ? false : "bg-neutral-10"
                    }
                  >
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* CUSTOM INVOICE */}
          <div
            className="px-md inline-block overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 248px)" }}
          >
            <div className="py-md" id="invoice">
              {selectedMember.name && (
                <div className="grid grid-cols-5 gap-x-md gap-y-xs">
                  <div>Recipient</div>
                  <div className="col-span-4 text-neutral-1">
                    {selectedMember.name}
                  </div>
                </div>
              )}
              <div className="grid grid-cols-5 gap-x-md gap-y-xs">
                <div className="w-1/5">Invoice</div>
                <div className="col-span-4 text-neutral-1">{title}</div>
              </div>
              {selectedMember.email && (
                <div className="grid grid-cols-5 gap-x-md gap-y-xs">
                  <div className="w-1/5">Email</div>
                  <div className="col-span-4 text-neutral-1">
                    {selectedMember.email}
                  </div>
                </div>
              )}
            </div>
            <DatePicker
              date={customInvoice.dueDate || null}
              format="dd/MM/yyyy"
              onChange={(dueDate) =>
                updateCustomInvoice({
                  target: { name: "dueDate", value: dueDate },
                })
              }
              placeholder="Select date"
              popperPlacement="bottom"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "0, 5px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              minDate={new Date()}
              clearText="Clear date"
              customInput={
                <Input
                  small
                  label="Due date"
                  name="dueDate"
                  placeholder="Select date"
                  showSelectChevron
                  dateDisplay
                  mask="99/99/9999"
                  validateOnBlur={false}
                  classes={{
                    input: "cursor-pointer",
                  }}
                  validation={{
                    name: "dueDate",
                    messages: {
                      required: "Enter a due date",
                      date: "Enter a valid date",
                      after_or_equal: "Pick a date in the future",
                    },
                    rules: [
                      "required",
                      "date",
                      {
                        after_or_equal: moment(),
                      },
                    ],
                  }}
                />
              }
              todayButton
            />
            <div className="mt-md">
              <Textarea
                id="comments"
                small
                label="Comments"
                name="description"
                value={customInvoice.description}
                rows="3"
                onChange={updateCustomInvoice}
                length={500}
                validation={{
                  name: "description",
                  messages: {
                    max: "Maximum character limit exceeded",
                  },
                  rules: "max:500",
                }}
              />
            </div>
            <div className="mt-md">
              <div className="grid grid-cols-1 gap-sm">
                {customInvoice.lineItems &&
                  customInvoice.lineItems.map((item, index) => (
                    <LineItem
                      key={index}
                      index={index}
                      description={item.description}
                      amount={item.amount}
                      removeItem={removeLineItem}
                      setState={({ target }) => updateLineItems(target, index)}
                      showBin={index !== customInvoice.lineItems.length - 1}
                      validateRow={
                        index !== customInvoice.lineItems.length - 1 ||
                        customInvoice.lineItems.length === 1
                      }
                    />
                  ))}
                <div className="grid grid-cols-12 gap-sm">
                  <div
                    className={`flex ${
                      totalError ? "text-danger-5 items-end" : "text-neutral-5"
                    } col-span-11 grid grid-cols-5`}
                  >
                    {totalError ? (
                      <div className="col-span-3">
                        Invoice must be at least £0.50 (or £0.00)
                      </div>
                    ) : null}
                    <div
                      className={`font-semibold text-lg text-right col-span-${
                        totalError ? "2" : "5"
                      }`}
                    >
                      Total:{" "}
                      {customInvoice.lineItems &&
                        customInvoice.lineItems.length > 0 &&
                        formatCurrency(
                          customInvoice.lineItems
                            .filter((item) => item.amount)
                            .reduce(
                              (a, b) => a + currencyToPennies(b.amount),
                              0
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-md whitespace-pre-line">{footer}</div>
          </div>
        </div>
      </div>
    )
  }
)
