import React from "react"

export default ({ children, type, className }) => {
  let style = "bg-neutral-9 text-neutral-5"

  switch (type) {
    case "success":
      style = "bg-success-6 text-success-10"
      break
    case "danger":
      style = "bg-danger-5 text-danger-10"
      break
    case "warning":
      style = "bg-warning-8 text-warning-5"
    default:
      break
  }

  return (
    <div className={`${style} rounded px-xs inline-block ${className}`}>
      <div className="flex items-center h-full">{children}</div>
    </div>
  )
}
