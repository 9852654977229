import React from "react"

import Sidebar from "../Sidebar"
import DashboardHeader from "./DashboardHeader"
import DashboardContainer from "./DashboardContainer"
import StripeConnectBanner from "../Banners/StripeConnectBanner"

export default ({
  children,
  name,
  email,
  actions,
  menu = [],
  pathname,
  organisation,
  banner = {},
  sidebar = "",
  hideLogo = false,
  logoPath = "/",
  dashboardClassNames = "",
}) => {
  return (
    <DashboardContainer>
      <div className="sm:block hidden">
        <StripeConnectBanner />
      </div>
      <div
        className={
          sidebar !== ""
            ? "flex sm:max-w-admin-dashboard sm:mx-auto sm:px-lg justify-center"
            : "flex justify-center"
        }
      >
        {sidebar !== "" && (
          <Sidebar
            type={sidebar}
            menu={menu}
            pathname={pathname}
            organisation={organisation}
          />
        )}
        <div
          className={`sm:max-w-base w-full sm:h-auto h-screen sm:inline-block flex flex-col ${dashboardClassNames}`}
        >
          <DashboardHeader
            hideLogo={hideLogo}
            bannerText={banner.text}
            bannerClassName={banner.className}
            name={name}
            email={email}
            actions={actions}
            logoPath={logoPath}
          />

          {children}
        </div>
      </div>
    </DashboardContainer>
  )
}
