import { submitData } from "../utils/api"

import requests from "../utils/requests"

const states = {}
requests.map((request) => {
  states[request.target] = {
    id: request.target,
    type: "compound",
    initial: "idle",
    states: {
      idle: {
        on: {
          [request.target]: {
            target: "requesting",
          },
        },
      },
      requesting: {
        invoke: {
          id: request.target,
          src: (context, event) => {
            let onSuccess, onError
            if (event.data) {
              if (event.data.onSuccess) {
                onSuccess = event.data.onSuccess
              }
              if (event.data.onError) {
                onError = event.data.onError
              }
            }

            if (!onSuccess && request.onSuccess) {
              onSuccess = request.onSuccess
            }
            if (!onError && request.onError) {
              onError = request.onError
            }

            return submitData(
              request,
              event.data,
              context,
              onSuccess,
              onError,
              event.type
            )
          },
          onDone: {
            target: "idle",
            actions: [
              "handleRequestResponse",
              "handleSuccess",
              "handleSendEvent",
            ],
          },
          onError: {
            target: "idle",
            actions: ["handleError", "handleRetryBanner"],
          },
        },
        on: {
          [request.target + "_CANCEL"]: {
            target: "idle",
          },
          CANCEL_REQUESTS: {
            target: "idle",
          },
        },
      },
    },
  }

  return request
})

const mappedRequest = {
  type: "parallel",
  initial: "idle",
  states: {
    idle: {},
    ...states,
    export: {
      id: "exportTable",
      type: "compound",
      initial: "idle",
      states: {
        idle: {
          on: {
            exportTable: {
              target: "requesting",
            },
          },
        },
        requesting: {
          invoke: {
            id: "exportTable",
            src: (context, event) => {
              let onSuccess, onError
              if (event.data) {
                if (event.data.onSuccess) {
                  onSuccess = event.data.onSuccess
                }
                if (event.data.onError) {
                  onError = event.data.onError
                }
              }

              return submitData(
                event.data,
                event.data,
                context,
                onSuccess,
                onError,
                event.type
              )
            },
            onDone: {
              target: "idle",
              actions: ["handleSuccess", "handleSendEvent"],
            },
            onError: {
              target: "idle",
              actions: ["handleError"],
            },
          },
          on: {
            exportTable_CANCEL: {
              target: "idle",
            },
          },
        },
      },
    },
  },
}

export default mappedRequest
