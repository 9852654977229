export const verificationEmailSent = (send, email) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "removeManagerInvoice",
      title: "Verification email sent",
      body: `We’ve sent a verification link to ${email}. Please click the link to set your new email address.`,
      buttons: [
        {
          text: "Okay",
          type: "primary",
          iconType: "duotone",
          icon: "check-circle",
          callback: () => send("CLOSE_MODAL"),
        },
      ],
    },
  })
}

export const loseFormChangesConfirmation = (submitCallback, send) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "loseFormChanges",
      title: "Close form",
      body: "Closing this form will lose your recent changes.",
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => {
            send("CLOSE_CONFIRMATION")
          },
        },
        {
          text: "Close form",
          type: "danger",
          colWidth: 7,
          iconType: "duotone",
          icon: "exclamation-circle",
          callback: () => {
            submitCallback()
            send("CLOSE_CONFIRMATION")
            send("CLOSE_MODAL")
          },
        },
      ],
    },
  })
}

export const discardFormChangesConfirmation = (submitCallback, send) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "discardFormChanges",
      title: "Discard changes",
      body: "You will lose your recent changes.",
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => {
            send("CLOSE_CONFIRMATION")
          },
        },
        {
          text: "Discard changes",
          colWidth: 7,
          type: "danger",
          iconType: "duotone",
          icon: "exclamation-circle",
          callback: () => {
            submitCallback()
            send("CLOSE_MODAL")
          },
        },
      ],
    },
  })
}
