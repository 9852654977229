export const handleTableDataTransform = ({ data }, context) => {
  const pages = Math.ceil(data.count / 15)

  let tableProps = {
    ...context.tableProps,
    data: data.rows,
  }
  if (context.tableProps && context.tableProps.page > pages && pages > 0) {
    tableProps.page = pages
  }

  return {
    data: {
      tableProps,
      paginationProps: {
        ref: "batchInvoices",
        totalRowCount: data.count,
        pages: Math.ceil(data.count / 15),
      },
    },
  }
}

export const returnOrganisationsTransform = ({ data }, context) => {
  let organisation = data.organisation
  if (context.organisation) {
    organisation = context.organisation
  }

  return {
    data: {
      organisation,
      organisations: data.organisations,
    },
  }
}

export const rowsToMembersTransform = ({ data }) => {
  return { data: { members: data.rows } }
}
