import React, { useContext, useRef } from "react"

import Body from "../../containers/Body"
import { CSVLink } from "react-csv"
import Form from "../../Forms/Form"
import Footer from "../../containers/Footer"
import Header from "../../containers/Header"
import { MachineContext } from "../../../state"
import { pluraliseWord } from "../../../utils/functions"

export default ({
  updatedCount,
  validMembers,
  invalidMembers,
  setStep,
  handleClose,
}) => {
  const [current, send] = useContext(MachineContext)
  const csvDownloader = useRef()

  const uploadMembers = () => {
    send("addMembers", {
      data: {
        body: validMembers,
        variables: {
          organisationId: current.context.organisation.id,
          imported: true,
        },
        storeData: false,
        refreshPageData: true,
        onSuccess: {
          notification: ({ response }) => {
            return {
              title: `${response.members.length} ${pluraliseWord(
                "member",
                response.members.length
              )} added`,
              description: "Click here to view",
              to: "/members",
            }
          },
          callback: () => {
            send("UPDATE_TUTORIAL_CONTEXT", {
              tutorial: {
                hasMembers: true,
              },
            })

            send("CLOSE_MODAL")
          },
        },
      },
    })
  }

  return (
    <Form>
      <Header>Complete import</Header>
      <Body bodyStyles="text-neutral-6">
        <div className="font-semibold text-neutral-5 mb-xs">Summary</div>
        <div>
          <span className="font-semibold">
            {validMembers.length - updatedCount > 0
              ? validMembers.length - updatedCount
              : 0}{" "}
          </span>
          new{" "}
          {pluraliseWord(
            "member",
            validMembers.length - updatedCount > 0
              ? validMembers.length - updatedCount
              : 0
          )}{" "}
          will be imported
        </div>
        {updatedCount > 0 && (
          <div>
            <span className="font-semibold">{updatedCount} </span>
            {pluraliseWord("member", updatedCount)} already exist and will be
            updated
          </div>
        )}
        {invalidMembers.length > 0 && (
          <div>
            <span className="font-semibold">{invalidMembers.length} </span>
            {pluraliseWord("row", invalidMembers.length)} had errors and will
            not be imported (
            <CSVLink
              ref={csvDownloader}
              data={invalidMembers.map((member) => {
                const rows = [member.name, member.email, member.reason]

                if (Array.isArray(member.groups) && member.groups.length > 0) {
                  rows.splice(2, 0, member.groups[0])
                }

                return rows
              })}
              target="_blank"
              filename="invalid-members.csv"
              className="inline"
            >
              download
            </CSVLink>{" "}
            to view them)
          </div>
        )}
      </Body>
      <Footer
        leftButton={{
          text: "Previous",
          callback: () => setStep(3),
        }}
        buttons={[
          { callback: () => handleClose(), text: "Cancel" },
          {
            type: "primary",
            callback: uploadMembers,
            iconType: "duotone",
            icon: "check-circle",
            text: "Complete import",
            submitting:
              current.matches("app.requests.addMembers.requesting") ||
              current.matches(
                "app.requests.checkForExistingMembers.requesting"
              ),
          },
        ]}
      />
    </Form>
  )
}
