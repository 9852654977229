import React, { useState, useContext } from "react"
import Header from "../../containers/Header"
import Form from "../../Forms/Form"
import Footer from "../../containers/Footer"
import Input from "../../Input"
import { MachineContext } from "../../../state"

export default ({ invoice, member }) => {
  const [step, setStep] = useState(1)
  const [credit, setCredit] = useState({
    reason: "",
    amount: invoice.owed,
  })
  const [creditId, setCreditId] = useState(null)
  const [, send] = useContext(MachineContext)
  const [displayWarning, setDisplayWarning] = useState(false)

  const addCredit = () => {
    send("addCredit", {
      data: {
        variables: {
          invoiceId: invoice.id,
          batchId: invoice.metadata.batchId,
        },
        body: {
          ...credit,
        },
        onSuccess: {
          notification: {
            title: "Credit applied",
          },
          callback: ({ response }) => {
            setCreditId(response.id)
            send("getInvoiceDetails", {
              data: {
                variables: {
                  organisationId: invoice.organisation.id,
                  batchId: invoice.metadata.batchId,
                  memberId: member.id,
                },
                storeData: false,
                onSuccess: {
                  callback: ({ response }) => {
                    setDisplayWarning(false)
                    setStep(2)

                    send("UPDATE_MODAL_STATE", {
                      payload: {
                        state: {
                          invoice: response.invoice,
                        },
                      },
                    })
                  },
                },
              },
            })
          },
        },
      },
    })
  }

  const recordCredit = (event) => {
    event.preventDefault()

    if (
      invoice.owed - credit.amount < 50 &&
      invoice.owed - credit.amount !== 0
    ) {
      setDisplayWarning(true)
    } else {
      addCredit()
    }
  }

  const stepOneButtons = [
    {
      text: "Cancel",
      colWidth: 4,
      callback: () => send("CLOSE_CONFIRMATION"),
    },
    {
      text: "Add credit",
      type: "primary",
      icon: "arrow-circle-right",
      colWidth: 7,
      iconType: "duotone",
      submitting: "app.requests.addCredit.requesting",
    },
  ]

  const warningButtons = [
    {
      text: "Cancel",
      colWidth: 4,
      callback: () => send("CLOSE_CONFIRMATION"),
    },
    {
      text: "Continue",
      type: "primary",
      icon: "check-circle",
      colWidth: 7,
      iconType: "duotone",
      submitting: "app.requests.addCredit.requesting",
      callback: () => {
        addCredit()
      },
    },
  ]

  const stepTwoButtons = [
    {
      text: "Don't send",
      colWidth: 4,
      callback: () => send("CLOSE_CONFIRMATION"),
    },
    {
      text: "Send receipt",
      type: "primary",
      icon: "arrow-circle-right",
      colWidth: 7,
      iconType: "duotone",
      submitting: "app.requests.sendCreditReceipt.requesting",
      callback: () =>
        send("sendCreditReceipt", {
          data: {
            variables: {
              creditId,
            },
            onSuccess: {
              notification: {
                title: "Receipt sent",
              },
              callback: ({ response }) => {
                send("getInvoiceDetails", {
                  data: {
                    variables: {
                      organisationId: invoice.organisation.id,
                      batchId: invoice.metadata.batchId,
                      memberId: member.id,
                    },
                    storeData: false,
                    onSuccess: {
                      callback: ({ response }) => {
                        send("UPDATE_MODAL_STATE", {
                          payload: {
                            state: {
                              invoice: response.invoice,
                            },
                          },
                        })
                        send("CLOSE_CONFIRMATION")
                      },
                    },
                  },
                })
              },
            },
          },
        }),
    },
  ]

  const renderStepOne = () => (
    <div className="sm:max-w-md w-full rounded shadow-lg flex sm:h-auto h-full">
      <div className="w-full">
        <Form onSubmit={recordCredit} className="h-full sm:block flex flex-col">
          <Header title="Step 1 of 2: Add credit" />
          <div className="p-md bg-neutral-10 text-neutral-6 overflow-y-auto sm:max-h-modal-body sm:h-auto h-full">
            <div className="grid grid-cols-12 gap-sm text-neutral-5 font-medium mb-xs">
              <div className="col-span-9 flex justify-between items-end">
                Reason
                <span
                  className={`text-sm font-regular ${
                    credit.reason.length > 40
                      ? "text-danger-5"
                      : "text-neutral-6"
                  }`}
                >
                  {40 - credit.reason.length}
                </span>
              </div>
              <div className="col-span-3 text-right">Amount</div>
            </div>
            <div className="grid grid-cols-12 gap-sm">
              <div className="col-span-9">
                <Input
                  small
                  name="reason"
                  value={credit.reason}
                  onChange={(e) =>
                    setCredit({ ...credit, reason: e.target.value })
                  }
                  placeholder="Reason"
                  classes={{
                    input: "min-w-2/3",
                  }}
                  validation={{
                    messages: {
                      required: "Please enter a reason",
                      max: "Reason must be 40 characters or less",
                    },
                    rules: "required|max:40",
                  }}
                />
              </div>
              <div className="col-span-3">
                <Input
                  small
                  name="amount"
                  placeholder="£0.00"
                  value={credit.amount}
                  onChange={(e) =>
                    setCredit({ ...credit, amount: e.target.value })
                  }
                  type="currency"
                  maxValue={invoice.owed}
                  maskPattern="^-?\d+.?[0-9]{0,2}$"
                  classes={{
                    input: "text-right min-w-1/4",
                    label: "text-right",
                  }}
                  validation={{
                    messages: {
                      required: "Enter amount",
                      min: "Enter amount",
                      max: `Amount must be ${invoice.owed} or less`,
                    },
                    rules: `required|min:0,num|max:${invoice.owed},num`,
                  }}
                />
              </div>
            </div>
          </div>
          <Footer buttons={stepOneButtons} />
        </Form>
      </div>
    </div>
  )

  const renderOwedWarning = () => (
    <div className="sm:max-w-md w-full rounded shadow-lg flex sm:h-auto h-full">
      <div className="w-full">
        <Header title="Warning" />
        <div className="p-md bg-neutral-10 text-neutral-6 overflow-y-auto sm:max-h-modal-body sm:h-auto h-full">
          This will make the outstanding balance less than £0.50 which will not
          be able to be paid online. Do you want to continue?
        </div>
        <Footer buttons={warningButtons} useValidation={false} />
      </div>
    </div>
  )

  const renderStepTwo = () => (
    <div className="sm:max-w-md w-full rounded shadow-lg flex sm:h-auto h-full">
      <div className="w-full">
        <Form className="h-full sm:block flex flex-col">
          <Header title="Step 2 of 2: Send receipt" />
          <div className="p-md bg-neutral-10 text-neutral-6 overflow-y-auto sm:max-h-modal-body sm:h-auto h-full">
            Send receipt to {member.name}
          </div>
          <Footer buttons={stepTwoButtons} />
        </Form>
      </div>
    </div>
  )

  if (displayWarning) {
    return renderOwedWarning()
  }

  return step == 1 ? renderStepOne() : renderStepTwo()
}
