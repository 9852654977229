import React from "react"
import Footer from "../containers/Footer"
import { pluraliseWord } from "../../utils/functions"
import { useHistory } from "react-router-dom"

export default ({ send, state }) => {
  const history = useHistory()

  const fullBatch = state.batch.recipients === state.batch.members.length

  return (
    <div className="-m-md rounded-b">
      <div className="p-md">
        <div className="grid gap-sm">
          {fullBatch ? (
            <React.Fragment>
              <div>
                You are about to delete {state.batch.members.length}{" "}
                {pluraliseWord("invoice", state.batch.members.length)}.
              </div>
              <div>
                Payment links in previously sent invoices will no longer work.
                This action cannot be undone.
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                You can only delete {state.batch.members.length}{" "}
                {pluraliseWord("invoice", state.batch.members.length)}.
              </div>
              <div>
                Invoices that have been paid in full, part-paid or credited will
                not be deleted.
              </div>
            </React.Fragment>
          )}
          <div>
            For more information on deleting an invoice, see the{" "}
            <a
              href="https://docs.payzip.co.uk/article/50-deleting-an-invoice"
              target="_blank"
              rel="noreferrer noopener"
            >
              knowledgebase
            </a>
            .
          </div>
        </div>
      </div>
      <Footer
        buttons={[
          {
            text: "Cancel",
            callback: () => send("CLOSE_MODAL"),
          },
          {
            text: `Delete ${state.batch.members.length} ${pluraliseWord(
              "invoice",
              state.batch.members.length
            )}`,
            type: "danger",
            iconType: "duotone",
            icon: "exclamation-circle",
            submitting: "app.requests.deleteBatch.requesting",
            callback: () => {
              send("deleteBatch", {
                data: {
                  variables: {
                    batchId: state.batchId,
                  },
                  onSuccess: {
                    notification: {
                      title: "Invoice deleted",
                    },
                    target: "CLOSE_MODAL",
                    callback: ({ response }) => {
                      if (response.recipients === 0) {
                        history.push("/invoices")
                      }
                    },
                  },
                },
              })
            },
          },
        ]}
      />
    </div>
  )
}
