import React from "react"
import moment from "moment"

import ItemsAndPayments from "../../Invoice/ItemsAndPayments"
import Footer from "../../containers/Footer"
import Ribbon from "../../containers/Ribbon"
import Tag from "../../Tag"

export default ({ paid, invoice, organisation, send }) => {
  return (
    <div>
      <div className="bg-white overflow-hidden sm:rounded-t pb-md shadow-b-border">
        <div className="relative sm:block flex flex-col sm:h-auto h-full">
          <Ribbon
            rounded
            logo={true}
            background={invoice.overdue && !paid ? "danger" : ""}
          />
          {invoice.overdue && !paid && (
            <div className="absolute top-lg right-lg">
              <Tag type="danger" className="font-medium">
                Overdue
              </Tag>
            </div>
          )}
        </div>
        <div className="px-md text-center">
          <h2 className="text-2xl mt-xs">{invoice.title}</h2>
          <div className="text-neutral-5 font-medium text-lg">
            {organisation.name}
          </div>
        </div>
      </div>

      <div className="bg-neutral-10 sm:p-md px-md shadow-b-border">
        <div className="grid gap-sm invoice-details">
          <div className="sm:grid grid-cols-5">
            <div className="text-neutral-5 font-medium">Date sent</div>
            <div className="text-neutral-1 col-span-4">
              {moment(invoice.dateSent).format("DD MMM YYYY").toString()}
            </div>
          </div>
          <div className="sm:grid grid-cols-5">
            <div className="text-neutral-5 font-medium">Due date</div>
            <div className="text-neutral-1 col-span-4">
              {moment(invoice.dueDate).format("DD MMM YYYY").toString()}
            </div>
          </div>
          {invoice.description && (
            <div className="sm:grid grid-cols-5">
              <div className="text-neutral-5 font-medium">Comments</div>
              <div className="text-neutral-1 col-span-4 whitespace-pre-line">
                {invoice.description}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white pt-sm">
        <ItemsAndPayments lineItems={invoice.lineItems} owed={invoice.amount} />
      </div>

      {invoice.footer && (
        <div className="px-md py-md bg-white whitespace-pre-line">
          {invoice.footer}
        </div>
      )}
      <Footer
        border={invoice.footer}
        useValidation={false}
        buttons={[
          {
            text: "Okay",
            type: "primary",
            icon: "check-circle",
            iconType: "duotone",
            callback: () => send("CLOSE_CONFIRMATION"),
          },
        ]}
      />
    </div>
  )
}
