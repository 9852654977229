import React from "react"

import Icon from "../Icon"

export default ({ title, removeItem, onClick }) => {
  return (
    <div
      className="mr-md bg-white rounded flex items-center py-xs pr-xs pl-sm"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClick(event)
        }
      }}
    >
      <div
        onClick={(event) => onClick(event)}
        className="truncate inline font-medium text-neutral-5"
      >
        {title}
      </div>
      <button onClick={() => removeItem()}>
        <Icon type="duotone" icon="window-maximize" className="ml-xs" />
      </button>
    </div>
  )
}
