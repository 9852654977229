import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export const getUserProfile = {
  url: `/users/details`,
  type: "get",
  appState: true,
}

export const getUserAccount = {
  url: "/users/me",
  type: "get",
  appState: true,
}

export const updateUser = {
  url: "/users/update",
  type: "post",
  contextKey: "user",
  appState: true,
}

export const updateUserDetails = {
  url: `/users/details`,
  type: "post",
  appState: true,
}

export default [
  {
    target: "getUserAccount",
    payload: getUserAccount,
  },
  {
    target: "getUserProfile",
    payload: getUserProfile,
  },
  {
    target: "updateUser",
    payload: updateUser,
  },
  {
    target: "updateUserDetails",
    payload: updateUserDetails,
  },
  {
    target: "updateCompleteUser",
    payload: [
      { ...updateUser, id: "updateUser" },
      { ...updateUserDetails, id: "updateUserDetails" },
    ],
  },
  {
    target: "getOwnStats",
    payload: {
      url: "/users/stats",
      type: "get",
    },
  },
  {
    target: "getOwnPayments",
    payload: {
      url: ({ filters }) => `/users/payments${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getOwnInvoices",
    payload: {
      url: ({ filters }) => `/users/invoices${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getOwnPings",
    payload: {
      url: ({ filters }) => `/users/pings${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
]
