import "react-app-polyfill/stable"

import React from "react"
import SupportedBrowsers from "./supportedBrowsers"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./assets/activity.css"
import "./assets/main.css"
import "./assets/datepicker.css"
import "./assets/select.css"
import moment from "moment"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fas,
  faBolt,
  faCircle,
  faCheck,
  faTimes,
  faWindowMinimize,
  faArrowRight,
  faArrowUp,
  faSyncAlt,
  faChevronCircleDown as faSolidChevronCircleDown,
  faSpinner,
  faBars,
  faShare,
} from "@fortawesome/free-solid-svg-icons"
import { fab, faStripeS } from "@fortawesome/free-brands-svg-icons"
import {
  far,
  faDotCircle,
  faCircle as faRadioEmpty,
} from "@fortawesome/free-regular-svg-icons"
import {
  faLockAlt,
  faPencil,
  faPoundSign,
} from "@fortawesome/pro-solid-svg-icons"
import {
  fal,
  faChartArea,
  faFileInvoice,
  faSlidersHSquare,
  faCreditCard,
  faListAlt,
  faCoins,
  faUserCircle,
  faUsers,
  faEnvelopeOpenText,
  faCloudUpload,
  faUserPlus,
  faCheckSquare,
  faSquare,
  faMinusSquare,
  faExternalLink,
  faShieldAlt,
  faBullhorn,
} from "@fortawesome/pro-light-svg-icons"
import {
  faSearch,
  faPoundSign as faPoundSignRegular,
  faSignalStream,
  faPlusCircle as faPlusCircleRegular,
  faPlus,
  faMinus,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons"
import {
  fad,
  faPlusCircle,
  faChevronCircleDown,
  faChevronCircleRight,
  faChevronCircleLeft,
  faWindowMaximize,
  faClock,
  faTrashAlt,
  faArrowCircleUp,
  faArrowCircleRight,
  faArrowCircleDown,
  faBellSlash,
  faCommentAltLines,
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faCheckCircle,
  faQuestionCircle,
  faCreditCard as faDuoCrditCard,
  faInfoCircle,
} from "@fortawesome/pro-duotone-svg-icons"
import CookiePage from "./pages/admin/CookiePage"
import Logout from "./pages/common/Logout"
import IncompatibleBrowser from "./pages/errors/IncompatibleBrowser"

library.add(
  fas,
  faPencil,
  faBolt,
  faSpinner,
  faCircle,
  faCheck,
  faTimes,
  faArrowRight,
  faArrowUp,
  faSyncAlt,
  faLockAlt,
  faWindowMinimize,
  faSolidChevronCircleDown,
  faBars,
  faPoundSign,
  faShare,
  faSignalStream,

  far,
  faDotCircle,
  faRadioEmpty,
  faPoundSignRegular,
  faPlusCircleRegular,
  faMinusCircle,
  faPlus,
  faMinus,

  fal,
  faShieldAlt,
  faChartArea,
  faFileInvoice,
  faSlidersHSquare,
  faCreditCard,
  faListAlt,
  faCoins,
  faUserCircle,
  faUserPlus,
  faUsers,
  faSearch,
  faCloudUpload,
  faEnvelopeOpenText,
  faCheckSquare,
  faSquare,
  faMinusSquare,
  faExternalLink,

  fad,
  faChevronCircleDown,
  faChevronCircleRight,
  faChevronCircleLeft,
  faPlusCircle,
  faWindowMaximize,
  faTrashAlt,
  faClock,
  faBellSlash,
  faCommentAltLines,
  faEye,
  faEyeSlash,
  faArrowCircleUp,
  faArrowCircleRight,
  faArrowCircleDown,
  faExclamationCircle,
  faCheckCircle,
  faDuoCrditCard,
  faQuestionCircle,
  faInfoCircle,

  fab,
  faStripeS,
  faBullhorn
)

// Validation package requires moment to be mounted on window
window.moment = moment

ReactDOM.render(
  <React.StrictMode>
    {SupportedBrowsers.test(navigator.userAgent) ? (
      <Router>
        <Switch>
          <Route exact path="/cookie">
            <CookiePage />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route path="*">
            <App />
          </Route>
        </Switch>
      </Router>
    ) : (
      <IncompatibleBrowser />
    )}
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
