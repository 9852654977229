import React, { useContext, useRef } from "react"
import { Link } from "react-router-dom"

import PayzipLogo from "../../assets/img/logo.svg"
import MenuDropdown from "../MenuDropdown"
import { getOptionInitials } from "../../utils/functions"
import { MachineContext } from "../../state"
import Ribbon from "./Ribbon"
import Banner from "./Banner"
import HeadwayWidget from "@headwayapp/react-widget"
import Icon from "../Icon"

export default ({
  name,
  email,
  title,
  actions,
  bannerText = null,
  bannerClassName,
  hideLogo = false,
  logoPath = "/",
  displayHeadwayWidget = true,
  hideAccountLink = false,
}) => {
  const [{ context }, send] = useContext(MachineContext)
  const menuEl = useRef()

  const triggerLogout = () => {
    handleClick()
    send("LOGOUT")
  }

  const handleClick = () => {
    if (menuEl && menuEl.current) {
      menuEl.current.close()
    }
  }

  const dropdownContent = (
    <MenuDropdown
      ref={menuEl}
      icon={getOptionInitials(name)}
      arrow="right"
      withChevron={false}
    >
      <div className="sm:py-md py-0 px-md  sm:border-b border-neutral-8 sm:text-left text-right">
        <div>{name}</div>
        <div className="text-sm text-neutral-5">{email}</div>
      </div>
      <div className="sm:py-sm sm:text-left text-right">
        <Link
          onClick={handleClick}
          to="/account"
          className={`block px-md sm:py-xs py-md hover:bg-neutral-10 ${
            hideAccountLink ? "hidden" : ""
          }`}
        >
          Account
        </Link>
        <div
          onClick={triggerLogout}
          className={`block px-md sm:py-xs hover:bg-neutral-10 text-primary-6 ${
            hideAccountLink ? "py-md" : ""
          }`}
        >
          Sign out
        </div>
      </div>
    </MenuDropdown>
  )

  return (
    <div>
      {bannerText !== null && (
        <Banner className={bannerClassName} text={bannerText} />
      )}
      <div className="sm:py-md relative">
        <div className="sm:hidden block bg-white relative">
          <Ribbon logo={PayzipLogo} logoPath={logoPath} enableLogoLink={true} />

          {dropdownContent}

          <div className="px-md bg-white sm:rounded-t shadow-b-border flex justify-between sm:h-2xl sm:pb-0 pb-md">
            <h3 className="leading-tight">{title || context.pageTitle}</h3>
          </div>
        </div>
        <div
          className={`sm:flex hidden ${
            context.user.roles.includes("admin") || hideLogo
              ? "justify-end"
              : "justify-between"
          }`}
        >
          {!context.user.roles.includes("admin") && !hideLogo && (
            <Link to={logoPath}>
              <img
                className="h-28"
                src={PayzipLogo}
                alt="Payzip"
                width="100px"
              />
            </Link>
          )}
          <div className="flex">
            <div className="">{actions}</div>
            {displayHeadwayWidget && (
              <HeadwayWidget
                account="yZv60y"
                widgetPosition="bottom-left"
                badgePosition="top-right"
                styles={{
                  marginTop: 50,
                }}
                options={{
                  trigger: "#releaseNotes",
                }}
              >
                <div
                  id="releasNotes"
                  className="ml-md rounded-full bg-neutral-10 text-neutral-5 h-28 w-28 flex items-center justify-center"
                >
                  <Icon type="light" icon="bullhorn" baseSize="16px" />
                </div>
              </HeadwayWidget>
            )}
            <div className="ml-md">{dropdownContent}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
