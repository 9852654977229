import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react"
import moment from "moment"

import DatePicker from "../../Datepicker"
import Input from "../../Input"
import Textarea from "../../Input/Textarea"
import HelperRow from "../../containers/HelperRow"
import LineItem from "../../Forms/LineItem"

import { formatCurrency, currencyToPennies } from "../../../utils/functions"
import { useFormValidator } from "../../Forms/Form"
import { MachineContext } from "../../../state"

export default forwardRef(
  (
    {
      title,
      dueDate,
      description,
      footer,
      lineItems,
      members,
      showTotalError,
      totalError,

      setState,
    },
    ref
  ) => {
    const [current, send] = useContext(MachineContext)
    const [validator] = useFormValidator()
    const datePickerRef = useRef()
    const [updateFirstItem, setUpdateFirstItem] = useState(true)
    const [invoice, setInvoice] = useState({
      title,
      dueDate,
      description,
      footer,
      lineItems,
      members,
    })

    useEffect(() => {
      validator.purgeFields()
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        showTotalError(false)
        const invoiceTotal = lineItems.reduce((a, b) => a + b.amount, 0)

        if (validator.allValid() && (invoiceTotal > 49 || invoiceTotal == 0)) {
          send("getOrganisationBatches", {
            data: {
              storeData: false,
              variables: {
                organisationId: current.context.organisation.id,
                filters: {
                  statuses: "owed",
                  page: 1,
                  "batch[title][like]": title,
                },
              },
              onSuccess: {
                callback: ({ response }) => {
                  if (response.tableProps.data.length > 0) {
                    send("OPEN_CONFIRMATION", {
                      payload: {
                        title: "Invoice name already exists",
                        body: [
                          "There is already an outstanding invoice with this name.",
                          "You can contine with this name, or you can go back and change it to something else.",
                        ],
                        buttons: [
                          {
                            text: "Go back",
                            colWidth: 4,
                            callback: () => send("CLOSE_CONFIRMATION"),
                          },
                          {
                            text: "Continue",
                            type: "primary",
                            icon: "check-circle",
                            iconType: "duotone",
                            callback: () => {
                              setState({ step: 2 })
                              send("CLOSE_CONFIRMATION")
                            },
                          },
                        ],
                      },
                    })
                  } else {
                    setState({ step: 2 })
                  }
                },
              },
            },
          })
          return false
        } else {
          validator.showMessages()
          setState({ step: 1 })
          if (invoiceTotal < 50) {
            showTotalError(true)
          }
          return false
        }
      },
    }))

    useEffect(() => {
      const lastLineItem = lineItems[lineItems.length - 1]
      if (lastLineItem.description) {
        lineItems.push({ description: "", amount: "" })
      }

      setInvoice((i) => ({
        ...i,
        lineItems,
      }))
    }, [lineItems])

    const updateInvoice = ({ target: { name, value } }) => {
      setInvoice({
        ...invoice,
        [name]: value,
      })
    }

    const updateTitle = ({ target }) => {
      if (updateFirstItem) {
        updateLineItems({ name: "description", value: target.value }, 0)
      }

      updateInvoice({ target })
    }

    const updateLineItems = ({ name, value }, index) => {
      let items = invoice.lineItems
      if (typeof value === "number" && isNaN(value)) {
        value = ""
      }

      items[index][name] = value

      if (index === items.length - 1) {
        items.push({ description: "", amount: "" })
      }
      updateInvoice({ target: { name: "lineItems", value: items } })
    }

    const updateParent = () => {
      setState({ invoice })
    }

    const handleTitleBlur = () => {
      updateParent()

      if (
        invoice.title ||
        (invoice.lineItems[0] && invoice.lineItems[0].description)
      ) {
        setUpdateFirstItem(false)
      }
    }

    const removeLineItem = (index) => {
      const updatedInvoice = {
        ...invoice,
        lineItems: invoice.lineItems.filter((item, key) => index !== key),
      }

      setInvoice(updatedInvoice)
      setState({ invoice: updatedInvoice })
      validator.purgeFields()
    }

    const updateInvoiceDate = (dueDate) => {
      if (dueDate) {
        setInvoice({ ...invoice, dueDate })
        setState({ invoice: { ...invoice, dueDate } })
      } else {
        validator.showMessageFor("dueDate")
      }
    }

    return (
      <div className="p-md overflow-y-auto sm:max-h-modal-body max-h-modal-mobile">
        <div className="border-b border-neutral-8 pb-md mb-md">
          <HelperRow
            title="Invoice details"
            text={[
              "Give your invoice a distinct name, so you can quickly find it again later.",
              "The due date is the latest date for members to pay you. Remember to allow time for the money to be processed by Payzip and paid out to your club too.",
              "You can also add a message that is specific to this invoice.",
            ]}
          >
            <div className="grid grid-cols-1 gap-sm">
              <Input
                small
                label="Name"
                name="title"
                value={invoice.title}
                placeholder="Name"
                onChange={updateTitle}
                onBlur={handleTitleBlur}
                validation={{
                  message: "Your invoice needs a name",
                  rules: "required",
                }}
              />

              <div className="w-3/5">
                <DatePicker
                  date={invoice.dueDate}
                  format="dd/MM/yyyy"
                  onChange={updateInvoiceDate}
                  placeholder="Select date"
                  popperPlacement="bottom"
                  minDate={new Date()}
                  clearText="Clear date"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "0, 5px",
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                  customInput={
                    <Input
                      small
                      ref={datePickerRef}
                      date={moment(invoice.dueDate).format("DD/MM/YYYY")}
                      label="Due date"
                      name="dueDate"
                      placeholder="Select date"
                      showSelectChevron
                      classes={{
                        input: "cursor-pointer",
                      }}
                      dateDisplay
                      mask="99/99/9999"
                      validateOnBlur={false}
                      validation={{
                        name: "dueDate",
                        messages: {
                          required: "Enter a due date",
                          date: "Enter a valid date",
                          after_or_equal: "Pick a date in the future",
                        },
                        rules: [
                          "required",
                          "date",
                          {
                            after_or_equal: moment(),
                          },
                        ],
                      }}
                    />
                  }
                  todayButton
                />
              </div>
              <Textarea
                id="comments"
                small
                label="Comments"
                name="description"
                value={invoice.description}
                rows="5"
                onChange={updateInvoice}
                onBlur={updateParent}
                length={500}
                validation={{
                  name: "description",
                  messages: {
                    max: "Maximum character limit exceeded",
                  },
                  rules: "max:500",
                }}
              />
            </div>
          </HelperRow>
        </div>

        <div className="border-b border-neutral-8 pb-md mb-md">
          <HelperRow
            title="Payment information"
            text="We've put the invoice name as the first one by default, but you can change this. You can add as many additional items as you need."
          >
            <div className="grid grid-cols-1 gap-sm">
              {invoice.lineItems &&
                invoice.lineItems.map((item, index) => (
                  <LineItem
                    key={index}
                    index={index}
                    description={item.description}
                    amount={item.amount}
                    removeItem={removeLineItem}
                    onBlur={handleTitleBlur}
                    setState={({ target }) => updateLineItems(target, index)}
                    showBin={index !== invoice.lineItems.length - 1}
                    validateRow={
                      index !== invoice.lineItems.length - 1 ||
                      invoice.lineItems.length === 1
                    }
                  />
                ))}
              <div className="grid grid-cols-12 gap-sm">
                <div
                  className={`flex ${
                    totalError
                      ? "text-danger-5 justify-between items-end"
                      : "text-neutral-5 justify-end"
                  } col-span-11`}
                >
                  {totalError ? (
                    <div>Invoice must be at least £0.50 (or £0.00)</div>
                  ) : null}
                  <div className="font-semibold text-lg text-right">
                    Total:{" "}
                    {invoice.lineItems &&
                      invoice.lineItems.length > 0 &&
                      formatCurrency(
                        invoice.lineItems
                          .filter((item) => item.amount)
                          .reduce((a, b) => a + currencyToPennies(b.amount), 0)
                      )}
                  </div>
                </div>
              </div>
            </div>
          </HelperRow>
        </div>

        <HelperRow
          title="Footer"
          text="The information you add here will automatically show on the next invoice you create. You can always edit it, and any changes are carried forward."
        >
          <Textarea
            small
            label="Footer"
            name="footer"
            rows="5"
            value={invoice.footer}
            onChange={updateInvoice}
            onBlur={updateParent}
            length={500}
            validation={{
              name: "footer",
              messages: {
                max: "Maximum character limit exceeded",
              },
              rules: "max:500",
            }}
          />
        </HelperRow>
      </div>
    )
  }
)
