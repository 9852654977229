import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'

export default ({
  date,
  setTypedDate
}) => {
  const inputRef = useRef()
  const [inputDate, setInputDate] = useState(moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : null)

  const keyPressHandler = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur()
    }
  }

  useEffect(() => {
    const newDate = moment(date)
    if (newDate.isValid()) {
      setInputDate(newDate.format('DD/MM/YYYY'))
    }
    if (!date) {
      setInputDate('')
    }
  }, [date])

  return (
    <div className="pt-md">
      <input
        ref={ inputRef }
        value={ inputDate || '' }
        onChange={ ({ target }) => setInputDate(target.value) }
        onBlur={ ({ target }) => setTypedDate(target) }
        className="border border-neutral-8 rounded py-xs px-sm w-full text-neutral-1"
        placeholder={ moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : 'Select date' }
        onKeyPress={ keyPressHandler }
      />
    </div>
  )
}
