import React, { useContext, forwardRef } from "react"

import { useFormValidator } from "../Forms/Form"
import InputError from "./Error"
import { MachineContext } from "../../state"

const InternalLabelInput = forwardRef(
  (
    {
      id,
      label,
      name,
      icon,
      placeholder,
      value,
      onChange,
      onClick = () => {},
      onBlur = () => {},
      onFocus = () => {},
      onKeyPress = () => {},
      classes = {},
      large,
      largeOnMobile,
      disabled = false,
      disabledStyling = true,
      autocomplete = "",
      type = "",
      validateOnBlur = true,
      validation = "",
      danger,
      width = "w-full",
      useValidation = true,
    },
    ref
  ) => {
    const [
      {
        context: { errors },
      },
      send,
    ] = useContext(MachineContext)
    const [validator, { handleValidation }] = useFormValidator(useValidation)
    const validationField =
      validation && validation.name ? validation.name : name || id

    const handleChange = (event) => {
      if (validator && validator.fieldValid(validationField)) {
        validator.hideMessageFor(validationField)
      }

      onChange({ target: { name, value: event.target.value } })

      if (
        validator &&
        validation &&
        (validator.visibleFields.includes(validationField) ||
          validator.messagesShown)
      ) {
        handleValidation(validationField)
      }

      if (errors && errors.fields && errors.fields[validationField]) {
        send("UPDATE_CONTEXT", {
          errors: {
            ...errors,
            fields: { ...errors.fields, [validationField]: "" },
          },
        })
      }
    }

    const handleBlur = (event) => {
      onBlur({ target: { name, value: event.target.value } })

      if (validateOnBlur) {
        handleValidation(validationField)
      }
    }

    let hasErrors = false
    if (validator && validation) {
      hasErrors =
        (!validator.check(value, validation.rules) &&
          (validator.messagesShown ||
            validator.visibleFields.includes(validationField))) ||
        (errors && errors.fields && errors.fields[validationField])
    }

    let classNames = `input
    ${largeOnMobile ? "sm:px-sm sm:py-xs p-sm" : "pl-sm py-0 pr-0"}
    ${danger ? "input--danger" : ""}
    ${hasErrors ? "input--error" : ""}
    ${large ? "input--lg" : ""}
    ${disabled && disabledStyling ? "input--disabled" : ""}
    ${icon ? "z-10" : ""}
    ${classes.input}`

    const handleFocus = (event) => {
      onFocus(event)
    }

    return (
      <div className={`text-neutral-5 ${width}`}>
        <div className={classNames + " sm:flex items-center"}>
          <label
            htmlFor={id || name}
            className="text-neutral-6 whitespace-nowrap"
          >
            {label}
          </label>
          <input
            ref={ref}
            className="bg-transparent placeholder-neutral-8 w-full py-xs pl-xs pr-sm"
            id={id || name}
            name={name}
            value={value || ""}
            autoComplete={autocomplete}
            type={type}
            onClick={onClick}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onFocus={handleFocus}
            onBlur={(e) => handleBlur(e, validator)}
            disabled={disabled}
            style={
              // Stop Safari from displaying disabled input with a blue tint
              // Used in affiliate channel selection
              disabled && !disabledStyling && value
                ? {
                    WebkitTextFillColor: "#102A43",
                    opacity: 1,
                  }
                : {}
            }
          />
        </div>
        <InputError
          validation={validation}
          large={large}
          name={validationField}
          id={id}
          value={value}
        />
      </div>
    )
  }
)

export default InternalLabelInput
