import React from "react"
import Panel from "../../Panel"
import SkeletonText from "../Text"

const SkeletonPanel = ({ panelCount, subtext = false }) => {
  return (
    <Panel
      headerClasses="sm:flex hidden" // Hide header on mobile as only members view is being displayed on mobile currently
      height={subtext && "6.071rem"}
      columns={[...Array(panelCount)].map((_) => {
        return {
          heading: (
            <SkeletonText
              width="w-80"
              colour="sm:bg-neutral-5 bg-primary-8"
              className="mb-xs"
            />
          ),
          defaultValue: (
            <SkeletonText
              width="w-110"
              colour="sm:bg-primary-4 bg-primary-10"
              height="h-md"
              className="sm:my-0 my-xs"
            />
          ),
          subtext: subtext && (
            <SkeletonText
              width="w-80"
              colour="sm:bg-neutral-5 bg-primary-8"
              className="sm:mt-xs"
            />
          ),
        }
      })}
    >
      <SkeletonText width="w-3xl" height="h-16" />
    </Panel>
  )
}

export default SkeletonPanel
