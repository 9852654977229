import { constructQueryString } from "../functions"

export default [
  {
    target: "getApprovalClub",
    payload: {
      url: ({ token }) => `/admin/organisation/${token}/approval`,
      type: "get",
      contextKey: "approvalOrganisation",
    },
  },
  {
    target: "approveClub",
    payload: {
      url: ({ token }) => `/admin/organisation/${token}/approve`,
      type: "post",
      contextKey: "approvalOrganisation",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "rejectClub",
    payload: {
      url: ({ token }) => `/admin/organisation/${token}/reject`,
      type: "post",
      contextKey: "approvalOrganisation",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getAppFees",
    payload: {
      url: (query) =>
        `/admin/fees${constructQueryString({
          affiliate: query ? query.affiliate : "",
        })}`,
      type: "get",
      storeData: false,
    },
  },
  {
    target: "getAppSettings",
    payload: {
      url: "/admin/settings",
      type: "get",
    },
  },
  {
    target: "getAppVouchers",
    payload: {
      url: "/admin/vouchers",
      type: "get",
      storeData: false,
    },
  },
]
