import React from "react"

import Cell from "./Cell"

export default function SkeletonRow({
  rowKey = "",
  cells,
  roundBottom = false,
  header = false,
}) {
  const rowStyle = header
    ? "border-b border-neutral-8 rounded-t py-base"
    : "even:bg-neutral-10 odd:bg-white rounded-b overflow-hidden h-row"

  return (
    <tr className={rowStyle}>
      {cells.map(({ right, colSpan, text, className }, index) => {
        const renderText = text || {}
        let classes = className
        if (header) {
          renderText.colour = "bg-neutral-5"
          classes = classes + " py-base"
        }
        return (
          <Cell
            key={rowKey + "-cell-" + index}
            right={right}
            roundBottom={roundBottom}
            colSpan={colSpan}
            text={renderText}
            className={classes}
          />
        )
      })}
    </tr>
  )
}
