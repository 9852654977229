import React from 'react'

import Icon from '../Icon'

export default ({
  display,
  selectDate
}) => {
  if (display) {
    return (
        <div onClick={ () => selectDate(new Date()) } className="bg-white rounded-full pl-sm border border-neutral-8 flex text-neutral-5 font-medium cursor-pointer">
          <div className="leading-tight my-xs">
            Today
          </div>
          <div className="inline mx-icon flex items-center">
            <Icon type="duotone" icon="clock" baseSize="20px" />
          </div>
        </div>
    )
  }

  return <React.Fragment />
}
