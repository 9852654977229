import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"

import SidebarItem from "./SidebarItem"
import ClubMenu from "./ClubMenu"
import SidebarCategory from "./SidebarCategory"
import GettingStarted from "./GettingStarted"
import { MachineContext } from "../../state"
import Flag from "../Flag"
import { tutorialModal } from "../../utils/modals/organisation"

export default ({ menu, pathname, organisation = {} }) => {
  const [objectives, setObjectives] = useState([])
  let [
    {
      context: { user, tutorial },
    },
    send,
  ] = useContext(MachineContext)
  let location = useLocation()

  useEffect(() => {
    const { hasMembers, hasInvoices, hasCardPayments, hasRemittance } = tutorial
    setObjectives([
      { step: 1, done: true, name: "Create a club" },
      { step: 1, done: hasMembers, name: "Add a member" },
      { step: 1, done: hasInvoices, name: "Send an invoice" },
      { step: 1, done: hasCardPayments, name: "Receive card payment" },
      { step: 1, done: hasRemittance, name: "Receive a payout" },
    ])
  }, [tutorial])

  let menuTitle = organisation.name

  const viewingPersonInvoices = location.pathname.includes("/dashboard")

  if (viewingPersonInvoices) {
    menuTitle = "My invoices"
  }

  const showGuide = () => {
    send("updateTutorial", {
      data: {
        variables: {
          organisationId: organisation.id,
        },
        body: {
          tutorialComplete: false,
        },
      },
    })
  }

  const openTutorial = (step) => {
    tutorialModal(send, step)
  }

  const completeTutorial = () => {
    if (objectives.filter(({ done }) => done).length === objectives.length) {
      send("updateTutorial", {
        data: {
          variables: {
            organisationId: organisation.id,
          },
          body: {
            tutorialComplete: true,
          },
        },
      })
    }
  }

  // TODO: REMOVE IF STATEMENT WHEN ADMIN PAGES ARE MOBILE OPTIMISED
  if (!isMobileOnly) {
    return (
      <div className="sm:max-w-sidebar pt-md sm:inline-block hidden w-full">
        <div className="mb-md">
          {menu ? (
            <ClubMenu options={menu} title={menuTitle} name={user.name} />
          ) : (
            <React.Fragment />
          )}
        </div>
        <div className="bg-neutral-2 rounded text-neutral-8 mr-md sm:shadow-container">
          <div className="py-md pl-md pr-sm">
            <ul className="mb-lg">
              <SidebarItem
                pathname={pathname}
                to="/"
                icon="chart-area"
                label="Dashboard"
                disabled={viewingPersonInvoices}
              />
              {/* <SidebarItem
                pathname={pathname}
                to="/activity"
                icon="list-alt"
                label="Activity"
                disabled={ viewingPersonInvoices }
              /> */}
            </ul>
            <ul className="mb-lg">
              <SidebarItem
                pathname={pathname}
                to="/invoices"
                icon="file-invoice"
                label="Invoices"
                disabled={viewingPersonInvoices}
              />
              <SidebarItem
                pathname={pathname}
                to="/pings"
                icon="signal-stream"
                label="Pings"
                iconType="regular"
                disabled={viewingPersonInvoices}
              />
            </ul>
            <ul className="mb-lg">
              <SidebarItem
                pathname={pathname}
                to="/unpaid"
                icon="wallet"
                label={
                  <div>
                    Unpaid{" "}
                    <span
                      style={{
                        paddingRight: "2px",
                        paddingLeft: "2px",
                      }}
                      className={
                        viewingPersonInvoices
                          ? `rounded-sm text-neutral-2 text-xxs align-middle bg-neutral-5`
                          : `rounded-sm text-neutral-2 text-xxs align-middle ${
                              location.pathname.includes("/unpaid")
                                ? "bg-neutral-10"
                                : "bg-neutral-8"
                            }`
                      }
                    >
                      BETA
                    </span>
                  </div>
                }
                disabled={viewingPersonInvoices}
              />
              <SidebarItem
                pathname={pathname}
                to="/payments"
                icon="credit-card"
                label="Payments"
                disabled={viewingPersonInvoices}
              />
              <SidebarItem
                pathname={pathname}
                to="/credits"
                icon="money-check-edit"
                label="Credits"
                disabled={viewingPersonInvoices}
              />
              <SidebarItem
                pathname={pathname}
                to="/payouts"
                icon="coins"
                label="Payouts"
                disabled={viewingPersonInvoices}
              />
            </ul>
            <ul className="mb-lg">
              <SidebarItem
                pathname={pathname}
                to="/members"
                icon="user-circle"
                label="Members"
                disabled={viewingPersonInvoices}
              />
              <SidebarItem
                pathname={pathname}
                to="/group"
                icon="users"
                label="Groups"
                disabled={viewingPersonInvoices}
              />
            </ul>
            <SidebarCategory
              category="Settings"
              icon="sliders-h-square"
              pathname={pathname}
              root="/settings"
              categories={[
                { label: "Club", to: "/club" },
                { label: "Team", to: "/team" },
              ]}
              disabled={viewingPersonInvoices}
            />
          </div>
          {!viewingPersonInvoices && tutorial && (
            <GettingStarted
              openTutorial={openTutorial}
              objectives={objectives}
              tutorialComplete={tutorial.tutorialComplete}
              completeTutorial={completeTutorial}
            />
          )}
        </div>
      </div>
    )
  }

  return <React.Fragment />
}
