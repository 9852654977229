import React from "react"

import Tag from "../Tag"
import { formatCurrency } from "../../utils/functions"

export default ({
  overdue = false,
  title,
  total,
  totalPaid,
  contextLabel,
  showValue = true,
}) => (
  <div className="px-md text-center">
    <h2 className="text-xl mt-xs">{title}</h2>
    <div className="text-neutral-5 font-medium text-lg">for {contextLabel}</div>
    {total - totalPaid > 0 && showValue && (
      <div className="pt-sm">
        <h1 className="inline-block leading-6 align-top">
          {formatCurrency(total - totalPaid)}
        </h1>
        {overdue && total !== totalPaid && (
          <div className="ml-sm inline-block">
            <Tag type="danger">Overdue</Tag>
          </div>
        )}
      </div>
    )}
  </div>
)
