export const getTeam = {
  url: ({ organisationId }) => `/organisation/${organisationId}/team`,
  type: "get",
  contextKey: "team",
  appState: true,
}

const getInvites = {
  url: ({ organisationId }) => `/organisation/${organisationId}/invites`,
  type: "get",
}

export default [
  {
    target: "getTeam",
    payload: getTeam,
  },
  {
    target: "getInvites",
    payload: getInvites,
  },
  {
    target: "getCompleteTeam",
    payload: [
      { ...getTeam, id: "getTeam" },
      { ...getInvites, id: "getInvites" },
    ],
  },
  {
    target: "resendTeamInvite",
    payload: {
      url: ({ organisationId, teamMemberId }) =>
        `/organisation/${organisationId}/team/${teamMemberId}`,
      type: "post",
    },
    onSuccess: {
      notification: {
        title: "Invite re-sent",
      },
      target: "CLOSE_MODAL",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "removeTeamInvite",
    payload: {
      url: ({ organisationId, inviteId }) =>
        `/organisation/${organisationId}/invites/${inviteId}/delete`,
      type: "post",
    },
    onSuccess: {
      notification: {
        title: "Invite removed",
      },
      target: "CLOSE_MODAL",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "removeTeamMember",
    payload: {
      url: ({ organisationId, teamMemberId }) =>
        `/organisation/${organisationId}/team/${teamMemberId}/delete`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "inviteTeam",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/team`,
      type: "post",
    },
  },
]
