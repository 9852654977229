import React, { useRef, useContext, useEffect, useState } from "react"
import moment from "moment"
import { CSVLink } from "react-csv"

import { MachineContext } from "../../state"
import { formatCurrency } from "../../utils/functions"
import requests from "../../utils/requests"

const Export = ({
  fileSuffix,
  headers = [],
  transformer,
  separator = ",",
  requestVariables = {},
  requestKey,
}) => {
  const [data, setData] = useState([])
  const [formatObject, setFormatObject] = useState({})

  const csvDownloader = useRef()

  const [current, send] = useContext(MachineContext)
  const filePrefix = moment().format("YYYYMMDDHHmmss")

  useEffect(() => {
    let keyFormatting = {}
    headers.map((head) => {
      if (head.format) {
        keyFormatting[head.key] = head.format
      }
      return head
    })
    setFormatObject(keyFormatting)
  }, [headers])

  const formatData = (value, format) => {
    switch (format) {
      case "currency":
        return formatCurrency(value)
      case "date":
        if (moment(value).isValid()) {
          return moment(value).format("DD/MM/YYYY")
        }
        return "-"
      default:
        return value
    }
  }

  const transform = (data) => {
    let transformedData = data

    if (transformer) {
      transformedData = transformer(transformedData)
    }

    transformedData = transformedData.map((data) => {
      Object.entries(data).map(([key, value]) => {
        if (formatObject[key]) {
          data[key] = formatData(data[key], formatObject[key])
        }

        return [key, value]
      })

      return data
    })

    return { data: transformedData }
  }

  const handleClick = (event, done) => {
    const foundRequests = requests.filter(({ target }) => target === requestKey)
    if (
      !requestKey ||
      !foundRequests.length ||
      current.matches("app.requests.exportTable.requesting")
    ) {
      return
    }

    let variables = requestVariables
    if (variables.filters && variables.filters.page) {
      variables.filters = Object.keys(variables.filters)
        .filter((key) => {
          return key !== "page"
        })
        .reduce((obj, key) => {
          obj[key] = variables.filters[key]
          return obj
        }, {})
    }

    send("exportTable", {
      data: {
        payload: foundRequests[0].payload,
        variables,
        transformResponse: ({ data }, context) => transform(data.rows),
        onSuccess: {
          callback: ({ response }, context) => {
            setData(response)
            let btn = csvDownloader.current
            btn.link.click()
          },
        },
        onError: {
          notification: {
            title: "Whoops",
            description: "Something went wrong",
          },
        },
      },
    })
  }

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        className="inline-block align-middle py-xs text-primary-6 px-sm hover:underline cursor-pointer"
      >
        Export to CSV
      </div>
      <CSVLink
        ref={csvDownloader}
        data={data}
        target="_blank"
        headers={headers}
        separator={separator}
        filename={`${filePrefix}-${fileSuffix}.csv`}
        style={{ display: "none" }}
      />
    </React.Fragment>
  )
}

export default Export
