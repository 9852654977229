export default [
  {
    target: "createManualPayment",
    payload: {
      url: ({ organisationId, batchId, memberId }) =>
        `/organisation/${organisationId}/batch/${batchId}/member/${memberId}/payments`,
      type: "post",
      storeData: false,
      refreshPageData: true,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "deletePayment",
    payload: {
      url: ({ paymentId, batchId, memberId }) =>
        `/batch/${batchId}/members/${memberId}/payments/${paymentId}/delete`,
      type: "post",
      refreshPageData: true,
      storeData: false,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getPaymentStats",
    payload: {
      url: "/payments/stats",
      type: "get",
    },
  },
]
