import React from "react"

import Cell from "./Cell"
import Checkbox from "../Input/Checkbox"
import Form from "../Forms/Form"

export default function TableRow({
  id = "",
  row,
  onClick,
  index,
  headers,
  selectable,
  checked,
  rowSelect,
  unselectableIcon,
  isClickable,
  danger,
  success,
  styles = {},
  withHoverStyle = true,
  roundBottom = false,
}) {
  return (
    <tr
      onClick={(event) => onClick(event, row)}
      className={`even:bg-neutral-10 odd:bg-white
        ${isClickable ? " cursor-pointer " : ""}
        ${roundBottom ? " rounded-b overflow-hidden" : ""}
        ${withHoverStyle ? " row-hover-effect" : ""}
        h-row`}
    >
      {selectable &&
        (row.isSelectable === undefined || row.isSelectable ? (
          <td className="px-md w-md text-center">
            <Form>
              <Checkbox
                checked={checked}
                id={"selectRow" + index + id}
                name={"selectRow" + index}
                onChange={() => rowSelect(row, index)}
              />
            </Form>
          </td>
        ) : (
          <td>{unselectableIcon}</td>
        ))}
      {headers.map(
        ({ key, below, right, format, render, defaultValue }, index) => (
          <Cell
            key={"cell" + index}
            right={right}
            selectable={selectable}
            render={render}
            below={below}
            row={row}
            cellKey={key}
            format={format}
            defaultValue={defaultValue}
            styles={styles}
            roundBottom={roundBottom}
            danger={index === headers.length - 1 && danger}
            success={index === headers.length - 1 && success}
          />
        )
      )}
    </tr>
  )
}
