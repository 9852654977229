import React from 'react'

export default ({
  white,
  children,
  bodyStyles,
  height = 'auto'
}) => (
  <div className={ `bg-${white ? 'white' : 'neutral-10'} p-md sm:h-${height} ${bodyStyles}` }>
    { children }
  </div>
)
