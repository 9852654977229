import React, { useRef } from "react"

import MenuDropdown from "../MenuDropdown"
import { getOptionInitials } from "../../utils/functions"
import { Link, useLocation } from "react-router-dom"
import Icon from "../Icon"

export default ({ options, title, name, menuIcon }) => {
  const location = useLocation()
  const menuEl = useRef()

  const handleClubClick = (option) => {
    if (menuEl && menuEl.current) {
      menuEl.current.close()
    }

    if (option.callback) {
      option.callback()
    }
  }

  const viewingOwnDashboard = location.pathname.includes("/dashboard")
  const affiliateDashboard = location.pathname.includes("/affiliate")

  return (
    <MenuDropdown
      iconBackgroundColour={affiliateDashboard ? "support-6" : false}
      icon={
        menuIcon ? (
          <Icon {...menuIcon} />
        ) : (
          getOptionInitials(viewingOwnDashboard ? name : title)
        )
      }
      title={title}
      truncateLabel={true}
      truncateWidth="10rem"
      ref={menuEl}
    >
      {options.length &&
        options.map((option, index) => {
          if (option.to) {
            return (
              <Link
                to={option.to}
                className={
                  "flex flex-1 p-xs hover:bg-neutral-10 cursor-pointer first:rounded-t last:rounded-b " +
                  option.style
                }
                key={index}
                onClick={() => handleClubClick(option)}
              >
                <div
                  className={`${
                    !option.icon ? "bg-primary-4 justify-center" : ""
                  } rounded-full h-28 min-w-28 text-white flex items-center mr-sm text-sm`}
                >
                  {option.icon ? (
                    <Icon {...option.icon} />
                  ) : (
                    getOptionInitials(option.iconText || option.name)
                  )}
                </div>
                <div className="my-auto text-neutral-1 w-full">
                  {option.name}
                </div>
              </Link>
            )
          }

          return (
            <div
              className={
                "flex flex-1 p-xs hover:bg-neutral-10 cursor-pointer first:rounded-t last:rounded-b " +
                option.style
              }
              key={index}
              onClick={() => handleClubClick(option)}
            >
              <div
                className={`${
                  !option.icon ? "bg-primary-4" : ""
                } rounded-full h-28 min-w-28 text-white flex items-center justify-center mr-sm text-sm`}
              >
                {option.icon ? (
                  <Icon {...option.icon} />
                ) : (
                  getOptionInitials(option.iconText || option.name)
                )}
              </div>
              <div className="my-auto text-neutral-1 w-full">{option.name}</div>
            </div>
          )
        })}
    </MenuDropdown>
  )
}
