import React, { useEffect, useRef, Suspense } from "react"
import moment from "moment"
import { useMachine } from "@xstate/react"
import { isMobileOnly } from "react-device-detect"
import { Switch, useLocation, Redirect } from "react-router-dom"

import { getQueryObject } from "./utils/functions"
import { useApplicationInProgress } from "./utils/hooks"
import { appMachine, MachineContext } from "./state"
import config from "./config"

import ProtectedRoute from "./components/ProtectedRoute"
import PublicRoute from "./components/PublicRoute"

import Minimised from "./components/Minimised"
import Toast from "./components/Toast"

import AffiliateRoutes from "./routes/AffiliateRoutes"
import OrganisationRoutes from "./routes/OrganisationRoutes"
import MemberRoutes from "./routes/MemberRoutes"

import ModalWrapper from "./components/containers/ModalWrapper"
import ConfirmationWrapper from "./components/containers/ConfirmationWrapper"

const ClubApproval = React.lazy(() => import("./pages/admin/ClubApproval"))
const AffiliateApproval = React.lazy(() =>
  import("./pages/admin/AffiliateApproval")
)

const Login = React.lazy(() => import("./pages/common/Login"))
const VerifyEmail = React.lazy(() => import("./pages/common/VerifyEmail"))
const Onboarding = React.lazy(() => import("./pages/common/Onboarding"))
const ForgotPassword = React.lazy(() => import("./pages/common/ForgotPassword"))
const UpdatePassword = React.lazy(() => import("./pages/common/UpdatePassword"))
const ChangeEmail = React.lazy(() => import("./pages/common/ChangeEmail"))
const Register = React.lazy(() => import("./pages/common/Register"))
const ResetPassword = React.lazy(() => import("./pages/common/ResetPassword"))
const Maintenance = React.lazy(() => import("./pages/common/Maintenance"))
const APIHoldingPage = React.lazy(() => import("./pages/common/OrgHoldingPage"))

const AcceptInvite = React.lazy(() =>
  import("./pages/organisation/AcceptInvite")
)
const InviteLogin = React.lazy(() => import("./pages/organisation/InviteLogin"))

const InvoicePayment = React.lazy(() =>
  import("./pages/payments/InvoicePayment")
)
const PingPayment = React.lazy(() => import("./pages/payments/PingPayment"))
const PingPaymentReceipt = React.lazy(() =>
  import("./pages/payments/PingPaymentReceipt")
)

const Page404 = React.lazy(() => import("./pages/errors/404"))

function App() {
  const [currentMachine, sendToMachine, machineService] = useMachine(appMachine)
  const location = useLocation()
  const locationRef = useRef({
    pathname: location.pathname,
    hash: location.hash,
  })

  const applicationInProgress = useApplicationInProgress(currentMachine.context)

  useEffect(() => {
    const urlQuery = getQueryObject(location.search)

    if (urlQuery && urlQuery.referrer) {
      localStorage.setItem(
        "referrer",
        JSON.stringify({
          code: urlQuery.referrer,
          expires: moment().add(90, "days").toDate(),
        })
      )
    }
  }, [location])

  useEffect(() => {
    sendToMachine("CLEAR_ERRORS")

    if (
      locationRef.current.pathname !== location.pathname &&
      locationRef.current.hash !== location.hash
    ) {
      sendToMachine("UPDATE_TABLE_PROPS", {
        props: {
          data: [],
          page: 1,
        },
      })
    }
    locationRef.current = {
      pathname: location.pathname,
      hash: location.hash,
    }
  }, [location, sendToMachine])

  if (
    ["mounting", "mountingRequests"].some((value) => {
      return currentMachine.matches(value)
    })
  ) {
    return <React.Fragment />
  }

  if (!location.pathname.includes("/maintenance") && config.maintenance) {
    return <Redirect to="/maintenance" />
  }

  return (
    <MachineContext.Provider
      value={[currentMachine, sendToMachine, machineService]}
    >
      <Switch>
        <PublicRoute exact path="/maintenance">
          <Maintenance />
        </PublicRoute>
        <PublicRoute exact path="/holding">
          <APIHoldingPage />
        </PublicRoute>

        <PublicRoute exact path="/signup">
          <Register />
        </PublicRoute>

        <PublicRoute exact path="/signin">
          <Login />
        </PublicRoute>

        <PublicRoute
          exact
          killBeacon={false}
          path={["/verified/:verificationString", "/onboarding"]}
          render={(props) => <Onboarding {...props} />}
        />

        <PublicRoute
          exact
          path="/invite/:inviteToken"
          render={(props) => <AcceptInvite {...props} />}
        />

        <PublicRoute
          exact
          path="/signin/:inviteToken"
          render={(props) => <InviteLogin {...props} />}
        />
        <PublicRoute exact path="/verify">
          <VerifyEmail />
        </PublicRoute>
        <PublicRoute exact path="/verify/update">
          <ChangeEmail />
        </PublicRoute>
        <PublicRoute
          exact
          path="/password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <PublicRoute
          exact
          path="/password/:resetToken"
          render={(props) => <ResetPassword {...props} />}
        />
        <PublicRoute exact path="/update">
          <UpdatePassword />
        </PublicRoute>
        <PublicRoute
          exact
          path="/invoice/:token"
          render={(props) => <InvoicePayment {...props} />}
        />
        <PublicRoute
          exact
          path="/ping/:pingCode"
          render={(props) => <PingPayment {...props} />}
        />
        <PublicRoute
          exact
          path="/ping/:pingId/receipt/:purchaseId"
          render={(props) => <PingPaymentReceipt {...props} />}
        />
        <PublicRoute
          exact
          path="/ping/:pingId/invoice/:invoiceId"
          render={(props) => <PingPaymentReceipt {...props} />}
        />

        <PublicRoute
          exact
          path="/404"
          render={(props) => <Page404 {...props} />}
        />
        <PublicRoute
          exact
          path="/scope/emit/venae/anathema/:approvalToken"
          render={(props) => <ClubApproval {...props} />}
        />
        <PublicRoute
          exact
          path="/scope/emit/venae/affiliate/:approvalToken"
          render={(props) => <AffiliateApproval {...props} />}
        />

        <ProtectedRoute
          path="/affiliate"
          props={{
            applicationInProgress,
          }}
          component={AffiliateRoutes}
        />
        {currentMachine.context.user &&
        currentMachine.context.user.roles.includes("admin") ? (
          <ProtectedRoute
            path="/"
            props={{
              applicationInProgress,
            }}
            component={OrganisationRoutes}
          />
        ) : (
          <ProtectedRoute
            path="/"
            props={{
              applicationInProgress,
            }}
            component={MemberRoutes}
          />
        )}
      </Switch>

      <ModalWrapper />
      <ConfirmationWrapper />
      <Toast
        toastList={currentMachine.context.notifications}
        position="top-right"
      />
      {!isMobileOnly && // TODO: REMOVE ISMOBILEONLY CHECK WHEN ADMIN PAGES ARE MOBILE OPTIMISED
        currentMachine.context.user &&
        currentMachine.context.user.roles.includes("admin") &&
        !location.pathname.includes("/invoice/") &&
        !location.pathname.includes("/dashboard") &&
        !location.pathname.includes("/affiliate") &&
        !location.pathname.includes("/maintenance") &&
        !location.pathname.includes("/holding") &&
        !location.pathname.includes("/ping/") &&
        !location.pathname.includes("/404") &&
        !location.pathname.includes("/scope/emit/venae") && <Minimised />}
    </MachineContext.Provider>
  )
}

export default App
