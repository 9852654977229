import React, { useImperativeHandle, forwardRef, useEffect } from "react"

import Input from "../../Input"
import Textarea from "../../Input/Textarea"
import HelperRow from "../../containers/HelperRow"
import { useFormValidator } from "../../Forms/Form"

export default forwardRef(
  ({ name, type, intent, website, social, setState, submitClub }, ref) => {
    const [validator, { validateForm }] = useFormValidator()

    useEffect(() => {
      validator.purgeFields()
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        validateForm()

        if (validator.allValid()) {
          validator.hideMessages()
          submitClub()
        } else {
          validator.showMessages()
        }
      },
    }))

    return (
      <div>
        <div className="mb-md">
          <HelperRow
            title="Club details"
            text="Tell us what your club is called, what it does, and where we can find you online. We’ll use this to ensure you’re a good match for using Payzip."
            columns={4}
            textCols={2}
          >
            <div className="grid grid-cols-1 gap-sm">
              <Input
                label="Club name"
                name="name"
                value={name}
                placeholder="Club name"
                onChange={({ target }) =>
                  setState({ [target.name]: target.value })
                }
                validation={{
                  message: "What's your club called?",
                  rules: "required",
                }}
              />
              <Input
                label="Club type"
                name="type"
                value={type}
                placeholder="e.g. Football"
                onChange={({ target }) =>
                  setState({ [target.name]: target.value })
                }
                validation={{
                  message: "What type of club is it?",
                  rules: "required",
                }}
              />
              <Input
                label="Online presence"
                name="website"
                value={website}
                placeholder="Website"
                onChange={({ target }) =>
                  setState({ [target.name]: target.value })
                }
                validation={{
                  rules: "string",
                }}
              />
              <Input
                name="social"
                value={social}
                placeholder="Twitter / Facebook"
                onChange={({ target }) =>
                  setState({ [target.name]: target.value })
                }
              />
            </div>
          </HelperRow>
        </div>
        <HelperRow
          title="Intended use"
          text="Tell us what type of payments you plan to collect using Payzip (e.g. annual subs, match fees, tour payments etc.)."
          columns={4}
          textCols={2}
        >
          <Textarea
            label="Summary"
            name="intent"
            value={intent}
            onChange={({ target }) => setState({ [target.name]: target.value })}
            rows={7}
            validation={{
              message: "What will you use Payzip for?",
              rules: "required",
            }}
          />
        </HelperRow>
      </div>
    )
  }
)
