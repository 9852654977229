import { createContext } from "react"
import { Machine, assign, send } from "xstate"

import requests from "./requests"

import { submitData } from "../utils/api"
import { handleMountRequest } from "../utils/functions"
import { getUserAccount, getUserProfile } from "../utils/requests/user"
import { getUserOrganisations } from "../utils/requests/organisation"

import actions from "./actions"
import events from "./events"
import { modalMachine, confirmationMachine } from "./modals"

const MachineContext = createContext()
MachineContext.displayName = "AppContext"

let appMachine = Machine(
  {
    id: "app",
    initial: "mounting",
    context: {
      pageTitle: "Overview",

      errors: {},
      errorRedirect: false,
      errorBanner: true,
      previousEvent: null,

      user: undefined,
      token: undefined,
      expandedMenu: undefined,
      requestCallback: null,
      notifications: [],
      stats: {},
      overdueInvoices: [],
      invalidEmails: [],
      activePings: [],
      overdueInvoicesPaginationProps: {},
      activePingsPaginationProps: {},
      invalidEmailsPaginationProps: {},
      tableProps: {
        ref: "",
        filters: [],
        data: [],
        search: "",
        page: 1,
      },
      paginationProps: {},
      refreshPageData: false,
      ping: {},

      organisation: undefined,
      confirmation: {},
      tutorial: {},
      affiliateTutorial: {},
      organisations: [],
      member: {},
      payout: {},
      batch: {},
      activity: [],
      groups: [],
      requests: null,
      team: [],
      invoice: undefined,
      updatedEmail: "",

      settings: {},
    },
    states: {
      mounting: {
        invoke: {
          id: "mounting",
          src: (context, event) =>
            new Promise((resolve, reject) => {
              let prevContext =
                JSON.parse(localStorage.getItem("app-state")) || {}
              Object.entries(prevContext).map((key) => {
                try {
                  let parsedObject = JSON.parse(key[1])

                  prevContext[key[0]] = parsedObject
                } catch (e) {
                  prevContext[key[0]] = key[1]
                }
                return key
              })
              resolve(prevContext)
            }),
          onDone: {
            target: "mountingRequests",
            actions: assign((context, event) => {
              // Fix related to release v2.9
              if (event.data.user && !event.data.user.roles) {
                return {
                  forceRedirect: "/logout",
                }
              }
              return event.data
            }),
          },
          onError: {
            target: "app",
            actions: ["handleError"],
          },
        },
      },
      mountingRequests: {
        invoke: {
          id: "mountingRequests",
          src: (context, event) => {
            let requests = []
            if (context.user) {
              requests = [getUserAccount, getUserProfile, getUserOrganisations]
            }

            return submitData({ payload: requests }, {}, context)
          },
          onDone: {
            target: "app",
            actions: [assign(handleMountRequest)],
          },
          onError: {
            target: "app",
            actions: ["handleError", "handleRetryBanner"],
          },
        },
      },
      app: {
        id: "app",
        type: "parallel",
        entry: [send("getAppSettings")],
        states: {
          init: {},
          requests,
          modalMachine,
          confirmationMachine,
        },
      },
    },
    on: events,
  },
  {
    actions,
  }
)

export { appMachine, MachineContext }
