import React from 'react'
import moment from 'moment'

import { formatCurrency } from '../../utils/functions'
import Card from '../containers/Card'

export default function PaymentCard ({
  payment,
  onClick
}) {
  return <Card
    onClick={ onClick }
    title={ payment.invoiceTitle }
    subtitle={ (payment.member && payment.member.name) ? payment.member.name : '' }
    middleText={ payment.organisationName }
    leftTitle={ `Paid by ${payment.paymentMethod}` }
    leftData={
      moment(payment.paymentDate).format(
        (payment.paymentMethod && payment.paymentMethod.toLowerCase()) === 'card' ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY'
      )
    }
    rightTitle="Amount"
    rightData={ formatCurrency(payment.amount) }
  />
}
