import React from 'react'

export default () => {
  return (
    <div>
      <div className="mb-md">
        <div className="text-neutral-5 font-medium mb-xs">
          What is this?
        </div>
        <div className="mb-md">
          Payzip offers an affiliate programme where you can earn money for introducing new clubs and organisations. Affiliates receive a commission on every card payment the club receives, for the entire life of the club’s Payzip account.
        </div>
        <div className="text-neutral-5 font-medium mb-xs">
          What happens next?
        </div>
        <div className="grid grid-cols-1 gap-xs mb-md">
          <div>
            First you’ll need to tell us about you and how you intend to promote Payzip. Then, once we’ve approved your application, you’ll need to sign up to Stripe to connect your bank account. There is no cost to joining the programme.
          </div>
          <div>
            The whole process should take no more than ten minutes and is only done once. We’re also on-hand to help if you get stuck.
          </div>
        </div>
        <div className="text-neutral-5 font-medium mb-xs">
          Want to know more?
        </div>
        <div>
          More information about our affiliate programme is available on our <a href="https://www.payzip.co.uk/affiliates" target="_blank" rel="noreferrer noopener">website</a>
        </div>
      </div>
    </div>
  )
}
