import React from 'react'
import ShareableLinks from '../containers/ShareableLinks'

export default function ShareAffiliateLink({ send, state: { affiliateLinks } }) {
  const onCopy = () => {
    send('updateAffiliateTutorial', {
      data: {
        body: {
          linkShared: true
        }
      }
    })
  }
  return (
    <div className="grid grid-cols-1 gap-sm">
      <div>
        Copy your link below to share with your audience.
      </div>
      <ShareableLinks urls={ affiliateLinks } onCopy={ onCopy } />
    </div>
  )
}
