import React, { useContext, useEffect, useRef } from "react"
import Route from "../components/LazyRoute"
import { Switch, Redirect } from "react-router-dom"

import { MachineContext } from "../state"
import Button from "../components/Button"
import { createClubModal } from "../utils/modals/organisation"
import { viewAffiliateTutorialModal } from "../utils/modals/affiliate"
import Dashboard from "../components/containers/Dashboard"
import AffiliateHomeSkeleton from "../pages/affiliate/Home/Skeleton"
import SignupsSkeleton from "../pages/affiliate/Signups/Skeleton"
import AffiliatePayoutSkeleton from "../pages/affiliate/Payout/Skeleton"
import AffiliatePayoutsSkeleton from "../pages/affiliate/Payouts/Skeleton"

const Home = React.lazy(() => import("../pages/affiliate/Home"))
const Payout = React.lazy(() => import("../pages/affiliate/Payout"))
const Payouts = React.lazy(() => import("../pages/affiliate/Payouts"))
const Signups = React.lazy(() => import("../pages/affiliate/Signups"))

export default ({ location, applicationInProgress }) => {
  const [{ context }, send] = useContext(MachineContext)

  if (!context.user.roles.includes("affiliate")) {
    return <Redirect to="/" />
  }

  const urlRef = useRef(
    context.user.affiliateProfile ? context.user.affiliateProfile.urls : []
  )

  useEffect(() => {
    send("getAffiliateTutorial", {
      data: {
        onSuccess: {
          callback: ({ response }) => {
            if (response.showOnMount) {
              viewAffiliateTutorialModal(send, urlRef.current)
            }
          },
        },
      },
    })
  }, [send])

  return (
    <Dashboard
      hideLogo
      logoPath="/affiliate"
      sidebar="affiliate"
      pathname={location.pathname}
      name={context.user.name}
      email={context.user.email}
      organisation={context.organisation}
      actions={[
        !applicationInProgress && !context.user.roles.includes("admin") ? (
          <Button
            key="create-club-button"
            type="secondary"
            border={false}
            icon="plus-circle"
            iconType="duotone"
            containerStyle={
              context.user.roles && context.user.roles.includes("admin")
                ? "hidden"
                : ""
            }
            onClick={() => createClubModal(send, context.user, context.profile)}
          >
            Create club
          </Button>
        ) : (
          <React.Fragment key="fragment" />
        ),
        !context.user.roles.includes("admin") ? (
          <Button
            key="affiliate-view-button"
            type="support"
            border={false}
            icon="user-circle"
            iconType="duotone"
            containerStyle="pl-md"
            to="/"
          >
            Member view
          </Button>
        ) : (
          <React.Fragment key="affiliate-fragment" />
        ),
      ]}
    >
      <Switch>
        <Route
          exact
          path="/affiliate"
          fallback={<AffiliateHomeSkeleton />}
          render={(props) => <Home {...props} />}
        />
        <Route
          exact
          path="/affiliate/signups"
          fallback={<SignupsSkeleton />}
          render={(props) => <Signups {...props} />}
        />
        <Route
          exact
          path="/affiliate/payouts"
          fallback={<AffiliatePayoutsSkeleton />}
        >
          <Payouts />
        </Route>
        <Route
          exact
          path="/affiliate/payouts/:id"
          fallback={<AffiliatePayoutSkeleton />}
          render={(props) => <Payout {...props} />}
        />
      </Switch>
    </Dashboard>
  )
}
