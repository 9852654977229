import React, { useContext } from "react"
import { checkFeatureFlag } from "../../utils/functions"
import { MachineContext } from "../../state"

export default ({ children, name }) => {
  let [
    {
      context: { organisation },
    },
  ] = useContext(MachineContext)

  if (!checkFeatureFlag(name, organisation)) {
    return <React.Fragment />
  }

  return children
}
