import React from "react"
import SkeletonPanel from "../../../components/Skeleton/Panel"
import SkeletonTable from "../../../components/Skeleton/Table"

const PaymentsSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-md">
      <SkeletonPanel panelCount={6} />
      <SkeletonTable
        header={[
          {
            width: "w-80",
          },
          {
            width: "w-80",
          },
        ]}
        headerCells={[
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 2,
            text: {
              width: "w-1/3",
              colour: "bg-neutral-5",
            },
            className: "py-base",
          },
          {
            colSpan: 4,
            text: {
              width: "w-1/6",
              colour: "bg-neutral-5",
            },
            className: "py-base",
          },
          {
            right: true,
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            right: true,
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
        ]}
        cells={[
          {
            colSpan: 1,
          },
          {
            colSpan: 2,
          },
          {
            colSpan: 4,
            text: {
              width: "w-2/5",
            },
          },
          {
            right: true,
            colSpan: 1,
          },
          {
            right: true,
            colSpan: 1,
          },
        ]}
      />
    </div>
  )
}

export default PaymentsSkeleton
