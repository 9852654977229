import { defaults } from "./config"
import { handleFormSubmission, pluraliseWord } from "../functions"

const importMembersPayload = (sheetData = []) => ({
  ...defaults,
  id: "importMembers",
  title: "Import members",
  overrideLayout: true,
  state: {
    sheetData,
  },
  componentPath: "ImportMembers",
  confirmOnClose: true,
  modalSize: "lg",
})

const addMembersPayload = (send, context) => ({
  ...defaults,
  id: "addMembers",
  title: "Add members",
  state: {
    members: [{ name: "", email: "", groups: [] }],
  },
  componentPath: "AddMembers",
  modalSize: "lg",
  confirmOnClose: true,
  buttons: [
    {
      text: "Add members",
      type: "primary",
      iconType: "duotone",
      colWidth: 7,
      icon: "plus-circle",
      submitting: "app.requests.addMembers.requesting",
      callback: (event, validator, validateForm, state) => {
        handleFormSubmission(validator, () => {
          const memberCount = state.members.filter(
            (member) => member.name && member.email
          ).length
          send("addMembers", {
            data: {
              body: state.members,
              storeData: false,
              refreshPageData: true,
              variables: {
                organisationId: context.organisation.id,
              },
              onSuccess: {
                notification: {
                  title: `${memberCount} ${pluraliseWord(
                    "member",
                    memberCount
                  )} added`,
                  description: "Click here to view",
                  callback: () => send("CLOSE_MODAL"),
                  to: "/members",
                },
                callback: () => {
                  send("UPDATE_TUTORIAL_CONTEXT", {
                    tutorial: {
                      hasMembers: true,
                    },
                  })

                  send("CLOSE_MODAL")
                },
              },
            },
          })
        })
      },
    },
  ],
})

const editMemberPayload = (send, member, memberId, successCallback) => ({
  id: "editMember",
  title: "Edit member",
  modalSize: "md",
  state: member,
  withHeader: true,
  withButtons: true,
  buttons: [
    {
      text: "Update",
      type: "primary",
      icon: "sync-alt",
      colWidth: 7,
      changeSensitive: true,
      submitting: "app.requests.updateMemberDetails.requesting",
      callback: (event, validator, checkValidation, state) => {
        checkValidation()
        if (validator.allValid()) {
          send("updateMemberDetails", {
            data: {
              refreshPageData: true,
              variables: {
                memberId,
              },
              body: {
                name: state.name,
                email: state.newEmail || state.email,
              },
              onSuccess: {
                notification: {
                  title: "Member updated",
                  description: `Successfully updated ${state.name}'s details`,
                },
                callback: ({ response }) => {
                  send("UPDATE_CONTEXT", {
                    member: {
                      archived: response.archived,
                      associatedUser: response.associatedUser,
                      email: response.email,
                      groups: response.groups,
                      id: response.id,
                      name: response.name,
                    },
                  })

                  if (
                    state.newEmail &&
                    state.newEmail !== state.email &&
                    successCallback
                  ) {
                    successCallback(response, state.email, state.newEmail)
                  } else {
                    send("UPDATE_CONTEXT", { refreshPageData: true })
                    send("CLOSE_MODAL")
                  }
                },
              },
              onError: {
                notification: {
                  title: "Whoops",
                  description: "Something went wrong",
                },
              },
            },
          })
        }
      },
    },
  ],
  componentPath: "EditMember",
})

const updateMembersPayload = (
  { newEmail, oldEmail, memberId },
  sharedEmail
) => ({
  id: "updateMembers",
  modalSize: "md",
  confirmOnClose: true,
  state: {
    newEmail,
    oldEmail,
    memberId: parseInt(memberId),
    members: sharedEmail,
  },
  overrideLayout: true,
  componentPath: "UpdateMembers",
})

export const viewEditMemberModal = (send, memberId, successCallback) => {
  send("getMemberDetails", {
    data: {
      variables: {
        memberId,
      },
      storeData: false,
      onSuccess: {
        callback: ({ response }) => {
          send("OPEN_MODAL", {
            payload: editMemberPayload(
              send,
              response,
              memberId,
              successCallback
            ),
          })
        },
      },
    },
  })
}

export const viewUpdateMembersModal = (send, { sharedEmail }, details) => {
  return send("OPEN_MODAL", {
    payload: updateMembersPayload(details, sharedEmail),
  })
}

export const addMembersModal = (send, organisation) => {
  send("getGroups", {
    data: {
      variables: {
        organisationId: organisation.id,
      },
      onSuccess: {
        callback: (data, context) => {
          send("OPEN_MODAL", {
            payload: addMembersPayload(send, context),
          })
        },
      },
    },
  })
}

export const importMembersModal = (send, sheetData) => {
  send("OPEN_MODAL", {
    payload: importMembersPayload(sheetData),
  })
}

export const openResentInvoicesModal = (
  send,
  { memberIds, newEmail, organisation, shouldRefreshTables, deliveryStatus }
) => {
  send("getMembersInvoices", {
    data: {
      variables: {
        organisationId: organisation.id,
        memberIds,
        statuses: "owed",
        deliveryStatus,
      },
      storeData: false,
      transformResponse: ({ data }, context) => {
        return {
          data: {
            modalData: {
              ...context.modalData,
              data: data.rows,
            },
          },
        }
      },
      onSuccess: {
        callback: ({ response }) => {
          if (response.modalData.data.filter((r) => !r.datePaid).length === 0) {
            send("CLOSE_MODAL")
          } else {
            send("sendRemindersList", {
              data: {
                body: response.modalData.data.map((entry) => {
                  return {
                    batchId: entry.metadata.batchId,
                    memberId: entry.metadata.memberId,
                  }
                }),
                variables: {
                  organisationId: organisation.id,
                  resentInvoices: true,
                },
              },
            })

            send("OPEN_MODAL", {
              payload: {
                title: "Outstanding invoices",
                id: "RemindersSent",
                overrideLayout: true,
                modalData: response.modalData,
                state: {
                  shouldRefreshTables,
                  newEmail,
                  deliveryStatus,
                },
                componentPath: "RemindersSent",
                handleClose: () => {
                  if (shouldRefreshTables) {
                    send("UPDATE_CONTEXT", { refreshPageData: true })
                  }
                },
              },
            })
          }
        },
      },
    },
  })
}
