import React, { useEffect, useState } from "react"

import Footer from "../../containers/Footer"
import Table from "../../Table"
import { pluraliseWord } from "../../../utils/functions"
import Header from "../../containers/Header"

const RemindersSent = ({ send, state = {}, modalData }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(modalData.data.filter((row) => !row.datePaid))
  }, [modalData.data])

  return (
    <div>
      <Header
        title={
          state.deliveryStatus === 6
            ? "Invoices re-sent"
            : `Outstanding ${pluraliseWord("invoice", data.length)}`
        }
      />
      <div className="bg-neutral-10 max-h-modal-body overflow-y-auto">
        {state.newEmail && (
          <div className="p-md shadow-b-border text-neutral-5">
            {state.deliveryStatus === 6
              ? ""
              : `There ${data.length === 1 ? "is" : "are"} ${
                  data.length
                } outstanding
            ${pluraliseWord("invoice", data.length)}. `}
            We've re-sent the following {pluraliseWord("invoice", data.length)}{" "}
            to {state.newEmail}.
          </div>
        )}
        <Table
          disableLoadingSpinner
          withShadow={false}
          hasRounding={false}
          isDangerRow={(row) => row.overdue && row.owed > 0}
          headers={[
            {
              name: "Invoice",
              key: "title",
            },
            {
              name: "Member",
              render: (row) => row.member.name,
            },
          ]}
          data={data}
        />
      </div>
      <Footer
        useValidation={false}
        buttons={[
          {
            type: "primary",
            callback: () => {
              send("CLOSE_MODAL")
              if (state.shouldRefreshTables) {
                send("UPDATE_CONTEXT", { refreshPageData: true })
              }
            },
            iconType: "duotone",
            icon: "check-circle",
            text: "Okay",
          },
        ]}
      />
    </div>
  )
}

export default RemindersSent
