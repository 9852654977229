import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  forwardRef,
} from "react"

import Row from "./Row"
import { MachineContext } from "../../state"
import { pluraliseWord } from "../../utils/functions"
import Checkbox from "../Input/Checkbox"
import Pagination from "./Pagination"
import Header from "../containers/Header"
import Form from "../Forms/Form"
import EmptyState from "../containers/EmptyState"
import InvoiceCard from "../Cards/Invoice"
import PaymentCard from "../Cards/Payment"
import MobileHeader from "../containers/MobileHeader"
import LoadingSpinner from "../LoadingSpinner"
import PingCard from "../Cards/Ping"
import SkeletonTable from "../Skeleton/Table"

const Table = forwardRef(
  (
    {
      reference,
      id,
      title,
      actions = [],
      data,
      headers,
      onRowClick,
      selectable = false,
      selectedRows = [],
      onSelectAll = () => {},
      onRowSelect = () => {},
      onRowDeselect = () => {},
      onPageChange = () => {},
      allSelected,
      actionsSpacing = "sm",
      withHoverStyle = true,
      noDataText,
      noDataComponent,
      noDataActions = [],
      isDangerRow = () => false,
      isSuccessRow = () => false,
      headerTabs = [],
      headerPage = "",
      actionContainerClasses = "",
      tableContainerClasses = "",
      withShadow = true,
      unselectableIcon,
      searchFilterKeys = [],
      hasRounding = true,

      paginate,
      paginateData,
      styles = {},

      exportRequestKey = "",
      exportRequestVariables = {},
      enableExport = false,
      exportFileSuffix,
      exportHeaders,
      exportTransformer,

      disableLoadingSpinner = false,
      isLoading = false,

      // ONLY USED ON MOBILE
      handleMobilePagination = () => {},
      mobileHeaderPadding = false,
      cardType = "",
    },
    ref
  ) => {
    const [current, send] = useContext(MachineContext)
    const { tableProps, paginationProps } = current.context
    const referenceRef = useRef(reference)
    const filterKeysRef = useRef(searchFilterKeys)
    const [chosenData, setChosenData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [tableData, setTableData] = useState([])
    const [displayData, setDisplayData] = useState([])
    const [selectedData, updateSelectedData] = useState(
      selectedRows.map((row) => row.id)
    )
    const [hasLoaded, setHasLoaded] = useState(false)
    const rightAlignStyles = "text-right "
    const scrollToTopRef = useRef()

    const changePage = (page) => {
      setCurrentPage(page)
      onPageChange(page)
    }

    const modalMachine = current.children.modal.state
    const confirmationMachine = current.children.confirmation.state

    useEffect(() => {
      if (
        (modalMachine.matches("open") || confirmationMachine.matches("open")) &&
        !hasLoaded
      ) {
        setHasLoaded(true)
      }
    }, [current, hasLoaded, modalMachine, confirmationMachine])

    useEffect(() => {
      setHasLoaded(true)
    }, [chosenData])

    useEffect(() => {
      if (isLoading) {
        setHasLoaded(false)
      }
    }, [isLoading])

    useEffect(() => {
      // When tables finish fetching data and the user has scrolled beneath the head of the table, scroll to top
      if (
        !isLoading &&
        scrollToTopRef.current &&
        scrollToTopRef.current.offsetTop < window.pageYOffset
      ) {
        scrollToTopRef.current.scrollIntoView({
          behavior: "smooth",
        })
      }
    }, [isLoading])

    useEffect(() => {
      changePage(current.context.tableProps.page)
    }, [current.context.tableProps.page])

    useEffect(() => {
      setCurrentPage((page) => {
        if (page !== 1) {
          return 1
        }
        return page
      })
    }, [tableProps.search])

    useEffect(() => {
      filterKeysRef.current = searchFilterKeys
    }, [searchFilterKeys])

    useEffect(() => {
      if (selectable) {
        updateSelectedData(selectedRows.map((row) => row.id))
      }
    }, [selectable, selectedRows])

    useEffect(() => {
      referenceRef.current = reference

      if (reference) {
        send("UPDATE_TABLE_PROPS", {
          props: {
            data: [],
            search: "",
          },
        })
      }
    }, [reference, send])

    useEffect(() => {
      if (tableProps.ref && referenceRef.current !== tableProps.ref) {
        send("UPDATE_TABLE_PROPS", {
          props: {
            filters: [],
            ref: referenceRef.current,
            data: [],
            search: "",
          },
        })
      }
    }, [send, tableProps.ref])

    const onClick = (event, row, index) => {
      event.stopPropagation()

      if (onRowClick) {
        onRowClick(row)
      }

      if (selectable && (row.isSelectable === undefined || row.isSelectable)) {
        rowSelect(row, index)
      }
    }

    useEffect(() => {
      setDisplayData(Array.isArray(data) ? data : tableData)
    }, [data, tableData])

    const selectAll = ({ target: { checked } }) => {
      if (checked) {
        updateSelectedData(displayData.map((data) => data.id))
        onSelectAll(displayData)
      } else {
        updateSelectedData([])
        onSelectAll([])
      }
    }

    const rowSelect = (row, index) => {
      const foundRow = selectedData.findIndex((id) => id === row.id)
      if (foundRow > -1 || row.selected) {
        updateSelectedData(selectedData.filter((id) => id !== row.id))
        onRowDeselect(row, index)
      } else {
        updateSelectedData(selectedData.concat([row.id]))
        onRowSelect(row, index)
      }
    }

    useEffect(() => {
      if (tableProps.data) {
        setTableData(tableProps.data)
      }
    }, [tableProps.data])

    useEffect(() => {
      setChosenData(displayData)
    }, [displayData])

    const getDisplayData = () => {
      return chosenData.map((row, i) => {
        return (
          <Row
            id={`row${id ? id : ""}`}
            withHoverStyle={withHoverStyle}
            headers={headers}
            row={row}
            selectable={selectable}
            unselectableIcon={row.unselectableIcon || unselectableIcon}
            key={"row" + i}
            index={i}
            checked={
              row.selected !== undefined
                ? row.selected
                : selectedData.findIndex((id) => id === row.id) > -1
            }
            rowSelect={rowSelect}
            onClick={(event) => onClick(event, row, i)}
            styles={styles}
            roundBottom={
              !paginate && i === chosenData.length - 1 && hasRounding
            }
            danger={isDangerRow(row)}
            success={isSuccessRow(row)}
            isClickable={
              onRowClick !== undefined ||
              (selectable &&
                (row.isSelectable === undefined || row.isSelectable))
            }
          />
        )
      })
    }

    const showMoreClick = () => {
      if (!isLoading) {
        const currentPage = Math.ceil(tableProps.data.length / 15)
        handleMobilePagination(currentPage + 1)
      }
    }

    const currentSelectVisible =
      selectedData.length &&
      chosenData.length &&
      chosenData.filter((data) => selectedData.includes(data.id)).length ===
        chosenData.length

    const isAllSelected =
      allSelected !== undefined
        ? allSelected
        : selectedData.length === displayData.length && currentSelectVisible

    const isAllBulk =
      !allSelected &&
      (selectedData.length !== chosenData.length || !currentSelectVisible) &&
      selectedData.length > 0

    const renderTableBody = () => {
      const skeleton = headers
        .filter((cell) => !cell.skeleton || !cell.skeleton.hidden)
        .map((cell) => {
          if (cell.skeleton) {
            return cell.skeleton
          }
          return {
            header: {
              colSpan: 1,
            },
            body: {
              colSpan: 1,
            },
          }
        })

      if (!disableLoadingSpinner && isLoading && !displayData.length) {
        return (
          <SkeletonTable
            headerCells={skeleton.map((cell) => cell.header)}
            cells={skeleton.map((cell) => cell.body)}
            withShadow={false}
          />
        )
      }

      if (displayData.length) {
        return (
          <div>
            <div
              ref={ref ? ref.stickyRef : null}
              id="mobile-actions"
              className="hidden absolute inset-x-0 top-0 bg-neutral-10 z-10"
            >
              <div className="sm:hidden flex justify-between p-md">
                {actions
                  .filter((action) => !action.hide)
                  .map((action, index) => {
                    if (!action.hideOnMobile) {
                      if (action.render) {
                        return (
                          <div
                            key={"table-action-" + index}
                            className={`inline-block first:ml-0 ${action.containerClass}`}
                          >
                            {action.render}
                          </div>
                        )
                      }
                    }

                    return <React.Fragment key={"table-fragment-" + index} />
                  })}
              </div>
            </div>

            {/* DESKTOP TABLE VIEW */}
            <div
              className={
                tableContainerClasses +
                " sm:block hidden" +
                (!hasLoaded ? " relative" : "")
              }
            >
              {!hasLoaded && (
                // Set margin top to account for table header height
                <span className="absolute inset-0 bg-neutral-8 rounded-b overflow-hidden bg-opacity-0 z-10">
                  <SkeletonTable
                    rowCount={displayData.length}
                    headerCells={skeleton.map((cell) => cell.header)}
                    cells={skeleton.map((cell) => cell.body)}
                    withShadow={false}
                    rounding={false}
                    className="border-b border-neutral-8"
                  />
                </span>
              )}
              <div>
                <table className="table-auto w-full">
                  <thead
                    className={`bg-neutral-10${title ? "" : " rounded-t"}`}
                  >
                    <tr
                      className={`border-b border-neutral-8${
                        title ? "" : " rounded-t"
                      }`}
                    >
                      {selectable && (
                        <th className="px-md w-md">
                          <Form>
                            <Checkbox
                              key={id}
                              checked={isAllSelected}
                              bulk={isAllBulk}
                              id={`selectAll${id ? id : ""}`}
                              name="selectAll"
                              onChange={selectAll}
                            />
                          </Form>
                        </th>
                      )}
                      {headers.map((header, index) => {
                        const rounding = title
                          ? ""
                          : "first:rounded-tl last:rounded-tr "
                        const rightAligned = header.right
                          ? rightAlignStyles
                          : "text-left "
                        const cellStyle =
                          rightAligned +
                          (!selectable ? "first:pl-md " : "") +
                          rounding +
                          "last:pr-md font-medium py-sm text-neutral-5"

                        if (header.customHeader) {
                          return (
                            <td key={"header-" + index} className={cellStyle}>
                              {header.customHeader}
                            </td>
                          )
                        }

                        return (
                          <th key={"header-" + index} className={cellStyle}>
                            {header.name}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>{getDisplayData()}</tbody>
                </table>
              </div>
              {paginate && (
                <Pagination
                  isLoading={isLoading}
                  currentPage={currentPage}
                  handlePageChange={changePage}
                  selectedRowCount={selectedRows.length}
                  {...paginateData}
                  exportRequestVariables={exportRequestVariables}
                  exportRequestKey={exportRequestKey}
                  enableExport={enableExport}
                  exportHeaders={exportHeaders}
                  exportFileSuffix={exportFileSuffix}
                  exportTransformer={exportTransformer}
                />
              )}
              {enableExport && !paginate && (
                <Pagination
                  hidePagination={true}
                  isLoading={isLoading}
                  currentPage={currentPage}
                  handlePageChange={changePage}
                  selectedRowCount={selectedRows.length}
                  {...paginateData}
                  exportRequestVariables={exportRequestVariables}
                  exportRequestKey={exportRequestKey}
                  enableExport={enableExport}
                  exportHeaders={exportHeaders}
                  exportFileSuffix={exportFileSuffix}
                  exportTransformer={exportTransformer}
                />
              )}
            </div>

            {/* MOBILE TABLE VIEW */}
            {/* TODO: FIGURE OUT HOW TO PROPERLY HANDLE PAGINATION ON MOBILE */}
            <div className="sm:hidden block">
              {paginationProps && paginationProps.totalRowCount > 0 && (
                <div className="mx-md mb-md font-medium text-neutral-5">
                  {paginationProps.totalRowCount}{" "}
                  {pluraliseWord(cardType, paginationProps.totalRowCount)}
                </div>
              )}
              {tableProps.data &&
                tableProps.data.length > 0 &&
                tableProps.data.map((row, i) => {
                  switch (cardType) {
                    case "invoice":
                      return (
                        <InvoiceCard
                          key={"card-" + i}
                          invoice={row}
                          onClick={(event) => onClick(event, row, i)}
                        />
                      )
                    case "payment":
                      return (
                        <PaymentCard
                          key={"card-" + i}
                          payment={row}
                          onClick={(event) => onClick(event, row, i)}
                        />
                      )
                    case "ping":
                      return (
                        <PingCard
                          key={"card-" + i}
                          ping={row}
                          onClick={(event) => onClick(event, row, i)}
                        />
                      )
                    default:
                      return <React.Fragment key={"empty-card-" + i} />
                  }
                })}
              {Math.ceil(tableProps.data.length / 15) <
                paginationProps.pages && (
                <div
                  className="bg-primary-2 text-primary-10 py-sm mx-md mb-md flex justify-center rounded"
                  onClick={showMoreClick}
                >
                  {isLoading ? <LoadingSpinner size="1.498rem" /> : "Show more"}
                </div>
              )}
            </div>
          </div>
        )
      } else {
        return (
          noDataComponent || <EmptyState text={noDataText} fullRound={!title} />
        )
      }
    }

    return (
      <div>
        {/* DESKTOP TABLE VIEW */}
        <div
          ref={scrollToTopRef}
          className={
            "sm:block hidden" +
            (withShadow ? " sm:shadow-container sm:rounded" : "")
          }
        >
          {(title || headerTabs.length > 0) && (
            <Header
              actionContainerClasses={actionContainerClasses}
              tabs={headerTabs}
              title={title}
              actionsSpacing={actionsSpacing}
              actions={
                (Array.isArray(data) && data.length) ||
                (Array.isArray(tableData) && tableData.length)
                  ? actions
                  : noDataActions
              }
              tabPage={headerPage}
            />
          )}
          {renderTableBody()}
        </div>

        {/* MOBILE TABLE VIEW */}
        <div className="sm:hidden block bg-neutral-10">
          <MobileHeader
            ref={ref ? ref.actionsRef : null}
            paddingTop={mobileHeaderPadding}
            tabs={headerTabs}
            actions={
              (Array.isArray(data) && data.length) ||
              (Array.isArray(tableData) && tableData.length)
                ? actions
                : noDataActions
            }
            tabPage={headerPage}
          />

          {renderTableBody()}
        </div>
      </div>
    )
  }
)

export default Table
