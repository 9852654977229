import React, { useState, useEffect, useContext } from "react"
import moment from "moment"
import InputMask from "react-input-mask"

import Icon from "../Icon"

import { currencyToPennies } from "../../utils/functions"
import { useFormValidator } from "../Forms/Form"
import InputError from "./Error"
import SelectChevron from "./SelectChevron"
import { MachineContext } from "../../state"

const getMaskedData = (
  value,
  { type, maxValue = null, allowNegativeValue = false }
) => {
  switch (type.toLowerCase()) {
    case "currency":
      let maskedValue = ""
      let plainValue = value
      if (value !== null || value !== undefined) {
        if (maxValue) {
          if (typeof value === "string") {
            plainValue = parseInt(value.replace(/[^\d-]/g, ""))
          }

          if (plainValue > maxValue) {
            plainValue = maxValue
          }
        }

        plainValue = currencyToPennies(plainValue, allowNegativeValue)
        maskedValue = !isNaN(plainValue)
          ? Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: "GBP",
            }).format(plainValue / 100)
          : ""
      }

      return {
        maskedType: "tel",
        maskedValue,
        plainValue,
      }
    default:
      return {
        maskedType: type,
        maskedValue: value,
        plainValue: value,
      }
  }
}

const LabelElement = ({ classes, large, id, name, label }) => {
  return (
    <label
      className={`input-label ${large ? "input-label--lg" : ""} ${
        classes.label
      }`}
      htmlFor={id || name}
    >
      {label}
    </label>
  )
}

export default React.forwardRef(
  (
    {
      id,
      label,
      name,
      icon,
      placeholder,
      value,
      onChange,
      onClick = () => {},
      onBlur = () => {},
      onFocus = () => {},
      onKeyPress = () => {},
      classes = {},
      large,
      largeOnMobile,
      disabled = false,
      disabledStyling = true,
      autocomplete = "",
      prefix,

      type = "",
      allowNegativeValue = false,
      maxValue,
      mask,

      allowPasswordToggle = false,
      showSelectChevron = false,
      dateDisplay = false,
      validateOnBlur = true,
      validation = "",
      danger,
      width = "w-full",
      useValidation = true,
    },
    ref
  ) => {
    const [
      {
        context: { errors },
      },
      send,
    ] = useContext(MachineContext)
    const [validator, { handleValidation }] = useFormValidator(useValidation)
    const validationField =
      validation && validation.name ? validation.name : name || id

    let [tempValue, setTempValue] = useState(null)
    let [inputType, setInputType] = useState(type)

    useEffect(() => {
      const { maskedValue } = getMaskedData(value, { type, allowNegativeValue })

      setTempValue(maskedValue)
    }, [value, type, allowNegativeValue])

    const handleChange = (event) => {
      if (validator && validator.fieldValid(validationField)) {
        validator.hideMessageFor(validationField)
      }

      if (!dateDisplay) {
        const { maskedValue, plainValue } = getMaskedData(event.target.value, {
          type,
          maxValue,
          allowNegativeValue,
        })

        setTempValue(maskedValue)
        onChange({ target: { name, value: plainValue } })
      } else {
        setTempValue(event.target.value)
      }

      if (
        validator &&
        validation &&
        (validator.visibleFields.includes(validationField) ||
          validator.messagesShown)
      ) {
        handleValidation(validationField)
      }

      if (errors && errors.fields && errors.fields[validationField]) {
        send("UPDATE_CONTEXT", {
          errors: {
            ...errors,
            fields: { ...errors.fields, [validationField]: "" },
          },
        })
      }
    }

    const handleBlur = (event) => {
      if (!dateDisplay) {
        const { plainValue } = getMaskedData(event.target.value, {
          type,
          allowNegativeValue,
        })

        onBlur({ target: { name, value: plainValue } })
      } else {
        const dateValue = moment(event.target.value, "DD/MM/YYYY")
        if (dateValue.isValid()) {
          onChange({ target: { value: dateValue.toDate() } })
        }
      }

      if (validateOnBlur) {
        handleValidation(validationField)
      }
    }

    const toggleVisibility = (e) => {
      e.preventDefault()

      if (inputType === "password") {
        setInputType("text")
      } else {
        setInputType(type)
      }
    }

    let hasErrors = false
    if (validator && validation) {
      let validatedValue = tempValue
      if (moment(value, "DD/MM/YYYY", true).isValid()) {
        validatedValue = moment(tempValue, "DD/MM/YYYY")
      }
      hasErrors =
        (!validator.check(validatedValue, validation.rules) &&
          (validator.messagesShown ||
            validator.visibleFields.includes(validationField))) ||
        (errors && errors.fields && errors.fields[validationField])
    }

    let classNames = `input placeholder-neutral-8
    ${largeOnMobile ? "sm:px-sm sm:py-xs p-sm" : ""}
    ${danger ? "input--danger" : ""}
    ${hasErrors ? "input--error" : ""}
    ${large ? "input--lg" : "leading-tight"}
    ${disabled && disabledStyling ? "input--disabled" : ""}
    ${icon || showSelectChevron ? "z-10" : ""}
    ${classes.input}`

    const visiblityIcon = (
      <div
        onClick={toggleVisibility}
        className="absolute cursor-pointer inset-y-0 right-0 p-sm"
      >
        <Icon
          type="duotone"
          icon={inputType !== "password" ? "eye-slash" : "eye"}
          baseSize="20px"
        />
      </div>
    )

    const inputIcon = (
      <div
        onClick={toggleVisibility}
        className="absolute inset-y-0 right-0 p-sm text-neutral-8"
      >
        <Icon icon={icon} type="solid" baseSize="20px" />
      </div>
    )

    const searchIcon = (
      <Icon
        type="regular"
        icon="search"
        baseSize="16px"
        className={`absolute my-xs left-0 inset-y-0 z-10 ${
          tempValue ? "text-neutral-5" : "text-neutral-8"
        } z-0`}
        customStyle={{ marginLeft: "8px" }}
      />
    )

    if (mask) {
      return (
        <div className="relative">
          <InputMask
            name={name}
            mask={mask}
            value={tempValue}
            onChange={handleChange}
            onBlur={handleBlur}
            maskPlaceholder={null}
          >
            <div className="text-neutral-5">
              {label && (
                <LabelElement
                  classes={classes}
                  large={large}
                  id={id}
                  name={name}
                  label={label}
                />
              )}
              <div className="relative bg-white rounded">
                <input
                  ref={ref}
                  autoComplete={autocomplete}
                  className={classNames + " bg-transparent"}
                  id={id || name}
                  name={name}
                  type={inputType}
                  onClick={onClick}
                  placeholder={placeholder}
                  onFocus={onFocus}
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {showSelectChevron && (
                  <SelectChevron
                    value={tempValue}
                    disabled={disabled && disabledStyling}
                    large={large}
                    className="z-0"
                  />
                )}
              </div>
            </div>
          </InputMask>
          <InputError
            validation={validation}
            large={large}
            name={validationField}
            id={id}
            value={value}
            dateValidation={dateDisplay}
          />
        </div>
      )
    }

    return (
      <div className={`text-neutral-5 ${width}`}>
        {label && (
          <LabelElement
            classes={classes}
            large={large}
            id={id}
            name={name}
            label={label}
          />
        )}
        <div className="relative">
          <div className="bg-white rounded relative">
            {icon === "search" && searchIcon}
            <input
              ref={ref}
              className={classNames + " bg-transparent"}
              id={id || name}
              name={name}
              value={tempValue || ""}
              autoComplete={autocomplete}
              type={inputType}
              onClick={onClick}
              placeholder={placeholder}
              onChange={handleChange}
              onKeyPress={onKeyPress}
              onBlur={(e) => handleBlur(e, validator)}
              disabled={disabled}
              style={
                // Stop Safari from displaying disabled input with a blue tint
                // Used in affiliate channel selection
                disabled && !disabledStyling && value
                  ? {
                      WebkitTextFillColor: "#102A43",
                      opacity: 1,
                    }
                  : {}
              }
            />
            {allowPasswordToggle && visiblityIcon}
            {icon && icon !== "search" && inputIcon}
            {showSelectChevron && (
              <SelectChevron
                value={tempValue}
                disabled={disabled && disabledStyling}
                large={large}
              />
            )}
          </div>
          <InputError
            validation={validation}
            large={large}
            name={validationField}
            id={id}
            value={value}
          />
        </div>
      </div>
    )
  }
)
