import React, { useEffect, useState } from "react"

import Icon from "../Icon"

const MemberEmail = ({ email, undeliverable = false, emailIssue }) => {
  const [memberEmail, setMemberEmail] = useState(email)
  const [isUndeliverable, setIsUndeliverable] = useState(undeliverable)
  const [hasEmailIssue, setHasEmailIssue] = useState(emailIssue)

  useEffect(() => {
    setIsUndeliverable(undeliverable)
  }, [undeliverable])

  useEffect(() => {
    setMemberEmail(email)
  }, [email])

  useEffect(() => {
    setHasEmailIssue(emailIssue)
  }, [emailIssue])

  return (
    <div className="flex items-center">
      {(isUndeliverable || hasEmailIssue) && (
        <Icon
          className="mr-xs"
          type="duotone"
          icon="exclamation-circle"
          baseSize="1.176rem"
          colours={
            isUndeliverable
              ? {
                  primary: "#FFEEEE",
                  secondary: "#BA2525",
                }
              : hasEmailIssue
              ? {
                  primary: "#FFEEEE",
                  secondary: "#F0B429",
                }
              : null
          }
        />
      )}
      <div
        className={
          "inline-block" +
          (isUndeliverable
            ? " text-danger-5"
            : hasEmailIssue
            ? " text-warning-6"
            : "")
        }
      >
        {memberEmail}
      </div>
    </div>
  )
}

export default MemberEmail
