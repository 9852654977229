import React, { forwardRef, useImperativeHandle } from "react"

export default forwardRef(({ nextStep }, ref) => {
  useImperativeHandle(ref, () => ({
    validateStep: () => {
      nextStep()
    },
  }))

  return (
    <div>
      <div className="mb-md">
        <div className="text-neutral-5 font-medium mb-xs">What is this?</div>
        <div className="grid grid-cols-1 gap-xs">
          <div>
            If you run a club or member organisation, and you are responsible
            for collecting money from your members, having a Payzip club account
            will save you valuable admin time and let your members pay on their
            mobiles.
          </div>
          <div>
            Don’t worry if you’re already a member of another Payzip club, we’ll
            keep those invoices separate from the ones you create for your new
            club.
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-sm">
        <div className="text-neutral-5 font-medium mb-xs">
          What happens next?
        </div>
        <ul className="mb-xs list-decimal ml-sm pl-xs">
          <li>Tell us about your club</li>
          <li>We review your application</li>
          <li>Give your bank details to Stripe</li>
        </ul>
        <div>
          The whole process should take no more than ten minutes and is only
          done once. We’re also on-hand to help if you get stuck.
        </div>
      </div>
    </div>
  )
})
