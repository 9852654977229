import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react"

import Input from "../../Input"
import SelectChevron from "../../Input/SelectChevron"
import Icon from "../../Icon"
import FilterDropdown from "../../FilterDropdown"

import { MachineContext } from "../../../state"
import { useEventListener } from "../../../utils/hooks"

export default function MemberRow({
  member,
  index,
  removeRow,
  updateOptions,
  updateMember,
  displayBin,
  lastRow = false,
}) {
  const groupInputRef = useRef()
  const [{ context }, send] = useContext(MachineContext)
  const [submitting, setSubmitting] = useState(false)
  const [newGroup, setNewGroup] = useState("")

  const groups = context.groups || []

  const updateGroupsState = (state) => {
    if (submitting) {
      const newMemberGroups = groups.filter((group) => {
        let isNewGroup = state.findIndex((g) => {
          return g.id === group.id
        })

        return (
          member.groups.map((g) => g.id).includes(group.id) || isNewGroup < 0
        )
      })

      updateOptions(newMemberGroups.map((g) => ({ ...g, checked: true })))
    }

    setSubmitting(false)
    return groups
  }

  const [, setGroups] = useReducer(updateGroupsState, groups)

  useEffect(() => {
    setGroups(groups)
  }, [groups, setGroups])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setSubmitting(true)

      if (newGroup) {
        send("addGroup", {
          data: {
            variables: {
              organisationId: context.organisation.id,
            },
            body: { name: newGroup },
            onSuccess: {
              callback: () => {
                setNewGroup("")
              },
            },
          },
        })
      }
    },
    [context.organisation, newGroup, send]
  )

  const checkButtonPress = useCallback(
    (event) => {
      if (event.keyCode === 13 && event.target === groupInputRef.current) {
        event.preventDefault()
        event.stopPropagation()

        if (newGroup) {
          handleSubmit(event)
        }
      }
    },
    [handleSubmit, newGroup]
  )

  useEventListener("keydown", checkButtonPress)

  return (
    <div className="grid grid-cols-add-members w-full gap-sm mb-sm last:mb-0">
      <Input
        id={"name"}
        small
        placeholder="Name"
        name="name"
        onChange={({ target }) => updateMember(target)}
        value={member.name}
        validation={
          lastRow
            ? {
                name: `${index}.name`,
                message: "Enter a member name",
                rules: "required",
              }
            : false
        }
      />
      <Input
        id={"email"}
        small
        placeholder="Email"
        name="email"
        onChange={({ target }) => updateMember(target)}
        value={member.email}
        validation={
          lastRow
            ? {
                name: `${index}.email`,
                messages: {
                  required: "Enter a member email",
                  email: "Enter a valid email",
                },
                rules: "required|email",
              }
            : false
        }
      />
      <FilterDropdown
        className="sm:block hidden"
        clearable={false}
        updateOnClose={false}
        options={groups.map((group) => {
          return {
            ...group,
            checked: member.groups.map((g) => g.id).includes(group.id),
          }
        })}
        updateOptions={(options) => updateOptions(options)}
        selectedToTop
        placement="bottom"
        fallbackPlacements={["top"]}
        footer={
          <div className="mt-xs -mb-xs">
            <input
              ref={groupInputRef}
              className="shadow-t-border px-sm py-sm -mb-xs rounded-b outline-none placeholder-neutral-8 text-neutral-1"
              value={newGroup}
              placeholder="Add group"
              onChange={({ target: { value } }) => setNewGroup(value)}
            />
            {newGroup && (
              <div className="text-xs px-sm text-neutral-6 mt-xs">
                Press enter to add new group
              </div>
            )}
          </div>
        }
      >
        <div className="relative shadow-border rounded bg-white placeholder-neutral-8 px-sm py-xs w-full leading-sm undefined text-neutral-1">
          {member.groups.length ? (
            member.groups.length === 1 ? (
              member.groups[0].name
            ) : (
              `Groups (${member.groups.length})`
            )
          ) : (
            <div className="text-neutral-8">Select group</div>
          )}
          <SelectChevron value={member.groups.length} />
        </div>
      </FilterDropdown>

      {displayBin && (
        <div onClick={removeRow} className="cursor-pointer flex">
          <Icon
            type="duotone"
            icon="trash-alt"
            className="inline-block m-auto"
            baseSize="20px"
          />
        </div>
      )}
    </div>
  )
}
