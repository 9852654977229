import React from "react"

const SkeletonText = ({
  height = "h-base",
  width = "w-full",
  colour = "bg-neutral-1",
  className,
}) => {
  return (
    <div
      className={`${height} ${width} ${colour} ${className} sm:bg-opacity-20 bg-opacity-20 rounded-full animate-pulse`}
    />
  )
}

export default SkeletonText
