import React, { useContext, useEffect, useState } from "react"
import Header from "../containers/Header"
import Footer from "../containers/Footer"
import Form from "../Forms/Form"
import HelperRow from "../containers/HelperRow"
import Ping from "../Forms/Ping"
import ShareableLinks from "../containers/ShareableLinks"
import { MachineContext } from "../../state"
import { viewSendPingToMembersModal } from "../../utils/modals/ping"

export default function CreatePing({
  handleClose,
  state = {},
  setState,
  changesMade,
}) {
  const [{ context }, send] = useContext(MachineContext)
  const [modalHeader, setModalHeader] = useState("Create ping")
  const [submitButton, setSubmitButton] = useState({
    text: "Create ping",
    icon: "check-circle",
    type: "duotone",
  })

  useEffect(() => {
    if (state.isEditting && !state.restartingPing) {
      setSubmitButton({
        text: "Save changes",
        icon: "sync-alt",
        type: "solid",
        disabled: !changesMade,
      })
    }
  }, [changesMade, state.isEditting, state.restartingPing])

  useEffect(() => {
    if (state.restartingPing) {
      setModalHeader("Restart Ping")
      setSubmitButton({
        text: "Restart Ping",
        icon: "pencil",
        type: "solid",
      })
    } else if (state.isEditting) {
      setModalHeader("Edit Ping")
      setSubmitButton({
        text: "Save changes",
        icon: "sync-alt",
        type: "solid",
        disabled: true,
      })
    } else if (state.duplicatingPing) {
      setModalHeader("Duplicate Ping")
      setSubmitButton({
        text: "Duplicate Ping",
        icon: "pencil",
        type: "solid",
      })
    } else {
      setModalHeader("Create Ping")
      setSubmitButton({
        text: "Create Ping",
        icon: "check-circle",
        type: "duotone",
      })
    }
  }, [state.duplicatingPing, state.isEditting, state.restartingPing])

  const onChange = (event) => {
    const { target } = event

    switch (target.name) {
      case "amount":
        setState({
          free: false,
          [target.name]: target.value,
        })
        break
      case "maxQuantity":
        setState({
          unlimited: false,
          [target.name]: target.value,
        })
        break
      case "limit":
        setState({
          noLimit: false,
          [target.name]: target.value,
        })
        break
      case "endDate":
        setState({
          noEndDate: false,
          [target.name]: target.value,
        })
        break
      case "free":
        setState({
          amount: "",
          [target.name]: target.checked,
        })
        break
      case "unlimited":
        setState({
          maxQuantity: "",
          [target.name]: target.checked,
        })
        break
      case "noLimit":
        setState({
          limit: "",
          [target.name]: target.checked,
        })
        break
      case "noEndDate":
        setState({
          endDate: "",
          [target.name]: target.checked,
        })
        break
      default:
        setState({
          [target.name]: target.value,
        })
        break
    }
  }

  const checkForActivePings = (event, validator) => {
    if (validator) {
      if (validator.allValid()) {
        send("getPings", {
          data: {
            variables: {
              organisationId: context.organisation.id,
              filters: {
                statuses: "active",
                "ping[description]": state.description,
              },
            },
            storeData: false,
            transformResponse: (res) => res, // Override default request transformer
            onSuccess: {
              callback: ({ response }) => {
                let foundPings = response.rows
                if (state.isEditting) {
                  foundPings = foundPings.filter((ping) => ping.id !== state.id)
                }

                if (foundPings.length) {
                  send("OPEN_CONFIRMATION", {
                    payload: {
                      id: "duplicatePingName",
                      title: "Ping name already exists",
                      body: [
                        "There is already an active Ping with this name",
                        "You can continue with this name, or you can go back and change it to something else.",
                      ],
                      buttons: [
                        {
                          text: "Go back",
                          colWidth: 4,
                          callback: () => send("CLOSE_CONFIRMATION"),
                        },
                        {
                          text: "Continue",
                          type: "primary",
                          icon: "check-circle",
                          colWidth: 7,
                          iconType: "duotone",
                          callback: () => {
                            send("CLOSE_CONFIRMATION")
                            submitPingDetails()
                          },
                        },
                      ],
                    },
                  })
                } else {
                  submitPingDetails()
                }
              },
            },
          },
        })
      } else {
        validator.showMessages()
      }
    }
  }

  const submitPingDetails = () => {
    if (state.isEditting || state.restartingPing) {
      // Send request to update ping
      send("editPing", {
        data: {
          variables: {
            pingId: state.id,
          },
          body: {
            amount: state.amount || 0,
            comments: state.comments,
            description: state.description,
            endDate: state.endDate || null,
            limit: state.limit || 0,
            maxQuantity: state.maxQuantity || 0,
          },
          onSuccess: {
            callback: () => {
              send("CLOSE_MODAL")
            },
          },
        },
      })
    } else {
      // Send request to create ping
      send("createPing", {
        data: {
          variables: {
            organisationId: context.organisation.id,
          },
          body: {
            amount: state.amount || 0,
            comments: state.comments,
            description: state.description,
            endDate: state.endDate || null,
            limit: state.limit || 0,
            maxQuantity: state.maxQuantity || 0,
          },
          onSuccess: {
            callback: ({ response }) => {
              send("CLOSE_MODAL")
              // On request success, do the following
              send("OPEN_CONFIRMATION", {
                payload: {
                  id: "sharePingLinkModal",
                  title: "Ping created",
                  body: [
                    "Your Ping is ready to go live!",
                    <div className="mb-md">
                      Copy the link below, then share with your members via
                      social media, your newsletter, and anywhere else.
                    </div>,
                    <ShareableLinks urls={[response.url]} />,
                    <div className="mt-md">
                      You can copy the link later by clicking{" "}
                      <span className="font-semibold">Pings</span> in the
                      sidebar menu, opening this Ping and clicking{" "}
                      <span className="font-semibold">Share link</span>.
                    </div>,
                    <div className="mt-sm">
                      You can also email your members directly about this Ping.
                      You can choose who receives the email and what it says by
                      clicking the button below.
                    </div>,
                  ],
                  buttons: [
                    {
                      text: "Close",
                      colWidth: 4,
                      callback: () => send("CLOSE_CONFIRMATION"),
                    },
                    {
                      text: "Email members",
                      type: "primary",
                      icon: "paper-plane",
                      colWidth: 7,
                      submitting: "app.requests.getGroupsAndMembers.requesting",
                      callback: () =>
                        viewSendPingToMembersModal(send, context, {
                          id: response.id,
                          limit: response.limit,
                          amount: response.amount,
                          maxQuantity: response.maxQuantity,
                          description: response.description,
                          comments: response.comments,
                          endDate: response.endDate,
                        }),
                    },
                  ],
                },
              })
            },
          },
        },
      })
    }
  }

  return (
    <div>
      <Header title={modalHeader} />
      <Form onSubmit={checkForActivePings}>
        <div className="bg-neutral-10 text-neutral-6 sm:max-h-modal-body max-h-modal-mobile p-md overflow-y-auto">
          {state.isEditting || state.restartingPing || state.duplicatingPing ? (
            <Ping onChange={onChange} ping={state} />
          ) : (
            <HelperRow
              title="Ping details"
              text={[
                <div>
                  The <span className="font-semibold text-neutral-6">Name</span>{" "}
                  will appear in your list of pings so make it easy to
                  recognise.
                </div>,
                <div>
                  Use{" "}
                  <span className="font-semibold text-neutral-6">
                    Additional information
                  </span>{" "}
                  for anything members may need to know, such as available sizes
                  or colours of kit, start times of sessions, etc.
                </div>,
                <div>
                  Enter an{" "}
                  <span className="font-semibold text-neutral-6">Amount</span>{" "}
                  of at least £1.00, or tick ‘Free’ if you want to create a Ping
                  with no cost to your members.
                </div>,
                <div>
                  You can also set limits on the{" "}
                  <span className="font-semibold text-neutral-6">Quantity</span>{" "}
                  and{" "}
                  <span className="font-semibold text-neutral-6">
                    Limits per member
                  </span>
                  .
                </div>,
                <div>
                  If you set an{" "}
                  <span className="font-semibold text-neutral-6">End date</span>
                  , the Ping will be deactivated at the end of the date you
                  choose.
                </div>,
                "Currently Pings are only available to club members.",
                <div>
                  For more information, see our{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.payzip.co.uk/article/64-pings-overview"
                  >
                    knowledgebase
                  </a>
                  .
                </div>,
              ]}
            >
              <Ping onChange={onChange} ping={state} />
            </HelperRow>
          )}
        </div>
        <Footer
          buttons={[
            { text: "Cancel", callback: () => handleClose() },
            {
              text: submitButton.text,
              type: "primary",
              icon: submitButton.icon,
              iconType: submitButton.type,
              submitting:
                "app.requests.createPing.requesting|app.requests.editPing.requesting",
              callback: checkForActivePings,
              disabled: submitButton.disabled,
            },
          ]}
        />
      </Form>
    </div>
  )
}
