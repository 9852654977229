import React, { useRef } from "react"
import moment from "moment"
import Input from "../Input"
import Datepicker from "../Datepicker"
import Textarea from "../Input/Textarea"
import LineItem from "./LineItem"
import { formatCurrency, currencyToPennies } from "../../utils/functions"
import { useFormValidator } from "../Forms/Form"

export default ({
  invoice,
  updateInvoice,
  updateLineItems,
  editableTitle = false,
  totalError = false,
}) => {
  const [validator] = useFormValidator()
  const datePickerRef = useRef()

  const removeLineItem = (index) => {
    const items = invoice.lineItems.filter((item, key) => index !== key)

    updateInvoice({ target: { name: "lineItems", value: items } })
    validator.purgeFields()
  }

  return (
    <div className="p-md overflow-y-auto sm:max-h-modal-body max-h-modal-mobile bg-neutral-10">
      <div className="grid grid-cols-1 gap-sm">
        <Input
          small
          label="Name"
          name="title"
          value={invoice.title}
          disabled={!editableTitle}
          placeholder="Name"
          onChange={updateInvoice}
          validation={{
            message: "Your invoice needs a name",
            rules: "required",
          }}
        />

        <div className="w-1/2">
          <Datepicker
            date={moment(invoice.dueDate).toDate()}
            format="dd/MM/yyyy"
            onChange={(value) => {
              updateInvoice({ target: { name: "dueDate", value } })
            }}
            placeholder="Select date"
            popperPlacement="bottom"
            minDate={
              moment().isBefore(invoice.dueDate)
                ? new Date()
                : moment(invoice.dueDate).toDate()
            }
            clearText="Clear date"
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "0, 5px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
            }}
            customInput={
              <Input
                small
                ref={datePickerRef}
                date={moment(invoice.dueDate).format("DD/MM/YYYY")}
                label="Due date"
                name="dueDate"
                placeholder="Select date"
                showSelectChevron
                classes={{
                  input: "cursor-pointer",
                }}
                dateDisplay
                mask="99/99/9999"
                validateOnBlur={false}
                validation={{
                  name: "dueDate",
                  messages: {
                    required: "Enter a due date",
                    date: "Enter a valid date",
                  },
                  rules: ["required", "date"],
                }}
              />
            }
            todayButton
          />
        </div>
        <Textarea
          id="comments"
          small
          label="Comments"
          name="description"
          value={invoice.description}
          rows="5"
          onChange={updateInvoice}
          length={500}
          validation={{
            name: "description",
            messages: {
              max: "Maximum character limit exceeded",
            },
            rules: "max:500",
          }}
        />

        {invoice.lineItems &&
          invoice.lineItems.map((item, index) => (
            <LineItem
              key={index}
              index={index}
              description={item.description}
              amount={item.amount}
              removeItem={removeLineItem}
              setState={({ target }) => updateLineItems(target, index)}
              showBin={index !== invoice.lineItems.length - 1}
              validateRow={
                index !== invoice.lineItems.length - 1 ||
                invoice.lineItems.length === 1
              }
            />
          ))}
        <div className="grid grid-cols-12 gap-sm">
          <div
            className={`flex ${
              totalError
                ? "text-danger-5 justify-between items-end"
                : "text-neutral-5 justify-end"
            } col-span-11`}
          >
            {totalError ? (
              <div>Invoice must be at least £0.50 (or £0.00)</div>
            ) : null}
            <div className="font-semibold text-lg text-right">
              Total:{" "}
              {invoice.lineItems &&
                invoice.lineItems.length > 0 &&
                formatCurrency(
                  invoice.lineItems
                    .filter((item) => item.amount)
                    .reduce((a, b) => a + currencyToPennies(b.amount), 0)
                )}
            </div>
          </div>
        </div>
      </div>
      <Textarea
        small
        label="Footer"
        name="footer"
        rows="5"
        value={invoice.footer}
        onChange={updateInvoice}
        length={500}
        validation={{
          name: "footer",
          messages: {
            max: "Maximum character limit exceeded",
          },
          rules: "max:500",
        }}
      />
    </div>
  )
}
