import React, { useState } from 'react'

export default function ShareableLinks({ urls, onCopy }) {
  const [codeCopied, setCodeCopied] = useState(
    urls.map(link => {
      return {
        text: 'Copy',
        style: 'support'
      }
    })
  )

  const copyCode = (url, key) => {
    setCodeCopied(codeCopied.map((entry, index) => {
      if (key === index) {
        return {
          text: 'Copied',
          style: 'success'
        }
      }
      return {
        text: 'Copy',
        style: 'support'
      }
    }))
    navigator.clipboard.writeText(url)

    if (onCopy) {
      onCopy()
    }
  }

  return urls.map((url, index) => {
      return <div key={ 'shareable-url-' + index } className="rounded bg-white flex justify-between leading-loose">
        <div className="py-xs px-sm">
          { url }
        </div>
        <div onClick={ () => copyCode(url, index) } className={ `bg-${codeCopied[index].style}-6 rounded-r text-${codeCopied[index].style}-10 py-xs px-sm cursor-pointer text-center` }>
          <div className="w-2xl">
            { codeCopied[index].text }
          </div>
        </div>
      </div>
  })
}
