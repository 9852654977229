import React, { useContext } from "react"

import PanelContent from "./PanelContent"
import Header from "../containers/Header"
import { MachineContext } from "../../state"
import { statsExist } from "../../utils/functions"
import SkeletonText from "../Skeleton/Text"

export default function Panel({
  columns,
  title,
  children,
  actions,
  actionContainerClasses = "",
  actionsSpacing = "sm",
  headerClasses = "",
  isLoading = false,

  height = "4.857rem",

  // ONLY USED ON MOBILE
  headerTopPadding = false,
}) {
  const [
    {
      context: { stats },
    },
  ] = useContext(MachineContext)

  // Check to see if the panel stats are populated, this will set the default styling in the panels
  // Can be overwritten by using the `.isEmpty` key on panel object being passed into props
  const isNotEmpty = statsExist(stats)

  const gridColumns = `grid sm:grid-cols-${columns.length} grid-cols-2`
  return (
    <div className="sm:rounded w-full sm:shadow-container">
      <Header
        className={headerClasses}
        paddingTop={headerTopPadding}
        actionsSpacing={actionsSpacing}
        actions={actions}
        actionContainerClasses={actionContainerClasses}
      >
        {!isLoading ? (
          children || title || stats.title
        ) : (
          <SkeletonText width="w-3xl" height="h-md" />
        )}
      </Header>
      <div className="bg-neutral-10 sm:rounded-b-lg sm:overflow-hidden">
        <div
          className={`${gridColumns} sm:m-0 m-md sm:bg-neutral-10 bg-primary-4 sm:rounded-none rounded-lg`}
        >
          {columns.map((column, col) => {
            if (col === columns.length + 1) {
              return (
                <div key={col} className="p-md" style={{ height }}>
                  <PanelContent
                    isLoading={isLoading}
                    isEmpty={
                      column.isEmpty !== undefined
                        ? column.isEmpty
                        : !isNotEmpty
                    }
                    value={
                      column.content ||
                      stats[column.statKey] ||
                      column.defaultValue
                    }
                    content={column}
                  />
                </div>
              )
            }
            return (
              <div
                key={col}
                className={`pl-md pr-xs py-sm border-neutral-8 ${
                  col + 1 !== columns.length ? "sm:border-r" : ""
                }`}
                style={{ height }}
              >
                <PanelContent
                  isLoading={isLoading}
                  isEmpty={
                    column.isEmpty !== undefined ? column.isEmpty : !isNotEmpty
                  }
                  value={
                    column.content ||
                    stats[column.statKey] ||
                    column.defaultValue
                  }
                  content={column}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
