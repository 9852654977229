import React from 'react'
import Icon from '../../Icon'

export default () => {
  return (
    <div>
      <div className="pb-xs">
        You’re now ready to start collecting payments for your club.
      </div>
      <div>
        This short guide will show you how to:
      </div>
      <div className="py-md px-lg">
        <div className="grid grid-cols-9 gap-y-md gap-x-xl">
          <div className="flex items-center justify-center w-lg">
            <Icon className="col-span-1" icon="user-circle" baseSize="30px" />
          </div>
          <div className="flex items-center col-span-8">
            Add members to your club
          </div>
          <div className="flex items-center justify-center w-lg">
            <Icon className="col-span-1" icon="file-invoice" baseSize="30px" />
          </div>
          <div className="flex items-center col-span-8">
            Create and send your first invoice
          </div>
          <div className="flex items-center justify-center w-lg">
            <Icon className="col-span-1" icon="credit-card" baseSize="30px" />
          </div>
          <div className="flex items-center col-span-8">
            Receive your club's first card payment
          </div>
          <div className="flex items-center justify-center w-lg">
            <Icon className="col-span-1" icon="coins" baseSize="30px" />
          </div>
          <div className="flex items-center col-span-8">
            Receive your club's first payout from Payzip
          </div>
        </div>
      </div>
      <div>
        <span className="font-medium">Tip</span>: Show this guide again by clicking the checklist item in the sidebar.
      </div>
    </div>
  )
}
