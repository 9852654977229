import { useRef, useEffect, useState, useContext, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import SimpleReactValidator from "simple-react-validator"
import { MachineContext } from "../state"
import { createClubModal } from "./modals/organisation"

export const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export const usePageDataRefresh = (callback) => {
  const [current] = useContext(MachineContext)
  const { context } = current

  const refreshData = useCallback(() => {
    callback()
  }, [callback])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  useEffect(() => {
    if (context.refreshPageData) {
      refreshData()
    }
  }, [refreshData, context.refreshPageData])
}

export const useIsLoading = (shouldReload = true, data = null) => {
  const location = useLocation()

  const [current] = useContext(MachineContext)

  const [isLoading, setIsLoading] = useState(true)
  const [idle, setIdle] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [mounted, setMounted] = useState(shouldReload)
  const [loadingData, setLoadingData] = useState(data)

  const locationRef = useRef()

  useEffect(() => {
    setLoadingData(data)
  }, [data])

  useEffect(() => {
    if (loadingData) {
      let checkedData = loadingData
      if (!Array.isArray(loadingData)) {
        checkedData = Object.keys(loadingData)
      }

      if (checkedData.length) {
        setMounted(true)
      }
    }
  }, [loadingData])

  useEffect(() => {
    if (locationRef.current !== location.pathname) {
      locationRef.current = location.pathname
      setIsLoading(true)
      setMounted(false)
    }
  }, [location])

  useEffect(() => {
    if (idle && isLoading) {
      setIsLoading(false)
    }
  }, [idle, isLoading, mounted, data])

  useEffect(() => {
    if (
      requesting &&
      !isLoading &&
      (shouldReload || (!shouldReload && !mounted))
    ) {
      setIsLoading(true)
    }
  }, [idle, requesting, isLoading, shouldReload, mounted])

  useEffect(() => {
    setIdle(true)
    setRequesting(current.matches("requests"))
  }, [current, setIdle, setRequesting])

  return [isLoading, setIsLoading]
}

export const useValidator = (customMessages = {}) => {
  const [, forceUpdate] = useState()

  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        mobile_number: {
          message: "Enter a valid UK mobile number",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
            )
          },
        },
        populated_array: {
          message: "Please enter at least one value",
          rule: (val, params, validator) => {
            return Array.isArray(val) && val.filter((val) => val).length > 0
          },
        },
        required_if: {
          message: "Requirements must be met",
          rule: (val, params, validator) => {
            return !params[0]
          },
          required: true,
        },
        not_required: {
          message: "",
          rule: () => true,
        },
      },
      messages: customMessages,
      autoForceUpdate: {
        forceUpdate: () => {
          forceUpdate(1)
        },
      },
    })
  )

  return [validator.current, forceUpdate]
}

export const useApplicationInProgress = (context) => {
  const [applicationInProgress, setApplicationInProgress] = useState(false)

  useEffect(() => {
    const applicationOrg =
      context.organisation && context.organisation.verified
        ? context.organisations.filter((org) => {
            return (
              !org.verified ||
              (org.verified && !org.stripeConnection) ||
              (org.verified && !org.payoutsEnabled)
            )
          })[0]
        : context.organisation

    const affiliateApplication =
      context.user &&
      context.user.affiliateProfile &&
      !context.user.affiliateProfile.stripeApproved
    setApplicationInProgress(applicationOrg || affiliateApplication)
  }, [context.organisation, context.organisations, context.user])

  return applicationInProgress
}

export const useAdminMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const [{ context }, send] = useContext(MachineContext)
  const [filteredOrgs, setFilteredOrgs] = useState([])
  const [isMemberView, setIsMemberView] = useState(false)
  const [isAffiliateView, setIsAffiliateView] = useState(false)
  const applicationInProgress = useApplicationInProgress(context)

  useEffect(() => {
    setIsMemberView(location.pathname.includes("/dashboard"))
    setIsAffiliateView(location.pathname.includes("/affiliate"))
  }, [location.pathname])

  useEffect(() => {
    setFilteredOrgs(
      context.organisations.filter(({ id, verified, stripeUrl, ...org }) => {
        if (!verified || stripeUrl) {
          return false
        }

        if (isMemberView || isAffiliateView) {
          return true
        }

        return context.organisation.id !== id
      })
    )

    send("UPDATE_CONTEXT", {
      overdueInvoices: [],
      invalidEmails: [],
      overdueInvoicesPaginationProps: {},
      invalidEmailsPaginationProps: {},
    })
  }, [
    context.organisation,
    context.organisations,
    send,
    location.pathname,
    isMemberView,
    isAffiliateView,
  ])

  if (!context.user.roles.includes("admin")) {
    return false
  }

  let menuOptions = []

  if (context.user.roles.includes("affiliate") && !isAffiliateView) {
    menuOptions.push({
      name: "Affiliate view",
      icon: {
        type: "regular",
        icon: "pound-sign",
        colours: {
          primary: "#E0FCFF",
          secondary: "#38BEC9",
        },
        layer: true,
        layerOptions: {
          circleSize: "2rem",
          iconSize: "1rem",
        },
        width: "full",
      },
      to: "/affiliate",
    })
  }

  if (!isMemberView) {
    menuOptions.push({
      name: "My invoices",
      iconText: context.user.name,
      to: "/dashboard",
      style:
        filteredOrgs.length > 0 && menuOptions.length < 2
          ? "shadow-t-border"
          : "",
    })
  }

  if (!applicationInProgress) {
    menuOptions.push({
      name: "Create new club",
      icon: {
        type: "duotone",
        icon: "plus-circle",
        baseSize: "28px",
        colours: { primary: "#627D98", secondary: "#fff" },
      },
      style: isMemberView && filteredOrgs.length > 0 ? "shadow-t-border" : "",
      callback: () => createClubModal(send, context.user, context.profile),
    })
  }

  return [
    ...filteredOrgs
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((org) => {
        return {
          name: org.name,
          callback: () => {
            const selectedOrg = context.organisations.filter(
              (organisation) => organisation.id === org.id
            )
            // Selected org should be in state, so no need to send request to fetch their data
            if (selectedOrg.length) {
              send("UPDATE_APP_STATE", {
                organisation: selectedOrg[0],
                tutorial: selectedOrg[0].tutorial,
              })

              if (location.pathname !== "/") {
                history.push("/")
              }
            }
          },
        }
      }),
    ...menuOptions,
  ]
}
