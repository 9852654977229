import React, { useContext, useState, useCallback, useRef } from "react"

import MinimisedItem from "./Item"
import Icon from "../Icon"
import { MachineContext } from "../../state"
import { useEventListener } from "../../utils/hooks"
import Flag from "../Flag"
import { addMembersModal, importMembersModal } from "../../utils/modals/member"
import { viewCreateInvoice } from "../../utils/modals/invoice"
import { viewCreateOrEditPing } from "../../utils/modals/ping"

function Minimised() {
  const menu = useRef()
  const [{ context }, send] = useContext(MachineContext)
  const [viewActions, setViewActions] = useState(false)

  const { minimised } = context

  const checkClickPosition = useCallback(
    (event) => {
      if (
        menu &&
        menu.current &&
        !menu.current.contains(event.target) &&
        viewActions
      ) {
        setViewActions(false)
      }
    },
    [setViewActions, viewActions]
  )

  useEventListener("mousedown", checkClickPosition)

  const createInvoice = () => {
    viewCreateInvoice(send, context)

    setViewActions(false)
  }

  const createPing = () => {
    viewCreateOrEditPing(send, context)

    setViewActions(false)
  }

  const addMembers = () => {
    addMembersModal(send, context.organisation)
    setViewActions(false)
  }

  const importMembers = () => {
    importMembersModal(send)
    setViewActions(false)
  }

  return (
    <div
      className={
        "fixed inset-y-50 onboarding-3 onboarding-2 omnidraw" +
        (viewActions ? " is-open" : "")
      }
    >
      <div ref={menu} className="h-xl flex items-center">
        <div onClick={() => setViewActions(!viewActions)} className="tab">
          <div>
            <p>Quick add</p>
          </div>
        </div>
        <div className="z-20">
          <div className="omnidraw-body">
            <div className="hover:bg-warning-9" onClick={addMembers}>
              <div className="w-xl text-center">
                <Icon icon="user-plus" baseSize="20px" />
              </div>
              <div className="py-sm my-xs pl-sm w-full">Add Members</div>
            </div>
            <div className="hover:bg-warning-9" onClick={importMembers}>
              <div className="w-xl text-center">
                <Icon icon="cloud-upload" baseSize="20px" />
              </div>
              <div className="py-sm my-xs pl-sm w-full">Import Members</div>
            </div>
            <Flag name="pings">
              <div className="hover:bg-warning-9" onClick={createPing}>
                <div className="w-xl text-center">
                  <Icon icon="signal-stream" type="regular" baseSize="20px" />
                </div>
                <div className="py-sm my-xs pl-sm w-full">Create Ping</div>
              </div>
            </Flag>
            <div className="hover:bg-warning-9" onClick={createInvoice}>
              <div className="w-xl text-center">
                <Icon icon="envelope-open-text" baseSize="20px" />
              </div>
              <div className="py-sm my-xs pl-sm w-full">Create Invoice</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center z-30">
        {minimised &&
          minimised.map((item, index) => {
            return (
              <MinimisedItem
                key={index}
                title={item.title}
                onClick={() => {
                  send("MAXIMISE", { payload: item })
                }}
                removeItem={() => {
                  send("REMOVE", { id: item.id })
                }}
              />
            )
          })}
      </div>
    </div>
  )
}

export default Minimised
