import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react"
import { useEventListener } from "../../utils/hooks"

import { usePopper } from "react-popper"

export default forwardRef(
  (
    {
      disabled = false,
      label,
      children,
      placement = "bottom",
      fallbackPlacements = ["top", "bottom", "left", "right"],
      onClose = () => {},
      openOnMouseOver = false,
      closeOnMouseLeave = false,
      fullWidth = false,
      backgroundColour = "white",
      className = "inline-block",
      onOpen,
    },
    ref
  ) => {
    const menuEl = useRef()
    const customPopperRef = useRef()
    const [display, setDisplay] = useState(false)

    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)
    const {
      styles: popperStyles,
      attributes,
      update,
    } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [
        {
          name: "preventOverflow",
          enabled: false,
          options: {
            boundary: "window",
            priority: ["bottom", "top", "right", "left"],
          },
        },
        { name: "flip", enabled: false, options: { fallbackPlacements } },
        { name: "arrow", enabled: false, options: { element: arrowElement } },
        { name: "popper", enabled: false, options: { element: popperElement } },
      ],
    })

    useImperativeHandle(ref, () => ({
      close() {
        setDisplay(false)
        if (onClose) {
          onClose()
        }
      },
      update() {
        if (update) {
          update()
        }
      },
    }))

    const checkClickPosition = useCallback(
      (event) => {
        if (!menuEl.current.contains(event.target) && display) {
          setDisplay(false)
          onClose()
        }
      },
      [setDisplay, onClose, display]
    )

    useEventListener("mousedown", checkClickPosition)

    const handleFocus = () => {
      if (!disabled) {
        if (onOpen) {
          onOpen(() => {
            if (!disabled) {
              setDisplay(true)
            }
          })
        } else {
          if (!disabled) {
            setDisplay(true)
          }
        }
      }
    }

    const checkForTab = useCallback((event) => {
      if (event.keyCode === 9 && menuEl.current) {
        if (!menuEl.current.contains(event.target)) {
          setDisplay(false)
        }
      }
    }, [])

    const handleOpen = () => {
      if (onOpen) {
        onOpen(() => {
          if (!disabled) {
            setDisplay(!display)
          } else {
            setDisplay(false)
          }
        })
      } else {
        if (disabled) {
          setDisplay(false)
        }
      }
    }

    useEventListener("keyup", checkForTab)

    let popper = <React.Fragment />
    if (display && !disabled) {
      popper = (
        <div
          ref={setPopperElement}
          style={{ ...popperStyles.popper, zIndex: 100 }}
          {...attributes.popper}
          className="mt-xs z-10"
        >
          <div ref={customPopperRef} className="cursor">
            <div
              ref={setArrowElement}
              className="absolute dropdown-arrow left-1/2"
              style={popperStyles.arrow}
            >
              <div
                className={`absolute bg-${backgroundColour} transform rotate-45 -top-xs -left-xs w-base h-base dropdown-shadow`}
              />
            </div>

            <div
              className={`bg-${backgroundColour} rounded dropdown-shadow sm:mx-0 mx-md`}
            >
              {children}
            </div>
          </div>
        </div>
      )
    }

    const hidePopper = (event) => {
      event.stopPropagation()

      if (display && !customPopperRef.current.contains(event.target)) {
        setDisplay(false)
      }
    }

    const handleMouseOver = (event) => {
      if (openOnMouseOver && !disabled) {
        setDisplay(true)
      }
    }

    const handleMouseLeave = (event) => {
      if (closeOnMouseLeave && !disabled) {
        setDisplay(false)
      }
    }

    return (
      <div
        className={
          "cursor-pointer" + (fullWidth ? " w-full" : "") + " " + className
        }
        ref={menuEl}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <div ref={setReferenceElement} onFocus={handleFocus} tabIndex="0">
          <div onClick={handleOpen}>{label}</div>
        </div>

        {/* Create a transparent background behind the popper to stop other clickable items from triggering when clicking off the popper */}
        <div
          onClick={hidePopper}
          className={`${
            !display ? "hidden" : "absolute"
          } w-full h-full inset-0 z-20 cursor`}
        >
          {popper}
        </div>
      </div>
    )
  }
)
