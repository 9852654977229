import React, { useState, useEffect, useCallback, forwardRef, useRef } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import TimePicker from './TimePicker'
import TodayButton from './TodayButton'
import CustomHeader from './CustomHeader'
import CustomContainer from './CustomContainer'

import { addZero, getHoursAndMinutes } from '../../utils/functions'
import { useEventListener } from '../../utils/hooks'

export default forwardRef(({
  date = null,
  endDate,
  onChange,
  format,
  onEndDateChange,

  placeholder,
  minDate,
  maxDate,
  popperPlacement,
  popperModifiers,
  inline,
  clearText,
  todayButton,
  customInput,

  dateInput,
  dateRange,
  timePicker,
  disabled,
  closeOnSelect = true,
  className
}, ref) => {
  const [useCustomTime, setUseCustomTime] = useState(false)
  const [time, setTime] = useState('07:00')
  const [customTime, setCustomTime] = useState('')
  const [primaryDate, setPrimaryDate] = useState(date)
  const [secondaryDate, setSecondaryDate] = useState(null)

  const [selectedRange, setSelectedRange] = useState(dateRange ? 'selectsStart' : null)
  const internalRef = useRef()

  const checkForTab = useCallback(
    (event) => {
      if (event.keyCode === 9) {
        if (dateRange && selectedRange !== 'selectsEnd') {
          setSelectedRange('selectsEnd')
        } else {
          if (ref) {
            ref.current.setOpen(false)
          } else {
            internalRef.current.setOpen(false)
          }
        }
      }
    },
    [dateRange, ref, selectedRange]
  )

  useEventListener('keydown', checkForTab)

  const setPrimaryDateTime = useCallback(() => {
    let dateTime = time
    if (useCustomTime) {
      dateTime = customTime
    }

    const [hours, minutes] = getHoursAndMinutes(dateTime)

    if (primaryDate && validateTime(dateTime)) {
      primaryDate.setHours(hours, minutes)
    }

    setPrimaryDate(primaryDate)
  }, [time, customTime, primaryDate, setPrimaryDate, useCustomTime])

  useEffect(() => {
    if (time || customTime) {
      setPrimaryDateTime()
    }
  }, [primaryDate, time, customTime, setPrimaryDateTime])

  useEffect(() => {
    setPrimaryDate(date ? moment(date).toDate() : null)
  }, [date])

  useEffect(() => {
    setSecondaryDate(endDate)
  }, [endDate])

  const clearDate = () => {
    onChange(null)
    if (onEndDateChange) {
      onEndDateChange(null)
    }

    setPrimaryDate(null)
    setSecondaryDate(null)
    setTime('07:00')
    setCustomTime('')
    setUseCustomTime(false)
  }

  const validateTime = (timeStr) => {
    const validTimeCheck = RegExp('^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$')

    return validTimeCheck.test(timeStr)
  }

  const updateTime = async (event) => {
    setUseCustomTime(false)
    setCustomTime('')
    setTime(event.target.value)
  }

  const updateWithCustomTime = async (event) => {
    setUseCustomTime(true)
    setCustomTime(event.target.value)
  }

  const toggleCustomTime = () => {
    setTime(null)
    setUseCustomTime(true)
    setCustomTime(time)
  }

  const setDatePickerState = () => {
    if (dateRange) {
      setSelectedRange('selectsStart')
    }

    if (date) {
      setPrimaryDate(date)

      if (timePicker) {
        const hours = addZero(date.getHours())
        const minutes = addZero(date.getMinutes())
        const time = `${hours}:${minutes}`

        if (time !== '07:00' && time !== '12:00' && time !== '18:00') {
          setCustomTime(time)
          setUseCustomTime(true)
        } else {
          setTime(time)
          setUseCustomTime(false)
        }
      }
    }

    if (endDate) {
      setSecondaryDate(endDate)
    }
  }

  const isAfterMinDate = (date) => {
    return !minDate || moment(date, 'DD/MM/YYYY').isAfter(minDate) || moment(date, 'DD/MM/YYYY').isSame(minDate, 'day')
  }

  const isBeforeMaxDate = (date) => {
    return !maxDate || moment(date, 'DD/MM/YYYY').isBefore(maxDate) || moment(date, 'DD/MM/YYYY').isSame(maxDate, 'day')
  }

  const setTypedDate = ({ value }) => {
    const typedDate = moment(value, 'DD/MM/YYYY')

    if (typedDate.isValid() && isAfterMinDate(value) && isBeforeMaxDate(value)) {
      setPrimaryDate(typedDate.toDate())
      onChange(typedDate.toDate())
    }
  }

  const setTypedEndDate = ({ value }) => {
    const typedDate = moment(value, 'DD/MM/YYYY')
    if (typedDate.isValid() && isAfterMinDate(value) && isBeforeMaxDate(value)) {
      setSecondaryDate(typedDate.toDate())
    }
  }

  const selectDate = (date) => {
    if (dateRange && selectedRange === 'selectsEnd') {
      setSecondaryDate(date)
      ref.current.setOpen(false)
    } else {
      setPrimaryDate(date)
      setSelectedRange('selectsEnd')

      onChange(date)
    }
  }

  const closeDatePicker = () => {
    if (primaryDate) {
      onChange(primaryDate)
    }

    if (onEndDateChange && secondaryDate) {
      onEndDateChange(secondaryDate)
    }

    if (dateRange && (!primaryDate || !secondaryDate)) {
      setPrimaryDate(null)
      setSecondaryDate(null)
      onChange(null)
      onEndDateChange(null)
    }
  }

  return (
    <DatePicker
      ref={ ref || internalRef }
      selected={ (dateRange && selectedRange === 'selectsEnd') ? secondaryDate : primaryDate }
      minDate={ minDate }
      maxDate={ maxDate }
      onChange={ selectDate }
      inline={ inline }
      dateFormat={ format }
      className={ className }

      onCalendarClose={ closeDatePicker }
      onCalendarOpen={ setDatePickerState }
      customInput={ customInput || <React.Fragment /> }
      renderCustomHeader={
        (props) => (
          <CustomHeader
            {...props}
            state={ primaryDate }
            endDate={ secondaryDate }
            setTypedDate={ setTypedDate }
            setTypedEndDate={ setTypedEndDate }
            dateInput={ dateInput }
            dateRange={ dateRange }
            selectedRange={ selectedRange }
            setSelectedRange={ setSelectedRange }
          />
        )
      }
      calendarContainer={ CustomContainer }

      selectsStart={ selectedRange === 'selectsStart' }
      selectsEnd={ selectedRange === 'selectsEnd' }
      startDate={ dateRange ? primaryDate : null }
      endDate={ dateRange ? secondaryDate : null }

      showPopperArrow={ !inline }

      popperModifiers={ popperModifiers }

      placeholderText={ placeholder }
      popperPlacement={ popperPlacement }
      formatWeekDay={ dayOfWeek => dayOfWeek.substr(0, 1) }
      headerClassName="bg-white"
      calendarClassName="font-sans text-base"
      fixedHeight
      disabledKeyboardNavigation
      disabled={ disabled }
      dayClassName={ (date) => {
        if (selectedRange === 'selectsStart' && secondaryDate) {
          return moment(date).isSame(secondaryDate, 'day') ? 'react-datepicker__day--range-end' : ''
        }
        if (selectedRange === 'selectsEnd' && primaryDate) {
          return moment(date).isSame(primaryDate, 'day') ? 'react-datepicker__day--range-start' : ''
        }
      } }
      renderDayContents={(day) => {
        return <span>{ day }</span>
      }}
      shouldCloseOnSelect={ closeOnSelect }
    >
      <div className="grid text-neutral-1">
        <TimePicker
          display={ timePicker }
          useCustomTime={ useCustomTime }
          time={ time }
          customTime={ customTime }
          updateTime={ updateTime }
          toggleCustomTime={ toggleCustomTime }
          updateWithCustomTime={ updateWithCustomTime }
        />

        <div className="flex justify-between p-md">
          <div className="text-primary-6 cursor-pointer mr-auto my-auto" onClick={ clearDate }>
            { clearText || 'Clear' }
          </div>
          <TodayButton
            display={ todayButton }
            selectDate={ selectDate }
          />
        </div>
      </div>
    </DatePicker>
  )
})
