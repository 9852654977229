import React, { useRef } from "react"

import Popper from "../Popper"
import Icon from "../Icon"

const ActionOption = ({ option, handleClick }) => {
  let textClasses = "cursor-pointer"
  if (option.danger) textClasses += " text-danger-5"
  if (option.disabled) textClasses = "text-neutral-8 cursor-not-allowed"

  if (option.subMenu) {
    let menu = (
      <div className="py-sm rounded">
        {option.subMenu &&
          option.subMenu.options &&
          option.subMenu.options.length > 0 &&
          option.subMenu.options.map((option, index) => {
            return (
              <ActionOption
                key={
                  option.text
                    ? option.text.replace(/' '/g, "-")
                    : "action" + index
                }
                option={option}
                handleClick={handleClick}
              />
            )
          })}
      </div>
    )

    if (option.subMenu.render) {
      return option.subMenu.render
    }

    return (
      <Popper
        label={(props) => (
          <div
            {...props}
            className={`px-sm py-xs hover:bg-neutral-10 ${textClasses}`}
          >
            {option.text}
          </div>
        )}
        placement="right"
        fallbackPlacements={["left"]}
      >
        {menu}
      </Popper>
    )
  }

  return (
    <div
      className={`px-sm py-xs hover:bg-neutral-10 ${textClasses}`}
      onClick={() => handleClick(option)}
    >
      {option.text}
    </div>
  )
}

export default ({
  label,
  disabled = false,
  styles = {},
  actions,
  icon = "bolt",
  iconLayer = true,
  iconType = "solid",
  iconBaseSize,
  closeOnClick = false,
  className,
}) => {
  const popperRef = useRef()

  const handleClick = (option) => {
    if (!option.disabled) {
      if (option.callback) {
        option.callback(option)
      }

      if (closeOnClick) {
        popperRef.current.close()
      }
    }
  }

  return (
    <Popper
      ref={popperRef}
      disabled={disabled}
      label={
        <div
          className={`bg-white
            pl-sm
            ${styles.border || "shadow-border rounded-full"}
            whitespace-nowrap
            flex
            flex-1
            text-neutral-5
            font-medium
            ${disabled ? "opacity-75 cursor-not-allowed" : ""}`}
        >
          <div className="leading-tight my-xs">{label}</div>
          <Icon
            baseSize={iconBaseSize}
            type={iconType}
            layer={iconLayer}
            icon={icon}
            className="inline m-icon"
            iconClassName="align-top"
          />
        </div>
      }
      placement="bottom"
      fallbackPlacements={["top"]}
    >
      <div className={"py-sm rounded " + className}>
        {actions.length > 0 &&
          actions.map((option) => {
            return (
              <ActionOption
                key={option.text.replace(/' '/g, "-")}
                option={option}
                handleClick={handleClick}
              />
            )
          })}
      </div>
    </Popper>
  )
}
