import React, { useContext, useState } from "react"

import Confirmation from "./Confirmation"
import EditInvoice from "../../Forms/EditInvoice"
import Form from "../../Forms/Form"
import Header from "../../containers/Header"
import Footer from "../../containers/Footer"
import Reminders from "./Reminders"
import { pluraliseWord } from "../../../utils/functions"
import { MachineContext } from "../../../state"

export default ({ send, state, changesMade, setChanges, handleClose }) => {
  const [current] = useContext(MachineContext)
  const [totalError, showTotalError] = useState(false)
  const [step, setStep] = useState(state.step)
  const [batch, setState] = useState({
    title: state.batch.title,
    dueDate: state.batch.dueDate,
    description: state.batch.description,
    footer: state.batch.footer,
    lineItems: state.batch.lineItems
      .map(({ description, amount }) => ({ description, amount }))
      .concat([{ description: "", amount: "" }]),
  })
  const [members] = useState(state.batch.members)
  const [selectedRows, setSelectedRows] = useState([])

  let title = ""
  let buttons = []
  let footerText = ""
  let body = <React.Fragment />

  const handleStep = (event, validator, checkFormValidity) => {
    showTotalError(false)
    checkFormValidity()

    const invoiceTotal = batch.lineItems.reduce((a, b) => a + b.amount, 0)
    if (validator.allValid()) {
      switch (step) {
        case 1:
          setStep(2)
          break
        case 2:
          if (invoiceTotal > 49) {
            send("updateBatch", {
              data: {
                variables: {
                  batchId: state.batch.id,
                },
                body: {
                  batch: {
                    ...batch,
                    lineItems: batch.lineItems.filter(
                      (item) => item.description && item.amount
                    ),
                  },
                  memberIds: members.map((member) => member.id),
                },
                onSuccess: {
                  notification: {
                    title: "Invoice updated",
                  },
                  callback: () => {
                    setChanges(false)
                    setStep(3)
                  },
                },
              },
            })
          } else {
            showTotalError(true)
          }
          break
        case 3:
          send("sendBatchReminders", {
            data: {
              variables: {
                batchId: state.batch.id,
                resentInvoices: true,
              },
              body: {
                members: selectedRows.map((row) => row.id),
              },
              onSuccess: {
                target: "CLOSE_MODAL",
                notification: {
                  title: `${selectedRows.length} ${pluraliseWord(
                    "invoice",
                    selectedRows.length
                  )} re-sent`,
                },
              },
            },
          })
          break
        default:
          break
      }
    }
  }

  const updateBatchDetails = ({ target: { value, name } }) => {
    if (!changesMade) {
      setChanges(true)
    }
    setState({ ...batch, [name]: value })
  }

  const updateLineItems = ({ name, value }, index) => {
    let items = batch.lineItems
    if (typeof value === "number" && isNaN(value)) {
      value = ""
    }

    items[index][name] = value

    if (index === items.length - 1) {
      items.push({ description: "", amount: "" })
    }
    setChanges(true)
    updateBatchDetails({ target: { name: "lineItems", value: items } })
  }

  const selectRow = (row) => {
    setSelectedRows((rows) => {
      if (rows.filter((selected) => selected.id === row.id).length === 0) {
        return rows.concat(row)
      } else {
        return rows.filter((selected) => selected.id !== row.id)
      }
    })
  }

  const selectAll = (rows) => {
    setSelectedRows(rows.filter((member) => !member.undeliverable))
  }

  switch (step) {
    case 1:
      title = "Please note"
      buttons = [
        {
          text: "Cancel",
          callback: handleClose,
        },
        {
          text: "Continue",
          type: "primary",
          icon: "arrow-circle-right",
          iconType: "duotone",
        },
      ]
      body = <Confirmation count={members.length} />
      break
    case 2:
      title = "Edit invoice"
      buttons = [
        {
          text: "Cancel",
          callback: handleClose,
        },
        {
          disabled: !changesMade,
          text: `Edit ${members.length} ${pluraliseWord(
            "invoice",
            members.length
          )}`,
          type: "primary",
          submitting: current.matches("app.requests.updateBatch.requesting"),
          icon: "pencil",
        },
      ]
      body = (
        <EditInvoice
          invoice={batch}
          updateInvoice={updateBatchDetails}
          updateLineItems={updateLineItems}
          editableTitle={true}
          totalError={totalError}
        />
      )
      break
    case 3:
      title = `Resend invoices`
      buttons = [
        {
          text: "Don't send",
          callback: () => send("CLOSE_MODAL"),
        },
        {
          text: `Resend ${
            selectedRows.length
              ? pluraliseWord(
                  selectedRows.length + " invoice",
                  selectedRows.length
                )
              : "invoices"
          }`,
          submitting: current.matches(
            "app.requests.sendBatchReminders.requesting"
          ),
          type: "primary",
          icon: "paper-plane",
          disabled: selectedRows.length === 0,
        },
      ]
      body = (
        <Reminders
          selectAll={selectAll}
          selectRow={selectRow}
          selectedRows={selectedRows}
          members={members}
        />
      )
      footerText = `${selectedRows.length} of ${members.length} selected`
      break
    default:
      break
  }

  return (
    <Form onSubmit={handleStep}>
      {members.length === 0 ? (
        <div>
          <Header title="Cannot edit invoice" />
          <div className="p-md bg-neutral-10 text-neutral-5">
            <div>
              You cannot edit this invoice as they all either have payments made
              against them or have already been individually edited.
            </div>
          </div>
          <Footer
            buttons={[
              {
                type: "primary",
                callback: () => handleClose(),
                icon: "check-circle",
                iconType: "duotone",
                text: "Okay",
              },
            ]}
          />
        </div>
      ) : (
        <div>
          <Header
            title={step === 1 ? title : `Step ${step - 1} of 2: ${title}`}
          />
          {body}
          <Footer buttons={buttons} footerText={footerText} />
        </div>
      )}
    </Form>
  )
}
