import React, { useState, useContext, useEffect } from "react";

import Icon from "../Icon";
import SidebarItem from "./SidebarItem";
import { MachineContext } from "../../state/index";

export default ({ category, categories, icon, root, pathname, disabled, last = false }) => {
  const [{ context }, send] = useContext(MachineContext);
  const [showCategory, setShow] = useState(false);
  const active = pathname.includes(root);

  useEffect(() => {
    if (context.expandedMenu === root && !showCategory) {
      setShow(true);
    }

    if (context.expandedMenu !== root && showCategory) {
      setShow(false);
    }
  }, [context.expandedMenu, pathname, root, showCategory]);

  const toggleMenu = (displayMenu) => {
    if (displayMenu) {
      send("UPDATE_APP_STATE", { expandedMenu: root });
    } else {
      send("UPDATE_APP_STATE", { expandedMenu: undefined });
    }

    setShow(displayMenu);
  };

  if (disabled) {
    return (
      <div className={ `flex ${last ? '' : 'mb-sm'}` }>
        <div className="inline-block w-28">
          <Icon
            type="light"
            className="text-neutral-5"
            icon={icon}
            baseSize="20px"
          />
        </div>
        <div className="pl-sm inline-block my-auto text-neutral-5">
          {category}
        </div>
      </div>
    );
  }

  return (
    <ul>
      <li
        onClick={() => toggleMenu(!showCategory)}
        className={ `flex ${(last || showCategory) ? 'mb-xs' : 'mb-sm'} cursor-pointer` }
      >
        <div className="inline-block w-28">
          <Icon
            type="light"
            className={
              active || showCategory ? "text-neutral-10 font-semibold" : "text-neutral-8"
            }
            icon={icon}
            baseSize="20px"
          />
        </div>
        <div
          className={
            "pl-sm inline-block my-auto" +
            (active || showCategory ? " font-semibold text-neutral-10" : "")
          }
        >
          {category}
        </div>
      </li>
      {showCategory &&
        categories.filter(cat => !cat.hidden).map((option, i) => {
          return (
            <SidebarItem
              onClick={option.onClick}
              category
              marginBottom="xs"
              key={i}
              pathname={pathname}
              to={root + option.to}
              label={option.label}
              href={option.href}
            />
          );
        })}
    </ul>
  );
};
