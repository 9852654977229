import React, { useContext } from "react"

import Button from "../../Button"
import Input from "../../Input"
import Select from "../../Input/Select"
import DatePicker from "../../Datepicker"
import PaymentReview from "../../Invoice/PaymentReview"
import { formatCurrency } from "../../../utils/functions"
import Form from "../../Forms/Form"
import { MachineContext } from "../../../state"

export default ({
  paymentDate,
  paymentMethod,
  total,
  totalPaid,
  invoice,
  recordPayment,
  updateManualPayment,
  closeModal,
}) => {
  const [current] = useContext(MachineContext)

  return (
    <Form
      onSubmit={recordPayment}
      className="w-full flex flex-col flex-1 justify-between"
    >
      <div className="bg-white overflow-y-auto rounded-tr">
        <PaymentReview
          withLabel={false}
          total={total}
          totalPaid={totalPaid}
          title={invoice.member ? invoice.member.name : ""}
          contextLabel={invoice.title}
          owed={invoice.owed}
        />
        <div className="p-md">
          <Input
            large
            label="Amount"
            name="amount"
            value={invoice.owed}
            onChange={updateManualPayment}
            placeholder={formatCurrency(total - totalPaid)}
            type="currency"
            maxValue={invoice.owed}
            classes={{
              label: "text-neutral-1",
            }}
            validation={{
              message: "Enter an amount",
              rules: "required",
            }}
          />

          <div className="mt-md">
            <label
              htmlFor="paymentDate"
              className="mb-sm text-neutral-1 mt-md block font-medium"
            >
              Date paid
            </label>
            <DatePicker
              id="paymentDate"
              name="paymentDate"
              date={paymentDate || null}
              format="dd/MM/yyyy"
              onChange={(dueDate) =>
                updateManualPayment({
                  target: { name: "paymentDate", value: dueDate },
                })
              }
              placeholder="Select date"
              maxDate={new Date()}
              popperPlacement="bottom"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "0, 5px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              clearText="Clear date"
              customInput={
                <Input
                  large
                  dateDisplay
                  mask="99/99/9999"
                  showSelectChevron={true}
                  validateOnBlur={false}
                  classes={{
                    input: "cursor-pointer",
                    label: "text-neutral-1",
                  }}
                  validation={{
                    name: "paymentDate",
                    message: "Enter the payment date",
                    rules: "required",
                  }}
                />
              }
              todayButton
            />
          </div>

          <div>
            <label
              htmlFor="paymentMethod"
              className="mb-sm text-neutral-1 mt-md block font-medium"
            >
              Payment type
            </label>
            <Select
              large
              name="paymentMethod"
              fullWidth
              value={paymentMethod}
              onChange={updateManualPayment}
              placeholder={"Select payment type"}
              type="currency"
              options={["BACS", "Cash", "Cheque"]}
              className="w-full h-full"
              validation={{
                message: "Select a type of payment",
                rules: "required",
              }}
              menuPlacement="top"
            />
          </div>
        </div>
      </div>

      <div className="shadow-t-border p-sm">
        <div className="grid grid-cols-3 gap-sm text-center">
          <Button large type="secondary" label="Close" onClick={closeModal} />
          <div className="col-span-2">
            <Button
              large
              type="primary"
              submitting={current.matches(
                "app.requests.createManualPayment.requesting"
              )}
              label="Record payment"
            />
          </div>
        </div>
      </div>
    </Form>
  )
}
