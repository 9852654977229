import React from 'react'

import Icon from '../Icon'

export default function SelectChevron ({
  chevron = 'chevron-circle-down',
  value,
  disabled = false,
  large,
  className = ''
}) {
  return (
    <div className={ `absolute inset-y-0 right-0 flex items-center mr-${large ? 'sm' : 'xs'} ${!disabled ? 'cursor-pointer' : ''} ${className}` }>
      <Icon
        type="duotone"
        className="flex justify-center items-center h-full"
        icon={ chevron }
        colours={{
          primary: value ? (disabled ? '#829AB1' : '#102A43') : '#BCCCDC',
          secondary: '#FFF'
        }}
        baseSize="1.429rem"
      />
    </div>
  )
}
