import React, { useState } from "react"

import Icon from "../Icon"

export default ({ value = false, name, id, onChange, label }) => {
  const [selected, setSwitch] = useState(value)
  return (
    <div className="inline-block">
      <label htmlFor={id || name} className="flex flex-row items-center">
        {label}
        <div
          className={`rounded-full inline-block cursor-pointer w-xl ${
            selected ? "bg-primary-4" : "bg-neutral-9"
          } ${label ? "ml-sm" : ""}`}
        >
          <Icon
            iconClassName="align-top"
            className="m-icon text-white"
            textPosition={selected ? "right" : "left"}
            icon="circle"
            type="solid"
            baseSize="20px"
          />
          <input
            className="hidden"
            id={id || name}
            name={name}
            type="checkbox"
            checked={selected}
            onChange={(e) => {
              onChange(e)
              setSwitch(e.target.checked)
            }}
          />
        </div>
      </label>
    </div>
  )
}
