import React, { useContext, forwardRef, useRef } from "react"

import Declaration from "./Declaration"
import AboutYou from "./AboutYou"
import AboutYourClub from "./AboutYourClub"
import Header from "../../containers/Header"
import Body from "../../containers/ModalBody"
import Footer from "../../containers/Footer"

import { MachineContext } from "../../../state"
import Form from "../../Forms/Form"
import CreateClub from "./CreateClub"
import { returnOrganisationsTransform } from "../../../utils/transformers"
import { useHistory } from "react-router-dom"

const getStepPage = forwardRef(({ state, setState, submitForm }, ref) => {
  const updateDeclaration = (value) => {
    setState({
      ...state,
      declaration: {
        ...state.declaration,
        ...value,
      },
    })
  }

  const updateUser = (value) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...value,
      },
    })
  }
  const updateOrganisation = (value) => {
    setState({
      ...state,
      organisation: {
        ...state.organisation,
        ...value,
      },
    })
  }

  switch (state.step) {
    case 1:
      return <CreateClub ref={ref} nextStep={() => setState({ step: 2 })} />
    case 2:
      return (
        <Declaration
          ref={ref}
          isClubAdmin={state.declaration.isClubAdmin}
          isClubAuthed={state.declaration.isClubAuthed}
          isUKBased={state.declaration.isUKBased}
          acceptedTAndC={state.declaration.acceptedTAndC}
          allowIdentityCheck={state.declaration.allowIdentityCheck}
          ageCheck={state.declaration.ageCheck}
          step={state.step}
          setState={updateDeclaration}
          nextStep={() => setState({ step: 3 })}
        />
      )
    case 3:
      return (
        <AboutYou
          ref={ref}
          name={state.user.name}
          dob={state.user.dob}
          addressLine1={state.user.addressLine1}
          addressLine2={state.user.addressLine2}
          town={state.user.town}
          postcode={state.user.postcode}
          phoneNumber={state.user.phoneNumber}
          setState={updateUser}
          nextStep={() => setState({ step: 4 })}
        />
      )
    case 4:
      return (
        <AboutYourClub
          ref={ref}
          name={state.organisation.name}
          type={state.organisation.type}
          website={state.organisation.website}
          social={state.organisation.social}
          intent={state.organisation.intent}
          setState={updateOrganisation}
          submitClub={submitForm}
        />
      )
    default:
      return <React.Fragment />
  }
})

export default forwardRef(({ state, setState, handleClose }, ref) => {
  const history = useHistory()
  const [current, send] = useContext(MachineContext)
  const pageRef = useRef()

  let title = `Step ${state.step - 1} of 3: `
  let buttons = [
    {
      text: "Cancel",
      callback: (event) => {
        event.preventDefault()
        handleClose()
      },
    },
    {
      text: "Continue",
      type: "primary",
      iconType: "duotone",
      icon: "arrow-circle-right",
      callback: (event) => {
        event.preventDefault()
        if (pageRef.current && pageRef.current.validateStep) {
          pageRef.current.validateStep()
        }
      },
    },
  ]
  let leftButton = {
    text: "Previous",
    callback: (event, validator) => {
      event.preventDefault()
      validator.purgeFields()
      setState({ step: state.step - 1 })
    },
  }

  const submitForm = () => {
    send("updateCompleteUser", {
      data: {
        updateUser: {
          body: { name: state.user.name, email: current.context.user.email },
        },
        updateUserDetails: {
          body: state.user,
        },
        onSuccess: {
          callback: () =>
            send("createOrganisation", {
              data: {
                createOrganisation: {
                  body: state.organisation,
                  transformResponse: returnOrganisationsTransform,
                },
                onSuccess: {
                  target: "CLOSE_MODAL",
                },
              },
            }),
        },
      },
    })
  }

  switch (state.step) {
    case 1:
      title = "Create club"
      leftButton = null
      break
    case 2:
      title = title + "Declaration"
      leftButton = state.firstClub ? leftButton : null
      break
    case 3:
      title = title + "About you"
      break
    case 4:
      title = title + "About your club"
      buttons = [
        {
          text: "Cancel",
          callback: (event) => {
            event.preventDefault()
            handleClose()
          },
        },
        {
          text: "Create club",
          type: "primary",
          iconType: "duotone",
          icon: "check-circle",
          submitting:
            current.matches("app.requests.updateCompleteUser.requesting") ||
            current.matches("app.requests.createOrganisation.requesting"),
          callback: (event) => {
            event.preventDefault()
            if (pageRef.current && pageRef.current.validateStep) {
              pageRef.current.validateStep()
              if (
                !current.context.organisation ||
                (current.context.organisation &&
                  !current.context.organisation.id)
              ) {
                history.push("/")
              }
            }
          },
        },
      ]
      break
    default:
      break
  }

  return (
    <div>
      <Header title={title} />
      <Form>
        <Body
          component={getStepPage}
          componentProps={{ state, setState, submitForm }}
          ref={pageRef}
        />
        <Footer buttons={buttons} leftButton={leftButton} />
      </Form>
    </div>
  )
})
