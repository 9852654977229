import React from "react"
import UnreachableServerBanner from "../Banners/UnreachableServerBanner"

export default function DashboardContainer({ children }) {
  return (
    <div>
      <UnreachableServerBanner />
      {children}
    </div>
  )
}
