import React from "react"
import { useHistory } from "react-router-dom"

import Icon from "../Icon"

export default ({
  children,
  label,
  onClick = () => {},
  type,
  to,
  disabled,
  customStyle = "",
  large,
  background,
  iconType = "solid",
  icon,
  containerStyle = "",
  submitting = false,
  className,
  padding = "px-sm",

  border = true,
}) => {
  const history = useHistory()
  let buttonStyle = large
    ? "rounded w-full py-sm block "
    : `${icon ? "pl-sm" : padding} rounded-full leading-tight flex `
  let textStyle = disabled ? "text-neutral-4" : "text-primary-6 hover:underline"

  let iconColours = null
  if (disabled) {
    switch (type) {
      case "primary":
      case "secondary":
      case "danger":
        buttonStyle += "bg-neutral-10"
        textStyle = "text-neutral-8"
        iconColours = {
          primary: "#BCCCDC",
          secondary: "#D9E2EC",
        }
        break
      case "danger-text":
        buttonStyle = "w-full block cursor-not-allowed"
        textStyle = "text-neutral-8"
        break
      default:
        buttonStyle = "w-full block cursor-not-allowed " + padding
        textStyle = "text-neutral-8"
        iconColours = {
          primary: "#627D98",
          secondary: "#D9E2EC",
        }
        break
    }
  } else {
    switch (type) {
      case "primary":
        buttonStyle += background || "bg-primary-1"
        textStyle = "text-primary-10"
        iconColours = {
          primary: "#DCEEFB",
          secondary: "#186FAF",
        }
        break
      case "support":
        buttonStyle += background || "bg-support-6"
        textStyle = "text-support-10"
        iconColours = {
          primary: "#E0FCFF",
          secondary: "#14919B",
        }
        break
      case "secondary":
        buttonStyle +=
          (background || "bg-white") +
          (border
            ? typeof border === "string"
              ? border
              : " shadow-border"
            : " ")
        textStyle = "text-neutral-5"
        iconColours = {
          primary: "#627D98",
          secondary: "#D9E2EC",
        }
        break
      case "tertiary":
        buttonStyle += background || "bg-white"
        textStyle = "text-neutral-6"
        break
      case "danger":
        buttonStyle += background || "bg-danger-5"
        textStyle = "text-danger-10"
        iconColours = {
          primary: "#FFEEEE",
          secondary: "#E66A6A",
        }
        break
      case "text":
        buttonStyle = "w-full py-sm block cursor-pointer"
        textStyle = "text-primary-6 hover:underline"
        break
      case "danger-text":
        buttonStyle = "w-full py-sm block cursor-pointer"
        textStyle = "text-danger-5 hover:underline"
        break
      case "warning":
        break
      case "neutral":
        buttonStyle += background || "bg-neutral-1"
        textStyle = "text-neutral-10"
        break
      default:
        break
    }
  }
  textStyle += large ? " font-medium" : ""

  const handleClick = (event) => {
    if (!submitting) {
      if (to) {
        if (to.includes("http")) {
          window.location.href = to
        } else {
          history.push(to)
        }
      }

      onClick(event)
    }
  }

  let displayIcon = (
    <Icon
      type={iconType}
      layer={iconType !== "duotone"}
      icon={icon}
      colours={iconColours}
      iconClassName="align-top"
      baseSize="20px"
    />
  )

  if (submitting) {
    displayIcon = (
      <Icon
        type="solid"
        icon="spinner"
        pulse
        className={`text-${type || "primary"}-10`}
        iconClassName="align-top"
        baseSize="20px"
      />
    )
  }

  if (type === "primary" || type === "danger") {
    return (
      <div
        className={large ? containerStyle : "inline-block " + containerStyle}
      >
        <button
          onClick={handleClick}
          className={`${customStyle || buttonStyle} ${
            disabled ? "cursor-not-allowed" : ""
          } ${className}`}
          disabled={disabled}
          type={type === "primary" || type === "danger" ? "submit" : ""}
        >
          <div
            className={`${textStyle} h-28 inline-block flex items-center justify-center`}
          >
            {large && submitting ? (
              <Icon
                type="solid"
                icon="spinner"
                pulse
                className={`text-${type || "primary"}-10`}
                iconClassName="align-top"
                baseSize="20px"
              />
            ) : (
              children || label
            )}
          </div>
          {icon && (
            <div className="mx-icon h-28 flex items-center">{displayIcon}</div>
          )}
        </button>
      </div>
    )
  }

  return (
    <div className={large ? containerStyle : "inline-block " + containerStyle}>
      <div
        onClick={disabled ? () => {} : handleClick}
        className={`${customStyle || buttonStyle} ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } ${className}`}
        disabled={disabled}
      >
        <div
          className={`${textStyle} h-28 inline-block flex items-center justify-center`}
        >
          {children || label}
        </div>
        {icon && (
          <div className="inline mx-icon h-28 flex items-center">
            {displayIcon}
          </div>
        )}
      </div>
    </div>
  )
}
