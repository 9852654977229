import React, { useCallback } from "react"
import XLSX from "xlsx"
import { useDropzone } from "react-dropzone"

export default ({
  text = "Drag your spreadsheet here or click to choose a file",
  handleDrop,
  styles = {},
}) => {
  const dropzoneStyles = {
    border: "border-dashed hover:border-neutral-6 border border-neutral-8",
    padding: "p-xl",
    textMargin: "mt-0",
    ...styles,
  }
  const onDrop = useCallback(
    (files) => {
      const file = files[0]
      const reader = new FileReader()

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading failed")
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, {
          raw: true,
          cellDates: true,
          dateNF: "dd/mm/yyyy",
          type: "array",
        })
        const sheetData = workbook.SheetNames.map((sheetName) => {
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName],
            { raw: true, header: 1 }
          )
          if (sheetData.length > 0) {
            const data = sheetData
              .filter((data) => data.filter((valid) => valid).length) // Clear empty rows
              .map((member) => {
                // Destructure array to map empty values into strings
                return [...member].map((col) => {
                  switch (typeof col) {
                    case "string":
                      return col.trim()
                    case "object":
                      if (col instanceof Date) {
                        // Account for BST changes in date uploads
                        col.setHours(
                          col.getHours() + (1 + col.getTimezoneOffset() / 60)
                        )
                        return col.toLocaleDateString("en-GB")
                      }
                      return ""
                    default:
                      return ""
                  }
                })
              })

            return {
              name: sheetName,
              data,
            }
          }

          return {
            name: sheetName,
            data: [],
          }
        })
        handleDrop(sheetData.filter(({ data }) => data.length))
      }

      if (file) {
        reader.readAsArrayBuffer(file)
      }
    },
    [handleDrop]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      "text/csv, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })

  return (
    <div className="bg-neutral-10 p-md">
      <div
        {...getRootProps()}
        className={`h-full cursor-pointer rounded text-center ${dropzoneStyles.border} ${dropzoneStyles.padding}`}
      >
        <p className={`text-neutral-8 ${dropzoneStyles.textMargin}`}>{text}</p>
        <input {...getInputProps()} />
      </div>
    </div>
  )
}
