import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react"

import Modals from "../Modals"

import { MachineContext } from "../../state"

import Body from "./ModalBody"
import Header from "./Header"
import Footer from "./Footer"
import Form from "../Forms/Form"
import { useEventListener } from "../../utils/hooks"
import UnreachableServerBanner from "../Banners/UnreachableServerBanner"
import { loseFormChangesConfirmation } from "../../utils/confirmations/auth"

let CustomComponent = <React.Fragment />

export default () => {
  const [parentMachine, send] = useContext(MachineContext)
  // Get modal params from xstate
  const current = parentMachine.children.modal.state
  const confirmationMachine = parentMachine.children.confirmation.state
  const modal = current.context || {}

  const [modalState, setModalState] = useState(modal.state)
  const [changesMade, setChanges] = useState(false)

  const [currentModalId, setModalId] = useState(false)

  useEffect(() => {
    if (modal) {
      if (currentModalId !== modal.id) {
        setChanges(false)
      }

      setModalId(modal.id)
    }

    if (!modal.state) {
      setChanges(false)
    }

    setModalState(modal.state)
  }, [modal])

  useEffect(() => {
    // WHENEVER APP STATE CHANGES, ALL APP BODY TO SCROLL
    document.body.classList.remove("overflow-hidden")

    // WHEN MODAL EXISTS AND IS OPEN REMOVE SCROLL FROM APP BODY
    if (
      (current.matches("open") || confirmationMachine.matches("open")) &&
      currentModalId
    ) {
      document.body.classList.add("overflow-hidden")
    }
  }, [current])

  const customRef = useRef()

  if (
    !current.history ||
    modal.componentPath !== current.history.context.componentPath
  ) {
    CustomComponent = Modals[modal.componentPath]
  }

  const handleClose = useCallback(() => {
    let handlingClose = modal.handleClose ? modal.handleClose : () => {}
    if (customRef.current && customRef.current.onClose) {
      handlingClose = customRef.current.onClose
    }

    // Check if modal state is not submitting
    if (current.matches("open") || confirmationMachine.matches("open")) {
      if (modal.confirmOnClose) {
        if (changesMade) {
          loseFormChangesConfirmation(() => {
            setChanges(false)
            handlingClose()
          }, send)
        } else {
          setChanges(false)
          handlingClose()
          send("CLOSE_MODAL")
        }
      } else {
        setChanges(false)
        handlingClose()
        send("CLOSE_MODAL")
      }
    }
  }, [changesMade, current, modal, send])

  const closeModal = (event) => {
    if (event.currentTarget === event.target) {
      if (customRef.current && customRef.current.onClose) {
        customRef.current.onClose()
      } else {
        handleClose()
      }
    }
  }

  const checkButtonPress = useCallback(
    (event) => {
      // Check if modal state is not submitting
      if (current.matches("open") && !confirmationMachine.matches("open")) {
        // KEYCODE 27 = Esc key
        if (event.keyCode === 27) {
          if (customRef.current && customRef.current.onClose) {
            customRef.current.onClose()
          } else {
            handleClose()
          }
        }
      }
    },
    [handleClose, current]
  )

  useEventListener("keydown", checkButtonPress)

  if (current.matches("open") && currentModalId === modal.id) {
    const updateState = (newState) => {
      const updatedState = { ...modalState, ...newState }
      setModalState(updatedState)
      setChanges(true)
    }

    const onSubmit = (event, validator, validateForm) => {
      event.preventDefault()

      if (modal.buttons && modal.buttons.length > 0) {
        const submitButton = modal.buttons.find(
          (button) => button.type === "primary" || button.type === "danger"
        )
        if (validator) {
          if (validator.allValid()) {
            if (submitButton) {
              submitButton.callback({}, validator, validateForm, modalState)
            }
          } else {
            validator.showMessages()
          }
        } else {
          if (submitButton) {
            submitButton.callback({}, validator, validateForm, modalState)
          }
        }
      }
    }

    const componentProps = {
      ...modal,
      state: modalState,
      setState: updateState,
      send,
      onSubmit,
      handleClose,
      modalData: current.context.modalData,
      changesMade,
      setChanges,
    }

    // Every modal will have a cancel button but potentially an array of buttons
    // Set cancel button to be an array to allow us to concat the modals buttons
    const cancelButton = [
      { text: "Cancel", colWidth: 4, callback: () => handleClose() },
    ]
    let buttons = cancelButton
    if (modal.buttons && modal.buttons.length > 0) {
      buttons = modal.buttons.map((button) => {
        if (button.changeSensitive) {
          button.disabled = !changesMade
        }

        return button
      })

      if (!modal.overrideDefaultButtons) {
        buttons = cancelButton.concat(buttons)
      }
    }

    let modalSize = ""
    switch (modal.modalSize) {
      case "lg":
        modalSize = "sm:max-w-lg sm:min-w-xl"
        break
      case "xl":
        modalSize = "sm:max-w-xl sm:min-w-xxl"
        break
      case "md":
      default:
        modalSize = "sm:max-w-md sm:min-w-lg"
        break
    }

    return (
      <React.Fragment>
        <div className="modal-background" onMouseDown={closeModal}></div>
        <div
          onMouseDown={closeModal}
          className="modal-container items-center justify-center"
        >
          <UnreachableServerBanner className="absolute inset-x-0 top-0" />
          <div
            className={`sm:max-h-modal-container ${modalSize} sm:h-auto h-full sm:w-auto w-full`}
          >
            {!modal.overrideLayout ? (
              <div className="sm:block flex flex-col flex-1 h-full">
                {modal.withHeader && <Header title={modal.title} />}

                <Form
                  className={`sm:h-auto sm:block flex flex-col flex-1 h-full${
                    !modal.withButtons ? " sm:rounded-b sm:overflow-hidden" : ""
                  }`}
                  onSubmit={onSubmit}
                >
                  <Body
                    isAdmin={modal.isAdmin}
                    component={CustomComponent}
                    body={modal.body}
                    componentProps={componentProps}
                  />

                  {modal.withButtons &&
                    modal.buttons &&
                    modal.buttons.length > 0 && (
                      <Footer
                        buttons={buttons}
                        leftButton={modal.leftButton}
                        state={modalState}
                      />
                    )}
                </Form>
              </div>
            ) : (
              <CustomComponent ref={customRef} {...componentProps} />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return <React.Fragment />
}
