import React from "react"
import Header from "../../containers/Header"
import SkeletonText from "../Text"

const SkeletonHeader = ({ header }) => {
  return (
    <Header>
      <div className="flex">
        {Array.isArray(header) ? (
          header.map((text, index) => {
            return (
              <SkeletonText
                key={`header-${index}`}
                className="mr-md"
                height="h-16"
                {...text}
              />
            )
          })
        ) : (
          <SkeletonText height="h-16" {...header} />
        )}
      </div>
    </Header>
  )
}

export default SkeletonHeader
