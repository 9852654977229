import { Machine, assign, send } from "xstate"

export const assignPayload = assign((context, event) => {
  return event.payload
})
export const assignState = assign((context, event) => {
  return {
    state: {
      ...context.state,
      ...event.payload.state,
    },
  }
})

export const displayMachine = Machine({
  initial: "closed",
  context: {
    state: {},
    modalData: {},
  },
  states: {
    open: {
      on: {
        UPDATE_CONTEXT: {
          actions: assignPayload,
        },
        OPEN: {
          actions: assignPayload,
        },
        UPDATE_STATE: {
          actions: assignState,
        },
        CLOSE: {
          target: "closed",
          actions: assign((context, event) => {
            const clearContext = {}
            Object.entries(context).map(
              ([key, value]) => (clearContext[key] = null)
            )
            return {
              ...clearContext,
              state: {},
              modalData: {},
            }
          }),
        },
      },
    },
    closed: {
      on: {
        OPEN: {
          target: "open",
          actions: assignPayload,
        },
        DONE: "clear",
      },
    },
    clear: {
      type: "final",
    },
  },
})

export const modalMachine = {
  invoke: {
    id: "modal",
    src: displayMachine,
  },
  on: {
    UPDATE_MODAL_CONTEXT: {
      actions: send(
        (context, event) => {
          return {
            type: "UPDATE_CONTEXT",
            payload: event.payload,
          }
        },
        {
          to: "modal",
        }
      ),
    },
    UPDATE_MODAL_STATE: {
      actions: send(
        (context, event) => {
          return {
            type: "UPDATE_STATE",
            payload: event.payload,
          }
        },
        {
          to: "modal",
        }
      ),
    },
    OPEN_MODAL: {
      actions: send(
        (context, event) => {
          return {
            type: "OPEN",
            payload: event.payload,
          }
        },
        {
          to: "modal",
        }
      ),
    },
    CLOSE_MODAL: {
      actions: send(
        (context, event) => {
          return {
            type: "CLOSE",
            payload: event.payload,
          }
        },
        {
          to: "modal",
        }
      ),
    },
  },
}

export const confirmationMachine = {
  invoke: {
    id: "confirmation",
    src: displayMachine,
  },
  on: {
    OPEN_CONFIRMATION: {
      actions: send(
        (context, event) => {
          return {
            type: "OPEN",
            payload: event.payload,
          }
        },
        {
          to: "confirmation",
        }
      ),
    },
    CLOSE_CONFIRMATION: {
      actions: send(
        (context, event) => {
          return {
            type: "CLOSE",
            payload: event.payload,
          }
        },
        {
          to: "confirmation",
        }
      ),
    },
    CLOSE_MODAL: {
      actions: send(
        (context, event) => {
          return {
            type: "CLOSE",
            payload: event.payload,
          }
        },
        {
          to: "confirmation",
        }
      ),
    },
  },
}
