import React from "react"
import SkeletonText from "../Text"

export default function Cell({
  right,
  roundTop,
  roundBottom,
  className,
  colSpan = 1,
  text = {},
}) {
  return (
    <td
      colSpan={colSpan}
      className={`${right ? "text-right " : "text-left "}
        first:pl-md last:pr-md py-xs px-base
        ${roundBottom ? "first:rounded-bl last:rounded-br" : ""}
        ${roundTop ? "first:rounded-tl last:rounded-tr" : ""}
        ${className}`}
    >
      <SkeletonText {...text} />
    </td>
  )
}
