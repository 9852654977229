import moment from "moment"

export const checkFeatureFlag = (flag, organisation) => {
  if (organisation && organisation.flags) {
    if (organisation.flags.includes(flag)) {
      return true
    }
  }
  return false
}

export const getOptionInitials = (name = "") => {
  let words = name.split(" ")
  let initials = words[0].charAt(0).toUpperCase()
  if (words.length > 1) {
    initials = initials + words[words.length - 1].charAt(0).toUpperCase()
  }

  return initials
}

export const addZero = (i) => {
  if (i < 10) {
    i = "0" + i
  }
  return i
}

export const getHoursAndMinutes = (timeString) => {
  const timeSegments = timeString.split(":")
  const hours = parseInt(timeSegments[0])
  const minutes = parseInt(timeSegments[1])

  return [hours, minutes]
}

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, index) => start + index)
}

export const formatCurrency = (amount) => {
  let value = Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amount / 100)

  if (isNaN(amount)) {
    return Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(0)
  }

  return value
}

export const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY")
}

export const currencyToPennies = (currency, allowNegativeValue = false) => {
  if (typeof currency === "string") {
    if (currency.includes("-") && allowNegativeValue) {
      if (currency === "-") {
        return "-0"
      }

      if (currency.match(/-/g).length !== 2) {
        return parseInt("-" + currency.replace(/[^\d]/g, ""))
      }
    }

    return parseInt(currency.replace(/[^\d]/g, ""))
  }

  return currency
}

export const pluraliseWord = (word, value, pluralCharacters = "s") => {
  if (value > 1 || value === 0) {
    const ayCheck = /([aeiou]{1}y)$/i
    if (
      word.substr(word.length - 1).toLowerCase() === "y" &&
      !ayCheck.test(word)
    ) {
      return word.substr(0, word.length - 1) + "ies"
    }

    return word + pluralCharacters
  }

  return word
}

export const getHashKey = (location) => {
  const { hash } = location
  if (hash && hash.length > 1) {
    return hash.substring(1, hash.length)
  }

  return null
}

export const getQueryObject = (search) => {
  const queries = search.substring(1)
  const queryArray = queries.split("&")

  const isValueArray = /\[(.+?)\]/g
  const getArrayValue = /(?!\[)(.+?)(?=\])/g

  let queryObject = {}
  queryArray.map((query) => {
    const parts = query.split("=")
    const key = parts[0]
    let value = parts[1]

    if (isValueArray.test(value)) {
      const arrayString = value.match(getArrayValue)[0]
      queryObject[key] = arrayString.split(",")
    } else {
      queryObject[key] = value
    }

    return query
  })

  return queryObject
}

export const updateAppState = (newState, contextKey) => {
  let state = JSON.parse(localStorage.getItem("app-state"))
  if (contextKey) {
    let updatedContextValue
    if (Array.isArray(newState)) {
      updatedContextValue = state[contextKey] || []

      if (updatedContextValue.length !== newState.length) {
        updatedContextValue = updatedContextValue.concat(newState)
      }
      state = {
        ...state,
        [contextKey]: updatedContextValue,
      }
    } else {
      updatedContextValue = state[contextKey] || {}
      state = {
        ...state,
        [contextKey]: {
          ...updatedContextValue,
          ...newState,
        },
      }
    }
  } else {
    state = {
      ...state,
      ...newState,
    }
  }
  localStorage.setItem("app-state", JSON.stringify(state))
}

export const cloneObject = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const updateTableData = (updatedEntry, context) => {
  let updatedData = context.tableProps.data
  if (updatedEntry.length > 0) {
    let updatedIds = updatedEntry.map((e) => e.id)
    updatedData = context.tableProps.data.map((entry) => {
      if (updatedIds.includes(entry.id)) {
        return updatedEntry.find((e) => e.id === entry.id)
      }
      return entry
    })
  } else {
    updatedData = context.tableProps.data.map((entry) => {
      if (updatedEntry.id === entry.id) {
        return updatedEntry
      }
      return entry
    })
  }
  return { tableProps: { data: updatedData } }
}

export const pushTableData = (updatedEntry, context) => {
  return { tableProps: { data: context.tableProps.data.concat(updatedEntry) } }
}

export const removeTableData = (removedEntries, context) => {
  const removedIds = removedEntries.map((r) => r.id)
  let updatedData = context.tableProps.data.filter((entry) => {
    return !removedIds.includes(entry.id)
  })
  return { tableProps: { data: updatedData } }
}

export const handleRequestResponse = (context, event) => {
  if (Array.isArray(event.data)) {
    let shouldRefreshPageData =
      event.data.findIndex((req) => req.refreshPageData) > -1

    let data = {
      refreshPageData: shouldRefreshPageData || false,
    }

    event.data.map((obj) => {
      if (!Array.isArray(obj.response)) {
        Object.entries(obj.response).map(([key, value]) => {
          if (obj.contextKey) {
            data = {
              ...data,
              ...setByContextKey(context, obj, data),
            }
          } else {
            data[key] = value
          }
          return [key, value]
        })
      } else {
        if (obj.contextKey) {
          data = {
            ...data,
            ...setByContextKey(context, obj, data),
          }
        }
      }
      return obj
    })

    return data
  }

  let stateResponse = {}
  if (event.data) {
    stateResponse = {
      refreshPageData: event.data.refreshPageData || false,
    }
    if (event.data.storeData === undefined || event.data.storeData) {
      if (event.data.response) {
        if (event.data.contextKey) {
          return setByContextKey(context, event.data)
        }

        stateResponse = {
          ...event.data.response,
          refreshPageData: event.data.refreshPageData || false,
        }
      }
    }
  }

  return stateResponse
}

export const handleMountRequest = (context, event) => {
  let organisation = {}
  let newRequestContext = handleRequestResponse(context, event)

  // Get organisations from previous context
  const currentOrgs = context.organisations
  // Get organisations from api request
  const updatedOrgs = newRequestContext.organisations

  if (
    Array.isArray(currentOrgs) &&
    Array.isArray(updatedOrgs) &&
    updatedOrgs.length > 0 &&
    currentOrgs.length > 0
  ) {
    // Check previous context for unapproved organisations
    const currentUnapprovedOrgs = currentOrgs.filter(
      (org) => !org.payoutsEnabled || !org.stripeConnection || org.stripeUrl
    )
    // Check request for unapproved organisations
    const updatedUnapprovedOrgs = updatedOrgs.filter(
      (org) => !org.payoutsEnabled || !org.stripeConnection || org.stripeUrl
    )

    // If previous context has unapproved orgs but the request has only approved orgs
    if (
      currentUnapprovedOrgs.length > 0 &&
      updatedUnapprovedOrgs.length === 0
    ) {
      // Should only ever be one unapproved org in context at a time
      const unapprovedOrg = currentUnapprovedOrgs[0]

      // Fetch the updated version of the unapproved org
      const newlyVerifiedOrgs = updatedOrgs.filter(
        (org) => org.id === unapprovedOrg.id
      )
      if (newlyVerifiedOrgs.length > 0) {
        organisation = newlyVerifiedOrgs[0]
      }
    }
  }

  // If no organisation found from the previous and updated organisations context
  if (!organisation.id) {
    // Set organisation in context to the latest one from the request
    organisation = newRequestContext.organisation
  }

  const newContext = {
    ...newRequestContext,
    organisation,
    tutorial: organisation ? organisation.tutorial : {},
  }
  updateAppState(newContext)

  const newUserContext = newRequestContext.user
  const existingUserContext = context.user

  // If user roles has updated to include affiliate, force the user to be redirected to the affiliate dashboard
  if (
    existingUserContext &&
    !existingUserContext.roles.includes("affiliate") &&
    newUserContext &&
    newUserContext.roles.includes("affiliate")
  ) {
    newContext.forceRedirect = "/affiliate"
  }

  return newContext
}

const setByContextKey = (
  context,
  { contextKey, response, refreshPageData = false },
  existingData = {}
) => {
  let keys = contextKey.split(".")

  if (keys.length > 1) {
    switch (keys.length) {
      case 2:
        return {
          refreshPageData,
          ...existingData,
          [keys[0]]: {
            ...context[keys[0]],
            ...existingData[keys[0]],
            [keys[1]]: response,
          },
        }
      case 3:
        return {
          refreshPageData,
          ...existingData,
          [keys[0]]: {
            ...context[keys[0]],
            ...existingData[keys[0]],
            [keys[1]]: {
              ...context[keys[0]][keys[1]],
              ...(existingData[keys[0]] ? existingData[keys[0]][keys[1]] : {}),
              [keys[2]]: response,
            },
          },
        }
      default:
        break
    }
  }

  if (Array.isArray(response)) {
    return {
      refreshPageData,
      [contextKey]: response,
    }
  }

  return {
    refreshPageData,
    [contextKey]: {
      ...context[contextKey],
      ...existingData[contextKey],
      ...response,
    },
  }
}

export const humaniseTimeSince = (date) => {
  if (!date) return "Never"

  const yearsDiff = moment(new Date()).diff(date, "years")
  if (yearsDiff >= 1) {
    return `${yearsDiff} ${pluraliseWord("year", yearsDiff)} ago`
  }
  const monthsDiff = moment(new Date()).diff(date, "months")
  if (monthsDiff >= 1) {
    return `${monthsDiff} ${pluraliseWord("month", monthsDiff)} ago`
  }
  const weeksDiff = moment(new Date()).diff(date, "weeks")
  if (weeksDiff >= 1) {
    return `${weeksDiff} ${pluraliseWord("week", weeksDiff)} ago`
  }
  const daysDiff = moment(new Date()).diff(date, "days")
  if (daysDiff >= 1) {
    return `${daysDiff} ${pluraliseWord("day", daysDiff)} ago`
  }
  const hoursDiff = moment(new Date()).diff(date, "hours")
  if (hoursDiff >= 1) {
    return `${hoursDiff} ${pluraliseWord("hour", hoursDiff)} ago`
  }
  const minutesDiff = moment(new Date()).diff(date, "minutes")
  if (minutesDiff >= 1) {
    return `${minutesDiff} ${pluraliseWord("minute", minutesDiff)} ago`
  }
  const secondsDiff = moment(new Date()).diff(date, "seconds")
  if (secondsDiff >= 1) {
    return `${secondsDiff} ${pluraliseWord("second", secondsDiff)} ago`
  }
  return "Just a moment ago"
}

export const getInvoiceTotal = (member, baseInvoice) => {
  if (!member.invoice) {
    return baseInvoice.lineItems.reduce(
      (a, b) => a + currencyToPennies(b.amount || 0),
      0
    )
  }

  return member.invoice.lineItems.reduce(
    (a, b) => a + currencyToPennies(b.amount || 0),
    0
  )
}

export const sortArray = (data, sortSettings) => {
  if (sortSettings) {
    let { sortKey, order = "asc", type = "string" } = sortSettings
    let sortFunction = (a, b) => {
      if (a[sortKey] && b[sortKey]) {
        if (order === "desc") {
          return b[sortKey].localeCompare(a[sortKey])
        }
        return a[sortKey].localeCompare(b[sortKey])
      }
      return 0
    }
    switch (type) {
      case "date":
        return data.sort((a, b) => {
          if (a[sortKey] && b[sortKey]) {
            if (order === "desc") {
              return moment(b[sortKey]).diff(a[sortKey])
            }
            return moment(a[sortKey]).diff(b[sortKey])
          }
          return 0
        })
      default:
        return data.sort(sortFunction)
    }
  }

  return data
}

export const statsExist = (stats) => {
  if (stats) {
    // Take a stats object and grab out all its values
    const totalStatValue = Object.values(stats).filter((value) => {
      // Filter out all non-numerical values and check to see if the existing values are greater than 0
      return !isNaN(value) && value > 0
    })

    // Check to see if there are any values
    return totalStatValue.length > 0
  }

  return false
}

export const filterOptionsToString = (options, key = "id") => {
  return options
    .filter((group) => group.checked)
    .map((group) => {
      if (key && group[key]) {
        if (typeof group[key] === "string") {
          return group[key].toLowerCase()
        }

        return group[key]
      }

      return group
    })
    .join(",")
}

export const handleStickyFilters = (event, refElement, stickyRefElement) => {
  let element = event.target
  let filterElement = refElement.current
  let stickyElement = stickyRefElement.current

  if (element.scrollTop >= filterElement.offsetTop) {
    if (stickyElement) {
      stickyElement.classList.remove("hidden")
    }
  } else {
    if (stickyElement && ![...stickyElement.classList].includes("hidden")) {
      stickyElement.classList.add("hidden")
    }
  }
}

export const constructQueryString = (queries, baseQuery = "") => {
  let queryArray = []
  if (baseQuery) {
    queryArray.push(baseQuery)
  }

  if (queries) {
    Object.entries(queries).map(([key, value]) => {
      if (
        (typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean") &&
        value
      ) {
        const encodedComponent = encodeURIComponent(value)
        if (encodedComponent !== value) {
          value = encodedComponent
        }

        queryArray.push(`${key}=${value}`)
      } else if (Array.isArray(value)) {
        queryArray.push(`${key}=${value.join(",")}`)
      } else if (value) {
        if (value.like) {
          queryArray.push(
            `${key}[like]=%25${value.like.replace(/\+/g, "%2B")}%25`
          )
        } else {
          const valueArray = Object.values(value).filter((value) => value)
          if (valueArray.length > 0) {
            queryArray.push(`${key}=${valueArray.join(",")}`)
          }
        }
      }

      return [key, value]
    })
  }

  return queryArray.length > 0 ? "?" + queryArray.join("&") : ""
}

export const handleFormSubmission = (validator, callback) => {
  if (validator.allValid()) {
    validator.hideMessages()
    callback()
  } else {
    validator.showMessages()
  }
}
