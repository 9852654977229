import React from "react"
import moment from "moment"

import Input from "../../components/Input"
import HelperRow from "../containers/HelperRow"
import Datepicker from "../Datepicker"

export default ({
  name,
  disabled,
  setState,
  dob = null,
  addressLine1,
  addressLine2,
  town,
  postcode,
  phoneNumber,
  contactInfoText = "We need these details to contact you about your club, for example if we have trouble paying money into the club’s account.",

  order = "horizontal",
}) => {
  let wrapperClass = "grid grid-cols-2 gap-lg"
  if (order !== "horizontal") {
    wrapperClass = ""
  }

  return (
    <div className={wrapperClass}>
      <div className={order === "horizontal" ? "" : "mb-md"}>
        <HelperRow
          title="Personal details"
          text="We only use your date of birth to establish that you are over 18."
          columns={order === "horizontal" ? 2 : 4}
          textCols={order === "horizontal" ? 1 : 2}
        >
          <div
            className={
              "grid grid-cols-1 gap-sm" +
              (order === "horizontal" ? " mr-xs" : "")
            }
          >
            <Input
              label="Full name"
              name="name"
              value={name}
              placeholder="Full name"
              onChange={setState}
              validation={{
                message: "Enter your name",
                rules: "required",
              }}
              disabled={disabled}
            />

            <div>
              <Datepicker
                date={moment(dob).isValid() ? moment(dob).toDate() : null}
                format="dd/MM/yyyy"
                onChange={(dob) =>
                  setState({ target: { name: "dob", value: dob } })
                }
                placeholder="Select date"
                popperPlacement="bottom"
                maxDate={moment().subtract(18, "years").toDate()}
                id="dob"
                name="dob"
                clearText="Clear date"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "0, 5px",
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
                disabled={disabled}
                customInput={
                  <Input
                    id="dob"
                    small
                    date={moment(dob).format("DD/MM/YYYY")}
                    label="Date of birth"
                    name="dob"
                    dateDisplay
                    showSelectChevron
                    mask="99/99/9999"
                    autocomplete="bday"
                    disabled={disabled}
                    validateOnBlur={false}
                    validation={{
                      name: "dob",
                      messages: {
                        required: "Enter your date of birth",
                        before_or_equal:
                          "You must be 18 years old to use Payzip",
                        date: "Enter a valid date",
                      },
                      rules: [
                        "required",
                        "date",
                        {
                          before_or_equal: moment().subtract(18, "years"),
                        },
                      ],
                    }}
                  />
                }
              />
            </div>
          </div>
        </HelperRow>
      </div>
      <div className={order === "horizontal" ? "ml-xs" : ""}>
        <HelperRow
          title="Contact information"
          text={contactInfoText}
          columns={order === "horizontal" ? 2 : 4}
          textCols={order === "horizontal" ? 1 : 2}
        >
          <div className="grid grid-cols-1 gap-sm">
            <Input
              label="Address"
              name="addressLine1"
              value={addressLine1}
              placeholder="Address 1"
              onChange={setState}
              validation={{
                message: "Enter your address",
                rules: "required",
              }}
              disabled={disabled}
            />
            {disabled && !addressLine2 ? (
              <React.Fragment />
            ) : (
              <Input
                name="addressLine2"
                value={addressLine2}
                placeholder="Address 2"
                onChange={setState}
                disabled={disabled}
              />
            )}
            <Input
              name="city"
              value={town}
              placeholder="Town"
              onChange={({ target: { value } }) =>
                setState({ target: { name: "town", value } })
              }
              validation={{
                message: "Enter your town",
                rules: "required",
              }}
              disabled={disabled}
            />
            <Input
              name="postcode"
              value={postcode}
              placeholder="Postcode"
              onChange={({ target: { name, value } }) =>
                setState({ target: { name, value: value.toUpperCase() } })
              }
              mask={[
                /[a-zA-Z]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9\s]/,
                /[a-zA-Z0-9\s]/,
                /[a-zA-Z0-9\s]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z]/,
                /[a-zA-Z]/,
              ]}
              validation={{
                messages: {
                  required: "Enter your postcode",
                  regex: "Enter a valid postcode",
                },
                rules: [
                  "required",
                  {
                    regex:
                      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                  },
                ],
              }}
              disabled={disabled}
            />
            <Input
              label="Mobile"
              name="phoneNumber"
              value={phoneNumber}
              placeholder="Mobile number"
              onChange={setState}
              autocomplete="tel"
              inputType="tel"
              validation={{
                messages: {
                  required: "Enter your mobile number",
                },
                rules: "required|mobile_number",
              }}
              disabled={disabled}
            />
          </div>
        </HelperRow>
      </div>
    </div>
  )
}
