import { assign } from "xstate"

import { updateAppState } from "../utils/functions"

export default {
  CLEAR_ERRORS: {
    actions: assign(() => {
      return {
        errors: {},
        errorRedirect: false,
      }
    }),
  },
  UPDATE_CONTEXT: {
    actions: assign((context, event) => {
      return {
        ...event,
      }
    }),
  },
  UPDATE_TUTORIAL_CONTEXT: {
    actions: assign((context, event) => {
      const updatedTutorial = {
        ...context.tutorial,
        ...event.tutorial,
      }
      return {
        organisation: {
          ...context.organisation,
          tutorial: updatedTutorial,
        },
        organisations: context.organisations.map((org) => {
          if (context.organisation.id === context.tutorial.organisationId) {
            org.tutorial = updatedTutorial
          }
          return org
        }),
        tutorial: updatedTutorial,
      }
    }),
  },
  UPDATE_APP_STATE: {
    actions: assign((context, event) => {
      updateAppState(event)

      return {
        ...event,
      }
    }),
  },
  LOGOUT: {
    actions: assign((context, event) => {
      localStorage.removeItem("app-state")

      return {
        user: undefined,
        token: undefined,
        errors: {},
        expandedMenu: undefined,
        stats: {},
        profile: {},
        overdueInvoices: [],
        invalidEmails: [],
        overdueInvoicesPaginationProps: {},
        invalidEmailsPaginationProps: {},
        tableProps: {
          ref: "",
          filters: [],
          data: [],
          search: "",
          page: 1,
        },
        ping: {},
        organisation: undefined,
        confirmation: {},
        tutorial: {},
        organisations: [],
        member: {},
        payout: {},
        batch: {},
        activity: [],
        groups: [],
        requests: null,
        team: [],
        invoice: undefined,
      }
    }),
  },
  UPDATE_TABLE_PROPS: {
    actions: assign((context, event) => {
      return {
        refreshPageData: false,
        tableProps: { ...context.tableProps, ...event.props },
      }
    }),
  },
  DUMMY: {
    actions: () => {},
  },
}
