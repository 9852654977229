import React from "react"

import Objective from "./Objective"
import ProgressBar from "./ProgressBar"

export default ({
  openTutorial,
  objectives,
  tutorialComplete,
  completeTutorial,
}) => {
  const toDoSteps = objectives.filter(({ done }) => !done)

  const progress = 100 - (100 / objectives.length) * toDoSteps.length
  if (!tutorialComplete) {
    return (
      <div className="pb-sm px-sm">
        <div className="bg-neutral-10 rounded p-sm text-neutral-1 relative onboarding-1 onboarding-6">
          <div className="mb-sm flex justify-between">
            <div onClick={() => openTutorial(1)} className="cursor-pointer">
              Getting started
            </div>
            <div
              onClick={completeTutorial}
              className={`text-neutral-5 text-right ${
                progress === 100 ? "cursor-pointer" : "cursor"
              }`}
            >
              {progress !== 100 ? `${progress}%` : "Close"}
            </div>
          </div>
          <div className="my-xs">
            <ProgressBar width={progress} />
          </div>
          <div className="mt-md grid grid-cols-1 gap-sm">
            {objectives.map((obj, index) => {
              return (
                <Objective
                  key={`objective-${index}`}
                  onClick={() => openTutorial(obj.step)}
                  complete={obj.done}
                  goal={obj.name}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  return <React.Fragment />
}
