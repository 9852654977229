import React from "react"
import Textarea from "../Input/Textarea"

const EditPingComment = ({ state, setState }) => {
  return (
    <Textarea
      onChange={({ target: { value } }) => setState({ note: value })}
      value={state.note}
      rows={4}
      classes={{
        input: "leading-sm",
      }}
    />
  )
}

export default EditPingComment
