import React from "react"

import UserLabel from "../UserLabel"
import { formatCurrency } from "../../utils/functions"

export default ({ withLabel = true, title, labelName, contextLabel, owed }) => (
  <div className="px-md">
    {withLabel && (
      <div className="font-medium py-sm">
        <UserLabel title={labelName} />
      </div>
    )}
    <h2>{title}</h2>
    <div>for {contextLabel}</div>
    <div className="pt-sm">
      <h1 className="inline-block leading-6 align-top">
        {formatCurrency(owed)}
      </h1>
    </div>
  </div>
)
