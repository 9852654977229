import React from 'react'
import Input from '../Input'

export default function ChangePassword({ state: { passwords }, setState }) {
  const updatePassword = ({ target: { name, value } }) => {
    setState({
      passwords: {
        ...passwords,
        [name]: value,
      },
    })
  }

  return (
    <div className="grid grid-cols-1 gap-md">
      <Input
        large
        label="Current password"
        name="currentPassword"
        value={passwords.currentPassword}
        type="password"
        placeholder="Current password"
        onChange={updatePassword}
        validation={{
          message: 'Enter your current password',
          rules: 'required',
        }}
      />
      <Input
        large
        label="New password"
        name="password"
        value={passwords.password}
        type="password"
        placeholder="New password (min. 12 characters)"
        onChange={updatePassword}
        allowPasswordToggle
        validation={{
          messages: {
            required: 'Enter a new password',
            min: 'Make sure you password is 12 characters long',
            not_regex: 'Password must not contain repeating characters',
          },
          rules: 'not_regex:.*(.)\\1{1,}.*|required|min:12',
        }}
      />
      <Input
        large
        name="confirmNewPassword"
        value={passwords.confirmNewPassword}
        type="password"
        placeholder="Confirm new password"
        onChange={updatePassword}
        allowPasswordToggle
        validation={{
          messages: {
            in: 'Passwords need to match',
            required: 'Confirm your new password',
            not_regex: 'Password must not contain repeating characters',
          },
          rules: `not_regex:.*(.)\\1{1,}.*|required|in:${passwords.password}`,
        }}
      />
    </div>
  )
}
