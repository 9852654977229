import React from "react"
import SkeletonPanel from "../../../components/Skeleton/Panel"
import SignupsSkeleton from "../Signups/Skeleton"

const AffiliateHomeSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-md">
      <SkeletonPanel panelCount={4} />
      <SignupsSkeleton />
    </div>
  )
}

export default AffiliateHomeSkeleton
