import React, { useState } from "react"

import Footer from "../../containers/Footer"
import Header from "../../containers/Header"
import Form from "../../Forms/Form"

const SheetSelection = ({ worksheets, setDroppedMembers, handleClose }) => {
  const [selectedWorksheet, setSelectedWorkSheet] = useState()

  const chooseWorksheet = (data) => {
    setSelectedWorkSheet(data)
  }

  return (
    <Form>
      <Header>Select worksheet</Header>
      <div className="bg-neutral-10 pb-md">
        <div className="p-md text-neutral-6">
          Please select the worksheet you would like to import:
        </div>
        <div className="bg-white">
          {worksheets.map((worksheet, index) => {
            return (
              <div
                key={`worksheet-${index}`}
                className="border-t last:border-b border-neutral-8"
              >
                <label className="flex items-center p-md">
                  <input
                    type="radio"
                    name="worksheet"
                    id={`worksheet-${index}`}
                    onClick={() => chooseWorksheet(worksheet)}
                    className="inline mr-sm"
                  />
                  {worksheet.name}
                </label>
              </div>
            )
          })}
        </div>
      </div>
      <Footer
        buttons={[
          { text: "Cancel", callback: handleClose },
          {
            type: "primary",
            callback: () => setDroppedMembers([selectedWorksheet]),
            iconType: "duotone",
            icon: "check-circle",
            text: "Select worksheet",
            disabled: !selectedWorksheet,
          },
        ]}
      />
    </Form>
  )
}

export default SheetSelection
