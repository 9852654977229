import React from "react"

export default () => {
  return (
    <div>
      <div className="pb-xs">
        When members receive their invoice, our experience shows that some will
        pay it straight away. In larger clubs, this can happen within a few
        minutes of sending the invoice! To see the payments made, click on{" "}
        <span className="font-medium">Payments</span> in the sidebar menu.
      </div>
      <div className="py-md my-sm">
        Get more help on payments in our{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.payzip.co.uk/category/18-payments"
        >
          knowledgebase
        </a>
        .
      </div>
      <div>
        <span className="font-medium">Tip</span>: when your club receives its
        first credit or debit card payment, we’ll send you an email to let you
        know.
      </div>
    </div>
  )
}
