import React, { useEffect } from "react"
import Route from "../LazyRoute"
import { destroyBeacon } from "../../utils/beacon"

export default ({
  path,
  exact = false,
  render,
  children,
  killBeacon = true,
}) => {
  useEffect(() => {
    if (killBeacon) {
      destroyBeacon()
    }
  }, [killBeacon])

  return (
    <Route path={path} exact={exact} render={render}>
      {children}
    </Route>
  )
}
