import React from "react"

import Icon from "../Icon"
import Popper from "../Popper"

export default ({
  text,
  className,
  icon = "info-circle",
  colours,
  onClick,
}) => {
  return (
    <Popper
      label={
        <Icon
          type="duotone"
          icon={icon}
          colours={colours}
          baseSize="20px"
          onClick={onClick}
        />
      }
      placement="bottom"
      fallbackPlacements={["top", "left", "right"]}
      backgroundColour="neutral-10"
      className={className}
    >
      <div className="text-sm text-neutral-1 grid gap-sm p-md sm:max-w-sm rounded">
        {Array.isArray(text)
          ? text.map((tip, index) => <div key={"tooltip-" + index}>{tip}</div>)
          : text}
      </div>
    </Popper>
  )
}
