import React from "react"
import Input from "../Input"

export default ({ state, setState }) => {
  return (
    <div className="grid gap-md">
      {state.associatedUser && (
        <div>
          This user has created their own Payzip account. Only they can change
          their email address. &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.payzip.co.uk/article/40-editing-member-details"
          >
            Learn more
          </a>
        </div>
      )}
      <Input
        large
        label="Name"
        placeholder="Name"
        name="name"
        value={state.name}
        onChange={({ target: { value } }) => setState({ name: value })}
        validation={{
          message: "Enter a member name",
          rules: "required",
        }}
      />
      <Input
        large
        label="Email"
        placeholder="Email"
        name="newEmail"
        value={state.newEmail !== undefined ? state.newEmail : state.email}
        onChange={({ target: { value } }) => setState({ newEmail: value })}
        validation={{
          message: "Enter a member email",
          rules: "required|email",
        }}
        disabled={state.associatedUser}
        icon={state.associatedUser ? "lock-alt" : ""}
      />
    </div>
  )
}
