import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "getPayout",
    payload: {
      url: ({ payoutId, filters }) =>
        `/payouts/${payoutId}/payments${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getPayoutStats",
    payload: {
      url: ({ filters, payoutId }) =>
        `/payouts/${payoutId}${constructQueryString(filters)}`,
      type: "get",
    },
  },
  {
    target: "getAffiliatePayout",
    payload: {
      url: ({ payoutId, filters }) =>
        `/affiliate/payouts/${payoutId}/payments${constructQueryString(
          filters
        )}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getAffiliatePayoutStats",
    payload: {
      url: ({ filters, payoutId }) =>
        `/affiliate/payouts/${payoutId}${constructQueryString(filters)}`,
      type: "get",
    },
  },
  {
    target: "getPayouts",
    payload: {
      url: ({ filters }) => `/payouts${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
]
