import React, { useState, useContext } from "react"
import { MachineContext } from "../../../state"

import Tooltip from "../../Tooltip"
import Footer from "../../containers/Footer"
import Header from "../../containers/Header"
import Form from "../../Forms/Form"

import Table from "../../Table"
import ColumnHeader from "./ColumnHeader"
import Checkbox from "../../Input/Checkbox"

export default ({
  members,
  uploadingMembers = [],
  columnCount,
  handleClose,
  setStep,
  setUpdatedCount,
  setValidMembers,
  setInvalidMembers,
  headers,
  setHeaders,
  ignoreFirstRow,
  setIgnoreFirstRow,
}) => {
  const [refresh, forceRefresh] = useState(0)
  const [current, send] = useContext(MachineContext)
  const [error, setValidationError] = useState({
    type: "",
    message: "",
  })

  const setColumnHeader = (option, index) => {
    Object.entries(headers).map(([key, column]) => {
      if (column.value === option.value && key !== index) {
        delete headers[key]
      }
      if (column.value !== option.value) {
        headers[key] = column
      }
      return [key, column]
    })

    if (error.type === option.value) {
      setValidationError({
        type: "",
        message: "",
      })
    }

    if (headers[index] && headers[index].value === option.value) {
      delete headers[index]
    } else {
      headers[index] = option
    }

    Object.entries(headers).map(([key, column]) => {
      if (
        (option.value === "name" &&
          (column.value === "first_name" || column.value === "last_name")) ||
        (column.value === "name" &&
          (option.value === "first_name" || option.value === "last_name"))
      ) {
        delete headers[key]
      }
      return [key, column]
    })

    setHeaders(headers)
    forceRefresh((count) => count + 1)
  }

  const setFilteredMembers = () => {
    const trimmedMembers = ignoreFirstRow ? members.slice(1) : members
    const uploadMembers = trimmedMembers.map((mem) => {
      let memObject = {
        name: "",
        email: "",
        groups: [],
      }
      const valueArray = Object.values(mem)

      Object.keys(headers).map((key) => {
        const value = valueArray[key]

        const keyValue = headers[key].value

        switch (keyValue) {
          case "groups":
            memObject[keyValue] = [value]
            break
          default:
            memObject[keyValue] = value
            break
        }

        return key
      })

      if ((memObject.first_name || memObject.last_name) && !memObject.name) {
        memObject.name = `${memObject.first_name || ""} ${
          memObject.last_name || ""
        }`
      }

      return {
        name: memObject.name,
        email: memObject.email,
        groups: memObject.groups || [],
      }
    })
    send("checkForExistingMembers", {
      data: {
        variables: {
          organisationId: current.context.organisation.id,
        },
        body: uploadMembers,
        onSuccess: {
          callback: ({ response }) => {
            setUpdatedCount(response.count)

            // Pattern found at http://emailregex.com/
            const emailRegex = RegExp(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
            const validMembers = uploadMembers.filter((member) => {
              return (
                emailRegex.test(member.email) &&
                (member.name || (member.first_name && member.last_name))
              )
            })
            const invalidMembers = uploadMembers
              .filter((member) => {
                return (
                  !emailRegex.test(member.email) ||
                  !(member.name || (member.first_name && member.last_name))
                )
              })
              .map((member) => {
                let reason = "Reason: "
                if (!member.email) {
                  reason = reason + "No email"
                } else if (!emailRegex.test(member.email)) {
                  reason = reason + "Invalid email"
                } else if (!member.name) {
                  reason = reason + "No name"
                }
                return {
                  ...member,
                  reason,
                }
              })

            if (!validMembers.length) {
              setValidationError({
                type: "email",
                message:
                  "No emails found in the column, please select another.",
              })
            } else {
              setValidMembers(validMembers)
              setInvalidMembers(invalidMembers)
              setStep(4)
            }
          },
        },
      },
    })
  }

  return (
    <Form>
      <Header
        actions={[
          {
            render: (
              <Checkbox
                label="Ignore first row"
                name="ignoreFirstRow"
                labelStyle="text-neutral-1"
                withGrid={false}
                onChange={() => {
                  setIgnoreFirstRow(!ignoreFirstRow)
                }}
                checked={ignoreFirstRow}
                withGrid={false}
                checkboxMargin="mr-xs"
              />
            ),
          },
        ]}
      >
        <div>
          Choose columns to import{" "}
          {
            <Tooltip
              text={[
                "Choose which columns hold your members' names and email addresses. Names can be either a full name in one column, or first and last names in two separate columns.",
                "If your file already has group names against each member, choose the groups column and Payzip will import the members into those groups for you too.",
                <div>
                  For more information, see this{" "}
                  <a
                    target="_blank"
                    className="link"
                    href="http://docs.payzip.co.uk/article/35-adding-members#importing"
                    rel="noreferrer noopener"
                  >
                    knowledgebase article
                  </a>
                </div>,
              ]}
            />
          }
        </div>
      </Header>
      <div className="overflow-x-auto bg-white sm:max-h-modal-body modal-body-mobile">
        {error.type && (
          <div className="p-md text-danger-5 bg-neutral-10 shadow-b-border">
            {error.message}
          </div>
        )}
        <Table
          disableLoadingSpinner
          withShadow={false}
          headers={[...Array(columnCount)].map((key, index) => {
            return {
              customHeader: (
                <ColumnHeader
                  columnCount={columnCount}
                  index={index}
                  columnHeaders={headers}
                  setColumnHeader={setColumnHeader}
                  refresh={refresh}
                  error={error}
                />
              ),
              key: index,
            }
          })}
          withHoverStyle={false}
          data={uploadingMembers}
        />
      </div>
      <Footer
        footerText={
          members && members.length > 0
            ? members.length > 8
              ? `Showing 8 of ${members.length} rows`
              : `${members.length} rows`
            : ""
        }
        buttons={[
          { callback: () => handleClose(), text: "Cancel" },
          {
            type: "primary",
            callback: setFilteredMembers,
            iconType: "duotone",
            icon: "arrow-circle-right",
            text: "Continue",
            submitting:
              current.matches("app.requests.addMembers.requesting") ||
              current.matches(
                "app.requests.checkForExistingMembers.requesting"
              ),
          },
        ]}
      />
    </Form>
  )
}
