import moment from "moment"
import { pluraliseWord } from "../functions"

export const cannotDeleteInvoiceConfirmation = (send) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "cannotDeleteInvoiceConfirmation",
      title: "Cannot delete invoice",
      body: [
        "Invoices that have been paid in full, part-paid or credited cannot be deleted.",
        "Remove any manual payments or credits and try again.",
      ],
      buttons: [
        {
          text: "Okay",
          type: "primary",
          iconType: "duotone",
          icon: "check-circle",
          callback: () => send("CLOSE_CONFIRMATION"),
        },
      ],
    },
  })
}

export const deleteInvoiceConfirmation = (send, member, invoice, history) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "deleteInvoice",
      title: "Delete invoice",
      body: [
        `You are about to delete an invoice for  ${member.name}`,
        "Payment links in previously sent invoices will no longer work. This action cannot be undone",
      ],
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Delete invoice",
          type: "danger",
          icon: "exclamation-circle",
          iconType: "duotone",
          colWidth: 7,
          submitting: "app.requests.deleteInvoice.requesting",
          callback: () => {
            send("deleteInvoice", {
              data: {
                variables: {
                  batchId: invoice.metadata.batchId,
                  invoiceId: invoice.id,
                  memberId: invoice.metadata.memberId,
                },
                onSuccess: {
                  notification: {
                    title: "Invoice deleted",
                  },
                  target: "CLOSE_MODAL",
                  callback: ({ response }) => {
                    if (response.recipients === 0) {
                      history.push("/invoices")
                    }
                  },
                },
                onError: {
                  notification: (response) => {
                    if (response.data.message !== "Payments against invoice") {
                      return {
                        title: "Whoops",
                        description: "Something went wrong",
                      }
                    }
                    return {}
                  },
                  callback: (response) => {
                    if (response.data.message === "Payments against invoice") {
                      cannotDeleteInvoiceConfirmation(send)
                    }
                  },
                },
              },
            })
          },
        },
      ],
    },
  })
}

export const sendInvoicesConfirmation = (send, invoices, submitCallback) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "confirmSendingInvoices",
      title: `Send ${invoices.length} ${pluraliseWord(
        "invoice",
        invoices.length
      )}`,
      body: `Are you sure you want to send ${invoices.length} ${pluraliseWord(
        "invoice",
        invoices.length
      )} now?`,
      buttons: [
        {
          text: "Back",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Send now",
          type: "primary",
          icon: "paper-plane",
          colWidth: 7,
          callback: () => submitCallback(),
        },
      ],
    },
  })
}

export const cannotEditInvoiceConfirmation = (send) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "cannotEditInvoice",
      title: "Cannot edit invoice",
      body: [
        "This invoice cannot be edited.",
        "Remove the payment or credit and try again.",
      ],
      buttons: [
        {
          text: "Okay",
          type: "primary",
          icon: "check-circle",
          iconType: "duotone",
          callback: () => send("CLOSE_CONFIRMATION"),
        },
      ],
    },
  })
}

export const cannotSendIndividualReminderConfirmation = (
  send,
  invoice,
  newInvoice = false
) => {
  const resendTime = moment(invoice.lastReminderDate)
    .add(30, "minutes")
    .format("HH:mm")
  let response =
    "A reminder was sent less than 30mins ago. You can send again at"
  if (newInvoice) {
    response =
      "This invoice was sent less than 30mins ago. You can send again at "
  }

  send("OPEN_CONFIRMATION", {
    payload: {
      id: "cannotSendIndividualReminder",
      title: "Cannot send reminder",
      body: `${response} ${resendTime}.`,
      buttons: [
        {
          text: "Okay",
          type: "primary",
          icon: "check-circle",
          iconType: "duotone",
          callback: () => send("CLOSE_CONFIRMATION"),
        },
      ],
    },
  })
}

export const sendIndividualReminderConfirmation = (send, member, invoice) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "sendIndividualReminder",
      title: "Send reminder",
      body: `Send a reminder to ${member.name}`,
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Send reminder",
          type: "primary",
          colWidth: 7,
          icon: "paper-plane",
          submitting:
            "app.requests.sendBatchReminders.requesting|app.requests.getInvoiceDetails.requesting",
          callback: () => {
            send("sendBatchReminders", {
              data: {
                body: {
                  members: [member.id],
                },
                variables: {
                  batchId: invoice.metadata.batchId,
                },
                onSuccess: {
                  callback: ({ response }, context) => {
                    send("getInvoiceDetails", {
                      data: {
                        variables: {
                          organisationId: context.organisation.id,
                          batchId: invoice.metadata.batchId,
                          memberId: member.id,
                        },
                        storeData: false,
                        onSuccess: {
                          target: "CLOSE_CONFIRMATION",
                          callback: ({ response }) => {
                            send("UPDATE_MODAL_STATE", {
                              payload: {
                                state: {
                                  invoice: {
                                    ...response.invoice,
                                    lastReminderDate: new Date(),
                                  },
                                },
                              },
                            })
                          },
                        },
                      },
                    })
                  },
                  notification: {
                    title: "1 reminder sent",
                  },
                },
                onError: {
                  notification: {
                    title: "Whoops",
                    description: "Something went wrong",
                  },
                },
              },
            })
          },
        },
      ],
    },
  })
}

export const saveChangesConfirmation = (
  id,
  setState,
  resetInvoice,
  validator,
  send,
  {
    currentId,
    baseInvoice,
    selectedId,
    customInvoice,
    member,
    members,
    callback,
  }
) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id,
      title: "Save changes",
      body: `Save changes to ${member.name}?`,
      buttons: [
        {
          text: "Discard",
          colWidth: 4,
          callback: () => {
            setState({ selectedId: currentId })

            resetInvoice()
            send("CLOSE_CONFIRMATION")
          },
        },
        {
          text: "Save changes",
          type: "primary",
          colWidth: 7,
          iconType: "duotone",
          icon: "check-circle",
          callback: () => {
            setState({
              invoice: {
                ...baseInvoice,
                members: members.map((member) => {
                  if (member.id === selectedId) {
                    member.invoice = {
                      ...customInvoice,
                      lineItems: customInvoice.lineItems.filter(
                        (item) => item.description && item.amount
                      ),
                    }
                  }
                  return member
                }),
              },
              selectedId: currentId,
            })
            validator.hideMessages()

            if (callback) {
              callback()
            } else {
              send("CLOSE_CONFIRMATION")
            }
          },
        },
      ],
    },
  })
}

export const removeMemberConfirmation = (
  setState,
  send,
  { member, members, baseInvoice, selectedId }
) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "removeMemberFromInvoice",
      title: "Delete invoice",
      body: `Delete invoice for ${member.name}?`,
      buttons: [
        {
          text: "Cancel",
          colWidth: 4,
          callback: () => send("CLOSE_CONFIRMATION"),
        },
        {
          text: "Delete invoice",
          type: "danger",
          colWidth: 7,
          iconType: "duotone",
          icon: "exclamation-circle",
          callback: () => {
            const updatedList = members.filter(({ id }) => id !== selectedId)
            setState({
              selectedId: updatedList.length ? updatedList[0].id : null,
              invoice: {
                ...baseInvoice,
              },
              members: updatedList,
            })
            send("CLOSE_CONFIRMATION")
          },
        },
      ],
    },
  })
}
