import React, {
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
  useRef,
} from "react"

import { useEventListener } from "../../utils/hooks"
import UserLabel from "../UserLabel"
import Icon from "../Icon"

export default forwardRef(
  (
    {
      title,
      icon,
      children,
      arrow,
      withChevron = true,
      truncateLabel = false,
      truncateWidth,
      iconBackgroundColour,
    },
    ref
  ) => {
    const [state, setState] = useState(false)
    const menuEl = useRef()

    const checkClickPosition = useCallback(
      (event) => {
        if (
          menuEl &&
          menuEl.current &&
          !menuEl.current.contains(event.target)
        ) {
          setState(false)
        }
      },
      [setState, menuEl]
    )

    useImperativeHandle(ref, () => ({
      close() {
        setState(false)
      },
    }))

    useEventListener("mousedown", checkClickPosition)

    let arrowPosition
    let dropwdownPosition
    switch (arrow) {
      case "right":
        arrowPosition = "right-lg"
        dropwdownPosition = "-right-xs"
        break
      case "left":
      default:
        arrowPosition = "left-xs"
        dropwdownPosition = "-left-xs"
        break
    }

    return (
      <div ref={menuEl} className="cursor-pointer">
        <div className="sm:block hidden">
          <div className="inline-block">
            <UserLabel
              title={title}
              icon={icon}
              iconBackground={iconBackgroundColour}
              onClick={() => setState(!state)}
              truncateLabel={truncateLabel}
              truncateWidth={truncateWidth}
            />
          </div>
          {withChevron && (
            <Icon
              type="solid"
              icon="chevron-down"
              className="inline mx-xs"
              onClick={() => setState(!state)}
            />
          )}
        </div>
        <Icon
          icon="bars"
          baseSize="1.571rem"
          type="solid"
          className="text-neutral-1 absolute top-0 right-0 p-md sm:hidden block"
          onClick={() => setState(!state)}
        />
        {state && (
          <div className="sm:relative fixed inset-0 z-20">
            <div className={`absolute dropdown-arrow ${arrowPosition} mt-xs`}>
              <div
                className={`absolute bg-white transform rotate-45 w-base h-base top-0 dropdown-shadow`}
              />
            </div>
            <div
              className={`menu absolute sm:mt-sm bg-white sm:rounded dropdown-shadow sm:min-w-sm sm:${dropwdownPosition} sm:w-auto w-full sm:h-auto h-full`}
            >
              <Icon
                icon="times"
                textPosition="right"
                baseSize="1.571rem"
                type="solid"
                className="text-neutral-1 p-md sm:hidden block"
                onClick={() => setState(!state)}
              />
              {children}
            </div>
          </div>
        )}
      </div>
    )
  }
)
