import React from 'react'
import InputMask from 'react-input-mask'

export default ({
  display,
  useCustomTime,
  time,
  customTime,
  updateTime,
  toggleCustomTime,
  updateWithCustomTime
}) => {
  if (!display) return <React.Fragment />

  return (
    <div className="bg-neutral-10 py-sm border-t border-b border-neutral-8 px-md mt-md">
      <div className="w-full text-center text-neutral-5 mb-sm">
        Choose send time
      </div>
      <div className="grid grid-cols-3 gap-sm mb-sm">
        <label htmlFor="07:00">
          <input
            checked={ time === "07:00" }
            onChange={ updateTime }
            className="mr-sm"
            id="07:00"
            type="radio"
            name="time"
            value="07:00"
          />
          07:00
        </label>
        <label htmlFor="12:00">
          <input
            checked={ time === "12:00" }
            onChange={ updateTime }
            className="mr-sm"
            id="12:00"
            type="radio"
            name="time"
            value="12:00"
          />
          12:00
        </label>
        <label htmlFor="18:00">
          <input
            checked={ time === "18:00" }
            onChange={ updateTime }
            className="mr-sm"
            id="18:00"
            type="radio"
            name="time"
            value="18:00"
          />
          18:00
        </label>
      </div>
      <div className="grid grid-cols-3 items-center">
        <label htmlFor="custom">
          <input
            onChange={ toggleCustomTime }
            checked={ useCustomTime }
            className="mr-sm"
            id="custom"
            type="radio"
            name="time"
          />
          Custom
        </label>
        <div className="row-span-1 mb-xs">
          <InputMask
            mask="99:99"
            value={ customTime }
            onChange={ updateWithCustomTime }
            maskPlaceholder={ null }
          >
            <input
              value={ customTime }
              placeholder={ time || '12:00' }
              className="border border-neutral-8 rounded px-sm py-xs w-9/12 text-center"
            />
          </InputMask>
        </div>
      </div>
    </div>
  )
}
