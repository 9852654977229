import React, { useContext } from "react"
import { MachineContext } from "../../state"
import { openChat } from "../../utils/beacon"
import Button from "../Button"
import Banner from "../containers/Banner"

export default function UnreachableServerBanner({ className }) {
  const [current, send] = useContext(MachineContext)

  const handleRetryingEvent = () => {
    const previousEvent = current.context.errorBanner.previousEvent

    if (previousEvent) {
      // Handle events that have multiple requests
      if (Array.isArray(previousEvent.data)) {
        const requestBody = {
          data: {
            onSuccess: previousEvent.onSuccess,
            onError: previousEvent.onError,
          },
        }
        // transform request body to a format useable by our request handler
        previousEvent.data.map((request) => {
          requestBody.data[request.id] = request
          return request
        })
        send(previousEvent.type, {
          ...previousEvent,
          ...requestBody,
        })
      } else {
        send(previousEvent.type, previousEvent)
      }
    }
  }

  if (
    current.context.errorBanner &&
    current.context.errorBanner.previousEvent
  ) {
    return (
      <Banner className={className}>
        <div>
          We had trouble contacting the Payzip server.&nbsp;
          {current.context.errorBanner.previousEvent &&
            current.context.errorBanner.previousEvent.data && (
              <span>
                You can&nbsp;
                {
                  <Button padding="0" onClick={() => handleRetryingEvent()}>
                    try again
                  </Button>
                }
                &nbsp;or&nbsp;
                {
                  <Button padding="0" onClick={() => send("LOGOUT")}>
                    log back in
                  </Button>
                }
                .&nbsp;
              </span>
            )}
          If the issue persists, please{" "}
          <span className="link" onClick={() => openChat()}>
            let us know
          </span>
          .
        </div>
      </Banner>
    )
  }

  return <React.Fragment />
}
