import React from "react"

export default ({ count }) => (
  <div className="p-md overflow-y-auto sm:max-h-modal-body max-h-modal-mobile bg-neutral-10 text-neutral-5 grid gap-sm">
    <div>You can only edit {count} invoices.</div>
    <div>
      Invoices that have been paid in full, part-paid or previously edited will
      not be updated.
    </div>
    <div>
      For more information on editing an invoice, see the{" "}
      <a
        href="https://docs.payzip.co.uk/article/45-editing-an-invoice"
        target="_blank"
        rel="noreferrer noopener"
      >
        knowledgebase
      </a>
      .
    </div>
  </div>
)
