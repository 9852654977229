import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import moment from "moment"

import Footer from "../containers/Footer"
import Table from "../Table"
import Form from "../Forms/Form"
import { formatCurrency, pluraliseWord } from "../../utils/functions"
import Icon from "../Icon"
import Tag from "../Tag"
import Header from "../containers/Header"
import { MachineContext } from "../../state"

export default forwardRef(
  ({ send, state = {}, modalData, handleClose }, ref) => {
    const [current] = useContext(MachineContext)
    const [selectedRows, setSelectedRows] = useState(
      modalData.data.filter((row) => {
        return (
          state.allowResending ||
          (!row.ignoreReminders &&
            (row.member
              ? !row.member.undeliverable && !row.member.emailIssue
              : !row.undelivered && row.statusId !== 6))
        )
      })
    )
    const [data, setData] = useState([])
    const [selectableDataCount, setSelectableDataCount] = useState([])

    const checkInvoiceForErrors = useCallback((invoice) => {
      if (invoice.member) {
        return invoice.member.undeliverable || invoice.member.emailIssue
      }

      return invoice.undelivered || invoice.statusId === 6
    }, [])

    useEffect(() => {
      setSelectedRows(
        modalData.data.filter(
          (row) =>
            state.allowResending ||
            (!row.ignoreReminders &&
              !row.lastReminderDate &&
              !row.datePaid &&
              !checkInvoiceForErrors(row))
        )
      )
      setData(modalData.data.filter((row) => !row.datePaid))
      setSelectableDataCount(
        modalData.data.filter(
          (row) =>
            state.allowResending ||
            (!row.ignoreReminders &&
              !row.lastReminderDate &&
              !row.datePaid &&
              !checkInvoiceForErrors(row))
        ).length
      )
    }, [modalData.data, state, state.allowResending, checkInvoiceForErrors])

    const sendReminders = () => {
      let title = `${selectedRows.length} ${pluraliseWord(
        "reminder",
        selectedRows.length
      )} sent`

      if (state && state.resentInvoices) {
        title = `${selectedRows.length} ${pluraliseWord(
          "invoice",
          selectedRows.length
        )} re-sent`
      }

      const onSuccess = {
        notification: {
          title,
        },
        callback: () => {
          send("CLOSE_MODAL")
          send("CLOSE_CONFIRMATION")
        },
      }

      if (state.memberId) {
        send("sendMemberReminders", {
          data: {
            variables: {
              memberId: state.memberId,
              resentInvoices:
                state && state.resentInvoices ? state.resentInvoices : false,
            },
            body: {
              invoices: selectedRows.map((row) => row.metadata.batchId),
            },
            onSuccess,
          },
        })
      }

      if (state.batchId) {
        send("sendBatchReminders", {
          data: {
            variables: {
              batchId: state.batchId,
              resentInvoices:
                state && state.resentInvoices ? state.resentInvoices : false,
            },
            body: {
              members: selectedRows.map((row) => row.metadata.memberId),
            },
            onSuccess,
          },
        })
      }

      if (!state.memberId && !state.batchId && state.updatedMember) {
        const reminders = selectedRows.map((invoice) => invoice.metadata)
        if (reminders.length) {
          send("sendRemindersList", {
            data: {
              body: reminders.map((entry) => {
                return {
                  batchId: entry.batchId,
                  memberId: entry.memberId,
                }
              }),
              variables: {
                organisationId: current.context.organisation.id,
                resentInvoices: state.updatedMember,
              },
              onSuccess,
            },
          })
        }
      }
    }

    const selectRow = (row) => {
      setSelectedRows((rows) => {
        if (rows.filter((current) => current.id === row.id).length === 0) {
          return rows.concat(row)
        } else {
          return rows.filter((current) => current.id !== row.id)
        }
      })
    }

    const selectAll = (rows) => {
      setSelectedRows(
        rows.filter(
          (data) =>
            state.allowResending ||
            (!data.ignoreReminders &&
              !data.lastReminderDate &&
              !checkInvoiceForErrors(data))
        )
      )
    }

    const renderRowTitle = (row) => {
      const newlyCreated = moment().isBefore(
        moment(row.dateSent).add(30, "minutes")
      )
      const reminderSentRecently =
        newlyCreated &&
        row.member &&
        !row.member.undeliverable &&
        !row.member.emailIssue
          ? "- invoice sent recently"
          : row.lastReminderDate
          ? "- reminder sent recently"
          : ""
      if (state.invoiceReminder) {
        return (
          <div
            className={
              row.member.undeliverable || row.undelivered
                ? "text-danger-5"
                : row.member.emailIssue || row.statusId === 6
                ? "text-warning-6"
                : ""
            }
          >
            {row.member.name} {reminderSentRecently}
          </div>
        )
      }
      return (
        <div>
          <div
            className={
              "inline " +
              ((row.member && row.member.undeliverable) || row.undelivered
                ? "text-danger-5"
                : (row.member && row.member.emailIssue) || row.statusId === 6
                ? "text-warning-6"
                : "")
            }
          >
            {row.title} {state.updatedMember ? "" : reminderSentRecently}
          </div>
          {row.overdue && row.owed > 0 && (
            <Tag
              type="danger"
              className="ml-sm text-sm leading-relaxed my-auto"
            >
              Overdue
            </Tag>
          )}
        </div>
      )
    }

    return (
      <Form className="max-h-full sm:max-w-md w-full mx-auto">
        <Header
          title={
            state.updatedMember
              ? `Resend ${pluraliseWord("invoice", data.length)}`
              : `Send ${pluraliseWord("reminder", data.length)}`
          }
        />
        {data.length ? (
          <div>
            <div className="bg-neutral-10 max-h-modal-body overflow-y-auto">
              {(state.updatedMember || state.newEmail) && (
                <div className="p-md shadow-b-border text-neutral-5">
                  There {data.length === 1 ? "is" : "are"} {data.length}{" "}
                  outstanding {pluraliseWord("invoice", data.length)}. We'll
                  resend the {pluraliseWord("invoice", data.length)} ticked
                  below to {state.newEmail}.
                </div>
              )}
              <Table
                disableLoadingSpinner
                withShadow={false}
                onSelectAll={selectAll}
                onRowSelect={selectRow}
                onRowDeselect={selectRow}
                hasRounding={false}
                selectable
                isDangerRow={(row) => row.overdue && row.owed > 0}
                selectedRows={selectedRows}
                allSelected={
                  selectedRows.length > 0 &&
                  selectableDataCount === selectedRows.length
                }
                headers={[
                  {
                    name: state.invoiceReminder ? "Member" : "Invoice",
                    render: renderRowTitle,
                  },
                  {
                    name: !state.id && state.updatedMember ? "Member" : "Owed",
                    render: (row) =>
                      !state.id && state.updatedMember && row.member
                        ? row.member.name
                        : formatCurrency(row.owed),
                    right: !state.updatedMember,
                  },
                ]}
                data={data.map((row) => {
                  let unselectableIcon = (
                    <Icon type="duotone" icon="bell-slash" baseSize="20px" />
                  )
                  if (row.lastReminderDate) {
                    unselectableIcon = (
                      <Icon type="duotone" icon="clock" baseSize="1.429rem" />
                    )
                  }
                  if (checkInvoiceForErrors(row)) {
                    unselectableIcon = (
                      <Icon
                        type="duotone"
                        icon="exclamation-circle"
                        baseSize="1.176rem"
                        colours={{
                          primary: "#FFEEEE",
                          secondary:
                            (row.member && row.member.undeliverable) ||
                            row.undeliverable
                              ? "#BA2525"
                              : "#F0B429",
                        }}
                      />
                    )
                  }
                  return {
                    ...row,
                    selected: selectedRows.map(({ id }) => id).includes(row.id),
                    isSelectable:
                      state.allowResending ||
                      (!row.ignoreReminders &&
                        !row.lastReminderDate &&
                        !checkInvoiceForErrors(row)),
                    unselectableIcon,
                  }
                })}
              />
            </div>
            <Footer
              buttons={[
                {
                  callback: () => handleClose(),
                  text: state.updatedMember ? "Don't send" : "Cancel",
                },
                {
                  type: "primary",
                  callback: sendReminders,
                  icon: "paper-plane",
                  text: state.updatedMember
                    ? `Resend${
                        selectedRows.length
                          ? ` ${selectedRows.length} ${pluraliseWord(
                              "invoice",
                              selectedRows.length
                            )}`
                          : " invoices"
                      }`
                    : `Send${
                        selectedRows.length
                          ? ` ${selectedRows.length} ${pluraliseWord(
                              "reminder",
                              selectedRows.length
                            )}`
                          : " reminders"
                      }`,
                  disabled: selectedRows.length === 0,
                  layer: true,
                  submitting:
                    current.matches(
                      "app.requests.sendMemberReminders.requesting"
                    ) ||
                    current.matches(
                      "app.requests.sendBatchReminders.requesting"
                    ) ||
                    current.matches(
                      "app.requests.sendRemindersList.requesting"
                    ),
                  background: selectedRows.length === 0 ? "bg-neutral-10" : "",
                },
              ]}
            />
          </div>
        ) : (
          <div>
            <div className="bg-neutral-10 max-h-modal-body overflow-y-auto py-2xl text-center text-neutral-8">
              This member has no outstanding invoices.
            </div>
            <Footer
              buttons={[
                {
                  type: "primary",
                  callback: () => handleClose(),
                  icon: "check-circle",
                  iconType: "duotone",
                  text: "Okay",
                },
              ]}
            />
          </div>
        )}
      </Form>
    )
  }
)
