import React from "react"
import moment from "moment"

import Button from "../Button"

import { formatCurrency } from "../../utils/functions"
import Tag from "../Tag"

export default ({
  lineItems = [],
  payments = [],
  credits = [],
  canRemove = false,
  removePayment = () => {},
  removeCredit = () => {},
  owed,
  isAdmin = false,
  custom = false,
}) => {
  return (
    <table className="bg-white w-full">
      <thead className="border-b border-neutral-8">
        <tr>
          <th className="text-left px-md py-sm font-medium">Item</th>
          <th className="text-right px-md font-medium">
            {isAdmin && custom && (
              <Tag type="warning" className="text-sm mr-sm">
                Custom
              </Tag>
            )}
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {lineItems.map((item, index) => (
          <tr key={"item" + index} className="px-md py-sm">
            <td className="px-md py-sm">{item.description}</td>
            <td className="text-right px-md">{formatCurrency(item.amount)}</td>
          </tr>
        ))}
        {credits.map((credit, index) => (
          <tr key={"item" + index} className="px-md py-sm">
            <td className="px-md py-sm">
              Credit: {credit.reason}
              {canRemove ? (
                <Button
                  customStyle="px-sm"
                  onClick={() => removeCredit(credit)}
                >
                  Remove
                </Button>
              ) : (
                <></>
              )}
            </td>
            <td className="text-right px-md">
              {formatCurrency(credit.amount)}
            </td>
          </tr>
        ))}
        {payments.length > 0 &&
          payments.map((payment, index) => (
            <tr key={"pament" + index} className="px-md py-sm">
              <td className="px-md py-sm">
                Paid by {payment.paymentMethod} on{" "}
                {moment(payment.paymentDate).format(
                  payment.paymentMethod.toLowerCase() === "card"
                    ? "DD MMM YYYY HH:mm"
                    : "DD MMM YYYY"
                )}
                {/* TODO: REMOVE BUTTON FOR UNAUTHED/MEMBER USERS */}
                {payment.paymentMethod.toLowerCase() !== "card" && canRemove && (
                  <Button
                    customStyle="px-sm"
                    onClick={() => removePayment(payment)}
                  >
                    Remove
                  </Button>
                )}
              </td>
              <td className="text-right px-md">
                -{formatCurrency(payment.amount)}
              </td>
            </tr>
          ))}
        <tr
          key="totalPaid"
          className="bg-neutral-10 border-t border-b border-neutral-8 px-md py-sm"
        >
          <td className="px-md py-sm font-medium">Amount due</td>
          <td className="text-right px-md font-medium">
            {formatCurrency(owed)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
