import React from "react"

import Input from "../Input"
import Icon from "../Icon"

export default ({
  setState,
  onBlur,
  removeItem,
  description = "",
  amount = "",
  showBin,
  index,
  validateRow = false,
}) => {
  return (
    <div>
      {index === 0 && (
        <div className="grid grid-cols-12 gap-sm text-neutral-5 font-medium mb-xs">
          <div className="col-span-7">Item</div>
          <div className="col-span-4 text-right">Amount</div>
        </div>
      )}

      <div className="grid grid-cols-12 gap-sm">
        <div className="col-span-7">
          <Input
            small
            name="description"
            value={description}
            placeholder="Description"
            onChange={setState}
            onBlur={onBlur}
            classes={{
              input: "min-w-2/3",
            }}
            validation={
              validateRow
                ? {
                    name: `${index}.lineItem.description`,
                    message: "Enter a description",
                    rules: "required",
                  }
                : false
            }
          />
        </div>
        <div className="col-span-4">
          <Input
            small
            name="amount"
            placeholder="£0.00"
            allowNegativeValue
            value={amount}
            onChange={setState}
            onBlur={onBlur}
            type="currency"
            maskPattern="^-?\d+.?[0-9]{0,2}$"
            classes={{
              input: "text-right min-w-1/4",
              label: "text-right",
            }}
            validation={
              validateRow
                ? {
                    name: `${index}.lineItem.amount`,
                    message: "Enter amount",
                    rules: "required",
                  }
                : false
            }
          />
        </div>
        {showBin && (
          <div className="my-auto" onClick={() => removeItem(index)}>
            <Icon
              baseSize="20px"
              type="duotone"
              icon="trash-alt"
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  )
}
