import React, { useContext, useState, useEffect } from "react"
import { isMobileOnly } from "react-device-detect"

import SidebarItem from "./SidebarItem"
import GettingStarted from "./GettingStarted"
import { MachineContext } from "../../state"
import { Link } from "react-router-dom"
import PayzipLogo from "../../assets/img/logo.svg"
import { useAdminMenu } from "../../utils/hooks"
import ClubMenu from "./ClubMenu"
import { viewAffiliateTutorialModal } from "../../utils/modals/affiliate"

export default ({ pathname }) => {
  const menu = useAdminMenu()

  const [objectives, setObjectives] = useState([])
  let [
    {
      context: { user, affiliateTutorial },
    },
    send,
  ] = useContext(MachineContext)

  useEffect(() => {
    const { linkShared, firstClubSignup, firstCardPayment, firstPayout } =
      affiliateTutorial
    setObjectives([
      { done: true, name: "Affiliate approved" },
      { done: linkShared, name: "Share your link" },
      { done: firstClubSignup, name: "First club signup" },
      { done: firstCardPayment, name: "First card payment" },
      { done: firstPayout, name: "First payout" },
    ])
  }, [affiliateTutorial])

  const showGuide = () => {
    send("updateAffiliateTutorial", {
      data: {
        body: {
          tutorialComplete: false,
        },
      },
    })
  }

  const openTutorial = () => {
    viewAffiliateTutorialModal(send, user.affiliateProfile.urls)
  }

  const completeTutorial = () => {
    if (objectives.filter(({ done }) => done).length === objectives.length) {
      send("updateAffiliateTutorial", {
        data: {
          body: {
            tutorialComplete: true,
          },
        },
      })
    }
  }

  if (!isMobileOnly) {
    return (
      <div className="sm:max-w-sidebar pt-md sm:inline-block hidden w-full">
        <div className="mb-md">
          {menu ? (
            <ClubMenu
              options={menu}
              title="Affiliate view"
              menuIcon={{
                type: "regular",
                icon: "pound-sign",
                colour: "#E0FCFF",
                width: "full",
              }}
            />
          ) : (
            <Link to="/affiliate">
              <img
                className="h-28"
                src={PayzipLogo}
                alt="Payzip"
                width="100px"
              />
            </Link>
          )}
        </div>
        <div className="bg-neutral-2 rounded text-neutral-8 mr-md sm:shadow-container">
          <div className="py-md pl-md pr-sm">
            <ul className="mb-lg">
              <SidebarItem
                pathroot="/affiliate"
                pathname={pathname}
                to="/affiliate"
                icon="chart-area"
                label="Dashboard"
              />
            </ul>
            <ul>
              <SidebarItem
                pathroot="/affiliate"
                pathname={pathname}
                to="/affiliate/signups"
                icon="shield-alt"
                label="Signups"
              />
              <SidebarItem
                marginBottom="0"
                pathroot="/affiliate"
                pathname={pathname}
                to="/affiliate/payouts"
                icon="coins"
                label="Payouts"
              />
            </ul>
          </div>
          {affiliateTutorial &&
          affiliateTutorial.tutorialComplete !== undefined &&
          !affiliateTutorial.tutorialComplete ? (
            <GettingStarted
              openTutorial={openTutorial}
              objectives={objectives}
              tutorialComplete={affiliateTutorial.tutorialComplete}
              completeTutorial={completeTutorial}
            />
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    )
  }

  return <React.Fragment />
}
