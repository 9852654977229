import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"

import Input from "../../Input"
import Textarea from "../../Input/Textarea"
import HelperRow from "../../containers/HelperRow"
import { useFormValidator } from "../../Forms/Form"
import FilterDropdown from "../../FilterDropdown"

export default forwardRef(
  ({ intent, channels, setState, submitApplication }, ref) => {
    const [validator, { validateForm }] = useFormValidator()
    const [selectableChannels] = useState([
      { name: "Facebook", value: "Facebook" },
      { name: "Instagram", value: "Instagram" },
      { name: "LinkedIn", value: "LinkedIn" },
      { name: "Pinterest", value: "Pinterest" },
      { name: "Snapchat", value: "Snapchat" },
      { name: "Twitter", value: "Twitter" },
      { name: "YouTube", value: "YouTube" },
      { name: "Email", value: "Email" },
      { name: "Newsletter", value: "Newsletter" },
      { name: "Website", value: "Website" },
      { name: "Other", value: "Other" },
    ])

    useEffect(() => {
      validator.purgeFields()
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        validateForm()

        if (validator.allValid()) {
          validator.hideMessages()
          submitApplication()
        } else {
          validator.showMessages()
        }
      },
    }))

    return (
      <div>
        <div className="mb-md">
          <HelperRow
            title="Instructions"
            text={[
              "Use the box on the right to tell us about you, what you do, and how you intend to promote Payzip.",
              "Below, tell us all of the channels you will use to promote Payzip. Choose a channel from the drop-down list, then in the text box give us the handle, account name, or web address where we can find it.",
            ]}
            columns={4}
            textCols={2}
          >
            <label
              className="input-label input-label--lg mb-xs"
              htmlFor="intent"
            >
              About you
            </label>
            <Textarea
              name="intent"
              value={intent}
              onChange={({ target }) =>
                setState({ [target.name]: target.value })
              }
              rows={7}
              validation={{
                message: "How will you promote Payzip?",
                rules: "required",
              }}
            />
          </HelperRow>
        </div>
        <div className="grid grid-cols-2 gap-sm">
          <label className="input-label input-label--lg mb-xs">Channel</label>
          <label className="input-label input-label--lg mb-xs">
            Account / address
          </label>
        </div>

        <div className="grid grid-cols-1 gap-sm">
          {channels.map(({ channel, account }, index) => {
            const notRequired = ["Email", "Newsletter", "Other"].includes(
              channel
            )
            return (
              <div key={`channel-${index}`} className="grid grid-cols-2 gap-sm">
                <FilterDropdown
                  single
                  fullWidth
                  closeOnSelect
                  closeOnClear
                  clearable
                  updateOnClose={false}
                  icons={{
                    unselected: null,
                    selected: {
                      type: "duotone",
                      icon: "check-circle",
                    },
                  }}
                  options={selectableChannels.map((ch) => {
                    return {
                      ...ch,
                      checked: channel === ch.value,
                    }
                  })}
                  placeholder="Select"
                  onChange={(option) => {
                    channels[index].channel =
                      channel !== option.value ? option.value : ""
                    setState({
                      channels,
                    })
                  }}
                  updateOptions={(options) => {
                    const selectedOptions = options.filter((op) => op.checked)

                    // Handle clearing selected
                    if (!selectedOptions.length) {
                      channels[index].channel = ""
                      setState({
                        channels,
                      })
                    }
                  }}
                  placement="bottom"
                  fallbackPlacements={["top", "right"]}
                >
                  <Input
                    name={`channel-${index}`}
                    value={channel}
                    onChange={() => {}}
                    disabled
                    disabledStyling={false}
                    placeholder="Select channel"
                    showSelectChevron
                    classes={{
                      input: channel ? "text-neutral-1" : "",
                    }}
                    validation={{
                      messages: {
                        required_if: "Select a channel",
                      },
                      rules: [
                        {
                          required_if:
                            (account && !channel) ||
                            (index === 0 &&
                              channels.filter((ch) => ch.channel || ch.account)
                                .length === 0),
                        },
                      ],
                    }}
                  />
                </FilterDropdown>
                <Input
                  name={`channel-${index}`}
                  value={account}
                  placeholder={
                    ["Email", "Newsletter", "Other"].includes(channel)
                      ? "Not required"
                      : ""
                  }
                  onChange={({ target }) => {
                    channels[index].account = target.value
                    setState({
                      channels,
                    })
                  }}
                  validation={{
                    messages: {
                      required_if: "Provide an account / address",
                    },
                    rules: [
                      {
                        required_if:
                          ((!account && channel) ||
                            (index === 0 &&
                              channels.filter((ch) => ch.channel || ch.account)
                                .length === 0)) &&
                          !notRequired,
                      },
                    ],
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
)
