import React from "react"

export default () => {
  return (
    <div>
      <div className="pb-xs">
        Before you can send any invoices, you need to add members into your
        club. You can either type them in manually, or import them from a
        spreadsheet.
      </div>
      <div>To add a member, you can:</div>
      <div className="p-md m-sm">
        <ul>
          <li>
            • Click the <span className="font-medium">Members</span> entry in
            the sidebar menu and then click the{" "}
            <span className="font-medium">Add member</span> button
          </li>
        </ul>
        <div className="font-medium py-sm">OR</div>
        <ul>
          <li>
            • Click the plus in the bottom right-hand corner, and then choose{" "}
            <span className="font-medium">Add Members</span> or{" "}
            <span className="font-medium">Import Members</span>
          </li>
        </ul>
      </div>
      <div>
        Detailed instructions are available in our{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.payzip.co.uk/article/35-adding-members"
        >
          knowledgebase
        </a>
        .
      </div>
    </div>
  )
}
