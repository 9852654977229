import React, { useContext } from "react"
import { MachineContext } from "../../state"

import Button from "../Button"
import { useFormValidator } from "../Forms/Form"

export default ({
  buttons = [],
  leftButton,
  footerText,
  state,
  useValidation = true,
  className,
  border = true,
}) => {
  const [current] = useContext(MachineContext)
  const [validator, { validateForm }] = useFormValidator(useValidation)
  return (
    <div
      className={`${
        leftButton || footerText ? "justify-between" : "justify-end"
      }
      sm:flex
      sm:px-md px-sm sm:py-0 py-sm
      bg-white
      sm:rounded-b
      ${border ? "shadow-t-border" : ""}
      sm:h-2xl
      ${className}`}
    >
      {footerText && (
        <div className="my-auto">
          <div className="py-xs">{footerText}</div>
        </div>
      )}
      {leftButton && (
        <div className="my-auto">
          <Button
            type={leftButton.type}
            iconType={leftButton.iconType}
            icon={leftButton.icon}
            disabled={leftButton.disabled}
            onClick={(event) => leftButton.callback(event, validator)}
          >
            {leftButton.text}
          </Button>
        </div>
      )}
      <div
        className={`
      sm:flex my-auto sm:gap-0
      grid grid-cols-${buttons.reduce((a, b) => {
        if (b.hideOnMobile) {
          return a
        }
        return b.colWidth ? a + b.colWidth : a + 1
      }, 0)} gap-sm
    `}
      >
        {buttons.map((button, index) => {
          if (button.component) {
            return (
              <div key={index} className={`col-span-${button.colWidth || 1}`}>
                {button.component}
              </div>
            )
          }
          return (
            <div
              key={index}
              className={`${
                button.hideOnMobile ? "sm:block hidden" : ""
              } sm:w-auto w-full col-span-${button.colWidth || 1}`}
            >
              <Button
                containerStyle={button.containerStyle + " sm:block hidden"}
                disabled={button.disabled}
                type={button.type}
                iconType={button.iconType}
                layer={button.layer}
                to={button.to}
                submitting={
                  typeof button.submitting === "string"
                    ? button.submitting.split("|").some(current.matches)
                    : button.submitting
                }
                onClick={(event) => {
                  if (button.callback) {
                    button.callback(event, validator, validateForm, state)
                  }
                }}
                icon={button.icon}
                background={button.background}
              >
                {button.text}
              </Button>
              <Button
                large
                containerStyle={button.containerStyle + " sm:hidden block"}
                disabled={button.disabled}
                type={button.type || "secondary"}
                to={button.to}
                submitting={
                  typeof button.submitting === "string"
                    ? current.matches(button.submitting)
                    : button.submitting
                }
                onClick={(event) => {
                  if (button.callback) {
                    button.callback(event, validator, validateForm, state)
                  }
                }}
                background={button.background}
              >
                {button.text}
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
