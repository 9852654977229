import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "toggleReminderStatus",
    payload: {
      url: (values) =>
        `/organisation/${values.organisationId}/batch/${values.batchId}/member/${values.memberId}/reminder`,
      type: "post",
      refreshPageData: true,
    },
    onSuccess: {
      target: "CLOSE_MODAL",
    },
  },
  {
    target: "getInvoicePayments",
    payload: {
      url: ({ organisationId, filters }) =>
        `/payments${constructQueryString(
          filters,
          `organisationId=${organisationId}`
        )}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getInvoiceDetails",
    payload: {
      url: ({ organisationId, batchId, memberId, queryString }) =>
        `/organisation/${organisationId}/batch/${batchId}/member/${memberId}${constructQueryString(
          queryString
        )}`,
      type: "get",
    },
  },
  {
    target: "createInvoiceNote",
    payload: {
      url: ({ organisationId, batchId, memberId }) =>
        `/organisation/${organisationId}/batch/${batchId}/member/${memberId}/notes`,
      type: "post",
      refreshPageData: true,
      storeData: false,
    },
  },
  {
    target: "deleteNote",
    payload: {
      url: ({ noteId, batchId, memberId }) =>
        `/batch/${batchId}/members/${memberId}/notes/${noteId}/delete`,
      type: "post",
      refreshPageData: true,
      storeData: false,
    },
    onSuccess: {
      notification: {
        title: "Note removed",
      },
      target: "CLOSE_CONFIRMATION",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "checkMemberInvoiceIsValid",
    payload: {
      url: ({ organisationId, batchId, memberId, redirectPath }) =>
        `/organisation/${organisationId}/batch/${batchId}/member/${memberId}/check${constructQueryString(
          { redirectPath }
        )}`,
      type: "get",
    },
  },
  {
    target: "sendRemindersList",
    payload: {
      url: ({ organisationId, resentInvoices }) =>
        `/organisation/${organisationId}/batch/remind?resentInvoices=${resentInvoices}`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "addCredit",
    payload: {
      url: ({ batchId, invoiceId }) =>
        `/batch/${batchId}/invoice/${invoiceId}/credit`,
      type: "post",
      refreshPageData: true,
    },
  },
  {
    target: "deleteInvoice",
    payload: {
      url: ({ batchId, invoiceId, memberId }) =>
        `/batch/${batchId}/invoice/${invoiceId}/member/${memberId}/delete`,
      type: "post",
      refreshPageData: true,
    },
  },
  {
    target: "checkInvoiceIsValid",
    payload: {
      url: ({ token, memberId, redirectPath }) =>
        `/invoice/${token}/check${constructQueryString(
          { redirectPath },
          `memberId=${memberId}`
        )}`,
      type: "get",
    },
  },
  {
    target: "getEmailInvoiceDetails",
    payload: {
      url: ({ token, memberId }) =>
        `/invoice/${token}${constructQueryString({}, `memberId=${memberId}`)}`,
      type: "get",
    },
  },
]
