import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import Icon from '../Icon'
import { MachineContext } from '../../state'
import { Link } from 'react-router-dom'

const Toast = ({ position }) => {
  const [{ context }, send] = useContext(MachineContext)
  const [list, setList] = useState(context.notifications)

  useEffect(() => {
    setList(context.notifications)
  }, [context.notifications, list])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearToast = () => {
    const notificationItems = context.notifications.filter(e => {
      return moment().isBefore(e.expiryTime)
    })

    setList(notificationItems)
    send('UPDATE_CONTEXT', { notifications: notificationItems })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (context.notifications.length && list.length) {
        clearToast()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [clearToast, list.length, context.notifications])

  return (
    <div>
      <div className={`notification-container ${position}`}>
        {
          list.map((toast, i) => {
            let icon
            let iconColours
            switch (toast.type) {
              case 'warning':
                icon = 'exclamation-circle'
                iconColours = {
                  primary: '#FFFBEA',
                  secondary: '#CB6E17'
                }
                break
              case 'danger':
                icon = 'exclamation-circle'
                iconColours = {
                  primary: '#FFEEEE',
                  secondary: '#BA2525'
                }
                break
              case 'tip':
                icon = 'question-circle'
                iconColours = {
                  primary: '#F0F4F8',
                  secondary: '#627D98'
                }
                break
              case 'info':
                icon = 'info-circle'
                iconColours = {
                  primary: '#E0FCFF',
                  secondary: '#14919B'
                }
                break
              default:
                icon = 'check-circle'
                iconColours = {
                  primary: '#E3F9E5',
                  secondary: '#31B237'
                }
                break
            }

            const body = (
              <div
                key={ `toast-${i}` }
                className={`notification toast toast-${toast.type} ${position} justify-between`}
              >
                <div className="flex my-auto">
                  <div className={ `toast-border toast-border-${toast.type}` } />
                  <div className="inline-block my-auto px-sm mx-xs">
                    <p className={ `notification-title ${toast.type}` }>{toast.title}</p>
                    <p className={ `notification-message ${toast.type}` }>
                      {toast.description}
                    </p>
                  </div>
                </div>
                <Icon icon={ icon } className="my-auto pr-sm mr-xs" type="duotone" colours={ iconColours } baseSize="30px" />
              </div>
            )

            if (toast.to) {
              return <Link
                key={ `toast-${i}` }
                to={ toast.to }
                onClick={ (event) => toast.callback ? toast.callback(event) : null}
              >
                { body }
              </Link>
            }

            return body
          })
        }
      </div>
    </div>
  )
}

export default Toast
