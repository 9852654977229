import React, { useState } from "react"

import Ribbon from "../../containers/Ribbon"
import Icon from "../../Icon"
import moment from "moment"
import { formatCurrency } from "../../../utils/functions"
import Footer from "../../containers/Footer"

export default ({ ping, organisation = {}, send }) => {
  const [viewingDetails, toggleViewingDetails] = useState(false)

  return (
    <div
      className="sm:max-w-md sm:min-w-lg
        sm:rounded
        sm:mx-auto
        flex flex-col
        bg-white h-full"
    >
      <div
        className="sm:inline-block
          w-full
          sm:max-w-md
          sm:rounded
          sm:overflow-y-auto
          relative
          flex flex-col sm:h-auto h-full overflow-y-auto"
      >
        <div className="bg-white sm:rounded-t pb-md shadow-b-border">
          <div className="relative">
            <Ribbon rounded logo={true} background="primary" />
          </div>
          <div className="px-md text-center">
            <h2 className="text-xl mt-xs">{ping.description}</h2>
            <div className="text-neutral-5 font-medium text-lg">
              {organisation.name}
            </div>
          </div>
        </div>
        <div
          className={`grid gap-sm bg-neutral-10 sm:py-md px-md shadow-b-border`}
        >
          <div
            className={`grid grid-cols-1 gap-sm ${
              viewingDetails ? "sm:pt-0 pt-md" : "sm:block hidden"
            }`}
          >
            {ping.comments && (
              <div
                className={
                  "whitespace-pre-line " +
                  (ping.limit || ping.endDate ? "pb-sm" : "")
                }
              >
                {ping.comments}
              </div>
            )}
            {ping.limit > 0 && (
              <div className="flex">
                <div className="font-semibold pr-xs">Limit:</div>
                <div>{ping.limit} per member</div>
              </div>
            )}
            {ping.endDate && (
              <div className="flex">
                <div className="font-semibold pr-xs">Closing date:</div>
                <div>{moment(ping.endDate).format("DD MMM YYYY")}</div>
              </div>
            )}
          </div>
          <div
            className={`sm:hidden text-center cursor-pointer text-neutral-5 font-medium flex items-center justify-center ${
              viewingDetails ? "py-md" : "py-sm"
            } -mx-md`}
            onClick={() => toggleViewingDetails(!viewingDetails)}
          >
            <div className="inline-block mr-xs font-medium">
              {viewingDetails ? "Hide" : "Show"} details
            </div>
            <Icon
              baseSize="20px"
              type="duotone"
              icon={viewingDetails ? "arrow-circle-up" : "arrow-circle-down"}
              className="inline-block"
            />
          </div>
        </div>
        <div className="bg-white pt-sm">
          <table className="bg-white w-full">
            <thead className="border-b border-neutral-8">
              <tr>
                <th className="text-left px-md py-sm font-medium">Item</th>
                <th className="text-right px-md font-medium">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="px-md py-sm sm:h-auto h-55">
                <td className="px-md py-sm">{ping.description}</td>
                <td className="text-right px-md">
                  {ping.amount ? formatCurrency(ping.amount) : "FREE"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer
        useValidation={false}
        buttons={[
          {
            text: "Okay",
            type: "primary",
            icon: "check-circle",
            iconType: "duotone",
            callback: () => send("CLOSE_CONFIRMATION"),
          },
        ]}
      />
    </div>
  )
}
