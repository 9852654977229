import React, { useRef, useState, useEffect } from "react"
import moment from "moment"

import Icon from "../Icon"
import Input from "../Input"
import Form from "../Forms/Form"

const checkDate = (date = "") => {
  return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : date
}

export default ({
  startDate,
  endDate,
  setTypedDate,
  setTypedEndDate,
  selectedRange,
  setSelectedRange,
}) => {
  const startDateRef = useRef()
  const endDateRef = useRef()
  const [firstDate, setFirstDate] = useState(checkDate(startDate))
  const [lastDate, setLastDate] = useState(checkDate(endDate))

  useEffect(() => {
    setFirstDate(checkDate(startDate))
  }, [startDate])

  useEffect(() => {
    setLastDate(checkDate(endDate))
  }, [endDate])

  const blurInput = (event) => {
    if (event.key === "Enter") {
      endDateRef.current.blur()
    }
  }

  const handleDateChange = (event) => {
    if (event.target !== endDateRef.current) {
      endDateRef.current.focus()
    }
  }

  const handleEndInputFocus = (event) => {
    if (!startDate) {
      startDateRef.current.focus()
      setSelectedRange("selectsStart")
    } else {
      setSelectedRange("selectsEnd")
    }
  }

  return (
    <div className="mt-md">
      <Form
        onSubmit={handleDateChange}
        className="flex justify-between items-center"
      >
        <div className="w-5/12">
          <Input
            mask="99/99/9999"
            ref={startDateRef}
            value={firstDate || ""}
            onFocus={() => setSelectedRange("selectsStart")}
            onBlur={({ target }) => setTypedDate(target)}
            onChange={({ target }) => setFirstDate(target.value)}
            classes={{
              input: `border ${
                selectedRange === "selectsStart"
                  ? "border-primary-6"
                  : "border-neutral-8"
              } shadow-none`,
            }}
            placeholder={
              moment(firstDate).isValid()
                ? moment(firstDate).format("DD/MM/YYYY")
                : "Start Date"
            }
          />
        </div>

        <div className="">
          <Icon type="solid" icon="arrow-right" />
        </div>

        <div className="w-5/12">
          <Input
            mask="99/99/9999"
            ref={endDateRef}
            value={lastDate || ""}
            onFocus={handleEndInputFocus}
            onBlur={({ target }) => setTypedEndDate(target)}
            onChange={({ target }) => setLastDate(target.value)}
            classes={{
              input: `border ${
                selectedRange === "selectsEnd"
                  ? "border-primary-6"
                  : "border-neutral-8"
              } shadow-none`,
            }}
            placeholder={
              moment(lastDate).isValid()
                ? moment(lastDate).format("DD/MM/YYYY")
                : "End Date"
            }
            onKeyPress={blurInput}
          />
        </div>
      </Form>
    </div>
  )
}
