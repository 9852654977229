import { handleRequestResponse } from "../utils/functions"
import { assign, send } from "xstate"
import moment from "moment"

export default {
  handleRequestResponse: assign(handleRequestResponse),
  handleSendEvent: send((context, event) => {
    let successEvent = event.data

    if (Array.isArray(successEvent)) {
      successEvent = successEvent.filter((e) => e.onSuccess)[0]
    }

    if (successEvent && successEvent.onSuccess) {
      const { target } = successEvent.onSuccess

      if (target) {
        if (typeof target === "string") {
          return target
        } else {
          return target(context, event)
        }
      }
    }
    return "DUMMY"
  }),
  handleSuccess: assign((context, event) => {
    let notifications = context.notifications

    let successEvent = event.data
    if (Array.isArray(successEvent)) {
      successEvent = successEvent.filter((e) => e.onSuccess)[0]
    }

    if (successEvent && successEvent.onSuccess) {
      const { notification, callback } = successEvent.onSuccess

      // Handle notification
      if (notification) {
        let title, type, description, to
        if (typeof notification === "function") {
          const notif = notification(event.data, context)
          if (notif) {
            title = notif.title
            type = notif.type
            description = notif.description
            to = notif.to
          }
        } else {
          title = notification.title
          type = notification.type
          description = notification.description
          to = notification.to
        }

        // Title is the only required notification field
        if (title) {
          let id =
            notifications.length > 0
              ? notifications[notifications.length - 1].id + 1
              : 1
          notifications.push({
            id,
            title: title || "Success",
            type: type || "success",
            description,
            to,
            expiryTime: moment().add(5.7, "seconds"),
          })
        }
      }

      // Handle callback
      if (callback) {
        callback(event.data, context, send)
      }
    }

    return { notifications, onSuccess: null, errorBanner: false }
  }),
  handleRetryBanner: assign((context, event, { state }) => {
    let previousEvent = state ? state.event : false
    let errorEvent = event.data

    let errorBanner = context.errorBanner
    if (previousEvent && previousEvent.data) {
      errorBanner = {
        previousEvent,
      }
    } else if (
      errorEvent &&
      errorEvent.request &&
      errorEvent.data &&
      errorEvent.data.name !== "validation"
    ) {
      errorBanner = {
        previousEvent: {
          type: errorEvent.type || "HANDLE_REQUEST",
          data: errorEvent.request,
          onSuccess: errorEvent.onSuccess,
          onError: errorEvent.onError,
        },
      }
    }
    return {
      errorBanner,
    }
  }),
  handleError: send((context, event, { state }) => {
    let notifications = context.notifications
    let errorEvent = event.data

    if (Array.isArray(errorEvent)) {
      errorEvent = errorEvent.filter((e) => e.onSuccess)[0]
    }
    if (errorEvent && errorEvent.onError) {
      const { notification, callback } = errorEvent.onError

      // Handle notification
      if (notification) {
        let id =
          notifications.length > 0
            ? notifications[notifications.length - 1].id + 1
            : 1
        let title, type, description, to
        if (typeof notification === "function") {
          const notif = notification(errorEvent.data)
          title = notif.title
          type = notif.type
          description = notif.description
          to = notif.to
        } else {
          title = notification.title
          type = notification.type
          description = notification.description
          to = notification.to
        }

        if (title || description) {
          notifications.push({
            ...errorEvent.onError,
            id,
            title: title || "Danger",
            type: type || "danger",
            description,
            to,
            expiryTime: moment().add(5.7, "seconds"),
          })
        }
      }

      // Handle callback
      if (callback) {
        callback(errorEvent)
      }
    }

    let errors = {}
    let errorRedirect = false
    if (errorEvent) {
      errors = errorEvent.data
      if (errorEvent.status === 401) {
        return "LOGOUT"
      }

      if (errorEvent.status === 404) {
        errorRedirect = errorEvent.status
      }
    }

    return {
      type: "UPDATE_CONTEXT",
      notifications,
      errors,
      errorRedirect,
    }
  }),
}
