import React, { useContext, useEffect, useState } from "react"
import { MachineContext } from "../../state"
import { pluraliseWord } from "../../utils/functions"
import Icon from "../Icon"
import Export from "./Export"

const Selector = ({
  currentPage = 0,
  selectorKey,
  text = null,
  children,
  className,
  onClick,
  disabled = false,
}) => (
  <div
    key={selectorKey || text}
    className={`${
      disabled
        ? "cursor-default text-neutral-8"
        : "cursor-pointer text-neutral-5"
    }
      inline-block w-lg h-lg text-center font-medium border-t border-b border-l border-neutral-8 last:border-r
      ${currentPage === text ? " bg-neutral-10" : " bg-white"}
      ${className}`}
    onClick={(event) => {
      if (!disabled) {
        onClick(event)
      }
    }}
  >
    {text || children}
  </div>
)

const PageSelector = ({ pages, currentPage, handlePageChange, isLoading }) => {
  if (pages > 1) {
    const changePage = (page) => {
      if (!isLoading) {
        if (currentPage < 1) {
          handlePageChange(1)
          return
        }

        if (currentPage > pages) {
          handlePageChange(pages)
          return
        }

        handlePageChange(page)
      }
    }

    return (
      <div>
        <Selector
          disabled={currentPage === 1}
          selectorKey="back"
          onClick={() => changePage(currentPage - 1)}
          className="rounded-l"
        >
          <Icon className="inline-block" icon="chevron-left" type="solid" />
        </Selector>
        <Selector
          selectorKey="first"
          onClick={() => changePage(1)}
          text={1}
          currentPage={currentPage}
        />
        {currentPage > 5 && (
          <Selector
            selectorKey="decreasePage"
            onClick={() => changePage(currentPage - 3)}
            text={"..."}
            currentPage={currentPage}
          />
        )}
        {[...Array(pages).keys()]
          .filter((y) => {
            const yMax =
              currentPage === 5 ? y >= currentPage - 4 : y >= currentPage - 3
            const yMin =
              currentPage === pages - 4
                ? y <= currentPage + 2
                : y <= currentPage + 1
            return y !== 0 && y !== pages - 1 && yMax && yMin
          })
          .map((x) => (
            <Selector
              key={x + 1}
              selectorKey={"page" + (x + 1)}
              onClick={() => changePage(x + 1)}
              text={x + 1}
              currentPage={currentPage}
            />
          ))}
        {currentPage < pages - 4 && (
          <Selector
            selectorKey="increasePage"
            onClick={() => changePage(currentPage + 3)}
            text={"..."}
            currentPage={currentPage}
          />
        )}
        <Selector
          selectorKey="last"
          onClick={() => changePage(pages)}
          text={pages}
          currentPage={currentPage}
        />
        <Selector
          disabled={currentPage === pages}
          selectorKey="next"
          onClick={() => changePage(currentPage + 1)}
          className="rounded-r"
        >
          <Icon className="inline-block" icon="chevron-right" type="solid" />
        </Selector>
      </div>
    )
  }

  return <React.Fragment />
}

export default function Pagination({
  isLoading = false,
  currentPage = 1,
  selectedRowCount = 0,
  rowName,
  handlePageChange,
  totalRowCount,
  pages,

  exportRequestKey,
  exportRequestVariables,
  enableExport = false,
  exportHeaders,
  exportFileSuffix,
  exportTransformer,
  hidePagination = false,
}) {
  const [rowCount, setRowCount] = useState(0)
  const [totalpages, setTotalPages] = useState(0)
  const [current] = useContext(MachineContext)

  useEffect(() => {
    setRowCount(
      totalRowCount
        ? totalRowCount
        : current.context.paginationProps.totalRowCount
    )
    setTotalPages(pages ? pages : current.context.paginationProps.pages)
  }, [
    current.context.paginationProps.pages,
    current.context.paginationProps.totalRowCount,
    pages,
    totalRowCount,
  ])

  return (
    <div className="bg-white rounded-b py-sm px-md shadow-t-border flex justify-between items-center leading-loose h-2xl">
      <div>
        <div className="inline-block align-middle py-xs">
          {selectedRowCount > 0
            ? `${selectedRowCount} selected`
            : `${rowCount} ${pluraliseWord(rowName, rowCount)}`}
        </div>
        {enableExport && (
          <div className="inline-block">
            <Export
              requestVariables={exportRequestVariables}
              requestKey={exportRequestKey}
              fileSuffix={exportFileSuffix}
              transformer={exportTransformer}
              headers={exportHeaders}
            />
          </div>
        )}
      </div>
      {!hidePagination && (
        <div className="text-right py-xs">
          <PageSelector
            pages={totalpages}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  )
}
