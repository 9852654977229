import React from "react"
import Footer from "../../containers/Footer"
import Header from "../../containers/Header"
import Dropzone from "../../Dropzone"
import Form from "../../Forms/Form"

export default ({ setDroppedMembers, handleClose }) => {
  return (
    <Form>
      <Header>Import members</Header>
      <Dropzone handleDrop={setDroppedMembers} />
      <Footer
        buttons={[
          { callback: () => handleClose(), text: "Cancel" },
          {
            type: "primary",
            disabled: true,
            iconType: "duotone",
            icon: "arrow-circle-right",
            text: "Continue",
          },
        ]}
      />
    </Form>
  )
}
