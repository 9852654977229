import React from 'react'
import moment from 'moment'

import { formatCurrency } from '../../utils/functions'
import Card from '../containers/Card'

export default function PingCard ({
  ping,
  onClick
}) {
  return <Card
    onClick={ onClick }
    title={ ping.description }
    subtitle={ ping.name }
    middleText={ ping.organisationName }
    leftTitle={ ping.amount ? `Paid by Card` : 'Confirmed' }
    leftData={
      moment(ping.datePaid).format(
        (ping.amount) === 'card' ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY'
      )
    }
    rightTitle="Amount"
    rightData={ formatCurrency(ping.amount) }
    valueComponent={
      <div className="flex">
        <div className="pr-xs mr-sm">
          <div className="text-right text-sm text-neutral-5 leading-tight">Qty</div>
          <div className="text-right">{ping.quantity}</div>
        </div>
        <div>
          <div className="text-right text-sm text-neutral-5 leading-tight">Paid</div>
          <div className="text-right">{formatCurrency(ping.amount)}</div>
        </div>
      </div>
    }
  />
}
