import React, { useState, useContext } from "react"
import moment from "moment"
import { loadStripe } from "@stripe/stripe-js"

import ItemsAndPayments from "./ItemsAndPayments"
import InvoiceReview from "./InvoiceReview"
import Ribbon from "../containers/Ribbon"
import Header from "../containers/Header"
import { MachineContext } from "../../state"
import { formatCurrency } from "../../utils/functions"
import Button from "../Button"
import Icon from "../Icon"
import Tag from "../Tag"
import { Link, useLocation } from "react-router-dom"
import Footer from "../containers/Footer"
import Banner from "../containers/Banner"
import MemberEmail from "../MemberEmail"

export default ({
  invoice,
  children,
  customHeader = null,
  displayInvoiceInBody = false,
  displayClubInBody = true,
  displayMemberInBody = true,
  displayEmailInBody = false,
  withShadow = true,
  maxHeight = "",
  isAdmin,
  checkInvoiceIsValid,
  errorFetchingLink = false,
  paymentRemoved,
}) => {
  const location = useLocation()

  const [viewingDetails, toggleViewingDetails] = useState(!invoice.owed)
  const [
    {
      context: { user },
    },
    send,
  ] = useContext(MachineContext)
  const lineItems = invoice.lineItems
    ? invoice.lineItems.filter((i) => i.description)
    : []
  const credits = invoice.credits
    ? invoice.credits.filter((i) => i.reason && i.amount)
    : []
  const member = invoice.member || {}
  const total = lineItems
    ? lineItems.reduce((a, b) => a + parseInt(b.amount), 0)
    : 0
  const totalPaid = invoice.payments
    ? invoice.payments.reduce((a, b) => a + parseInt(b.amount), 0)
    : 0
  const memberView =
    !user || (user && !user.roles.includes("admin")) || !isAdmin
  const paid = invoice.owed !== undefined && !invoice.owed && memberView

  const confirmRemovePayment = (payment) => {
    send("OPEN_CONFIRMATION", {
      payload: {
        id: "removePayment",
        title: "Remove payment",
        body: `You are about to remove a payment of ${formatCurrency(
          payment.amount
        )} from this invoice.`,
        buttons: [
          {
            text: "Cancel",
            callback: () => {
              send("CLOSE_CONFIRMATION")
            },
          },
          {
            text: "Remove payment",
            type: "danger",
            iconType: "duotone",
            icon: "exclamation-circle",
            submitting: "app.requests.deletePayment.requesting",
            callback: () => {
              removePayment(payment)
            },
          },
        ],
      },
    })
  }

  const removePayment = (payment) => {
    send("deletePayment", {
      data: {
        variables: {
          batchId: invoice.metadata.batchId,
          memberId: invoice.member.id,
          paymentId: payment.id,
        },
        onSuccess: {
          notification: {
            title: "Manual payment removed",
            description: `${payment.paymentMethod} payment for ${formatCurrency(
              payment.amount
            )} removed`,
          },
          callback: ({ response }) => {
            if (paymentRemoved) {
              paymentRemoved(payment)
            }

            send("UPDATE_MODAL_STATE", {
              payload: {
                state: response,
              },
            })
          },
          target: "CLOSE_CONFIRMATION",
        },
      },
    })
  }

  const confirmRemoveCredit = (credit) => {
    send("OPEN_CONFIRMATION", {
      payload: {
        id: "removeCredit",
        title: "Remove credit",
        body: `You are about to remove a credit of ${formatCurrency(
          credit.amount
        )} from this invoice.`,
        buttons: [
          {
            text: "Cancel",
            callback: () => {
              send("CLOSE_CONFIRMATION")
            },
          },
          {
            text: "Remove credit",
            type: "danger",
            iconType: "duotone",
            icon: "exclamation-circle",
            submitting: "app.requests.removeCredit.requesting",
            callback: () => {
              removeCredit(credit, send)
            },
          },
        ],
      },
    })
  }

  const removeCredit = (credit) => {
    send("removeCredit", {
      data: {
        variables: {
          creditId: credit.id,
        },
        onSuccess: {
          notification: {
            title: "Credit removed",
          },
          callback: ({ response }) => {
            send("UPDATE_MODAL_STATE", {
              payload: {
                state: response,
              },
            })
          },
          target: "CLOSE_CONFIRMATION",
        },
      },
    })
  }

  const goToCheckout = async () => {
    checkInvoiceIsValid(async (invoice) => {
      const stripe = await loadStripe(invoice.publishableKey)

      stripe.redirectToCheckout({
        sessionId: invoice.session,
      })
    })
  }

  const renderHeader = () => {
    if (memberView) {
      return (
        <div
          className={`bg-white overflow-hidden pb-md shadow-b-border ${
            (invoice.owed > 0 && invoice.owed < 50) || errorFetchingLink
              ? ""
              : "sm:rounded-t"
          }`}
        >
          <div className="relative">
            <Ribbon
              rounded={!(invoice.owed || errorFetchingLink)}
              logo={true}
              background={paid ? "success" : invoice.overdue ? "danger" : ""}
            />

            {/* INVOICE STATUS PILL */}
            {memberView && (
              <div className="absolute top-lg right-lg">
                {invoice.overdue && !paid && (
                  <Tag
                    type="danger"
                    className={`font-medium ${
                      !errorFetchingLink ? "sm:hidden" : ""
                    }`}
                  >
                    Overdue
                  </Tag>
                )}
                {paid && (
                  <Tag type="success" className="font-medium">
                    Paid
                  </Tag>
                )}
              </div>
            )}
          </div>
          <InvoiceReview
            total={total}
            totalPaid={totalPaid}
            labelName={invoice.organisation.name}
            title={invoice.title}
            contextLabel={member.name}
            showValue={isAdmin}
          />

          {!paid &&
            location.pathname.includes("/invoice") &&
            !errorFetchingLink && (
              <div className="sm:hidden block mx-md mt-md">
                <Button
                  large
                  label="Pay now"
                  type="primary"
                  disabled={
                    (invoice.owed > 0 && invoice.owed < 50) || errorFetchingLink
                  }
                  onClick={goToCheckout}
                />
              </div>
            )}
        </div>
      )
    }
    return <Header title={invoice.title} className="rounded-tl" />
  }

  return (
    <div
      className={`sm:max-w-${memberView ? "md" : "xl"} sm:min-w-${
        memberView ? "lg" : "xxl"
      }
        sm:rounded
        sm:mx-auto
        flex
        ${withShadow ? "shadow-container" : ""}
        bg-white
        sm:min-h-modal
        sm:${maxHeight} h-full`}
    >
      <div
        className={`sm:inline-block
          w-full
          sm:max-w-md
          sm:${memberView ? "rounded" : "rounded-l"}
          ${maxHeight ? "sm:overflow-y-auto" : ""}
          ${!user ? "sm:container-button-spacing" : ""}
          relative
          flex flex-col sm:h-auto h-full`}
      >
        {/* INVOICE ERROR BANNER */}
        {((invoice.owed > 0 && invoice.owed < 50) || errorFetchingLink) &&
          memberView && (
            <Banner className="sm:rounded-t">
              {invoice.owed < 50
                ? "Balance below £0.50. Payment cannot be made online."
                : "There was an issue getting the invoice. Payment cannot be made online"}
            </Banner>
          )}
        <div className="relative sm:block flex flex-col sm:h-auto h-full">
          {customHeader || renderHeader()}

          <div className="flex flex-col flex-1 sm:h-auto h-full sm:overflow-y-hidden overflow-y-auto relative">
            {" "}
            {/** only set max height when being viewed as a modal */}
            {/* START INVOICE DETAILS SECTION */}
            <div
              className={`bg-neutral-10 sm:p-md px-md shadow-b-border ${
                paid ? "pb-md" : ""
              }`}
            >
              <div
                className={`grid gap-sm invoice-details${
                  viewingDetails ? "" : " collapse"
                }`}
              >
                {displayInvoiceInBody && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Invoice</div>
                    <div className="text-neutral-1 col-span-4">
                      {invoice.title}
                    </div>
                  </div>
                )}
                {displayClubInBody && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Club</div>
                    <div className="text-neutral-1 col-span-4">
                      {invoice.organisation.name}
                    </div>
                  </div>
                )}
                {displayMemberInBody && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Member</div>
                    <div className="text-neutral-1 col-span-4">
                      {member.name}
                    </div>
                  </div>
                )}
                {displayEmailInBody && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Email</div>
                    <div className="text-neutral-1 col-span-4">
                      <MemberEmail
                        undeliverable={!memberView && member.undeliverable}
                        email={member.email}
                        emailIssue={member.emailIssue}
                      />
                    </div>
                  </div>
                )}
                <div className="sm:grid grid-cols-5">
                  <div className="text-neutral-5 font-medium">Date sent</div>
                  <div className="text-neutral-1 col-span-4">
                    {moment(invoice.dateSent).format("DD MMM YYYY").toString()}
                  </div>
                </div>
                {(!memberView || !user) && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Due date</div>
                    <div className="text-neutral-1 col-span-4">
                      {moment(invoice.dueDate).format("DD MMM YYYY").toString()}
                    </div>
                  </div>
                )}
                {invoice.description && (
                  <div className="sm:grid grid-cols-5">
                    <div className="text-neutral-5 font-medium">Comments</div>
                    <div className="text-neutral-1 col-span-4 whitespace-pre-line">
                      {invoice.description}
                    </div>
                  </div>
                )}
              </div>
              {!paid && (
                <div
                  className={`sm:hidden text-center cursor-pointer text-neutral-5 font-medium flex items-center justify-center ${
                    viewingDetails ? "py-md" : "py-sm"
                  } -mx-md`}
                  onClick={() => toggleViewingDetails(!viewingDetails)}
                >
                  <div className="inline-block mr-xs font-medium">
                    {viewingDetails ? "Hide" : "Show"} details
                  </div>
                  <Icon
                    baseSize="20px"
                    type="duotone"
                    icon={
                      viewingDetails ? "arrow-circle-up" : "arrow-circle-down"
                    }
                    className="inline-block"
                  />
                </div>
              )}
            </div>
            {/* END INVOICE DETAILS SECTION */}
            {/* START INVOICE LINE ITEMS AND FOOTER */}
            <div className="bg-white pt-sm pb-md">
              <ItemsAndPayments
                canRemove={
                  isAdmin && invoice.member && !invoice.member.archived
                }
                lineItems={lineItems}
                credits={credits}
                payments={invoice.payments}
                removePayment={confirmRemovePayment}
                removeCredit={confirmRemoveCredit}
                owed={invoice.owed}
                isAdmin={isAdmin}
                custom={
                  invoice.metadata &&
                  invoice.metadata.custom &&
                  parseInt(invoice.metadata.custom)
                }
              />
            </div>
            {invoice.footer && (
              <div
                className={`px-md pb-md bg-white whitespace-pre-line sm:${
                  memberView ? "rounded-b" : "rounded-bl"
                }`}
              >
                {invoice.footer}
              </div>
            )}
            {/* END INVOICE LINE ITEMS AND FOOTER */}
          </div>

          {/* START INVOICE ACTIONS */}
          {memberView && (
            <div className="sm:absolute sm:top-lg sm:right-lg sm:left-auto sm:bottom-auto">
              {!paid && !errorFetchingLink && (
                <div className="hidden sm:block">
                  <Button
                    icon="credit-card"
                    layer
                    type={invoice.overdue ? "danger" : "primary"}
                    onClick={goToCheckout}
                    disabled={
                      (invoice.owed > 0 && invoice.owed < 50) ||
                      errorFetchingLink
                    }
                  >
                    {invoice.overdue ? "Overdue - Pay now" : "Pay now"}
                  </Button>
                </div>
              )}
              {!location.pathname.includes("/invoice") && (
                <Footer
                  useValidation={false}
                  className="sm:hidden"
                  buttons={
                    !paid && !errorFetchingLink
                      ? [
                          {
                            text: "Close",
                            colWidth: 4,
                            hideOnMobile:
                              !memberView ||
                              location.pathname.includes("/invoice"),
                            callback: () => send("CLOSE_MODAL"),
                          },
                          {
                            text: "Pay now",
                            colWidth: 7,
                            type: "primary",
                            disabled:
                              (invoice.owed > 0 && invoice.owed < 50) ||
                              errorFetchingLink,
                            callback: goToCheckout,
                          },
                        ]
                      : [
                          {
                            text: "Close",
                            type: "primary",
                            callback: () => send("CLOSE_MODAL"),
                          },
                        ]
                  }
                />
              )}
              {paid && !user && (
                // Hide 'free dashboard' button till app is mobile optimised
                <div className="hidden shadow-t-border bg-white p-sm">
                  <Link to="/signup">
                    <Button large type="primary">
                      Get your free dashboard
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        {/* END INVOICE ACTIONS */}
      </div>
      {!memberView && children}
    </div>
  )
}
