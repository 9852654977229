import React, { Suspense } from "react"
import { Route } from "react-router-dom"

const LazyRoute = ({ children, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback || <React.Fragment />}>
      <Route {...props}>{children}</Route>
    </Suspense>
  )
}

export default LazyRoute
