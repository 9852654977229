import React from "react"
import { formatCurrency, formatDate } from "../../utils/functions"
import SkeletonText from "../Skeleton/Text"

const formatContent = (content, format) => {
  switch (format) {
    case "date":
      return formatDate(content)
    case "currency":
      return formatCurrency(content)
    default:
      return content
  }
}

export default ({ content, value, isEmpty, isLoading }) => {
  const textColour = isEmpty
    ? "sm:text-neutral-8 text-primary-10"
    : "sm:text-primary-4 text-primary-10"

  if (content.multi) {
    return (
      <div className="grid grid-cols-2">
        <div>
          <span className="font-medium text-neutral-5 block leading-tight">
            {isLoading ? (
              <SkeletonText
                width="w-80"
                colour="bg-neutral-5"
                className="mb-xs"
              />
            ) : (
              content.headingOne
            )}
          </span>
          <span className={`font-medium text-3xl ${textColour}`}>
            {isLoading ? (
              <SkeletonText width="w-110" height="h-md" colour="bg-primary-4" />
            ) : (
              formatContent(content.contentOne, content.format)
            )}
          </span>
        </div>
        <div className="text-right">
          <span className="font-medium text-neutral-5 block">
            {content.headingTwo}
          </span>
          <span className={`font-medium text-3xl ${textColour}`}>
            {content.contentTwo}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex sm:flex-col flex-col-reverse justify-center h-full">
      <span className="font-medium sm:text-neutral-5 sm:text-neutral-7 text-primary-8 block leading-tight">
        {isLoading ? (
          <SkeletonText
            width="w-80"
            colour="sm:bg-neutral-5 bg-primary-8"
            className="mb-xs"
          />
        ) : (
          content.heading
        )}
      </span>
      <span
        className={`font-medium ${
          content.fontSize || "text-2xl"
        } ${textColour}`}
      >
        {isLoading ? (
          <SkeletonText
            width="w-110"
            height="h-md"
            colour="sm:bg-primary-4 bg-primary-10"
            className="sm:my-0 my-xs"
          />
        ) : (
          formatContent(value, content.format)
        )}
        {}
      </span>
      {content.subtext && (
        <span
          className={`${
            content.isEmpty ? "text-neutral-8" : "text-neutral-5"
          } block leading-tight`}
        >
          {isLoading ? (
            <SkeletonText
              width="w-80"
              colour="sm:bg-neutral-5 bg-primary-8"
              className="sm:mt-xs"
            />
          ) : (
            content.subtext
          )}
        </span>
      )}
    </div>
  )
}
