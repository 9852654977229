import React from "react"
import SkeletonPanel from "../../../components/Skeleton/Panel"
import SkeletonTable from "../../../components/Skeleton/Table"

const HomeSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-md">
      <SkeletonPanel panelCount={4} subtext />
      <SkeletonTable
        header={{
          width: "w-3xl",
        }}
        headerCells={[
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 5,
            text: {
              width: "w-1/4",
              colour: "bg-neutral-5",
            },
            className: "py-base",
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
        ]}
        cells={[
          {
            colSpan: 1,
          },
          {
            colSpan: 1,
          },
          {
            colSpan: 5,
            text: {
              width: "w-2/5",
            },
          },
          {
            colSpan: 1,
          },
          {
            colSpan: 1,
          },
          {
            colSpan: 1,
          },
        ]}
      />
      <SkeletonTable
        header={{
          width: "w-3xl",
        }}
        headerCells={[
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 5,
            text: {
              width: "w-1/4",
              colour: "bg-neutral-5",
            },
            className: "py-base",
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
          {
            colSpan: 1,
            className: "py-base",
            text: {
              colour: "bg-neutral-5",
            },
          },
        ]}
        cells={[
          {
            colSpan: 1,
          },
          {
            colSpan: 1,
          },
          {
            colSpan: 5,
            text: {
              width: "w-2/5",
            },
          },
          {
            colSpan: 1,
          },
          {
            colSpan: 1,
          },
        ]}
      />
    </div>
  )
}

export default HomeSkeleton
