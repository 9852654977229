import React from "react"

import FilterDropdown from "../FilterDropdown"
import InputError from "./Error"
import SelectChevron from "./SelectChevron"
import { useFormValidator } from "../Forms/Form"

export default ({
  id,
  label: inputLabel,
  name,
  value = null,
  large,
  onChange,
  options = [],
  placeholder,
  disabled,
  className,
  chevron = "chevron-circle-down",
  validation,
  inputStyle = "",
  fullWidth = false,
}) => {
  const [validator] = useFormValidator()

  const handleChange = (event) => {
    onChange({ target: { name, value: event.text } })
  }

  let hasErrors = false
  if (validator && validation) {
    hasErrors =
      !validator.check(value, validation.rules) &&
      (validator.messagesShown || validator.visibleFields.includes(name))
  }

  const defaultInputStyle = `input block
    ${disabled ? "input--disabled" : "cursor-pointer"}
    ${large ? "input--lg" : ""}
    ${hasErrors ? "input--error" : ""}
    ${!value ? "text-neutral-8" : ""}`

  return (
    <div>
      <div className={disabled ? "text-neutral-6" : "text-neutral-5"}>
        {inputLabel && (
          <label className="block font-medium mb-xs" htmlFor={id || name}>
            {inputLabel}
          </label>
        )}
      </div>
      <div className="sm:hidden relative">
        <select
          onChange={onChange}
          className={defaultInputStyle}
          id={id}
          name={name || id}
        >
          <option disabled selected={value === null} value>
            {placeholder}
          </option>
          {options.map((option, index) => {
            return (
              <option
                selected={
                  (typeof option === "string" ? option : option.text) === value
                }
                key={(name || id) + index}
                value={option.text ? option.text : option}
              >
                {typeof option === "string" ? option : option.text}
              </option>
            )
          })}
        </select>
        <SelectChevron
          chevron={chevron}
          value={value}
          disabled={disabled}
          large={large}
        />
      </div>
      <div
        className={`sm:inline-block hidden ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } ${className}`}
      >
        <FilterDropdown
          fullWidth={fullWidth}
          single
          clearable={false}
          icons={{
            unselected: null,
            selected: {
              type: "duotone",
              icon: "check-circle",
            },
          }}
          options={options.map((option) => {
            if (typeof option === "string") {
              return {
                text: option,
                checked:
                  value &&
                  value.toString().toLowerCase() ===
                    option.toString().toLowerCase(),
              }
            }

            return {
              ...option,
              checked:
                value &&
                ((value.text &&
                  value.text.toString().toLowerCase() ===
                    option.text.toString().toLowerCase()) ||
                  value.toString().toLowerCase() ===
                    option.text.toString().toLowerCase()),
            }
          })}
          handleOption={handleChange}
          placement="bottom"
          fallbackPlacements={["top", "left"]}
          disabled={disabled}
        >
          <label
            htmlFor={id || name}
            className={inputStyle || defaultInputStyle + " relative"}
          >
            <div>
              {value
                ? typeof value === "string"
                  ? value
                  : value.text
                : placeholder}
            </div>
            <SelectChevron
              chevron={chevron}
              value={value}
              disabled={disabled}
              large={large}
            />
          </label>
        </FilterDropdown>
      </div>
      <InputError validation={validation} name={name} id={id} value={value} />
    </div>
  )
}
