import React from 'react'

export default function Card ({
  danger,
  title,
  subtitle,
  middleText,
  leftTitle,
  leftData,
  rightTitle,
  rightData,
  success,
  onClick,
  valueComponent = null
}) {
  return (
    <div onClick={ onClick } className="bg-white rounded text-neutral-1 font-medium p-sm m-md relative">
      <div className="p-xs">
        <div className={ `${ success ? 'bg-success-5' : (danger ? 'bg-danger-5' : 'bg-neutral-9') } w-xs h-full absolute top-0 left-0 rounded-l` } />
        <div className="text-lg leading-tight">
          { title }
        </div>
        <div className="text-neutral-5 font-regular">
          { subtitle }
        </div>
        <div className="py-sm my-xs font-regular leading-tight">
          { middleText }
        </div>
        <div className="flex justify-between">
          <div>
            <div className="text-sm text-neutral-5 leading-tight">
              { leftTitle }
            </div>
            <div>
              { leftData }
            </div>
          </div>
          {
            valueComponent ?
              valueComponent : (
              <div>
                <div className="text-sm text-neutral-5 leading-tight">
                  { rightTitle }
                </div>
                <div>
                  { rightData }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}
