import React, { useRef } from "react"
import Icon from "../Icon"

export default ({
  id,
  name,
  value = null,
  onChange,
  options = [],
  placeholder,
  disabled,
  children,
  className,
  chevron = "chevron-down",
  chevronStyle = "",
}) => {
  const selectRef = useRef()

  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <div
      className={`inline-block ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${className}`}
    >
      <label htmlFor={id || name} className="label text-neutral-1 relatve flex">
        <div className="inline mr-xs">{children || value || placeholder}</div>
        <Icon
          type="solid"
          icon={chevron}
          className={`inline ${chevronStyle}`}
        />
        <select
          id={id || name}
          ref={selectRef}
          name={name}
          defaultValue={null}
          className="select hidden w-full h-full cursor-pointer"
          value={value}
          onChange={handleChange}
        >
          <option value={null} disabled={value}>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}
