import React, {
  useContext,
  forwardRef,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react"

import CreateBaseInvoice from "./CreateBaseInvoice"
import AddRecipients from "./AddRecipients"
import CustomiseInvoices from "./CustomiseInvoices"

import Header from "../../containers/Header"
import Footer from "../../containers/Footer"
import { MachineContext } from "../../../state"
import Form from "../../Forms/Form"
import { useEventListener } from "../../../utils/hooks"
import Input from "../../Input"
import FilterDropdown from "../../FilterDropdown"
import { getInvoiceTotal } from "../../../utils/functions"
import { sendInvoicesConfirmation } from "../../../utils/confirmations/invoice"
import { useHistory } from "react-router-dom"

const Body = forwardRef(({ component: Component, state }, ref) => {
  return (
    <div className="bg-neutral-10 text-neutral-6 sm:max-h-modal-body max-h-modal-mobile">
      <Component {...state} ref={ref} />
    </div>
  )
})

const getStepPage = forwardRef(
  ({ state, setState, members, showTotalError, totalError }, ref) => {
    switch (state.step) {
      case 1:
        return (
          <CreateBaseInvoice
            ref={ref}
            title={state.invoice.title}
            dueDate={state.invoice.dueDate}
            description={state.invoice.description}
            footer={state.invoice.footer}
            lineItems={state.invoice.lineItems}
            showTotalError={showTotalError}
            totalError={totalError}
            members={state.members}
            amountError
            setState={setState}
          />
        )
      case 2:
        const selectedGroups = state.groups.filter((g) => g.checked)
        const displayMembers = state.showSelected ? state.members : members

        const filteredMembers = displayMembers
          .filter(
            (member) =>
              !state.search ||
              member.name.toLowerCase().includes(state.search.toLowerCase()) ||
              member.email.toLowerCase().includes(state.search.toLowerCase())
          )
          .filter(
            (member) =>
              !selectedGroups.length ||
              selectedGroups.filter((g) => {
                return (
                  (g.id === 0 && member.groups.length === 0) ||
                  member.groups.find((memGroup) => g.id === memGroup.id)
                )
              }).length > 0
          )
        return (
          <AddRecipients
            ref={ref}
            type="invoice"
            invoice={state.invoice}
            groups={state.groups}
            memberCount={members.length}
            filteredMembers={filteredMembers}
            selectedMembers={state.members || []}
            showSelected={state.showSelected}
            setState={setState}
          />
        )
      case 3:
        const searchedMembers = state.members.filter(
          (member) =>
            !state.search ||
            member.name.toLowerCase().includes(state.search.toLowerCase())
        )
        let selectedMember =
          searchedMembers.find(({ id }) => id === state.selectedId) || {}
        let renderedInvoice = state.invoice
        if (!selectedMember.id && searchedMembers.length) {
          selectedMember = searchedMembers[0]
          setState({
            selectedId: selectedMember.id,
          })
        }

        renderedInvoice = selectedMember.invoice || renderedInvoice

        return (
          <CustomiseInvoices
            ref={ref}
            baseInvoice={state.invoice}
            title={renderedInvoice.title}
            dueDate={renderedInvoice.dueDate}
            description={renderedInvoice.description}
            footer={renderedInvoice.footer}
            lineItems={renderedInvoice.lineItems}
            members={searchedMembers}
            allMembers={state.members}
            memberCount={state.members.length}
            selectedMember={selectedMember}
            selectedId={state.selectedId}
            showTotalError={showTotalError}
            totalError={totalError}
            setState={setState}
          />
        )
      default:
        return <React.Fragment />
    }
  }
)

export default forwardRef(
  ({ state, setState: updateState, modalData = {}, handleClose }, ref) => {
    const history = useHistory()
    const [current, send] = useContext(MachineContext)
    const [members, setMembers] = useState([])
    const [totalError, setTotalError] = useState(false)
    const pageRef = useRef()

    useEffect(() => {
      if (
        state.invoice &&
        Array.isArray(state.invoice.members) &&
        Array.isArray(modalData.members) &&
        state.addingRecipients
      ) {
        setMembers(
          modalData.members.filter(
            (member) =>
              !state.invoice.members.map(({ id }) => id).includes(member.id)
          )
        )
      } else if (Array.isArray(modalData.members)) {
        setMembers(modalData.members)
      }
    }, [modalData.members, state])

    const getInvoicesArray = () => {
      return state.members.map((member) => {
        const lineItems =
          member.invoice && member.invoice.lineItems
            ? member.invoice.lineItems
            : state.invoice.lineItems
        let invoiceDetails = {
          title: state.invoice.title,
          lineItems: lineItems
            .filter((item) => item.description)
            .map((item) => {
              return { amount: item.amount, description: item.description } // Removes line item id when adding recipients
            }),
          amount: lineItems
            .filter((item) => item.amount && item.description)
            .reduce((a, b) => a + parseInt(b.amount), 0),
          description: state.invoice.description,
          dueDate: state.invoice.dueDate,
          memberId: member.id,
          custom: member.invoice && member.invoice.custom,
          footer: state.invoice.footer || "",
        }

        if (member.invoice) {
          invoiceDetails.description = member.invoice.description
          invoiceDetails.dueDate = member.invoice.dueDate
        }

        return invoiceDetails
      })
    }

    const createInvoiceRequest = (state, send) => {
      sendInvoicesConfirmation(send, state.members, () => {
        const lineItems = state.invoice.lineItems
          .filter((item) => item.description)
          .map((item) => {
            return { amount: item.amount, description: item.description } // Removes line item id when adding recipients
          })
        // CREATE BASE BATCH
        send("CLOSE_CONFIRMATION")
        send("createInvoiceBatch", {
          data: {
            body: {
              organisationId: current.context.organisation.id,
              title: state.invoice.title,
              dueDate: state.invoice.dueDate,
              description: state.invoice.description,
              lineItems,
              footer: state.invoice.footer || "",
              totalAmount: state.members.reduce(
                (a, b) => a + parseInt(getInvoiceTotal(b, state.invoice)),
                0
              ),
              invoices: getInvoicesArray(),
            },
            onSuccess: {
              notification: ({ response }) => {
                const notification = {
                  title: `${state.invoice.title} created`,
                }
                if (!state.duplicatingInvoice) {
                  notification.description = "Click here to view"
                  notification.to = `/invoices/${response.id}`
                }
                return notification
              },
              callback: ({ response }, context) => {
                send("UPDATE_TUTORIAL_CONTEXT", {
                  tutorial: {
                    hasInvoices: true,
                  },
                })

                if (
                  context.organisation.defaultFooter !== state.invoice.footer
                ) {
                  send("getUserOrganisations")
                }

                if (state.duplicatingInvoice) {
                  history.push(`/invoices/${response.id}`)
                }

                send("CLOSE_MODAL")
              },
            },
          },
        })
      })
    }

    const addRecipientsToInvoiceRequest = (state, send) => {
      const invoices = getInvoicesArray()
      send("addRecipients", {
        data: {
          variables: {
            batchId: state.invoice.id,
          },
          body: {
            invoices,
            organisationId: state.invoice.organisationId,
          },
        },
      })
    }

    const setState = useCallback(
      (newState) => {
        updateState({
          ...state,
          ...newState,
          invoice: {
            ...state.invoice,
            ...newState.invoice,
          },
        })
      },
      [state, updateState]
    )

    const progressStep = useCallback(() => {
      let valid = true
      if (pageRef.current && pageRef.current.validateStep) {
        valid = pageRef.current.validateStep()
      }

      if (valid) {
        setState({
          search: "",
          groups: state.groups.map((g) => {
            g.checked = false
            return g
          }),
          showSelected: false,
          step: state.step + 1,
        })
      }
    }, [setState, state.groups, state.step])

    const checkButtonPress = useCallback(
      (event) => {
        // When ENTER key is pressed an is currently on step 2 (add recipients)
        if (event.keyCode === 13 && state.step === 2) {
          progressStep()
        }
      },
      [progressStep, state.step]
    )

    useEventListener("keydown", checkButtonPress)

    let buttons = [
      { text: "Cancel", callback: () => handleClose() },
      {
        text: "Continue",
        type: "primary",
        iconType: "duotone",
        icon: "arrow-circle-right",
        submitting: "app.requests.getOrganisationBatches.requesting",
        disabled: !Array.isArray(members) || !members.length,
        callback: (event) => {
          event.preventDefault()
          progressStep()
        },
      },
    ]

    let leftButton = {
      text: "Previous",
      callback: () =>
        setState({
          search: "",
          groups: state.groups.map((g) => {
            g.checked = false
            return g
          }),
          step: state.step - 1,
        }),
    }

    switch (state.step) {
      case 1:
        leftButton = null
        break
      case 2:
        if (state.addingRecipients) {
          leftButton = null
        }
        break
      case 3:
        buttons = [
          { text: "Cancel", callback: () => handleClose() },
          {
            text: "Send now",
            type: "primary",
            icon: "paper-plane",
            submitting:
              current.matches("app.requests.createInvoiceBatch.requesting") ||
              current.matches("app.requests.addRecipients.requesting"),
            disabled: state.members && state.members.length === 0,
            callback: (event, validator) => {
              if (pageRef.current && pageRef.current.validateStep) {
                pageRef.current.validateStep(() => {
                  if (state.addingRecipients) {
                    addRecipientsToInvoiceRequest(state, send)
                  } else {
                    createInvoiceRequest(state, send)
                  }
                })
              }
            },
          },
        ]
        break
      default:
        break
    }

    const showTotalError = (value) => {
      setTotalError(value)
    }

    let title = state.addingRecipients
      ? `Step ${state.step - 1} of 2: `
      : `Step ${state.step} of 3: `
    let actions = []
    let headerClasses = ""
    switch (state.step) {
      case 1:
        title =
          title +
          (state.duplicatingInvoice ? "Duplicate invoice" : "Create invoice")
        actions = []
        headerClasses = ""
        break
      case 2:
        title = title + "Add recipients"
        headerClasses = "grid grid-cols-2"
        actions = [
          {
            hide: !(
              Array.isArray(modalData.groups) && modalData.groups.length > 0
            ),
            render: (
              <div className="inline-block">
                <FilterDropdown
                  options={modalData.groups}
                  placeholder="All groups"
                  updateOptions={(groups) => {
                    setState({
                      groups,
                    })
                  }}
                />
              </div>
            ),
          },
          {
            render: (
              <Form className="inline-block w-full">
                <Input
                  small
                  id="header-search"
                  name="search"
                  icon="search"
                  classes={{
                    input: "rounded-full pl-lg",
                  }}
                  value={state.search}
                  onChange={({ target: { value } }) =>
                    setState({ search: value })
                  }
                  placeholder="Search"
                />
              </Form>
            ),
            containerClass: "sm:flex flex-1",
          },
        ]
        break
      case 3:
        title = title + "Customise invoice"
        headerClasses = "grid grid-cols-2"
        actions = [
          {
            render: (
              <Form className="inline-block w-full">
                <Input
                  small
                  id="header-search"
                  name="search"
                  icon="search"
                  classes={{
                    input: "rounded-full pl-lg",
                  }}
                  value={state.search}
                  onChange={({ target: { value } }) =>
                    setState({ search: value })
                  }
                  placeholder="Search"
                />
              </Form>
            ),
            containerClass: "w-full",
          },
        ]
        break
      default:
        break
    }

    return (
      <div>
        <Header
          title={title}
          actions={actions}
          className={headerClasses}
          actionContainerClasses="sm:flex flex-1"
        />
        <Form>
          <Body
            component={getStepPage}
            state={{
              state,
              setState,
              members,
              showTotalError,
              totalError,
            }}
            ref={pageRef}
          />
          <Footer buttons={buttons} leftButton={leftButton} />
        </Form>
      </div>
    )
  }
)
