import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"

export default () => {
  const [redirect, setRedirect] = useState(false)
  useEffect(() => {
    localStorage.removeItem("app-state")
    setRedirect(true)
  }, [])

  if (redirect) {
    return <Redirect to="/signin" />
  }

  return <React.Fragment />
}
