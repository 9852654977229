import React from "react"
import moment from "moment"
import InvoicePreview from "../../components/Modals/Invoice/Preview"
import { rowsToMembersTransform } from "../transformers"
import { cannotSendIndividualReminderConfirmation } from "../confirmations/invoice"
import ShareableLinks from "../../components/containers/ShareableLinks"
import { defaults } from "./config"

// Payload
export const cannotCreateInvoicePayload = (send, context) => ({
  id: "cannotCreateInvoice",
  title: "Cannot create invoice",
  body: [
    "You must have at least one member in your club to create an invoice.",
    "Go to 'Members' where you can import your member spreadsheet or add members manually.",
  ],
  buttons: [
    {
      text: "Cancel",
      colWidth: 4,
      callback: () => send("CLOSE_CONFIRMATION"),
    },
    {
      text: "Go to members",
      type: "primary",
      icon: "arrow-circle-right",
      colWidth: 7,
      iconType: "duotone",
      callback: () => send("CLOSE_MODAL"),
      to: "/members",
    },
  ],
})

export const createInvoicePayload = (organisation = {}) => ({
  id: "createInvoice",
  title: "Create invoice",
  state: {
    step: 1,
    groups: [],
    search: "",
    showSelected: false,
    invoice: {
      title: "",
      dueDate: "",
      description: "",
      footer: organisation.defaultFooter,
      lineItems: [{ description: "", amount: "" }],
    },
    members: [],
    selectedId: null,
  },
  confirmOnClose: true,
  componentPath: "CreateInvoice",
  modalSize: "lg",
  overrideLayout: true,
})

export const viewMemberInvoicePayload = (invoice) => ({
  title: "View Invoice",
  id: "viewMemberInvoice",
  state: {
    invoice,
  },
  overrideLayout: true,
  componentPath: "Invoice",
  modalSize:
    invoice.lineItems.reduce((a, b) => a + parseInt(b.amount), 0) ===
    invoice.payments.reduce((a, b) => a + parseInt(b.amount), 0)
      ? "md"
      : "xl",
})

const viewInvoicePayload = (invoice, ownInvoice) => ({
  title: "View Invoice",
  id: "viewInvoice",
  state: invoice,
  ownInvoice,
  overrideLayout: true,
  modalSize: ownInvoice ? "md" : "xl",
  componentPath: "Invoice",
})

const viewAddRecipientsPayload = (invoice) => ({
  id: "addRecipients",
  title: "Add recipients",
  state: {
    step: 2,
    invoice,
    addingRecipients: true,
    search: "",
    showSelected: false,
    selectedId: null,
    groups: [],
  },
  confirmOnClose: true,
  componentPath: "CreateInvoice",
  modalSize: "lg",
  overrideLayout: true,
})

const editInvoicePayload = (invoice, lineItems, member) => ({
  id: "editInvoice",
  modalSize: "md",
  confirmOnClose: true,
  state: {
    invoice: {
      ...invoice,
      lineItems,
    },
    member,
  },
  overrideLayout: true,
  componentPath: "EditInvoice",
})

const duplicateInvoicePayload = (invoice) => ({
  id: "duplicatingInvoice",
  title: "Duplicate invoice",
  state: {
    step: 1,
    invoice: {
      title: invoice.title,
      dueDate: invoice.dueDate,
      description: invoice.description,
      footer: invoice.footer,
      lineItems: invoice.lineItems,
    },
    members: invoice.members,
    duplicatingInvoice: true,
    search: "",
    showSelected: false,
    selectedId: null,
    groups: [],
  },
  confirmOnClose: true,
  componentPath: "CreateInvoice",
  modalSize: "lg",
  overrideLayout: true,
})

// Modal requests
export const viewInvoiceModal = (
  batchId,
  memberId,
  organisationId,
  send,
  ownInvoice = false
) => {
  send("getInvoiceDetails", {
    data: {
      variables: {
        organisationId,
        batchId,
        memberId,
        queryString: {
          storeViewActivity: ownInvoice,
        },
      },
      storeData: false,
      onSuccess: {
        callback: ({ response }) => {
          send("OPEN_MODAL", {
            payload: viewInvoicePayload(response, ownInvoice),
          })
        },
      },
    },
  })
}

export const viewCreateInvoice = (send, context) => {
  const variables = {
    organisationId: context.organisation.id,
  }

  send("checkForExistingMembers", {
    data: {
      variables,
      onSuccess: {
        callback: ({ response }) => {
          if (response.count) {
            send("OPEN_MODAL", {
              payload: createInvoicePayload(context.organisation),
            })

            send("getGroupsAndMembers", {
              data: {
                getGroups: {
                  variables,
                  storeData: false,
                },
                getMembers: {
                  variables,
                  transformResponse: rowsToMembersTransform,
                  storeData: false,
                },
                onSuccess: {
                  callback: (responses) => {
                    let updatedModalData = {}
                    responses.map(({ response }) => {
                      updatedModalData = {
                        ...updatedModalData,
                        ...response,
                      }
                      return response
                    })

                    updatedModalData.groups = [
                      ...updatedModalData.groups,
                      {
                        id: 0,
                        checked: false,
                        text: "No group",
                      },
                    ]

                    send("UPDATE_MODAL_CONTEXT", {
                      payload: {
                        modalData: updatedModalData,
                      },
                    })
                  },
                },
              },
            })
          } else {
            send("CLOSE_MODAL")
            send("OPEN_CONFIRMATION", {
              payload: cannotCreateInvoicePayload(send, context),
            })
          }
        },
      },
    },
  })
}

export const addRecipientsModal = (send, organisation, batchId) => {
  const variables = {
    organisationId: organisation.id,
  }
  send("getGroupsAndMembers", {
    data: {
      getGroups: {
        variables,
        storeData: false,
      },
      getMembers: {
        variables,
        transformResponse: rowsToMembersTransform,
        storeData: false,
      },
      onSuccess: {
        callback: (responses) => {
          let updatedModalData = {}
          responses.map(({ response }) => {
            updatedModalData = {
              ...updatedModalData,
              ...response,
            }
            return response
          })

          updatedModalData.groups = [
            ...updatedModalData.groups,
            {
              id: 0,
              checked: false,
              text: "No group",
            },
          ]

          send("getBatchDetails", {
            data: {
              variables: {
                batchId,
              },
              storeData: false,
              onSuccess: {
                callback: ({ response }) => {
                  if (response && response.batch) {
                    let dueDate = moment(response.batch.dueDate)
                    if (
                      !dueDate.isValid() ||
                      (dueDate.isValid() && dueDate.isBefore(new Date()))
                    ) {
                      dueDate = moment(new Date())
                    }
                    response.batch.dueDate = dueDate.toDate()

                    send("OPEN_MODAL", {
                      payload: viewAddRecipientsPayload(response.batch),
                    })
                    send("UPDATE_MODAL_CONTEXT", {
                      payload: {
                        modalData: updatedModalData,
                      },
                    })
                  }
                },
              },
            },
          })
        },
      },
    },
  })
}

export const viewEditInvoiceModal = (send, invoice, member) => {
  const lineItems = invoice.lineItems
  lineItems.push({ amount: "", description: "" })
  send("OPEN_MODAL", {
    payload: editInvoicePayload(invoice, lineItems, member),
  })
}

export const viewShareInvoiceModal = (send, invoice, member) => {
  send("OPEN_MODAL", {
    payload: {
      ...defaults,
      id: "shareInvoiceModal",
      title: "Share link",
      overrideDefaultButtons: true,
      body: [
        <div className="mb-md">
          Copy the link below and share it with {member.name}.
        </div>,
        <ShareableLinks urls={[invoice.shareUrl]} />,
      ],
      buttons: [
        {
          text: "Close",
          colWidth: 4,
          callback: () => send("CLOSE_MODAL"),
        },
      ],
    },
  })
}

export const viewDuplicateInvoiceModal = (
  send,
  { batchId, organisationId }
) => {
  send("getBatchDetails", {
    data: {
      variables: { batchId, organisationId },
      storeData: false,
      onSuccess: {
        callback: ({ response }) => {
          send("getGroupsAndMembers", {
            data: {
              getGroups: {
                variables: {
                  organisationId,
                },
                storeData: false,
              },
              getMembers: {
                variables: {
                  organisationId,
                },
                transformResponse: rowsToMembersTransform,
                storeData: false,
              },
              onSuccess: {
                callback: (responses) => {
                  let updatedModalData = {}
                  responses.map(({ response }) => {
                    updatedModalData = {
                      ...updatedModalData,
                      ...response,
                    }
                    return response
                  })

                  updatedModalData.groups = [
                    ...updatedModalData.groups,
                    {
                      id: 0,
                      checked: false,
                      text: "No group",
                    },
                  ]
                  send("UPDATE_MODAL_CONTEXT", {
                    payload: {
                      modalData: updatedModalData,
                    },
                  })
                },
              },
            },
          })

          if (response && response.batch) {
            let dueDate = moment(response.batch.dueDate)
            if (
              !dueDate.isValid() ||
              (dueDate.isValid() && dueDate.isBefore(new Date()))
            ) {
              dueDate = moment(new Date())
            }
            response.batch.dueDate = dueDate.toDate()

            send("OPEN_MODAL", {
              payload: duplicateInvoicePayload(response.batch),
            })
          }
        },
      },
    },
  })
}

export const viewInvoiceAsMemberModal = (send, { organisation, batchId }) => {
  send("getBatchDetails", {
    data: {
      variables: {
        batchId,
      },
      storeData: false,
      onSuccess: {
        callback: ({ response }) => {
          send("OPEN_CONFIRMATION", {
            payload: {
              id: "invoicePreview",
              title: "Invoice preview",
              component: (
                <InvoicePreview
                  invoice={response.batch}
                  organisation={organisation}
                  send={send}
                />
              ),
            },
          })
        },
      },
    },
  })
}

export const viewSendRemindersModal = (
  send,
  {
    memberId,
    batchId,
    invoiceReminder,
    updatedMember,
    newEmail,
    resentInvoices = false,
    closeIfError = false,
    allowResending = false,
  },
  callback
) => {
  const onSuccess = {
    callback: ({ response }) => {
      if (!response.modalData.data.length && updatedMember) {
        send("CLOSE_MODAL")
      } else {
        const recentlyCreatedInvoices = response.modalData.data.filter(
          (invoice) =>
            moment().isBefore(moment(invoice.dateSent).add(30, "minutes"))
        )
        const recentlyRemindedInvoices = response.modalData.data.filter(
          (invoice) =>
            moment().isBefore(
              moment(invoice.lastReminderDate).add(30, "minutes")
            )
        )
        const recentlyRemindedOrCreatedInvoices =
          response.modalData.data.filter(
            (invoice) =>
              moment().isBefore(
                moment(invoice.lastReminderDate).add(30, "minutes")
              ) ||
              moment().isBefore(moment(invoice.dateSent).add(30, "minutes"))
          )

        if (
          !resentInvoices &&
          (recentlyCreatedInvoices.length === response.modalData.data.length ||
            recentlyRemindedInvoices.length ===
              response.modalData.data.length ||
            recentlyRemindedOrCreatedInvoices ===
              response.modalData.data.length)
        ) {
          if (closeIfError) {
            send("CLOSE_MODAL")
            send("CLOSE_CONFIRMATION")
          } else {
            cannotSendIndividualReminderConfirmation(
              send,
              response.modalData.data[0],
              recentlyCreatedInvoices.length === response.modalData.data.length
            )
          }
        } else {
          send("OPEN_MODAL", {
            payload: {
              title: "Send reminders",
              id: "sendReminders",
              overrideLayout: true,
              modalData: response.modalData,
              state: {
                memberId,
                batchId,
                updatedMember,
                newEmail,
                invoiceReminder,
                resentInvoices,
                allowResending,
              },
              componentPath: "SendReminders",
            },
          })
        }
      }
      if (callback) {
        callback()
      }
    },
  }
  const transformResponse = ({ data }, context) => {
    return {
      data: {
        modalData: {
          ...context.modalData,
          data: data.rows,
        },
      },
    }
  }

  if (memberId) {
    // Update request to find member invoices with soft bounces
    send("getMemberInvoices", {
      data: {
        variables: {
          memberId,
          filters: {
            statuses: "owed",
          },
        },
        storeData: false,
        transformResponse,
        onSuccess,
        onError: {
          callback: () => {
            if (callback) {
              callback()
            }
          },
        },
      },
    })
  }

  if (batchId) {
    send("getBatchInvoices", {
      data: {
        variables: {
          batchId,
          filters: {
            statuses: "owed",
          },
        },
        storeData: false,
        transformResponse,
        onSuccess,
        onError: {
          callback: () => {
            if (callback) {
              callback()
            }
          },
        },
      },
    })
  }
}
