import React from 'react'
import moment from 'moment'

import { formatCurrency } from '../../utils/functions'
import Card from '../containers/Card'

export default function InvoiceCard ({
  invoice,
  onClick
}) {
  return <Card
    onClick={ onClick }
    success={ invoice.datePaid }
    danger={ invoice.overdue }
    title={ invoice.title }
    subtitle={ (invoice.member && invoice.member.name) ? invoice.member.name : '' }
    middleText={ invoice.organisationName }
    leftTitle="Due date"
    leftData={ moment(invoice.dueDate).format('DD MMM YYYY') }
    rightTitle="Owed"
    rightData={ formatCurrency(invoice.amount) }
  />
}
