import React, { forwardRef, useState, useEffect } from "react"
import FinaliseUpload from "./FinaliseUpload"
import SheetSelection from "./SheetSelection"

import UploadDropzone from "./UploadDropzone"
import UploadedMembers from "./UploadedMembers"

export default forwardRef(({ state, handleClose }, ref) => {
  const [ignoreFirstRow, setIgnoreFirstRow] = useState(false)
  const [worksheets, setWorksheets] = useState([])
  const [headers, setHeaders] = useState({})
  const [updatedCount, setUpdatedCount] = useState(0)
  const [droppedMembers, setUploadingMembers] = useState([])
  const [validMembers, setValidMembers] = useState([])
  const [invalidMembers, setInvalidMembers] = useState([])
  const [columnCount, setColumnCount] = useState(0)
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (state.sheetData.length) {
      if (state.sheetData.length > 1) {
        setWorksheets(state.sheetData)
        setStep(2)
      } else {
        const members = state.sheetData[0].data
        const columnCount = Math.max(
          ...members.map((memberData) => memberData.length)
        )
        setColumnCount(columnCount)
        setUploadingMembers(members)
        setStep(3)
      }
    }
  }, [state.sheetData])

  const setDroppedMembers = (sheetData) => {
    if (sheetData.length > 1) {
      setWorksheets(sheetData)
      setStep(2)
    } else {
      const members = sheetData[0].data
      const columnCount = Math.max(
        ...members.map((memberData) => memberData.length)
      )
      setColumnCount(columnCount)
      setUploadingMembers(members)
      setStep(3)
    }
  }

  useEffect(() => {
    if (droppedMembers.length) {
      const columnCount = Math.max(
        ...droppedMembers.map((memberData) => memberData.length)
      )
      setColumnCount(columnCount)
    }
  }, [droppedMembers])

  if (step === 4) {
    return (
      <FinaliseUpload
        invalidMembers={invalidMembers}
        validMembers={validMembers}
        setStep={setStep}
        updatedCount={updatedCount}
        handleClose={handleClose}
      />
    )
  }

  if (step === 3) {
    return (
      <UploadedMembers
        headers={headers}
        setHeaders={setHeaders}
        members={droppedMembers}
        uploadingMembers={droppedMembers.slice(0, 8)}
        columnCount={columnCount}
        handleClose={handleClose}
        setUpdatedCount={setUpdatedCount}
        validMembers={validMembers}
        invalidMembers={invalidMembers}
        setValidMembers={setValidMembers}
        setInvalidMembers={setInvalidMembers}
        setStep={setStep}
        ignoreFirstRow={ignoreFirstRow}
        setIgnoreFirstRow={setIgnoreFirstRow}
      />
    )
  }

  if (step === 2) {
    return (
      <SheetSelection
        worksheets={worksheets}
        setDroppedMembers={setDroppedMembers}
        handleClose={handleClose}
      />
    )
  }
  return (
    <UploadDropzone
      setDroppedMembers={setDroppedMembers}
      handleClose={handleClose}
    />
  )
})
