import React, { useContext } from "react"

import Select from "../Input/Select"
import Textarea from "../Input/Textarea"
import Button from "../Button"
import Form from "../Forms/Form"
import Header from "../containers/Header"
import { MachineContext } from "../../state"

export default function ApplicationJudgement({
  state: { judgement, choice, callback, name, fees, vouchers, isOrganisation },
  setState,
  send,
}) {
  const [current] = useContext(MachineContext)
  const updateDetails = ({ target: { name, value } }) => {
    setState({
      judgement: {
        ...judgement,
        [name]: value,
      },
    })
  }

  const selectFee = ({ target: { value } }) => {
    const fee = fees.filter((orgFee) => orgFee.name === value)[0]
    setState({
      judgement: {
        ...judgement,
        fee: {
          ...fee,
          text: fee.name,
        },
      },
    })
  }

  const selectVoucher = ({ target: { value } }) => {
    const voucher = vouchers.filter((voucher) => voucher.name === value)[0]
    setState({
      judgement: {
        ...judgement,
        voucher: {
          ...voucher,
          text: voucher.name,
        },
      },
    })
  }

  return (
    <Form
      state={judgement}
      onSubmit={(event, validator, validateForm, state) => {
        callback(state)
      }}
      className="bg-neutral-10 h-full sm:rounded"
    >
      <Header title={`${choice} ${name}`} />
      <div className="grid grid-cols-1 gap-md p-md">
        <div>
          <label
            htmlFor="judge"
            className="text-neutral-5 block font-medium mb-sm"
          >
            {choice === "Approve" ? "Approved by" : "Rejected By"}
          </label>
          <Select
            large
            name="judge"
            fullWidth
            value={judgement.judge}
            onChange={updateDetails}
            placeholder="Select administrator"
            options={["Daniel", "Jay", "Luke", "Simon"]}
            className="w-full h-full"
            menuPlacement="top"
            validation={{
              messages: {
                required: `Select who you are`,
              },
              rules: `required`,
            }}
          />
        </div>
        <div>
          <Textarea
            name="reasoning"
            label="Reason"
            rows="5"
            value={judgement.reasoning}
            onChange={updateDetails}
            validation={{
              messages: {
                required: `Give a reason behind your decision`,
              },
              rules: `required`,
            }}
          />
        </div>
        {choice === "Approve" && (
          <div>
            <label
              htmlFor="fee"
              className="text-neutral-5 block font-medium mb-sm"
            >
              Fee structure
            </label>
            <Select
              large
              name="fee"
              fullWidth
              disabled={current.matches("app.requests.getAppFees.requesting")}
              value={judgement.fee.text}
              onChange={selectFee}
              placeholder="Select fee structure"
              options={fees.map((fee) => {
                return {
                  ...fee,
                  text: fee.internalName,
                }
              })}
              className="w-full h-full"
              menuPlacement="top"
              validation={{
                messages: {
                  required: `Select fee structure`,
                },
                rules: `required`,
              }}
            />
          </div>
        )}
        {choice === "Approve" && isOrganisation && (
          <div>
            <label
              htmlFor="fee"
              className="text-neutral-5 block font-medium mb-sm"
            >
              Initial volume credit
            </label>
            <Select
              large
              name="voucher"
              fullWidth
              disabled={current.matches(
                "app.requests.getAppVouchers.requesting"
              )}
              value={judgement.voucher.text}
              onChange={selectVoucher}
              placeholder="Select initial volume credit"
              options={vouchers.map((voucher) => {
                return {
                  ...voucher,
                  text: voucher.name,
                }
              })}
              className="w-full h-full"
              menuPlacement="top"
              validation={{
                messages: {
                  required: `Select initial volume credit`,
                },
                rules: `required`,
              }}
            />
          </div>
        )}
      </div>
      <div className="sm:relative fixed bottom-0 inset-x-0 items-center justify-end flex sm:shadow-t-border sm:bg-white bg-neutral-10 my-auto h-2xl rounded-b">
        <div className="sm:block hidden px-md">
          <div className="flex my-auto">
            <div
              onClick={() => send("CLOSE_MODAL")}
              className="text-primary-6 px-md cursor-pointer my-auto"
            >
              Cancel
            </div>
            <Button
              icon={
                choice === "Approve" ? "check-circle" : "exclamation-circle"
              }
              label={choice}
              iconType="duotone"
              type={choice === "Approve" ? "primary" : "danger"}
              submitting={
                choice === "Approve"
                  ? current.matches("app.requests.approveClub.requesting") ||
                    current.matches("app.requests.approveAffiliate.requesting")
                  : current.matches("app.requests.rejectClub.requesting") ||
                    current.matches("app.requests.rejectAffiliate.requesting")
              }
            />
          </div>
        </div>
        <div className="sm:hidden p-sm bg-white rounded-b shadow-t-border w-full">
          <div className="grid gap-md grid-cols-5">
            <div
              onClick={() => send("CLOSE_MODAL")}
              className="text-neutral-5 px-md cursor-pointer col-span-2 shadow-border py-sm rounded text-center flex items-center justify-center"
            >
              Cancel
            </div>
            <Button
              containerStyle="col-span-3"
              label={choice}
              large
              type={choice === "Approve" ? "primary" : "danger"}
              submitting={
                current.matches("app.requests.approveClub.requesting") ||
                current.matches("app.requests.rejectClub.requesting") ||
                current.matches("app.requests.approveAffiliate.requesting") ||
                current.matches("app.requests.rejectAffiliate.requesting")
              }
            />
          </div>
        </div>
      </div>
    </Form>
  )
}
