import React, { useContext } from 'react'
import Input from '../Input'
import { MachineContext } from '../../state'

export default function ChangeEmail({ state: { data, confirmEmail }, setState }) {
  const [{ context: { user } }, send] = useContext(MachineContext)

  const handleNewEmail = ({ target: { value }}) => {
    send('UPDATE_CONTEXT', { updatedEmail: value  })
    setState({ data: { email: value }})
  }

  return (
    <div className="grid grid-cols-1 gap-md">
      <Input
        large
        label="Current email"
        name="currentEmail"
        value={user.email}
        type="email"
        disabled
      />
      <Input
        large
        label="New email"
        name="email"
        value={data.email}
        type="email"
        placeholder="New email"
        onChange={handleNewEmail}
        allowemailToggle
        validation={{
          messages: {
            required: 'Enter a new email',
            not_in: 'Enter a new email'
          },
          rules: `required|email|not_in:${user.email}`
        }}
      />
      <Input
        large
        name="confirmEmail"
        value={confirmEmail}
        type="email"
        placeholder="Confirm new email"
        onChange={({ target: { value } }) => setState({ confirmEmail: value })}
        allowemailToggle
        validation={{
          messages: {
            in: 'Emails need to match',
            required: 'Confirm your new email'
          },
          rules: `required|in:${data.email}|email`,
        }}
      />
    </div>
  )
}
