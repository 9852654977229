import React from "react"
import PayzipLogo from "../../assets/img/logo.svg"
import PayzipDangerLogo from "../../assets/img/logo-danger.svg"
import PayzipSuccessLogo from "../../assets/img/logo-success.svg"
import { Link } from "react-router-dom"

export default ({
  logo,
  background,
  enableLogoLink = false,
  rounded = true,
  logoPath = "/",
}) => {
  let topColour = "bg-neutral-9"
  let bottomColour = "bg-neutral-10"
  let renderLogo = PayzipLogo

  switch (background) {
    case "primary":
      topColour = "bg-primary-9"
      bottomColour = "bg-primary-10"
      break
    case "danger":
      topColour = "bg-danger-9"
      bottomColour = "bg-danger-10"
      renderLogo = PayzipDangerLogo
      break
    case "success":
      topColour = "bg-success-7"
      bottomColour = "bg-success-10"
      renderLogo = PayzipSuccessLogo
      break
    default:
      break
  }

  return (
    <React.Fragment>
      <div className="top-ribbon relative">
        <div
          className={`rect ${topColour} ${rounded ? "sm:rounded-tr" : ""}`}
        ></div>
        <div className={`ribbon ${topColour}`}></div>
      </div>
      {logo ? (
        enableLogoLink ? (
          <Link
            className="w-full max-w-full pt-md pl-md absolute top-0 left-0"
            to={logoPath}
          >
            <img
              className="mb-md"
              src={renderLogo}
              alt="Payzip"
              width="100px"
            />
          </Link>
        ) : (
          <div className="w-full max-w-full pt-md pl-md absolute top-0 left-0">
            <img
              className="mb-md"
              src={renderLogo}
              alt="Payzip"
              width="100px"
            />
          </div>
        )
      ) : (
        <React.Fragment />
      )}
      <div className="bottom-ribbon">
        <div className={`ribbon-1 ${bottomColour}`}></div>
        <div className={`rect ${bottomColour}`}></div>
        <div className={`ribbon-2 ${bottomColour}`}></div>
      </div>
      <div className="clear-both" />
    </React.Fragment>
  )
}
