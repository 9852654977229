import React, { useRef, useEffect, forwardRef, useState } from 'react'
import Activity from './Activity'

export default forwardRef(({
  activity = [],
  reverse = false
}, ref) => {
  const [currentActivity, setActivity] = useState(activity)

  const renderedActivity = currentActivity.map((activity, index) => <Activity key={ 'activity-' + activity.id + index } activity={ activity } />)
  const container = useRef()
  const shouldScrollRef = useRef(false)

  useEffect(() => {
    setActivity(activity)
  }, [activity])

  useEffect(() => {
    if (currentActivity.length !== activity.length) {
      shouldScrollRef.current = currentActivity.length < activity.length
    }
  }, [activity, currentActivity.length, ref])

  useEffect(() => {
    if (shouldScrollRef.current) {
      ref.current.scrollIntoView({ behaviour: 'smooth', block: 'end', inline: 'nearest' })
    }
  }, [currentActivity, ref])

  useEffect(() => {
    if (reverse) {
      ref.current.scrollIntoView({ behaviour: 'smooth', block: 'end', inline: 'nearest' })
    }
  }, [reverse, ref])

  return (
    <div ref={ container }>
      <div className={ `activity-feed${reverse ? '--reverse' : ''} pr-md mt-xs` }>
        {
          reverse ?
            renderedActivity.reverse() :
            renderedActivity
        }
      </div>
      <span className="float-left clear-both" ref={ ref } />
    </div>
  )
})
