import React, { useContext } from "react"

import { MachineContext } from "../../../state"
import Header from "../Header"
import InvoiceCard from "../../Cards/Invoice"
import PaymentCard from "../../Cards/Payment"
import MobileHeader from "../../containers/MobileHeader"
import PingCard from "../../Cards/Ping"
import SkeletonRow from "./Row"

const SkeletonTable = ({
  rowCount = 3,
  cells = [],
  headerCells = [],
  header,

  withShadow = true,
  className,
  rounding = true,
  // ONLY USED ON MOBILE
  cardType = "",
}) => {
  const [current] = useContext(MachineContext)
  const { tableProps } = current.context

  const renderTableBody = () => {
    return (
      <div>
        {/* DESKTOP TABLE VIEW */}
        <div className="sm:block hidden">
          <div>
            <table className="table-fixed w-full">
              <thead className="bg-neutral-10 rounded-t">
                {/* <tr className="border-b border-neutral-8 rounded-t"> */}
                {/** ADD HEADER SKELETON */}
                <SkeletonRow rowKey="header" cells={headerCells} header />
                {/* </tr> */}
              </thead>
              <tbody>
                {[...Array(rowCount)].map((_, index) => {
                  return (
                    <SkeletonRow
                      key={`skeleton-row-${index}`}
                      rowKey={index}
                      cells={cells}
                      roundBottom={index + 1 === rowCount && rounding}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* MOBILE TABLE VIEW */}
        <div className="sm:hidden block">
          {/* TODO: SKELETON CARDS */}
          {tableProps.data &&
            tableProps.data.length > 0 &&
            tableProps.data.map((row, i) => {
              switch (cardType) {
                case "invoice":
                  return <InvoiceCard key={"card-" + i} invoice={row} />
                case "payment":
                  return <PaymentCard key={"card-" + i} payment={row} />
                case "ping":
                  return <PingCard key={"card-" + i} ping={row} />
                default:
                  return <React.Fragment key={"empty-card-" + i} />
              }
            })}
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      {/* DESKTOP TABLE VIEW */}
      <div
        className={`sm:block hidden ${
          withShadow && "sm:shadow-container "
        } sm:rounded sm:overflow-hidden`}
      >
        {header && <Header header={header} />}
        {renderTableBody()}
      </div>

      {/* MOBILE TABLE VIEW */}
      <div className="sm:hidden block bg-neutral-10">
        <MobileHeader />

        {renderTableBody()}
      </div>
    </div>
  )
}

export default SkeletonTable
