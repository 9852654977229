import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "createAffiliate",
    payload: {
      url: `/affiliate`,
      type: "post",
    },
  },
  {
    target: "getAffiliateDetails",
    payload: {
      url: ({ url }) => `/admin/affiliate/${url}/approval`,
      type: "get",
      contextKey: "approvalAffiliate",
    },
  },
  {
    target: "approveAffiliate",
    payload: {
      url: ({ token }) => `/admin/affiliate/${token}/approve`,
      type: "post",
      storeData: false,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "rejectAffiliate",
    payload: {
      url: ({ token }) => `/admin/affiliate/${token}/reject`,
      type: "post",
      storeData: false,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "deleteApplication",
    payload: {
      url: `/affiliate/delete`,
      type: "post",
      appState: true,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "restartAffiliateOnboarding",
    payload: {
      url: `/affiliate/onboarding/restart`,
      type: "post",
    },
  },
  {
    target: "getAffiliateStripeLink",
    payload: {
      url: `/affiliate/stripe`,
      type: "post",
    },
  },
  {
    target: "getAffiliateTutorial",
    payload: {
      url: `/affiliate/tutorial`,
      type: "get",
      contextKey: "affiliateTutorial",
    },
  },
  {
    target: "updateAffiliateTutorial",
    payload: {
      url: `/affiliate/tutorial`,
      type: "post",
      contextKey: "affiliateTutorial",
    },
  },
  {
    target: "affiliateLinkClick",
    payload: {
      url: ({ affiliateLink }) => `/affiliate/${affiliateLink}/click`,
      type: "post",
    },
  },
  {
    target: "getAffiliateSignups",
    payload: {
      url: ({ filters }) =>
        `/affiliate/signups${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getAffiliateStats",
    payload: {
      url: `/affiliate/stats`,
      type: "get",
    },
  },
  {
    target: "getAffiliatePayouts",
    payload: {
      url: ({ filters }) =>
        `/affiliate/payouts${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
]
