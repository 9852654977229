import React, { useEffect, useState, useContext } from "react";

import Icon from "../Icon";
import { Link } from "react-router-dom";
import { MachineContext } from "../../state";

export default ({
  icon,
  onClick,
  label,
  to,
  href,
  pathname,
  pathroot = "/",
  marginBottom = "sm",
  category = false,
  disabled,
  iconType
}) => {
  const [, send] = useContext(MachineContext);
  const [active, setActive] = useState(false);

  useEffect(() => {
    switch (to) {
      case pathroot:
        setActive(pathname === to);
        break;
      default:
        if (pathname.includes(to)) {
          setActive(true);
        } else {
          setActive(false);
        }
        break;
    }
  }, [pathname, pathroot, to]);

  const collapseCategories = () => {
    if (!category) {
      send("UPDATE_APP_STATE", { expandedMenu: undefined });
    }
  };

  const handleClick = () => {
    collapseCategories()
    onClick()
  }

  if (disabled) {
    return (
      <div className="text-neutral-5">
        <li className={`mb-${marginBottom} flex`}>
          <div className="inline-block w-28">
            <Icon className="text-neutral-5" icon={icon} baseSize="20px" type={ iconType } />
          </div>
          <div className="pl-sm inline-block my-auto text-neutral-5">
            {label}
          </div>
        </li>
      </div>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        onClick={collapseCategories}
        className="text-neutral-8"
        rel="noopener noreferrer"
        target="_BLANK"
      >
        <li className={`mb-${marginBottom} flex`}>
          <div className="inline-block w-28">
            <Icon
              className={
                active ? "text-neutral-10 font-semibold" : "text-neutral-8"
              }
              icon={icon}
              type={ iconType }
              baseSize="20px"
            />
          </div>
          <div
            className={
              "pl-sm inline-block my-auto" +
              (active ? " font-semibold text-neutral-10" : "")
            }
          >
            {label} <Icon icon="external-link" type="light" className="inline-block" baseSize="12px" />
          </div>
        </li>
      </a>
    );
  }
  if (onClick) {
    return (
      <div onClick={handleClick} className="cursor-pointer">
        <li className={`mb-${marginBottom} flex text-neutral-8`}>
          <div className="inline-block w-28">
            <Icon
              className={
                active ? "text-neutral-10 font-semibold" : "text-neutral-8"
              }
              icon={icon}
              type={ iconType }
              baseSize="20px"
            />
          </div>
          <div
            className={
              "pl-sm inline-block my-auto" +
              (active ? " font-semibold text-neutral-10" : "")
            }
          >
            {label}
          </div>
        </li>
      </div>
    );
  }
  return (
    <Link to={to} onClick={collapseCategories} className="text-neutral-8">
      <li className={`mb-${marginBottom} flex`}>
        <div className="inline-block w-28">
          <Icon
            className={
              active ? "text-neutral-10 font-semibold" : "text-neutral-8"
            }
            icon={icon}
            type={ iconType }
            baseSize="20px"
          />
        </div>
        <div
          className={
            "pl-sm inline-block my-auto" +
            (active ? " font-semibold text-neutral-10" : "")
          }
        >
          {label}
        </div>
      </li>
    </Link>
  );
};
