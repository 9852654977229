import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react"

import Checkbox from "../../Input/Checkbox"
import { useEventListener } from "../../../utils/hooks"
import { useFormValidator } from "../../Forms/Form"

export default forwardRef(
  (
    {
      step,
      isClubAdmin,
      isClubAuthed,
      isUKBased,
      acceptedTAndC,
      ageCheck,

      setState,
      nextStep,
    },
    ref
  ) => {
    const [validator] = useFormValidator()
    const [displayError, updateDisplayError] = useState(false)

    useEffect(() => {
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        let checkedFields = {
          isClubAdmin: validator.fields.isClubAdmin,
          isClubAuthed: validator.fields.isClubAuthed,
          isUKBased: validator.fields.isUKBased,
          acceptedTAndC: validator.fields.acceptedTAndC,
          ageCheck: validator.fields.ageCheck,
        }
        let unchecked = Object.values(checkedFields).filter(
          (checked) => !checked
        )

        if (!unchecked.length) {
          nextStep()
        } else {
          updateDisplayError(true)
        }
      },
    }))

    const handleCheck = ({ target }) => {
      const { name, checked } = target

      setState({ [name]: checked })

      let currentState = {
        isClubAdmin,
        isClubAuthed,
        isUKBased,
        acceptedTAndC,
        ageCheck,
      }
      currentState[name] = checked

      if (!Object.values(currentState).filter((check) => !check).length) {
        updateDisplayError(false)
      }
    }

    const checkKeyPress = useCallback(
      (event) => {
        if (event.keyCode === 13 && step === 1) {
          let checkedFields = {
            isClubAdmin: validator.fields.isClubAdmin,
            isClubAuthed: validator.fields.isClubAuthed,
            isUKBased: validator.fields.isUKBased,
            acceptedTAndC: validator.fields.acceptedTAndC,
            ageCheck: validator.fields.ageCheck,
          }
          let unchecked = Object.values(checkedFields).filter(
            (checked) => !checked
          )

          if (!unchecked.length) {
            nextStep()
          } else {
            updateDisplayError(true)
          }
        }
      },
      [step, validator, nextStep, updateDisplayError]
    )

    useEventListener("keydown", checkKeyPress)

    return (
      <div>
        <div className="mb-md">
          <div className="text-neutral-5 font-medium mb-xs">
            Important information
          </div>
          <div className="grid grid-cols-1 gap-xs">
            <div>
              Having a club account allows you to create invoices for your
              members, which they can pay online. Payzip does not charge a fee
              to cardholders who pay this way. A service fee is deducted from
              the money paid to the club. More information can be found in the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.payzip.co.uk/article/77-is-there-a-fee-for-using-payzip"
              >
                Payzip knowledgebase
              </a>
              .
            </div>
            <div>
              Before creating your club account, we need to verify who you are
              and how you intend to use Payzip. In this process you will:
            </div>
            <ul className="list-disc pl-md">
              <li>confirm eligibility information about you and your club;</li>
              <li>
                provide personal details so we can contact you if we have
                further questions;
              </li>
              <li>tell us more about your club and its online presence.</li>
            </ul>
          </div>
        </div>
        <div className="text-neutral-5 font-medium mb-xs">Declaration</div>
        <div>
          To use Payzip, you must tick to confirm you agree to each of the
          following declarations. We will use these details to contact you if
          necessary and ensure that you meet our criteria for us to accept you
          as a customer.
        </div>
        <div className="grid grid-cols-1 gap-sm mt-sm">
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="ageCheck"
            checked={ageCheck}
            label="I am over 18"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="isClubAdmin"
            checked={isClubAdmin}
            label="I am a club administrator"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="isClubAuthed"
            checked={isClubAuthed}
            label="I am authorised to act on the club’s behalf"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="isUKBased"
            checked={isUKBased}
            label="My club is a UK-based member organisation"
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          />
          <Checkbox
            withGrid={false}
            iconSize="16px"
            onChange={handleCheck}
            name="acceptedTAndC"
            checked={acceptedTAndC}
            checkboxMargin="mr-sm"
            validation={{
              rules: "accepted",
            }}
            showError={displayError}
          >
            I accept Payzip's{" "}
            <a
              href="https://www.payzip.co.uk/terms-and-conditions.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              terms and conditions
            </a>
          </Checkbox>
        </div>
        {displayError ? (
          <div className="text-danger-5 mt-sm">
            You must be able to truthfully confirm and tick each of the
            checkboxes above to create a club
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
)
