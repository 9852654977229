import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({
  icon,
  iconClassName = '',
  className = '',
  onClick,
  customStyle,
  type = 'light',
  baseSize = '14px',
  size,
  pulse = false,
  layer = false,
  textPosition = 'center',
  colour,
  colours = {
    primary: '#627D98',
    secondary: '#D9E2EC'
  },
  layerOptions = {
    circleSize: '1.429rem',
    iconSize: '0.929rem'
  },
  width = 'md',
  height = 'md'
}) => {
  const [chosenIcon, setIcon] = useState(icon)

  useEffect(() => {
    setIcon(icon)
  }, [icon])

  let iconStyle = {}

  let iconPrefix = 'fas'
  switch (type) {
    case 'brand':
      iconPrefix = 'fab'
      break
    case 'solid':
      iconPrefix = 'fas'
      break
    case 'regular':
      iconPrefix = 'far'
      break
    case 'light':
      iconPrefix = 'fal'
      break
    case 'duotone':
      iconPrefix = 'fad'
      iconStyle = {
        "--fa-primary-color": colours.primary,
        "--fa-secondary-color": colours.secondary,
        "--fa-primary-opacity": 1,
        "--fa-secondary-opacity": 1
      }
      break
    default:
      break
  }

  if (!icon) {
    return <React.Fragment />
  }

  if (layer) {
    return (
      <span className={ `fa-layers fa-fw h-${height} w-${width} flex items-center justify-center` } style={ { ...customStyle } }>
        <span style={ { fontSize: layerOptions.circleSize } }>
          <FontAwesomeIcon icon={ ['fas', 'circle'] } color={ colours.secondary } />
        </span>
        <span style={ { fontSize: layerOptions.iconSize } }>
          <FontAwesomeIcon pulse={ pulse } icon={ [iconPrefix, chosenIcon] } color={ colours.primary } />
        </span>
      </span>
    )
  }

  return (
    <div
      className={ `text-${textPosition} ${className}` }
      onClick={ onClick }
      style={ { fontSize: baseSize, lineHeight: baseSize, ...customStyle, ...iconStyle } }
    >
      <FontAwesomeIcon pulse={ pulse } icon={ [iconPrefix, chosenIcon] } size={ size } className={ iconClassName } color={ colour } />
    </div>
  )
}
