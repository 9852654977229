import React, { useImperativeHandle, forwardRef, useEffect } from "react"

import { useFormValidator } from "../../Forms/Form"
import AboutYou from "../../Forms/AboutYou"

export default forwardRef(
  (
    {
      name,
      dob,
      addressLine1,
      addressLine2,
      town,
      postcode,
      phoneNumber,

      setState,
      nextStep,
    },
    ref
  ) => {
    const [validator, { validateForm }] = useFormValidator()

    useEffect(() => {
      validator.purgeFields()
      validator.hideMessages()
      validator.visibleFields.map((field) => {
        validator.hideMessageFor(field)
        return field
      })
    }, [validator])

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        validateForm()

        if (validator.allValid()) {
          nextStep()
        }
      },
    }))

    return (
      <AboutYou
        contactInfoText="We need these details to contact you, for example if we have trouble paying money into your account."
        name={name}
        dob={dob}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        town={town}
        postcode={postcode}
        phoneNumber={phoneNumber}
        setState={({ target: { name, value } }) => setState({ [name]: value })}
        order="stacked"
      />
    )
  }
)
