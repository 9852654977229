import React, { useContext, useEffect, useState } from "react"

import { MachineContext } from "../../state"
import {
  displayStripeSignupModal,
  restartOnboardingModal,
} from "../../utils/modals/organisation"
import Button from "../Button"
import Banner from "../containers/Banner"

export default function StripeConnectBanner() {
  const [current, send] = useContext(MachineContext)
  const [displayData, setDisplayData] = useState()
  const [approved, setApproved] = useState(false)
  const [affiliate, setAffiliate] = useState()
  const [isAffiliateLink, setIsAffiliateLink] = useState(false)
  const { organisation, organisations, user } = current.context

  useEffect(() => {
    if (displayData) {
      setApproved(displayData.verified || displayData.approved)
    }
  }, [displayData])

  useEffect(() => {
    if (
      user.affiliateProfile &&
      (!user.affiliateProfile.approved || !user.affiliateProfile.stripeApproved)
    ) {
      setDisplayData(user.affiliateProfile)
      setAffiliate(user)
      setIsAffiliateLink(true)
    } else {
      let org =
        organisation && organisation.verified
          ? organisations.filter((org) => {
              return (
                !org.verified ||
                (org.verified && !org.stripeConnection) ||
                (org.verified && !org.payoutsEnabled)
              )
            })[0]
          : organisation

      setDisplayData(org)
      setIsAffiliateLink(false)
    }
  }, [organisation, organisations, user])

  const restartOnboarding = () => {
    restartOnboardingModal(send, {
      application: displayData,
      affiliate,
      isAffiliateLink,
    })
  }

  const displayStripeWarning = () => {
    if (displayData && displayData.stripeConnection) {
      window.location.href = displayData.stripeUrl
    } else {
      displayStripeSignupModal(send, isAffiliateLink)
    }
  }

  if (displayData) {
    const bannerActions = (
      <div className="inline">
        <div className="px-sm inline">
          <Button
            type="primary"
            border={false}
            layer
            icon="stripe-s"
            iconType="brand"
            onClick={displayStripeWarning}
          >
            Connect to Stripe
          </Button>
        </div>
        <div className="inline">
          Or{" "}
          <span
            onClick={restartOnboarding}
            className="cursor-pointer text-primary-4"
          >
            Start over
          </span>
        </div>
      </div>
    )

    let bannerText = ""
    if (!approved) {
      return (
        <Banner>
          {displayData.name
            ? `We are reviewing your application for ${displayData.name} and will be in touch soon`
            : "We are reviewing your application to become an affiliate and will be in touch soon"}
        </Banner>
      )
    }

    if (approved && !displayData.stripeConnection && displayData.stripeUrl) {
      if (displayData.status === "detailsRejected") {
        bannerText =
          "Add/update your bank details in Stripe to receive payouts from Payzip"
      } else {
        bannerText = displayData.name
          ? `${displayData.name} approved, now connect to Stripe`
          : "You have been approved, now connect to Stripe"
      }

      return (
        <Banner className="text-center">
          <div className="inline">{bannerText}</div>
          {bannerActions}
        </Banner>
      )
    }

    if (displayData.status === "pendingVerification") {
      return (
        <Banner>
          Your identity documents are currently being verified by Stripe.
        </Banner>
      )
    }

    if (approved && !displayData.payoutsEnabled && displayData.stripeUrl) {
      if (displayData.status === "documentsRequired") {
        bannerText = "Your Stripe account application is incomplete"
      } else {
        bannerText = "Upload your ID documents to complete Stripe application"
      }

      return (
        <div className="py-sm bg-primary-8">
          <div
            className={`text-center mx-auto my-xs px-xl ${
              user.roles.includes("admin")
                ? "max-w-admin-dashboard"
                : "max-w-base"
            }`}
          >
            {displayData.errors && displayData.errors.length > 0 && (
              <div className="border-primary-6 border rounded mb-sm p-xs text-primary-1 bg-primary-7">
                <ul>
                  {displayData.errors
                    .filter(
                      (item, i, arr) => arr.indexOf(item) === i // Get array of unique errors
                    )
                    .map((error, index) => {
                      return <li key={`orgErr${index}`}>{error}</li>
                    })}
                </ul>
              </div>
            )}
            <div className="text-center pt-xs">
              <div className="inline">{bannerText}</div>
              {bannerActions}
            </div>
          </div>
        </div>
      )
    }
  }

  return <React.Fragment />
}
