import React from "react"
import AffiliateSidebar from "./AffiliateSidebar"
import ClubSidebar from "./ClubSidebar"

const SideBar = ({ type = "organisation", ...props }) => {
  switch (type) {
    case "organisation":
      return <ClubSidebar {...props} />
    case "affiliate":
      return <AffiliateSidebar {...props} />
    default:
      return <React.Fragment />
  }
}

export default SideBar
