import React from "react"
import moment from "moment"

import { formatCurrency } from "../../utils/functions"

const SkeletonCell = ({
  right,
  selectable,
  render,
  below,
  row,
  cellKey,
  format,
  defaultValue,
  danger,
  success,
  styles = {},
  roundBottom,
}) => {
  const formatRow = (value, format, defaultValue) => {
    const displayValue = value || defaultValue
    switch (format) {
      case "date":
        return moment(displayValue).format("DD MMM YYYY")
      case "currency":
        return formatCurrency(displayValue)
      default:
        return displayValue
    }
  }

  const rounding = roundBottom ? "first:rounded-bl last:rounded-br" : ""

  const renderRow = () => {
    let renderer = (
      <div>
        {formatRow(row[cellKey], format, defaultValue)}
        {below && <div className="text-neutral-5">{row[below]}</div>}
      </div>
    )
    if (render) {
      renderer = render(row)
    }

    return renderer
  }

  return (
    <td
      className={`${right ? "text-right " : "text-left "}
        ${!selectable ? "first:pl-md " : ""}
        last:pr-md py-xs ${styles.cell || ""}
        ${danger ? "danger-row" : ""}
        ${success ? "shadow-success-row" : ""}
        ${rounding}`}
    >
      {renderRow()}
    </td>
  )
}

export default SkeletonCell
