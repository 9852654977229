import React from 'react'

import Table from '../../Table'
import Icon from '../../Icon'

export default ({
  selectAll,
  selectRow,
  selectedRows,
  members
}) => {
  const renderItem = (row, key) => {
    return <div className={ row.undeliverable ? 'text-danger-5' : '' }>
      { row[key] }
    </div>
  }

  return (
    <div className="overflow-y-auto sm:max-h-modal-body max-h-modal-mobile bg-neutral-10">
      <div className="p-md shadow-b-border text-neutral-5">
        Choose who should receive the updated invoice. You can choose not to send it to anyone.
      </div>
      <Table
        disableLoadingSpinner
        withShadow={ false }
        onSelectAll={ selectAll }
        onRowSelect={ selectRow }
        onRowDeselect={ selectRow }
        hasRounding={ false }
        selectable
        isDangerRow={(row) => row.overdue && row.owed > 0}
        selectedRows={ selectedRows }
        allSelected={ selectedRows.length > 0 && selectedRows.length === members.filter(member => !member.ignoreReminders && !member.undeliverable).length }
        headers={
          [
            {
              name: 'Member',
              render: row => renderItem(row, 'name')
            },
            {
              name: 'Email',
              render: row => renderItem(row, 'email')
            },
          ]
        }
        data={
          members.map(member => {
            let unselectableIcon = <Icon type="duotone" icon="bell-slash" baseSize="20px" />
            if (member.undeliverable) {
              unselectableIcon = <Icon
                type="duotone"
                icon='exclamation-circle'
                baseSize="20px"
                colours={{
                  primary: '#FFEEEE',
                  secondary: '#BA2525'
                }}
              />
            }
            return {
              ...member,
              isSelectable: !(member.ignoreReminders || member.undeliverable),
              unselectableIcon
            }
          }).sort((a, b) => {
            if (b.undeliverable === a.undeliverable) {
              if (a.ignoreReminders === b.ignoreReminders) {
                return a.name.localeCompare(b.name)
              } else {
                return b.ignoreReminders ? 1 : -1
              }
            } else {
              return b.undeliverable ? 1 : -1
            }
          })
        }
      />
    </div>
  )
}
