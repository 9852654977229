import React from "react";

export default ({ state }) => {
  return (
    <div>
      <div className="pb-sm">
        {
          state.isAffiliateLink ?
            'You have been approved but your Stripe application is not yet complete.' :
            `${state.organisation.name} has been approved but your Stripe application is not yet complete.`
        }
      </div>
      <div>
        You can either delete everything and start again, continue with your current application or start a new Stripe application.
      </div>
    </div>
  );
};
