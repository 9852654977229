import { getMembers, getMembersWithoutStats } from "./members"

const getGroups = {
  url: ({ organisationId }) => `/organisation/${organisationId}/groups`,
  type: "get",
  appState: true,
}

export default [
  {
    target: "getGroups",
    payload: getGroups,
  },
  {
    target: "getGroupsAndMembers",
    payload: [
      { ...getGroups, id: "getGroups" },
      { ...getMembersWithoutStats, id: "getMembers" },
    ],
  },
  {
    target: "addGroup",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/groups`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "deleteGroup",
    payload: {
      url: ({ organisationId, groupId }) =>
        `/organisation/${organisationId}/groups/${groupId}/delete`,
      type: "post",
      contextKey: "groups",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "updateGroupName",
    payload: {
      url: ({ organisationId, groupId }) =>
        `/organisation/${organisationId}/groups/${groupId}/update`,
      type: "post",
      contextKey: "groups",
    },
    onSuccess: {
      notification: {
        title: "Group updated",
      },
      target: "CLOSE_MODAL",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
]
