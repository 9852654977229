import React from "react"

import Tooltip from "../Tooltip"

const renderText = (text = "") => {
  if (typeof text === "object") {
    return text.map((entry, index) => (
      <div className="mb-xs" key={`text-${index}`}>
        {entry}
      </div>
    ))
  }

  if (typeof text === "string") {
    return text
  }

  return text()
}

// Columns set to 17 and text col span set to 6 as this gives us the column widths closest to the Sketch designs
export default ({
  title,
  text,
  children,
  className,
  columns = 17,
  textCols = 6,
  gapSize = "md",
  textSize = "md",
}) => {
  return (
    <div className={`sm:grid grid-cols-${columns} gap-${gapSize} ${className}`}>
      <div
        className={`${columns > 1 ? "sm:max-w-sm" : ""} ${
          textCols ? `col-span-${textCols}` : ""
        }`}
      >
        <div className="text-neutral-5 font-medium flex">
          <div className="mr-xs sm:mb-xs mb-md">{title}</div>
          <Tooltip text={text} className="sm:hidden inline-block" />
        </div>
        <div
          className={`sm:block hidden text-${textSize} whitespace-pre-line text-neutral-6 leading-md`}
        >
          {renderText(text)}
        </div>
      </div>
      <div
        className={`w-full col-span-${columns > 2 ? columns - textCols : 1}`}
      >
        {children}
      </div>
    </div>
  )
}
