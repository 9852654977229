import React, { createContext, useState } from "react"

import { useValidator } from "../../utils/hooks"

const FormContext = createContext()

const Form = ({ children, onSubmit, className, state }) => {
  const [, forceUpdate] = useState(0)

  const [validator] = useValidator({
    email: "Email address is not valid",
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    if (validator && !validator.allValid()) {
      validator.showMessages()
    } else {
      if (onSubmit) {
        onSubmit(event, validator, validateForm, state)
      }
      if (validator) {
        validator.hideMessages()
      }
    }

    forceUpdate((x) => x + 1)
  }

  const handleValidation = (validationField) => {
    if (validator) {
      if (validator.fieldValid(validationField)) {
        validator.hideMessageFor(validationField)
      } else {
        validator.showMessageFor(validationField)
      }

      forceUpdate((x) => x + 1)
    }
  }

  const validateForm = () => {
    if (validator) {
      if (validator.allValid()) {
        validator.hideMessages()
      } else {
        validator.showMessages()
      }

      forceUpdate((x) => x + 1)
    }
  }

  return (
    <FormContext.Provider
      value={[validator, { handleValidation, validateForm }]}
    >
      <FormContext.Consumer>
        {(validator) => {
          if (onSubmit) {
            return (
              <form
                onSubmit={(event) => handleSubmit(event, validator)}
                className={className}
              >
                {children}
              </form>
            )
          }
          return <div className={className}>{children}</div>
        }}
      </FormContext.Consumer>
    </FormContext.Provider>
  )
}

const useFormValidator = (required = true) => {
  const context = React.useContext(FormContext)
  if (required) {
    if (context === undefined) {
      throw new Error("useFormValidator must be used within a FormProvider")
    }

    return context
  }

  return [{}, {}]
}

export { useFormValidator, FormContext }

export default Form
