import React, { useState, useEffect } from "react"

import InputError from "./Error"
import { useFormValidator } from "../Forms/Form"

export default ({
  id,
  label,
  name,
  placeholder,
  value = "",
  onChange,
  onBlur,
  rows,
  classes = {},
  small,
  validation,
  length = null,
  limitPlacement = "bottom",
}) => {
  const [limitColour, setLimitColour] = useState("text-neutral-6")
  const [isFocused, setFocused] = useState(false)
  const [validator, { handleValidation }] = useFormValidator()

  useEffect(() => {
    setLimitColour(() => {
      if (value && value.length < length && value.length > length - 21) {
        return "text-warning-5"
      }
      if (value && value.length >= length) {
        return "text-danger-5"
      }

      return "text-neutral-6"
    })
  }, [value, length])

  let inputClassName = "p-sm w-full"

  let labelClassName = "mb-sm"

  if (small) {
    inputClassName = "p-xs w-full leading-sm"
    labelClassName = "mb-xs"
  }

  const handleChange = (event) => {
    if (validator && validator.fieldValid(name)) {
      validator.hideMessageFor(name)
    }
    onChange(event)

    if (
      validator &&
      validation &&
      (validator.visibleFields.includes(name) || validator.messagesShown)
    ) {
      handleValidation(name)
    }
  }

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }

    setFocused(false)
    handleValidation(name)
  }

  let hasErrors = false
  if (validator && validation) {
    hasErrors =
      !validator.check(value, validation.rules) &&
      (validator.messagesShown || validator.visibleFields.includes(name))
  }

  return (
    <div className="relative">
      {label && (
        <label
          className={`block text-neutral-5 font-medium ${
            classes.label || labelClassName
          }`}
          htmlFor={id || name}
        >
          {label}
        </label>
      )}
      <textarea
        className={`${
          hasErrors
            ? "rounded-t rounded-br shadow-border-danger"
            : "rounded shadow-border"
        }
            placeholder-neutral-8
            text-neutral-1
            block
            ${inputClassName}
            ${classes.input}
          `}
        id={id || name}
        name={name}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
      />
      <InputError validation={validation} name={name} id={id} value={value} />
      {length && value && isFocused && (
        <div
          className={`${limitColour} absolute right-0 text-sm -bottom-lg ${
            limitPlacement === "top" ? "top-0 mt-xs" : ""
          }`}
        >
          {500 - value.length}
        </div>
      )}
    </div>
  )
}
