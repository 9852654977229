import React, { useContext } from "react"
import moment from "moment"

import { MachineContext } from "../../../state"
import Button from "../../Button"

export default ({ activity }) => {
  const [
    {
      context: { user },
    },
  ] = useContext(MachineContext)

  return (
    <div className="ml-lg pb-md border-l border-neutral-9 relative activity-container">
      <div className="absolute inset-t-0 inset-l-0 bg-neutral-9 rounded-full activity-dot" />
      <div className="pl-md activity-content inline-block">
        <div className="inline-block font-medium">{activity.title}</div>
        {activity.user && activity.user.name && (
          <div className="inline-block text-neutral-6 ml-xs">
            •{" "}
            {activity.user.id === user.id ||
            (activity.metadata && activity.metadata.userId === user.id)
              ? "You"
              : activity.user.name.split(" ")[0]}
            {activity.isRemovable && (
              <span className="ml-xs">
                <Button onClick={() => activity.removeCallback(activity)}>
                  Remove
                </Button>
              </span>
            )}
          </div>
        )}
        <div className="whitespace-pre-line">{activity.summary}</div>
        <div className="text-neutral-6 text-sm">
          {moment(activity.createdAt).format("DD MMM YYYY HH:mm")}
        </div>
      </div>
    </div>
  )
}
