import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import Header from "../../containers/Header"
import Footer from "../../containers/Footer"
import Form from "../../Forms/Form"
import AddRecipients from "../CreateInvoice/AddRecipients"
import Input from "../../Input"
import FilterDropdown from "../../FilterDropdown"
import ConstructEmail from "./ConstructEmail"
import { pluraliseWord } from "../../../utils/functions"

export default forwardRef(({ state, handleClose, send, setChanges }, ref) => {
  const addRecipientsRef = useRef()
  const [step, setStep] = useState(1)

  const [addRecipientsState, setAddRecipientsState] = useState({
    members: [],
    groups: [],
    search: "",
    showSelected: false,
  })
  const [email, setEmailState] = useState({
    subject: "",
    body: "",
  })

  useEffect(() => {
    const { description } = state.ping

    setEmailState({
      subject: description,
      body: "",
    })
  }, [state.ping])

  useEffect(() => {
    setAddRecipientsState((currentState) => {
      return {
        ...currentState,
        members: [],
        groups: state.groups,
      }
    })
  }, [state.groups, state.members])

  const handleRecipientsState = (updatedState) => {
    setChanges(true)
    setAddRecipientsState((currentState) => {
      return {
        ...currentState,
        ...updatedState,
      }
    })
  }

  const progressStep = useCallback(() => {
    let valid = true
    if (addRecipientsRef.current && addRecipientsRef.current.validateStep) {
      valid = addRecipientsRef.current.validateStep()
    }

    if (valid) {
      handleRecipientsState({
        search: "",
        groups: state.groups.map((g) => {
          g.checked = false
          return g
        }),
        showSelected: false,
      })
      setStep((step) => step + 1)
    }
  }, [state.groups])

  let body = <React.Fragment />
  let actions = [],
    buttons = []
  let title = "",
    headerClasses = ""
  let leftButton = {}
  switch (step) {
    case 1:
      title = "Add recipients"
      const displayMembers = addRecipientsState.showSelected
        ? addRecipientsState.members
        : state.members
      const selectedGroups = addRecipientsState.groups.filter((g) => g.checked)
      const what = displayMembers
        .filter(
          (member) =>
            !addRecipientsState.search ||
            member.name
              .toLowerCase()
              .includes(addRecipientsState.search.toLowerCase()) ||
            member.email
              .toLowerCase()
              .includes(addRecipientsState.search.toLowerCase())
        )
        .filter(
          (member) =>
            !selectedGroups.length ||
            selectedGroups.filter((g) => {
              return (
                (g.id === 0 && member.groups.length === 0) ||
                member.groups.find((memGroup) => g.id === memGroup.id)
              )
            }).length > 0
        )
      body = (
        <AddRecipients
          ref={addRecipientsRef}
          type="ping"
          groups={addRecipientsState.groups}
          filteredMembers={what}
          selectedMembers={addRecipientsState.members || []}
          showSelected={addRecipientsState.showSelected}
          setState={handleRecipientsState}
        />
      )
      headerClasses = "grid grid-cols-2"
      actions = [
        {
          hide: !(Array.isArray(state.groups) && state.groups.length > 0),
          render: (
            <div className="inline-block">
              <FilterDropdown
                options={state.groups}
                placeholder="All groups"
                updateOptions={(groups) => handleRecipientsState({ groups })}
              />
            </div>
          ),
        },
        {
          render: (
            <Form className="inline-block w-full">
              <Input
                small
                id="header-search"
                name="search"
                icon="search"
                classes={{
                  input: "rounded-full pl-lg",
                }}
                value={state.search}
                onChange={({ target: { value } }) =>
                  handleRecipientsState({ search: value })
                }
                placeholder="Search"
              />
            </Form>
          ),
          containerClass: "w-full",
        },
      ]

      buttons = [
        { text: "Cancel", callback: () => handleClose() },
        {
          text: "Continue",
          type: "primary",
          iconType: "duotone",
          icon: "arrow-circle-right",
          disabled: !Array.isArray(state.members) || !state.members.length,
          callback: (event) => {
            event.preventDefault()
            progressStep()
          },
        },
      ]
      break
    case 2:
      title = "Edit email"
      body = (
        <div className="p-md">
          <ConstructEmail
            email={email}
            onChange={setEmailState}
            ping={state.ping}
          />
        </div>
      )
      leftButton = {
        text: "Previous",
        callback: () => setStep(1),
      }
      buttons = [
        { text: "Cancel", callback: () => handleClose() },
        {
          text: "Send now",
          type: "primary",
          icon: "paper-plane",
          disabled: !email.subject,
          submitting: "app.requests.sendPingEmail.requesting",
          callback: (event) => {
            event.preventDefault()
            send("sendPingEmail", {
              data: {
                variables: {
                  pingId: state.ping.id,
                },
                body: {
                  memberIds: addRecipientsState.members.map(
                    (member) => member.id
                  ),
                  email,
                },
                onSuccess: {
                  notification: {
                    title: "Emails sent",
                    description: `${pluraliseWord(
                      "Email",
                      addRecipientsState.members.length
                    )} sent to ${
                      addRecipientsState.members.length
                    } ${pluraliseWord(
                      "member",
                      addRecipientsState.members.length
                    )}`,
                  },
                  callback: () => {
                    send("CLOSE_MODAL")
                  },
                },
              },
            })
          },
        },
      ]
      break
    default:
      body = <React.Fragment />
      break
  }

  return (
    <div>
      <Header
        title={`Step ${step} of 2: ` + title}
        actions={actions}
        className={headerClasses}
        actionContainerClasses="sm:flex flex-1"
      />
      <Form>
        <div className="bg-neutral-10 text-neutral-6 sm:max-h-modal-body max-h-modal-mobile">
          {body}
        </div>
        <Footer buttons={buttons} leftButton={leftButton} />
      </Form>
    </div>
  )
})
