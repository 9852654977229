import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getQueryObject } from "../../utils/functions"

import config from "../../config"
import axios from "axios"

const baseURL = config.api_url

export default () => {
  const location = useLocation()

  useEffect(() => {
    const query = getQueryObject(location.search)

    if (query.referrer) {
      axios.post(`${baseURL}/affiliate/${query.referrer}/click`)
    }
  }, [location.search])

  return <React.Fragment />
}
