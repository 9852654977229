import React, { useContext, useState } from "react"

import Header from "../containers/Header"
import Footer from "../containers/Footer"
import Form from "../Forms/Form"
import EditInvoice from "../Forms/EditInvoice"
import { MachineContext } from "../../state"

export default ({ state, changesMade, setChanges, handleClose }) => {
  const [current, send] = useContext(MachineContext)
  const [step, setStep] = useState(1)
  const [totalError, showTotalError] = useState(false)

  const [invoice, setState] = useState({
    title: state.invoice.title,
    dueDate: state.invoice.dueDate,
    description: state.invoice.description,
    footer: state.invoice.footer,
    lineItems: state.invoice.lineItems.map(({ description, amount }) => ({
      description,
      amount,
    })),
  })
  const { member } = state

  let title = ""
  let buttons = []
  let body = <React.Fragment />

  const handleStep = (event, validator, checkFormValidity) => {
    showTotalError(false)
    checkFormValidity()

    const invoiceTotal = invoice.lineItems.reduce((a, b) => a + b.amount, 0)
    if (validator.allValid() && (invoiceTotal > 49 || invoiceTotal == 0)) {
      if (step === 1) {
        send("updateBatchInvoice", {
          data: {
            variables: {
              batchId: state.invoice.metadata.batchId,
              invoiceId: state.invoice.id,
            },
            body: {
              ...invoice,
              custom: true,
              lineItems: invoice.lineItems.filter(
                (item) => item.description && item.amount
              ),
            },
            onSuccess: {
              notification: {
                title: "Invoice updated",
              },
              callback: () => {
                setChanges(false)
                setStep(2)
              },
            },
            onError: {
              notification: {
                title: "Whoops",
                description: "Something went wrong",
              },
            },
          },
        })
      } else {
        send("sendMemberReminders", {
          data: {
            variables: {
              memberId: member.id,
              resentInvoices: true,
            },
            body: {
              invoices: [state.invoice.metadata.batchId],
            },
            onSuccess: {
              target: "CLOSE_MODAL",
              notification: {
                title: `1 invoice re-sent`,
              },
            },
          },
        })
      }
    } else {
      if (invoiceTotal < 50 && invoiceTotal !== 0) {
        showTotalError(true)
      }
    }
  }

  const updateLineItems = ({ name, value }, index) => {
    let items = invoice.lineItems
    if (typeof value === "number" && isNaN(value)) {
      value = ""
    }

    items[index][name] = value

    if (index === items.length - 1) {
      items.push({ description: "", amount: "" })
    }
    setChanges(true)
    updateInvoice({ target: { name: "lineItems", value: items } })
  }

  const updateInvoice = ({ target: { value, name } }) => {
    if (!changesMade) {
      setChanges(true)
    }
    setState({ ...invoice, [name]: value })
  }

  switch (step) {
    case 1:
      title = "Edit invoice"
      buttons = [
        {
          text: "Cancel",
          callback: handleClose,
        },
        {
          disabled: !changesMade,
          text: "Save changes",
          type: "primary",
          icon: "sync-alt",
          submitting: current.matches(
            "app.requests.updateBatchInvoice.requesting"
          ),
        },
      ]
      body = (
        <EditInvoice
          invoice={invoice}
          updateInvoice={updateInvoice}
          updateLineItems={updateLineItems}
          totalError={totalError}
        />
      )
      break
    case 2:
      if (!member.undeliverable) {
        title = "Resend invoice"
        buttons = [
          {
            text: "Don't send",
            callback: () => send("CLOSE_MODAL"),
          },
          {
            text: "Resend invoice",
            type: "primary",
            icon: "paper-plane",
            submitting: current.matches(
              "app.requests.sendMemberReminders.requesting"
            ),
          },
        ]
        body = (
          <div className="p-md overflow-y-auto sm:max-h-modal-body max-h-modal-mobile bg-neutral-10 text-neutral-5">
            Resend edited invoice to {member.name}
          </div>
        )
      } else {
        title = "Cannot resend invoice"
        buttons = [
          {
            text: "Okay",
            type: "primary",
            iconType: "duotone",
            icon: "check-circle",
            callback: () => send("CLOSE_MODAL"),
          },
        ]
        body = (
          <div className="p-md overflow-y-auto sm:max-h-modal-body max-h-modal-mobile bg-neutral-10 text-neutral-5">
            You cannot resend this invoice as the email address is invalid.
          </div>
        )
      }
      break
    default:
      break
  }

  return (
    <Form onSubmit={handleStep}>
      <Header title={`Step ${step} of 2: ${title}`} />
      {body}
      <Footer buttons={buttons} />
    </Form>
  )
}
