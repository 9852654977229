import moment from "moment"

export const becomeAnAffiliateModal = (
  send,
  user,
  profile,
  backToOnboarding
) => {
  let userProfile = profile || {}

  if (user.profile) {
    userProfile = {
      dob: moment(user.profile.dob).toDate(),
      addressLine1: user.profile.addressLine1,
      addressLine2: user.profile.addressLine2,
      town: user.profile.town,
      postcode: user.profile.postcode,
      phoneNumber: user.profile.phoneNumber,
    }
  }

  send("OPEN_MODAL", {
    payload: {
      id: "becomeAffiliate",
      title: "Become affiliate",
      state: {
        step: 1,
        declaration: {
          isUKBased: false,
          acceptedTAndC: false,
          ageCheck: false,
        },
        user: {
          name: user.name,
          email: user.email,
          ...userProfile,
        },
        affiliate: {
          intent: "",
          channels: [
            { account: "", channel: "" },
            { account: "", channel: "" },
            { account: "", channel: "" },
            { account: "", channel: "" },
          ],
        },
      },
      confirmOnClose: true,
      handleClose: backToOnboarding
        ? backToOnboarding
        : () => send("CLOSE_MODAL"),
      componentPath: "BecomeAffiliate",
      modalSize: "md",
      overrideLayout: true,
    },
  })
}

export const viewAffiliateTutorialModal = (send, affiliateLinks) => {
  send("OPEN_MODAL", {
    payload: {
      id: "affiliateTutorial",
      title: "Let's get started!",
      state: {
        affiliateLinks,
      },
      componentPath: "AffiliateTutorial",
      withHeader: true,
      withButtons: true,
      overrideDefaultButtons: true,
      modalSize: "md",
      buttons: [
        {
          text: "Okay",
          type: "primary",
          icon: "check-circle",
          iconType: "duotone",
          callback: () => {
            send("updateAffiliateTutorial", {
              data: {
                body: {
                  showOnMount: false,
                },
              },
            })
            send("CLOSE_MODAL")
          },
        },
      ],
      handleClose: () => {
        send("updateAffiliateTutorial", {
          data: {
            body: {
              showOnMount: false,
            },
          },
        })
      },
    },
  })
}

export const viewShareAffiliateLinkModal = (send, affiliateLinks) => {
  send("OPEN_MODAL", {
    payload: {
      id: "shareAffiliateLinks",
      title: "Share link",
      state: {
        affiliateLinks,
      },
      componentPath: "ShareAffiliateLink",
      withHeader: true,
      withButtons: true,
      overrideDefaultButtons: true,
      modalSize: "md",
      buttons: [
        {
          text: "Okay",
          type: "primary",
          icon: "check-circle",
          iconType: "duotone",
          callback: () => send("CLOSE_MODAL"),
        },
      ],
    },
  })
}
