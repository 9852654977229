import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Button from '../Button'
import Icon from '../Icon'

export default ({
  title,
  actions = [],
  children,
  className,
  actionContainerClasses = '',
  actionsSpacing = 'sm',
  tabs = [],
  tabPage = '',
  paddingTop = true
}) => {
  const location = useLocation()
  return <div className={ `${className} px-md bg-white sm:rounded-t shadow-b-border flex justify-between sm:h-2xl sm:pb-0 pb-md${paddingTop ? ' sm:pt-0 pt-md' : ''}` }>
    <h3 className="leading-tight my-auto">
      { title || children }
      {
        tabs.length > 0 && (
          <div className="-ml-sm flex">
            {
              tabs.map((tab, index) => {
                return (
                    <Link
                      to={{
                        pathname: location.pathname,
                        hash: '#' + tab.page
                      }}
                      key={ 'tab-' + index }
                      onClick={ tab.onClick }
                      className={ `tab p-sm rounded-full text-base text-neutral-5 inline cursor-pointer flex items-center${tabPage === tab.page ? ' bg-neutral-9' : ''}` }
                    >
                      { tab.label }
                      {
                        tab.icon && (
                          <Icon icon={ tab.icon } type="regular" className="ml-xs" />
                        )
                      }
                    </Link>
                )
              })
            }
          </div>
        )
      }
    </h3>
    <div className={ `my-auto ${actionContainerClasses} sm:flex items-center hidden pl-sm` }>
      {
        actions.filter(action => !action.hide).map((action, index) => {
          if (action.render) {
            return (
              <div key={ 'action' + index } className={ `ml-${actionsSpacing} inline-block first:ml-0 ${action.containerClass}` }>
                { action.render }
              </div>
            )
          }

          return (
            <div key={ 'action' + index } className={`ml-${actionsSpacing} inline-block`}>
              <Button
                key={ 'action' + index }
                onClick={ action.callback }
                to={ action.to }
                type={ action.type || 'primary' }
                icon={ action.icon }
                className={ action.className }
                iconType={ action.iconType }
                disabled={ action.disabled }
              >
                { action.title }
              </Button>
            </div>
          )
        })
      }
    </div>
  </div>
}
