import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react"
import moment from "moment"

import ActivityFeed from "../Invoice/ActivityFeed"
import Ping from "../../Ping"
import { MachineContext } from "../../../state"
import Icon from "../../Icon"
import Tag from "../../Tag"
import Ribbon from "../../containers/Ribbon"
import Button from "../../Button"
import { deletePurchaseConfirmation } from "../../../utils/confirmations/ping"
import { editMemberCommentOnPing } from "../../../utils/modals/ping"

const PingModal = forwardRef(
  ({ state: { purchase = {} }, isAdmin: isAdminView = true }, ref) => {
    const [current, send] = useContext(MachineContext)
    const { context } = current
    const [note, setNote] = useState("")
    const [activity, setActivity] = useState([])
    const activityRef = useRef()

    const removeNote = useCallback(
      (activity) => {
        send("deletePingNote", {
          data: {
            variables: {
              purchaseId: purchase.id,
              noteId: activity.id,
              pingId: purchase.pingId,
            },
            transformResponse: (response) => {
              response.data = {
                ...response.data,
                purchase: {
                  members: response.data.members.map((purchase) => ({
                    ...purchase,
                    ...purchase.member,
                  })),
                },
              }

              return response
            },
            onSuccess: {
              target: "CLOSE_CONFIRMATION",
              callback: ({ response }) => {
                send("UPDATE_MODAL_STATE", {
                  payload: {
                    state: {
                      purchase: response,
                    },
                  },
                })
              },
            },
          },
        })
      },
      [purchase.id, purchase.pingId, send]
    )

    const confirmRemoveNote = useCallback(
      (activity) => {
        send("OPEN_CONFIRMATION", {
          payload: {
            id: "removeNote",
            title: "Remove note",
            body: `Are you sure you want to remove this note?`,
            buttons: [
              {
                text: "Cancel",
                callback: () => {
                  send("CLOSE_CONFIRMATION")
                },
              },
              {
                text: "Remove note",
                type: "danger",
                iconType: "duotone",
                icon: "exclamation-circle",
                submitting: "app.requests.deletePingNote.requesting",
                callback: () => {
                  removeNote(activity)
                },
              },
            ],
          },
        })
      },
      [removeNote, send]
    )

    useEffect(() => {
      let activity = purchase.activity || []

      if (activity) {
        setActivity(activity)
      }
    }, [confirmRemoveNote, purchase.activity])

    useImperativeHandle(ref, () => ({
      onClose() {
        send("CLOSE_MODAL")
      },
    }))

    const sendNote = (e) => {
      e.preventDefault()

      if (note) {
        send("createPingNote", {
          data: {
            variables: {
              purchaseId: purchase.id,
              pingId: purchase.pingId,
            },
            transformResponse: (response) => {
              response.data = {
                ...response.data,
                purchase: {
                  members: response.data.members.map((purchase) => ({
                    ...purchase,
                    ...purchase.member,
                  })),
                },
              }

              return response
            },
            body: { note },
            onSuccess: {
              callback: ({ response }) => {
                setNote("")
                send("UPDATE_MODAL_STATE", {
                  payload: {
                    state: {
                      purchase: response,
                    },
                  },
                })
              },
            },
          },
        })
      }
    }

    const handleEditingMemberComments = () => {
      editMemberCommentOnPing(send, { purchase })
    }

    const isAdmin = context.user.roles.includes("admin") && isAdminView

    return (
      <Ping
        paid
        ping={purchase}
        organisation={purchase.organisation}
        isAdmin={isAdmin}
        editMemberCommentsCallback={handleEditingMemberComments}
        maxHeight="max-h-modal-container"
        withShadow={false}
        customHeader={
          isAdmin && (
            <div className="bg-white p-md flex flex-row justify-between items-center rounded-tl shadow-b-border">
              <h1>{purchase.description}</h1>
              {isAdmin && !purchase.amount && (
                <div>
                  <Button
                    onClick={() =>
                      deletePurchaseConfirmation(send, {
                        pingId: purchase.pingId,
                        purchaseId: purchase.id,
                        members: purchase.members.map((mem) => mem.member),
                      })
                    }
                    customStyle="cursor-pointer"
                    label="Delete"
                    type="danger-text"
                  />
                </div>
              )}
            </div>
          )
        }
      >
        <div className="w-full max-w-sm border-l border-neutral-8 bg-white rounded-r flex flex-col">
          <div className="relative rounded-tr">
            <Ribbon logo={true} background="success" />
            <h2 className="px-md pt-sm pb-sm text-xl">Activity & notes</h2>
            <div className="absolute top-lg right-lg">
              <Tag type="success" className="font-medium">
                Paid
              </Tag>
            </div>
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto justify-between">
            <div className="overflow-y-auto">
              <ActivityFeed
                ref={activityRef}
                reverse
                activity={activity
                  .map((activity) => {
                    return {
                      ...activity,
                      isRemovable: activity.subcategory === "NOTE_ADDED",
                      removeCallback: confirmRemoveNote,
                    }
                  })
                  .sort((a, b) => {
                    if (
                      moment(b.createdAt).isSame(moment(a.createdAt), "second")
                    ) {
                      return b.id > a.id ? 1 : -1
                    } else {
                      return new Date(b.createdAt) > new Date(a.createdAt)
                        ? 1
                        : -1
                    }
                  })}
              />
            </div>
            {
              <form
                onSubmit={sendNote}
                className="shadow-t-border flex flex-row items-center px-md"
                style={{ minHeight: "65.91px" }}
              >
                {" "}
                {/* Max note input height on invoices */}
                <input
                  className="pr-sm py-sm my-xs placeholder-neutral-8 text-neutral-1 w-full outline-none"
                  onChange={({ target: { value } }) => setNote(value)}
                  placeholder="Type a note..."
                  value={note}
                />
                <div onClick={sendNote} className="cursor-pointer">
                  <Icon type="duotone" icon="arrow-circle-up" baseSize="28px" />
                </div>
              </form>
            }
          </div>
        </div>
      </Ping>
    )
  }
)

export default PingModal
