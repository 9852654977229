import config from "../config"

export const spawnBeacon = () => {
  const exisitingBeacon = window.Beacon("info")

  if (!exisitingBeacon) {
    window.Beacon("init", config.hs_token)
  }
}

export const destroyBeacon = () => {
  window.Beacon("destroy")
}

export const suggestDocs = () => {
  window.Beacon("suggest", [])
}

export const openChat = () => {
  window.Beacon("navigate", "/ask/chat/")
  window.Beacon("open")
}

export const openToPage = (page) => {
  window.Beacon("navigate", "/docs/search/" + page)
  window.Beacon("open")
}
