import React, { useContext, useRef, useEffect, Suspense } from "react"
import Route from "../components/LazyRoute"
import { Switch, Redirect, useLocation } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"

// import AdminContainer from "../../components/containers/Admin"
import Dashboard from "../components/containers/Dashboard"
import Button from "../components/Button"

import { MachineContext } from "../state"
import { useAdminMenu } from "../utils/hooks"
import { becomeAnAffiliateModal } from "../utils/modals/affiliate"
import { tutorialModal } from "../utils/modals/organisation"
import { suggestDocs } from "../utils/beacon"
import HomeSkeleton from "../pages/organisation/Home/Skeleton"
import UnpaidSkeleton from "../pages/organisation/Unpaid/Skeleton"
import InvoicesSkeleton from "../pages/organisation/Invoices/Skeleton"
import PingsSkeleton from "../pages/organisation/Pings/Skeleton"
import PaymentsSkeleton from "../pages/organisation/Payments/Skeleton"
import CreditsSkeleton from "../pages/organisation/Credits/Skeleton"
import PayoutsSkeleton from "../pages/organisation/Payouts/Skeleton"
import MembersSkeleton from "../pages/organisation/Members/Skeleton"
import GroupsSkeleton from "../pages/organisation/GroupSettings/Skeleton"
import InvoiceSkeleton from "../pages/organisation/Invoice/Skeleton"
import PingSkeleton from "../pages/organisation/Ping/Skeleton"
import MemberSkeleton from "../pages/organisation/Member/Skeleton"
import PayoutSkeleton from "../pages/organisation/Payout/Skeleton"

const Home = React.lazy(() => import("../pages/organisation/Home"))
// const import = React.lazy(() => import( from '../pages/organisation/Activity'))
const Member = React.lazy(() => import("../pages/organisation/Member"))
const Members = React.lazy(() => import("../pages/organisation/Members"))
const Unpaid = React.lazy(() => import("../pages/organisation/Unpaid"))
const Invoices = React.lazy(() => import("../pages/organisation/Invoices"))
const Invoice = React.lazy(() => import("../pages/organisation/Invoice"))
const Payments = React.lazy(() => import("../pages/organisation/Payments"))
const Payout = React.lazy(() => import("../pages/organisation/Payout"))
const Payouts = React.lazy(() => import("../pages/organisation/Payouts"))
const GroupSettings = React.lazy(() =>
  import("../pages/organisation/GroupSettings")
)
const ClubSettings = React.lazy(() =>
  import("../pages/organisation/ClubSettings")
)
const TeamSettings = React.lazy(() =>
  import("../pages/organisation/TeamSettings")
)
const Account = React.lazy(() => import("../pages/common/Account"))
const MemberRoutes = React.lazy(() => import("./MemberRoutes"))
const Pings = React.lazy(() => import("../pages/organisation/Pings"))
const Ping = React.lazy(() => import("../pages/organisation/Ping"))
const Credits = React.lazy(() => import("../pages/organisation/Credits"))

export default ({ applicationInProgress }) => {
  const location = useLocation()
  const defaultOptions = useAdminMenu()
  const currentClubId = useRef()
  const otherClubIds = useRef([])

  const [current, send] = useContext(MachineContext)
  const { context } = current

  const modalMachine = current.children.modal.state

  useEffect(() => {
    const tutorialState = context.tutorial

    if (
      tutorialState &&
      tutorialState.showOnMount &&
      modalMachine.matches("closed")
    ) {
      tutorialModal(send, 1)
    }
  }, [send, context.tutorial, current])

  useEffect(() => {
    if (
      context.organisation &&
      currentClubId.current !== context.organisation.id &&
      !otherClubIds.current.includes(context.organisation.id)
    ) {
      currentClubId.current = context.organisation.id
      otherClubIds.current = context.organisations.map((o) => o.id)
    }
  }, [currentClubId, context.organisation, context.organisations, send])

  // TODO: REMOVE IF STATEMENT WHEN ADMIN PAGES ARE MOBILE OPTIMISED
  if (isMobileOnly) {
    return (
      <Suspense fallback={<React.Fragment />}>
        <MemberRoutes />
      </Suspense>
    )
  }

  useEffect(() => {
    suggestDocs()
  }, [location])

  return (
    <Dashboard
      sidebar="organisation"
      pathname={location.pathname}
      name={context.user.name}
      email={context.user.email}
      organisation={context.organisation}
      menu={defaultOptions}
      banner={{
        text: "Admin functionality is not yet available on mobile. Use the desktop version to manage your club",
        className: "sm:hidden block",
      }}
      actions={[
        context.user.roles.includes("affiliate") ? (
          <React.Fragment key="affiliate-fragment" />
        ) : (
          <Button
            key="become-affiliate-button"
            type="support"
            border={false}
            icon="pound-sign"
            iconType="solid"
            containerStyle={applicationInProgress ? "hidden" : "pl-md"}
            onClick={() =>
              becomeAnAffiliateModal(send, context.user, context.profile)
            }
          >
            Earn money
          </Button>
        ),
      ]}
    >
      <Switch>
        <Route exact path="/" fallback={<HomeSkeleton />}>
          <Home />
        </Route>
        {/* <Route exact path="/activity">
          <Activity />
        </Route> */}
        <Route exact path="/account">
          <Account />
        </Route>
        <Route
          exact
          path="/unpaid"
          fallback={<InvoicesSkeleton />}
          render={(props) => <Unpaid {...props} />}
        />
        <Route
          exact
          path="/invoices"
          fallback={<InvoicesSkeleton />}
          render={(props) => <Invoices {...props} />}
        />
        <Route
          exact
          path="/pings"
          fallback={<PingsSkeleton />}
          render={(props) => <Pings {...props} />}
        />
        <Route
          exact
          path="/pings/:id"
          fallback={<PingSkeleton />}
          render={(props) => <Ping {...props} />}
        />
        <Route
          exact
          path="/invoices/:id"
          fallback={<InvoiceSkeleton />}
          render={(props) => <Invoice {...props} />}
        />
        <Route exact path="/payments" fallback={<PaymentsSkeleton />}>
          <Payments />
        </Route>
        <Route
          exact
          path="/payouts/:id"
          fallback={<PayoutSkeleton />}
          render={(props) => <Payout {...props} />}
        />
        <Route exact path="/payouts" fallback={<PayoutsSkeleton />}>
          <Payouts />
        </Route>
        <Route exact path="/members" fallback={<MembersSkeleton />}>
          <Members />
        </Route>
        <Route exact path="/group" fallback={<GroupsSkeleton />}>
          <GroupSettings />
        </Route>
        <Route exact path="/credits" fallback={<CreditsSkeleton />}>
          <Credits />
        </Route>
        <Route
          exact
          path="/members/:id"
          fallback={<MemberSkeleton />}
          render={(props) => (
            <Member key={props.match.params.pageid} {...props} />
          )}
        />
        <Route
          exact
          path="/settings/:type"
          render={(props) => {
            switch (props.match.params.type) {
              case "club":
                return <ClubSettings />
              case "team":
                return <TeamSettings />
              default:
                return <Redirect to="/" />
            }
          }}
        />

        {/* Allows us to navigate to the personal dashboard pages without being redirected to the home screen first */}
        <Route
          exact
          path={["/dashboard", "/dashboard/:invoiceToken"]}
          fallback={<MemberSkeleton />}
          render={(props) => (
            <Member key="my-invoices" ownInvoices={true} {...props} />
          )}
        />

        <Route exact path={["/account", "/dashboard"]}>
          <React.Fragment />
        </Route>

        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Dashboard>
  )
}
