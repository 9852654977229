import React from "react"

export default ({ groups = [], className }) => {
  const tagStyling =
    "rounded-full bg-neutral-9 text-neutral-5 inline-block text-sm " + className
  if (groups && groups.length === 1 && groups[0]) {
    return (
      <div className={tagStyling + " group-padding--single"}>
        {groups[0].name ? groups[0].name : groups[0]}
      </div>
    )
  }

  if (groups.length > 1) {
    return (
      <div className={tagStyling + "  group-padding--multiple"}>
        <div className="flex justify-center items-center">
          <div>Groups</div>
          <div
            className="bg-neutral-10 rounded-full w-base h-base inline-block ml-xs flex items-center justify-center"
            style={{ margin: "0.214rem" }}
          >
            {groups.length}
          </div>
        </div>
      </div>
    )
  }

  return <React.Fragment />
}
