import adminRequest from "./admin"
import affiliateRequest from "./affiliate"
import authRequest from "./auth"
import batchRequest from "./batch"
import creditsRequest from "./credits"
import groupsRequest from "./groups"
import invoiceRequest from "./invoice"
import membersRequest from "./members"
import organisationRequest from "./organisation"
import paymentRequest from "./payment"
import payoutRequest from "./payout"
import pingRequest from "./ping"
import teamRequest from "./team"
import userRequest from "./user"

export default [
  ...adminRequest,
  ...affiliateRequest,
  ...authRequest,
  ...batchRequest,
  ...creditsRequest,
  ...groupsRequest,
  ...invoiceRequest,
  ...membersRequest,
  ...organisationRequest,
  ...paymentRequest,
  ...payoutRequest,
  ...pingRequest,
  ...teamRequest,
  ...userRequest,
]
