import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"
import { getTeam } from "./team"
import { getUserAccount } from "./user"

export const getUserOrganisations = {
  url: `/organisation`,
  type: "get",
  appState: true,
  transformResponse: (response, context) => {
    if (context.organisation) {
      const { organisation, organisations } = response.data

      let currentOrgId = context.organisation.id
      let fetchedCurrentOrg = organisation
        ? organisation.id === currentOrgId
        : null

      if (fetchedCurrentOrg) {
        // Set the most recent version of the organisations tutorial
        response.data.tutorial = organisation.tutorial
        return response
      }

      if (organisations && organisations.length) {
        const org = organisations.filter((org) => org.id === currentOrgId)[0]
        response.data.organisation = org
        response.data.tutorial = org.tutorial
      } else {
        response.data.organisation = null
        response.data.tutorial = {}
      }
    }
    return response
  },
}

export const getOrganisationStats = {
  url: ({ organisationId }) => `/organisation/${organisationId}/stats`,
  type: "get",
}

const getOrganisationBatches = {
  url: ({ organisationId, filters }) =>
    `/organisation/${organisationId}/batch${constructQueryString(filters)}`,
  type: "get",
  transformResponse: handleTableDataTransform,
}

export const getUnpaidReport = {
  url: ({ organisationId }) => `/organisation/${organisationId}/unpaid`,
  type: "get",
  transformResponse: handleTableDataTransform,
}

export default [
  {
    target: "createOrganisation",
    payload: [
      { ...getUserAccount, id: "getUserAccount" },
      {
        url: "/organisation",
        type: "post",
        appState: true,
        id: "createOrganisation",
      },
    ],
  },
  {
    target: "restartStripeOnboarding",
    payload: {
      url: ({ organisationId }) =>
        `/organisation/${organisationId}/onboarding/restart`,
      type: "post",
    },
  },
  {
    target: "deleteOrganisation",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/delete`,
      type: "post",
      appState: true,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "stripeSiteVisited",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/stripe`,
      type: "post",
    },
  },
  {
    target: "updateTutorial",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}/tutorial`,
      type: "post",
      transformResponse: (response, context) => {
        const tutorial = response.data
        const organisation = context.organisation
        const organisations = context.organisations.map((org) => {
          if (tutorial.organisationId === org.id) {
            org.tutorial = tutorial
          }
          return org
        })

        if (tutorial.organisationId === context.organisation.id) {
          organisation.tutorial = tutorial
        }

        return {
          data: {
            tutorial,
            organisations,
            organisation,
          },
        }
      },
    },
  },
  {
    target: "changeOrganisation",
    payload: [
      { ...getUserOrganisations, id: "getUserOrganisations" },
      { ...getOrganisationStats, id: "getOrganisationStats" },
      { ...getTeam, id: "getTeam" },
      { ...getOrganisationBatches, id: "getOrganisationBatches" },
    ],
  },
  {
    target: "getUserOrganisations",
    payload: getUserOrganisations,
  },
  {
    target: "getOrganisationStats",
    payload: getOrganisationStats,
  },
  {
    target: "getOrganisationBatches",
    payload: getOrganisationBatches,
  },
  {
    target: "getUnpaidReport",
    payload: getUnpaidReport,
  },
  {
    target: "updateOrganisation",
    payload: {
      url: ({ organisationId }) => `/organisation/${organisationId}`,
      type: "post",
      appState: true,
    },
    onSuccess: {
      notification: {
        title: "Club updated",
      },
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
]
