import React, { useState, useCallback, forwardRef } from "react"

import Icon from "../Icon"
import { useFormValidator } from "../Forms/Form"
import { useEventListener } from "../../utils/hooks"

const Checkbox = forwardRef(
  (
    {
      label,
      className = "",
      checked,
      id,
      name,
      onChange = () => {},
      children,
      icons = false,
      bulk = false,
      selectedStyle = "",
      unselectedStyle = "",
      tabIndex = 0,
      validation,
      checkboxMargin = "mr-auto",
      labelStyle = "",
      withGrid = true,
      showError = false,
      iconSize = "18px",
      useValidator = true,
      disabled,
    },
    ref
  ) => {
    const [validator] = useFormValidator(useValidator)
    const [focused, setFocused] = useState(false)

    let hasError = false
    if (validator && validation) {
      hasError = !validator.fieldValid(name) && showError && !checked
    }

    const defaultStyles = `${hasError ? "text-danger-5" : ""} ${
      focused ? "text-primary-6" : ""
    }`

    let unselectedIcon = (
      <Icon
        baseSize={iconSize}
        className={`inline ${defaultStyles}`}
        type={focused ? "regular" : "light"}
        icon="square"
      />
    )
    let selectedIcon = (
      <Icon
        baseSize={iconSize}
        className={`inline ${defaultStyles}`}
        type={focused ? "regular" : "light"}
        icon="check-square"
      />
    )
    let bulkIcon = (
      <Icon
        baseSize={iconSize}
        className={`inline ${defaultStyles}`}
        type={focused ? "regular" : "light"}
        icon="minus-square"
      />
    )

    const handleClick = useCallback(
      (event) => {
        event.stopPropagation()
        if (!disabled) {
          onChange(event)
        }
      },
      [disabled, onChange]
    )

    const handleSpacePress = useCallback(
      (event) => {
        if (event.keyCode === 32 && focused && !disabled) {
          handleClick({
            target: {
              name,
              checked: !checked,
            },
            stopPropagation: () => {},
          })
        }
      },
      [checked, disabled, focused, handleClick, name]
    )

    useEventListener("keydown", handleSpacePress)

    if (icons) {
      if (icons.unselected) {
        unselectedIcon = (
          <Icon
            baseSize={iconSize}
            type={icons.unselected.type}
            icon={icons.unselected.icon}
            className={`my-auto ${defaultStyles}`}
            colours={{
              primary: "#186FAF",
              secondary: "#FFF",
            }}
          />
        )
      } else {
        unselectedIcon = <div className="w-md h-md" style={{ content: "" }} />
      }

      if (icons.selected) {
        selectedIcon = (
          <Icon
            baseSize={iconSize}
            type={icons.selected.type}
            icon={icons.selected.icon}
            className={`my-auto ${defaultStyles}`}
            colours={{
              primary: "#186FAF",
              secondary: "#FFF",
            }}
          />
        )
      } else {
        selectedIcon = <div className="w-md h-md" style={{ content: "" }} />
      }
    }
    return (
      <div>
        <label
          ref={ref}
          tabIndex={tabIndex}
          htmlFor={id || name}
          onFocus={() => (!disabled ? setFocused(true) : "")}
          onBlur={() => setFocused(false)}
          className={`${
            (label || children) && withGrid ? "grid grid-cols-12" : ""
          } outline-none ${className} flex items-center ${
            !disabled ? "cursor-pointer" : "text-neutral-7"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            id={id || name}
            name={name}
            type="checkbox"
            className="hidden"
            checked={checked}
            onChange={handleClick}
          />
          <div
            className={`${checkboxMargin} ${
              iconSize === "18px" ? "h-md w-md" : ""
            } inline-block leading-none`}
          >
            {checked ? selectedIcon : bulk ? bulkIcon : unselectedIcon}
          </div>
          {(label || children) && (
            <span
              className={`col-span-11 leading-tight
                ${checked || bulk ? selectedStyle : unselectedStyle}
                ${labelStyle}`}
            >
              {label || children}
            </span>
          )}
        </label>
        {validator && validation ? (
          validator.message(name, checked, validation.rules, {
            element: (message) => <React.Fragment />,
            messages: validation.message
              ? {
                  required: validation.message,
                }
              : {
                  ...validation.messages,
                },
          })
        ) : (
          <React.Fragment />
        )}
      </div>
    )
  }
)

export default Checkbox
