import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react"
import Popper from "../Popper"
import Icon from "../Icon"
import Checkbox from "../Input/Checkbox"
import Form from "../Forms/Form"
import { sortArray } from "../../utils/functions"
import Pill from "./Pill"

const FilterOption = forwardRef(
  ({ option, handleClick, handleOptionClick, icons = false }, ref) => (
    <label
      className={`px-sm py-xs hover:bg-neutral-10 cursor-pointer block whitespace-nowrap ${
        option.checked ? "font-medium text-primary-4" : ""
      }`}
      htmlFor={option.id || option.text}
    >
      <Checkbox
        ref={ref}
        onChange={(event) => {
          handleClick(event)
          handleOptionClick(option)
        }}
        name={option.id || option.text}
        bulk={option.bulk}
        checked={option.checked}
        label={option.text || option.name}
        icons={icons}
        selectedStyle="font-regular text-primary-4"
        unselectedStyle="font-regular"
        withGrid={false}
        checkboxMargin="mr-xs"
      />
    </label>
  )
)

export default forwardRef(
  (
    {
      openOnMouseOver = false,
      onOpen,
      options = [],
      placeholder = "",
      clearable = true,
      single,
      styles = {},
      selectedToTop = false,
      children: Label = null,
      icons,
      placement,
      fallbackPlacements,
      footer = null,
      closeOnSelect = false,
      closeOnClear = false,
      fullWidth = false,
      updateOptions = () => {},
      handleOption = () => {},
      handleMobileOption = () => {},
      onChange = () => {},
      disabled = false,
      sortOrder = false,
      sortKey = "name",
      className = "sm:inline-block hidden",
      busy = false,
    },
    ref
  ) => {
    const filterRefs = useRef()
    const popperRef = useRef()
    const [filterOptions, setFilterOptions] = useState([])

    useEffect(() => {
      setFilterOptions(options)
    }, [options])

    useImperativeHandle(ref, () => ({
      close() {
        if (popperRef.current.close) {
          popperRef.current.close()
        }
      },
      update() {
        if (popperRef.current.update) {
          popperRef.current.update()
        }
      },
    }))

    const sortOptions = useCallback(
      (values) => {
        if (sortOrder) {
          switch (sortOrder) {
            case "aphabetical":
              return sortArray(values, { sortKey })
            default:
              return values
          }
        }

        return values
      },
      [sortKey, sortOrder]
    )

    const handleClick = ({ target }) => {
      if (!disabled && !busy) {
        let options = filterOptions
        if (single) {
          options.map((option) => {
            option.checked =
              parseInt(option.id) === parseInt(target.name) ||
              option.text === target.name
            return option
          })
          const option = options.find((o) => o.checked)
          handleOption(option)
          popperRef.current.close()
        } else {
          const index = options.findIndex((option) => {
            if (option.id) {
              return parseInt(option.id) === parseInt(target.name)
            }
            return option.text.toLowerCase() === target.name.toLowerCase()
          })
          options[index].checked = !options[index].checked
        }

        setFilterOptions(sortOptions(options))
        updateOptions(options)

        popperRef.current.update()
        if (closeOnSelect) {
          popperRef.current.close()
        }
      }
    }

    const clearOptions = () => {
      if (!busy) {
        let options = filterOptions
        options.map((option) => {
          option.checked = false
          option.bulk = false
          return option
        })

        setFilterOptions(sortOptions(options))
        updateOptions(options)

        popperRef.current.update()
        if (closeOnClear) {
          popperRef.current.close()
        }
      }
    }

    const getLabel = () => {
      const selectedOptions = options.filter(
        (options) => options.checked && !options.hideOnDesktop
      )
      if (selectedOptions.length === 1) {
        return selectedOptions[0].text || selectedOptions[0].name
      } else if (selectedOptions.length === options.length) {
        return placeholder
      } else if (selectedOptions.length > 1) {
        let name = placeholder.toLowerCase().replace(/^.+?(?: )/, "")

        if (name.length) {
          name = name[0].toUpperCase() + name.slice(1)
        }
        return name
      }
      return placeholder
    }

    useEffect(() => {
      setFilterOptions(sortOptions(options))
    }, [options, sortOptions])

    return (
      <div>
        {/* DESKTOP FILTER */}
        <Popper
          className={className}
          ref={popperRef}
          fullWidth={fullWidth}
          onOpen={onOpen}
          openOnMouseOver={openOnMouseOver}
          label={
            Label || (
              <div
                className={`pl-sm
                ${
                  styles.background !== undefined
                    ? styles.background
                    : "bg-white"
                }
                ${
                  styles.border !== undefined
                    ? styles.border
                    : "shadow-border rounded-full"
                }
                whitespace-nowrap
                flex flex-1 items-center
                text-neutral-5 font-medium`}
              >
                <div className="inline-block leading-loose">
                  {getLabel() || placeholder}
                </div>
                {options.filter(
                  (options) => options.checked && !options.hideOnDesktop
                ).length > 1 ? (
                  <div className="bg-primary-4 rounded-full h-md w-md flex items-center justify-center text-sm mx-xs bg-neutral-9 font-semibold my-auto inline mx-icon">
                    {
                      options.filter(
                        (options) => options.checked && !options.hideOnDesktop
                      ).length
                    }
                  </div>
                ) : (
                  <div className="mx-icon h-28 flex items-center">
                    <Icon
                      type="duotone"
                      icon="chevron-circle-down"
                      iconClassName="align-top"
                      className={
                        options.filter(
                          (options) => options.checked && !options.hideOnDesktop
                        ).length > 1
                          ? "hidden"
                          : ""
                      }
                      baseSize="20px"
                    />
                  </div>
                )}
              </div>
            )
          }
          disabled={disabled}
          placement={placement || "bottom"}
          fallbackPlacements={fallbackPlacements || ["top"]}
        >
          <Form>
            <div className="py-sm rounded" style={{ minWidth: "110px" }}>
              {clearable && (
                <div
                  onClick={clearOptions}
                  className="py-xs px-sm text-primary-6 cursor-pointer hover:bg-neutral-10"
                >
                  Clear
                </div>
              )}
              {selectedToTop &&
                filterOptions.filter(
                  (o) => (o.checked || o.bulk) && !o.hideOnDesktop
                ).length > 0 && (
                  <div
                    className={
                      filterOptions.filter(
                        (o) => (o.checked || o.bulk) && !o.hideOnDesktop
                      ).length === filterOptions.length
                        ? ""
                        : "shadow-b-border mb-xs pb-xs"
                    }
                  >
                    {filterOptions
                      .filter((o) => (o.checked || o.bulk) && !o.hideOnDesktop)
                      .map((option, index) => {
                        return (
                          <FilterOption
                            ref={filterRefs}
                            icons={icons}
                            key={`selected-${index}`}
                            option={option}
                            handleClick={handleClick}
                            handleOptionClick={onChange}
                          />
                        )
                      })}
                  </div>
                )}
              {filterOptions &&
                filterOptions
                  .filter(
                    (o) =>
                      (!selectedToTop || (!o.checked && !o.bulk)) &&
                      !o.hideOnDesktop
                  )
                  .map((option, index) => {
                    return (
                      <FilterOption
                        ref={filterRefs}
                        icons={icons}
                        key={`unselected-${index}`}
                        option={option}
                        handleClick={handleClick}
                        handleOptionClick={onChange}
                      />
                    )
                  })}
              {footer}
            </div>
          </Form>
        </Popper>

        {/* MOBILE FILTER */}
        <div className="sm:hidden flex">
          {filterOptions
            .filter((o) => !o.hideOnMobile)
            .map((option, index) => {
              return (
                <Pill
                  key={"option-" + index}
                  active={option.checked}
                  onClick={() => {
                    const updatedOptions = options.map((opt) => {
                      opt.checked =
                        parseInt(opt.id) === parseInt(option.id) ||
                        opt.text === option.id ||
                        opt.text === option.text
                      return opt
                    })
                    const selectedOption = updatedOptions.find((o) => o.checked)
                    handleMobileOption(selectedOption, updatedOptions)
                  }}
                >
                  {option.mobileText || option.text}
                </Pill>
              )
            })}
        </div>
      </div>
    )
  }
)
