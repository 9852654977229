import { constructQueryString } from "../functions"
import { handleTableDataTransform } from "../transformers"

export default [
  {
    target: "createInvoiceBatch",
    payload: {
      url: `/batch`,
      type: "post",
      contextKey: "batch",
      refreshPageData: true,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getBatchDetails",
    payload: {
      url: ({ batchId, members }) =>
        `/batch/${batchId}${constructQueryString({ members })}`,
      type: "get",
    },
  },
  {
    target: "getBatchStats",
    payload: {
      url: ({ batchId, organisationId }) =>
        `/batch/${batchId}/stats?organisationId=${organisationId}`,
      type: "get",
    },
  },
  {
    target: "sendBatchReminders",
    payload: {
      url: ({ batchId, resentInvoices, filters }) =>
        `/batch/${batchId}/remind?resentInvoices=${resentInvoices}`,
      type: "post",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "updateBatch",
    payload: {
      url: ({ batchId }) => `/batch/${batchId}/update`,
      type: "post",
      contextKey: "batch",
      refreshPageData: true,
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "addRecipients",
    payload: {
      url: ({ batchId }) => `/batch/${batchId}/members`,
      type: "post",
      refreshPageData: true,
    },
    onSuccess: {
      notification: {
        title: "Recipients added",
      },
      target: "CLOSE_MODAL",
    },
    onError: {
      notification: {
        title: "Whoops",
        description: "Something went wrong",
      },
    },
  },
  {
    target: "getBatchInvoices",
    payload: {
      url: ({ batchId, filters }) =>
        `/batch/${batchId}/invoices${constructQueryString(filters)}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getUndeliverableBatchMembers",
    payload: {
      url: ({ batchId, filters }) =>
        `/batch/${batchId}/invoices/undeliverable${constructQueryString(
          filters
        )}`,
      type: "get",
      storeData: false,
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "getBatchPayments",
    payload: {
      url: ({ batchId, organisationId, filters }) =>
        `/batch/${batchId}/payments${constructQueryString(
          filters,
          `organisationId=${organisationId}`
        )}`,
      type: "get",
      transformResponse: handleTableDataTransform,
    },
  },
  {
    target: "deleteBatch",
    payload: {
      url: ({ batchId }) => `/batch/${batchId}/delete`,
      type: "post",
      refreshPageData: true,
    },
    onError: {
      notification: (response) => {
        return { title: "Whoops", description: "Something went wrong" }
      },
    },
  },
  {
    target: "updateBatchInvoice",
    payload: {
      url: ({ batchId, invoiceId }) =>
        `/batch/${batchId}/invoice/${invoiceId}/update`,
      type: "post",
      contextKey: "batch",
      refreshPageData: true,
    },
  },
]
