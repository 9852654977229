import React from 'react'
import Input from '../Input'

export default function EditBankDetails ({
  state: { details },
  setState
}) {
  const updateDetails = ({ target: { name, value } }) => {
    setState({
      details: {
        ...details,
        [name]: value
      }
    })
  }

  return (
    <div className="grid grid-cols-1 gap-md pb-md">
      <div>
        If you change your bank details, we'll need to re-verify them before we start sending money to your new account.
      </div>
      <Input
        large
        label="Account name"
        name="accountName"
        value={ details.accountName }
        placeholder="Account name"
        onChange={ updateDetails }
        validation={{
          message: 'Enter an account name',
          rules: 'required'
        }}
      />
      <Input
        large
        label="Sort code"
        name="sortCode"
        value={ details.sortCode }
        mask="99-99-99"
        placeholder="Sort code"
        onChange={ updateDetails }
        validation={{
          messages: {
            required: 'Enter a sort code',
            regex: 'Enter a valid sort code'
          },
          rules: ['required', { regex: /(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)/ }]
        }}
      />
      <Input
        large
        label="Account number"
        name="accountNumber"
        value={ details.accountNumber }
        mask={ [/[*0-9]/, /[*0-9]/, /[*0-9]/, /[*0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ] }
        placeholder="Account number"
        onChange={ updateDetails }
        validation={{
          messages: {
            required: 'Enter an account number',
            min: 'Enter a valid account number'
          },
          rules: 'required|min:8'
        }}
      />
    </div>
  )
}
