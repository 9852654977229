import React, { forwardRef, useImperativeHandle, useState } from "react"

import GroupTag from "../../GroupTag"
import Switch from "../../Input/Switch"
import Table from "../../Table"
import { sortArray } from "../../../utils/functions"
import Icon from "../../Icon"
import ToolTip from "../../Tooltip"

const AddRecipients = forwardRef(
  ({ filteredMembers, selectedMembers, setState, type = "invoice" }, ref) => {
    const [showSelectMemberError, setSelectError] = useState(false)

    useImperativeHandle(ref, () => ({
      validateStep: () => {
        const validStep = !!selectedMembers.length
        setSelectError(true)

        return validStep
      },
    }))

    const selectAll = (members) => {
      setSelectError(false)
      if (members.length) {
        let allCurrentMembers = selectedMembers.concat(filteredMembers)
        allCurrentMembers = Array.from(
          new Set(allCurrentMembers.map((m) => m.id))
        )
          .map((id) => {
            return allCurrentMembers.find((m) => m.id === id)
          })
          .filter((m) => !m.undeliverable || type === "invoice")

        setState({
          members: sortArray(allCurrentMembers, { sortKey: "name" }),
        })
      } else {
        setState({
          members: selectedMembers.filter(
            (m) =>
              !filteredMembers.find((x) => x.id === m.id) &&
              (!m.undeliverable || type === "invoice")
          ),
        })
      }
    }

    const selectMember = (member) => {
      if (!member.undeliverable || type === "invoice") {
        setSelectError(false)
        let allSelectedMembers = selectedMembers.concat([member])
        setState({
          members: sortArray(allSelectedMembers, { sortKey: "name" }),
        })
      }
    }

    const toggleMember = (member) => {
      const index = selectedMembers.findIndex(
        (m) =>
          m.id === member.id && (!member.undeliverable || type === "invoice")
      )

      if (index > -1) {
        setState({
          members: selectedMembers.filter((m) => m.id !== member.id),
        })
      } else {
        selectMember(member)
      }
    }

    const toggleSelectedMembers = ({ target: { checked } }) => {
      setState({
        showSelected: checked,
      })
    }

    const renderName = (row) => {
      return (
        <div className={row.undeliverable ? "text-danger-5" : ""}>
          {row.name}
        </div>
      )
    }

    const renderEmail = (row) => {
      return (
        <div
          className={
            (row.undeliverable ? "text-danger-5" : "") + " flex items-center"
          }
        >
          {row.undeliverable && type === "invoice" && (
            <span className="mr-xs" onClick={(e) => e.stopPropagation()}>
              <ToolTip
                icon="exclamation-circle"
                colours={{
                  primary: "#FFEEEE",
                  secondary: "#BA2525",
                }}
                text={
                  "This member won't be able to receive invoices until their email issue is resolved"
                }
              />
            </span>
          )}
          <span>{row.email}</span>
        </div>
      )
    }

    return (
      <div className="bg-white text-neutral-1 sm:max-h-modal-body max-h-modal-mobile overflow-y-auto">
        <Table
          disableLoadingSpinner
          withShadow={false}
          key="addRecipients"
          id="addRecipients"
          withHoverStyle={false}
          selectable
          unselectableIcon={
            <Icon
              type="duotone"
              icon="exclamation-circle"
              baseSize="20px"
              colours={{
                primary: "#FFEEEE",
                secondary: "#BA2525",
              }}
            />
          }
          headers={[
            { name: "Name", render: (row) => renderName(row) },
            { name: "Email", render: (row) => renderEmail(row) },
            {
              name: "Group",
              right: true,
              render: (row) => <GroupTag groups={row.groups} />,
            },
          ]}
          data={filteredMembers.map((member) => {
            return {
              ...member,
              isSelectable: !member.undeliverable || type === "invoice",
            }
          })}
          selectedRows={selectedMembers}
          onSelectAll={(value) => selectAll(value)}
          onRowSelect={selectMember}
          onRowDeselect={toggleMember}
          onRowClick={toggleMember}
          allSelected={
            filteredMembers.length &&
            selectedMembers.length &&
            filteredMembers.filter(
              (m) => !m.undeliverable || type === "invoice"
            ).length ===
              selectedMembers.filter((m) =>
                filteredMembers.find((x) => x.id === m.id)
              ).length
          }
          noDataText="No members to display"
          sortOrder={{
            sortKey: "name",
          }}
          styles={{
            cell: "py-sm",
          }}
        />

        <div className="sticky bottom-0 bg-white p-sm px-md min-h-md shadow-t-border">
          <div className="grid grid-cols-3">
            <div
              className={`col-span-2 ${
                showSelectMemberError && "text-danger-5"
              } my-auto`}
            >
              <div className="font-medium">
                {selectedMembers.length} selected
              </div>
              {showSelectMemberError && (
                <div className="inline text-sm">
                  Choose at least one recipient
                </div>
              )}
            </div>
            <div className="font-medium text-right">
              <Switch
                id="showAll"
                label="Show"
                onChange={toggleSelectedMembers}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default AddRecipients
