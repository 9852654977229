import React, { useContext, useEffect } from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import { MachineContext } from "../../state"
import { spawnBeacon } from "../../utils/beacon"

export default ({ path, exact = false, component: Component, props = {} }) => {
  const history = useHistory()
  const [{ context }, send] = useContext(MachineContext)

  useEffect(() => {
    if (context.user) {
      spawnBeacon()
    }
  }, [])

  useEffect(() => {
    if (context.forceRedirect) {
      history.push(context.forceRedirect)
      send("UPDATE_CONTEXT", {
        forceRedirect: null,
      })
    }
  }, [context.forceRedirect, history, send])

  if (!context.user) {
    return <Redirect to="/signin" />
  }

  if (!context.user.active) {
    return <Redirect to="/verify" />
  }

  if (context.errorRedirect === 404) {
    return <Redirect to="/404" />
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={(routeProps) => <Component {...routeProps} {...props} />}
    />
  )
}
