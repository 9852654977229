import React from "react"
import moment from "moment"

import { defaults } from "./config"
import { rowsToMembersTransform } from "../transformers"
import PingPreview from "../../components/Modals/Ping/Preview"
import ShareableLinks from "../../components/containers/ShareableLinks"
import { cannotCreatePingConfirmation } from "../confirmations/ping"

const viewPingPayload = (purchase, ownPing) => ({
  title: "View Ping",
  id: "ping-modal",
  state: {
    purchase,
  },
  isAdmin: !ownPing,
  overrideLayout: true,
  modalSize: ownPing ? "md" : "xl",
  componentPath: "Ping",
})

export const viewCreateOrEditPing = (send, context, ping, options = {}) => {
  const variables = {
    organisationId: context.organisation.id,
  }

  let modalState = {
    description: "",
    comments: "",
    amount: "",
    free: false,
    maxQuantity: "",
    unlimited: false,
    limit: "",
    noLimit: false,
    endDate: "",
    noEndDate: false,
    isEditting: false,
    restartingPing: options.restartingPing,
  }

  if (ping) {
    let endDate = ping.endDate
    const momentEndDate = moment(endDate)

    if (momentEndDate.isValid()) {
      if (moment().isAfter(momentEndDate.endOf("day"))) {
        endDate = ""
      } else {
        endDate = momentEndDate.toDate()
      }
    }

    modalState = {
      ...ping,
      endDate,
      free: !ping.amount,
      unlimited: !ping.maxQuantity,
      noLimit: !ping.limit,
      noEndDate: !ping.endDate,
      isEditting: !options.duplicatingPing,
      restartingPing: options.restartingPing,
      duplicatingPing: options.duplicatingPing,
    }
  }

  send("checkForExistingMembers", {
    data: {
      variables,
      onSuccess: {
        callback: ({ response }) => {
          if (response.count) {
            send("OPEN_MODAL", {
              payload: {
                id: "createPing",
                title: "Create Ping",
                state: modalState,
                confirmOnClose: true,
                componentPath: "CreateOrEditPing",
                modalSize: ping ? "md" : "lg",
                overrideLayout: true,
              },
            })
          } else {
            send("CLOSE_MODAL")
            cannotCreatePingConfirmation(send)
          }
        },
      },
    },
  })
}

export const viewPingPreview = (send, props) => {
  send("OPEN_CONFIRMATION", {
    payload: {
      id: "restartPingAfterDelete",
      title: "Restart Ping",
      component: <PingPreview {...props} send={send} />,
    },
  })
}

export const viewPingModal = (pingId, purchaseId, send, ownPing = false) => {
  send("getPingPurchase", {
    data: {
      variables: {
        pingId,
        purchaseId,
      },
      storeData: false,
      onSuccess: {
        callback: ({ response }) => {
          const purchase = {
            ...response,
            purchase: {
              members: response.members.map((purchase) => ({
                ...purchase,
                ...purchase.member,
              })),
            },
          }
          send("OPEN_MODAL", {
            payload: viewPingPayload(purchase, ownPing),
          })
        },
      },
    },
  })
}

export const viewStopPingModal = (send, ping) => {
  send("OPEN_MODAL", {
    payload: {
      ...defaults,
      id: "stopPingModal",
      title: "Stop Ping",
      body: [
        "Stop this Ping to deactivate the shareable link and stop any more responses being recorded. You can restart it later - you may have to pick a new end date if it has passed.",
      ],
      buttons: [
        {
          text: "Stop Ping",
          type: "primary",
          icon: "check-circle",
          colWidth: 7,
          iconType: "duotone",
          submitting: "app.requests.editPing.requesting",
          callback: () => {
            send("editPing", {
              data: {
                variables: {
                  pingId: ping.id,
                },
                body: {
                  comments: ping.comments,
                  description: ping.description,
                  stopped: true,
                },
                onSuccess: {
                  callback: () => {
                    send("CLOSE_MODAL")
                  },
                },
              },
            })
          },
        },
      ],
    },
  })
}

export const viewSendPingToMembersModal = (send, context, ping) => {
  send("getGroupsAndMembers", {
    data: {
      getGroups: {
        variables: {
          organisationId: context.organisation.id,
        },
        storeData: false,
      },
      getMembers: {
        variables: {
          organisationId: context.organisation.id,
        },
        transformResponse: rowsToMembersTransform,
        storeData: false,
      },
      onSuccess: {
        callback: (responses) => {
          let updatedModalData = {
            ping,
          }
          responses.map(({ response }) => {
            updatedModalData = {
              ...updatedModalData,
              ...response,
            }
            return response
          })

          updatedModalData.groups = [
            ...updatedModalData.groups,
            {
              id: 0,
              checked: false,
              text: "No group",
            },
          ]
          send("CLOSE_CONFIRMATION")
          send("OPEN_MODAL", {
            payload: {
              id: "sendingPingsToMember",
              state: updatedModalData,
              componentPath: "SendingPings",
              overrideLayout: true,
              confirmOnClose: true,
              modalSize: "lg",
            },
          })
        },
      },
    },
  })
}

export const viewSharePingLinkModal = (send, context) => {
  const ping = context.stats
  send("OPEN_MODAL", {
    payload: {
      ...defaults,
      id: "sharePingLinkModal",
      title: "Share link",
      overrideDefaultButtons: true,
      body: [
        <div className="mb-md">
          Copy the link below to share with your members.
        </div>,
        <ShareableLinks urls={[ping.url]} />,
        <div className="pt-md -mt-xs">
          You can also email your members directly about this Ping. You can
          choose who receives the email and what it says by clicking the button
          below.
        </div>,
      ],
      buttons: [
        {
          text: "Close",
          colWidth: 4,
          callback: () => send("CLOSE_MODAL"),
        },
        {
          text: "Email members",
          type: "primary",
          icon: "paper-plane",
          colWidth: 7,
          submitting: "app.requests.getGroupsAndMembers.requesting",
          callback: () => {
            viewSendPingToMembersModal(send, context, ping)
          },
        },
      ],
    },
  })
}

export const editMemberCommentOnPing = (send, { purchase }) => {
  send("OPEN_MODAL", {
    payload: {
      ...defaults,
      id: "editMemberComments",
      title: "Edit member comments",
      componentPath: "EditPingComment",
      modalSize: "md",
      state: {
        purchase,
        note: purchase.notes,
      },
      handleClose: () =>
        viewPingModal(purchase.pingId, purchase.id, send, false),
      confirmOnClose: true,
      buttons: [
        {
          changeSensitive: true,
          text: "Save changes",
          type: "primary",
          icon: "sync-alt",
          submitting: "app.requests.updatePurchaseNotes.requesting",
          colWidth: 7,
          callback: (event, validator, checkValidation, state) => {
            checkValidation()
            if (validator.allValid()) {
              send("updatePurchaseNotes", {
                data: {
                  variables: {
                    purchaseId: purchase.id,
                    pingId: purchase.pingId,
                  },
                  body: {
                    notes: state.note,
                  },
                  storeData: false,
                  onSuccess: {
                    notification: {
                      title: "Note updated",
                    },
                    callback: ({ response }) => {
                      const purchase = {
                        ...response,
                        purchase: {
                          members: response.members.map((purchase) => ({
                            ...purchase,
                            ...purchase.member,
                          })),
                        },
                      }

                      send("OPEN_MODAL", {
                        payload: viewPingPayload(purchase, false),
                      })
                    },
                  },
                  onError: {
                    notification: {
                      title: "Whoops",
                      description: "Something went wrong",
                    },
                  },
                },
              })
            }
          },
        },
      ],
    },
  })
}
