import React, { useState, useContext } from 'react'
import CompanyExample from "../../assets/img/stripe_company_example.png"

import Header from '../containers/Header'
import Footer from '../containers/Footer'
import { MachineContext } from '../../state'

export default ({ send, state: { affiliateLink = false } }) => {
  const [redirecting, setRedirecting] = useState(false)
  const [{ context }] = useContext(MachineContext)

  return (
    <div>
      <Header title="Important" />
      <div className="bg-neutral-10 text-neutral-5 p-md">
        <div className="flex items-center flex-col">
          <div className="pb-sm">
            When prompted in Stripe, you must choose <span className="font-medium">Individual / Sole Trader</span>, unless your organisation is registered at Companies House, in which case you can choose <span className="font-medium">Company</span>.
          </div>
          <div className="pb-sm">
            To Stripe, <span className="font-medium">Nonprofit Organization</span> means large, established charities like Comic Relief, Oxfam etc.
          </div>
          <img
            className="max-w-25"
            src={ CompanyExample }
            alt="Please select Individual / Sole Trade during Stripe signup"
          />
        </div>
      </div>
      <Footer
        useValidation={ false }
        buttons={ [
          {
            text: 'Cancel',
            callback: () => send("CLOSE_MODAL")
          },
          {
            text: 'Continue',
            icon: 'arrow-circle-right',
            iconType: 'duotone',
            type: 'primary',
            submitting: redirecting,
            callback: () => {
              setRedirecting(true)
              let variables = {}

              if (!affiliateLink) {
                variables.organisationId = context.organisations.filter(org => org.stripeUrl)[0].id
              }
              send(affiliateLink ? 'getAffiliateStripeLink' : 'stripeSiteVisited', {
                data: {
                  variables,
                  onSuccess: {
                    callback: ({ response }) => {
                      window.location.href = response.stripeUrl
                      setRedirecting(false)
                    }
                  },
                  onError: {
                    callback: () => {
                      setRedirecting(false)
                    }
                  }
                }
              })
            }
          }
        ] }
      />
    </div>
  )
}
