import React from "react"
import SkeletonTable from "../../../components/Skeleton/Table"

const InvoicesSkeleton = () => {
  return (
    <SkeletonTable
      header={{
        width: "w-80",
      }}
      headerCells={[
        {
          colSpan: 1,
          className: "py-base",
          text: {
            colour: "bg-neutral-5",
          },
        },
        {
          colSpan: 1,
          className: "py-base",
          text: {
            colour: "bg-neutral-5",
          },
        },
        {
          colSpan: 5,
          text: {
            width: "w-1/4",
            colour: "bg-neutral-5",
          },
          className: "py-base",
        },
        {
          colSpan: 1,
          className: "py-base",
          text: {
            colour: "bg-neutral-5",
          },
        },
        {
          colSpan: 1,
          className: "py-base",
          text: {
            colour: "bg-neutral-5",
          },
        },
        {
          colSpan: 1,
          className: "py-base",
          text: {
            colour: "bg-neutral-5",
          },
        },
      ]}
      cells={[
        {
          colSpan: 1,
        },
        {
          colSpan: 1,
        },
        {
          colSpan: 5,
          text: {
            width: "w-2/5",
          },
        },
        {
          colSpan: 1,
        },
        {
          colSpan: 1,
        },
        {
          colSpan: 1,
        },
      ]}
    />
  )
}

export default InvoicesSkeleton
