import React, { Suspense, forwardRef } from 'react'

export default forwardRef(({ component : Component, componentProps, body }, ref) => {
  return <div
    className="p-md bg-neutral-10 text-neutral-6 overflow-y-auto sm:max-h-modal-body sm:h-auto h-full"
  >
    <Suspense fallback={<React.Fragment />}>
      {
        Component ?
          <Component {...componentProps} ref={ ref } /> :
          (
            body && Array.isArray(body) ?
              body.map((text, i) => (
                <div key={ i } className="mb-xs last:mb-0">
                  { text }
                </div>
              )) :
              body
          )
      }
    </Suspense>
  </div>
})
