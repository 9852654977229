let Components = {}

Components["AffiliateTutorial"] = require("./AffiliateTutorial").default
Components["AddMembers"] = require("./AddMembers").default
Components["ApplicationJudgement"] = require("./ApplicationJudgement").default
Components["BecomeAffiliate"] = require("./BecomeAffiliate").default
Components["ChangeEmail"] = require("./ChangeEmail").default
Components["ChangePassword"] = require("./ChangePassword").default
Components["CreateClub"] = require("./CreateClub").default
Components["CreateInvoice"] = require("./CreateInvoice").default
Components["CreateOrEditPing"] = require("./CreateOrEditPing").default
Components["DeleteBatch"] = require("./DeleteBatch").default
Components["EditBankDetails"] = require("./EditBankDetails").default
Components["EditBatch"] = require("./EditBatch").default
Components["EditGroup"] = require("./EditGroup").default
Components["EditInvoice"] = require("./EditInvoice").default
Components["EditMember"] = require("./EditMember").default
Components["EditPingComment"] = require("./EditPingComment").default
Components["ImportMembers"] = require("./ImportMembers").default
Components["InviteManager"] = require("./InviteManager").default
Components["OnboardingStartOver"] = require("./OnboardingStartOver").default
Components["Invoice"] = require("./Invoice").default
Components["SendingPings"] = require("./SendingPings").default
Components["RemindersSent"] = require("./RemindersSent").default
Components["Ping"] = require("./Ping").default
Components["SendReminders"] = require("./SendReminders").default
Components["ShareAffiliateLink"] = require("./ShareAffiliateLink").default
Components["UpdateMembers"] = require("./UpdateMembers").default
Components["StripeConnectWarning"] = require("./StripeConnectWarning").default
Components["Tutorial"] = require("./Tutorial").default

export default Components
