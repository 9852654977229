import React from 'react'
import Input from '../Input'

export default function EditGroup ({
  state,
  setState
}) {
  return (
    <Input
      large
      label="Name"
      name="name"
      value={ state.group.name }
      placeholder="Group name"
      onChange={ ({ target }) => setState({ group: { [target.name]: target.value } }) }
      validation={{
        message: 'Enter a group name',
        rules: 'required'
      }}
    />
  )
}
