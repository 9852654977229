import React from "react"

export default () => {
  return (
    <div>
      <div className="pb-xs">
        Payzip bundles your member payments into one transaction to your club.
        You’ll receive an email when this happens, and you can see the detail of
        each payout in Payzip by clicking the{" "}
        <span className="font-medium">Payouts</span> sidebar menu entry.
      </div>
      <div>
        Each week’s payment is listed. To see the individual member payments
        inside each payout, click the row to open that week’s payment.
      </div>
      <div className="pt-md mt-sm">
        More information on payouts is available in our{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://docs.payzip.co.uk/article/82-receiving-payouts-from-payzip"
        >
          knowledgebase
        </a>
        .
      </div>
    </div>
  )
}
