import React from "react"
import Icon from "../../Icon"

const Objective = ({ complete = false, goal, onClick }) => {
  const completeIcon = (
    <Icon
      type="duotone"
      icon="check-circle"
      baseSize="20px"
      className="mr-sm"
      colours={{ primary: "#E3F9E5", secondary: "#31B237" }}
    />
  )
  const toDoIcon = (
    <Icon
      type="duotone"
      icon="question-circle"
      baseSize="20px"
      className="mr-sm"
    />
  )

  return (
    <div onClick={onClick} className="flex items-center">
      {complete ? completeIcon : toDoIcon}
      <div
        className={`text-sm leading-tight cursor-pointer ${
          complete ? "text-neutral-5" : "text-neutral-1"
        }`}
      >
        {goal}
      </div>
    </div>
  )
}

export default Objective
